import React from "react";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import ImageUtil from "../../utilities/ImageUtil";
import { LiaTimesCircle } from "react-icons/lia";

function ImageSelector(props) {
  const { buzzList, setSelectedImage, selectedImage } = props;
  return (
    <div
    style={{ width: "100%", height:"30%" }}
    className="d-flex justify-content-between overflow-hidden pb-3 align-items-center flex-wrap bg-white"
    role="button"
  >
    
    {buzzList.map((item, index) => {
      const camelCaseMonth = `${item.month
        .charAt(0)
        .toUpperCase()}${item.month.slice(1).toLowerCase()}`;
      return (
        <>
          {item.status != 0 ? (
            <div
              className={`mx-2 mt-5 ${selectedImage == index ? "selected-buzz":"bg-white"}`}
              key={index}
              style={{ height: "fit-content", flex: "0 0 calc(33.33% - 20px)" }}
              onClick={() => setSelectedImage(index)}
            >
              <img
                className=" image-top-border feature-image"
                src={item.featureImage}
                alt={item.featureImage}
                style={{ width: "100%", height: "auto" }}
              />
              <div className={`text-center text-bottom-border bg-white`}>
                {camelCaseMonth} {item.year}
              </div>
            </div>
          ) : null}
        </>
      );
    })}
 
  </div>
  
  );
}

export default ImageSelector;
