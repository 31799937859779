import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import MainImage from "../../components/Content/MainImage";
import ImageSelector from "../../components/Content/ImageSelector";
import BuzzService from "../../services/buzz.service";
import FlipPage from "react-flip-page";
import HTMLFlipBook from "react-pageflip";
import { PDFtoIMG } from "react-pdf-to-image";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
// import "../../assets/jquery.min.1.7";
// import "../../assets"
import { useSelector, useDispatch } from "react-redux";
import {
  IoIosArrowDropleft,
  IoIosArrowBack,
  IoIosArrowDropright,
  IoIosArrowForward,
} from "react-icons/io";
import {
  setBuzzList,
  setNewPageBuzzList,
  updateSingleBuzz,
  updateSingleBuzzLikedBy,
  updateSingleBuzzComments,
} from "../../features/buzzSlice";
import icon from "../../assets/Property 1=Default.svg";
import icon1 from "../../assets/Property 1=Variant2.svg";
// Core viewer
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import {
  ExitFullScreenIcon,
  FullScreenIcon,
} from "@react-pdf-viewer/full-screen";
import { fullScreenPlugin } from "@react-pdf-viewer/full-screen";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/full-screen/lib/styles/index.css";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
// Import styles
import { Viewer } from "@react-pdf-viewer/core";

import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
// Plugins
import { useNavigate, NavLink } from "react-router-dom";
import { FaGreaterThan, FaLessThan, FaTimes } from "react-icons/fa";
import { MdSnippetFolder } from "react-icons/md";
import licenceValidator from "../../permission/validate";
import ImageUtil from "../../utilities/ImageUtil";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
// Create new plugin instance
import demofile from "../../../src/assets/june.pdf";
import { pdfjs } from "react-pdf";

// Import styles

import workerSrc from "pdfjs-dist/build/pdf.worker.entry";
import { Col, Row, Toast } from "react-bootstrap";
import {
  LiaSearchPlusSolid,
  LiaSearchMinusSolid,
  LiaTimesCircle,
} from "react-icons/lia";
import {
  BsArrowLeft,
  BsArrowRight,
  BsTrash,
  BsTrash2Fill,
} from "react-icons/bs";

import { AiFillCloseCircle, AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { LuMessageSquare } from "react-icons/lu";
import buzzService from "../../services/buzz.service";
import { IoIosPaperPlane } from "react-icons/io";
import { toast } from "react-hot-toast";
import { GiHamburgerMenu } from "react-icons/gi";
import GradientBorderButton from "../../components/Widgets/GradientBorderButton";
import PdfViewer from "./pdf-viewer";
pdfjs.GlobalWorkerOptions.workerSrc = workerSrc;
function Buzz() {
  const [next, setNext] = useState(0);
  const [previous, setPrevious] = useState(0);

  const [arrowMarginTop, setArrowMarginTop] = useState("");
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const flipPageRef = useRef(null);
  const pdfRef = useRef();
  const {
    CurrentPageInput,
    GoToFirstPageButton,
    GoToLastPageButton,
    GoToNextPageButton,
    GoToPreviousPage,
  } = pageNavigationPluginInstance;
  const fullScreenPluginInstance = fullScreenPlugin();
  const { EnterFullScreenButton } = fullScreenPluginInstance;
  const [extractedImages, setExtractedImages] = useState([]);
  let buzzList = useSelector((state) => state.buzz.buzzList);
  const [numPages, setNumPages] = React.useState(null);
  const [imageUrls, setImageUrls] = useState([]);
  const pdfNextPage = () => {
    // this.pageFlip.getPageFlip().flipNext()
  };
  function exitHandler() {
    if (!document.fullscreenElement) {
      setFullscreen(false);
      setIsCommentsOpen(false);
      document.getElementsByTagName("html")[0].style.overflow = "auto";
    }
  }
  useEffect(() => {
    document.addEventListener("fullscreenchange", exitHandler);

    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        if (document.exitFullscreen) {
          setFullscreen(false);
          document.exitFullscreen();
        }
        // Handle ESC key press here
      }
    };

    // Add event listener when component mounts
    document.addEventListener("keydown", handleEsc);

    // Clean up the event listener when component unmounts
    return () => {
      document.removeEventListener("keydown", handleEsc);
    };
  }, []);

  function toggleFullScreen() {
    if (!document.fullscreenElement) {
      setFullscreen(true);
      setIsCommentsOpen(false);
      document.documentElement.requestFullscreen();
    } else if (document.exitFullscreen) {
      setFullscreen(false);

      document.exitFullscreen();
    }
  }
  function handleNav() {
    currLocation === "buzz"
      ? navigate("/buzz/admin/published")
      : navigate("/buzz");
  }

  const user = useSelector((state) => state.user.user);
  const [currentPage, setCurrentPage] = useState(1);
  const [startPage, setStartPage] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [endPage, setEndPage] = useState(0);
  const [isLiked, setIsLiked] = useState(false);
  const [isCommentsOpen, setIsCommentsOpen] = useState(false);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const visiblePages = 5;
  const dispatch = useDispatch();
  const [selectedImage, setSelectedImage] = useState(0);
  const navigate = useNavigate();
  const [currLocation, setCurrLocation] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [activeFile, setActiveFile] = useState(null);
  const [scale, setScale] = useState(0.8);
  const [likeCount, setLikeCount] = useState(0);
  const [position, setPosition] = useState({ x: 0, y: 0 }); // Initial position
  const [comments, setComments] = useState(["Hello", "Hi there"]);
  const commentBoxRef = useRef(null);
  const [isVisible1, setIsVisible1] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [showZoomButton, setShowZoomButton] = useState(false);
  const [buttonPosition, setButtonPosition] = useState({ top: 0, left: 0 });
  const [pdfFile, setPdfFile] = useState(null);
  const [fullScreen, setFullscreen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showMessage, setShowMessage] = useState(false);
  const [messagePosition, setMessagePosition] = useState({ x: 0, y: 0 });
  const handleMouseEnter = () => {
    setShowMessage(true);
  };

  const handleMouseLeave = () => {
    setShowMessage(false);
  };
  const handleMouseMove = (event) => {
    setMessagePosition({ x: event.clientX, y: event.clientY });
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleNext = () => {
    setNext(next + 1);
    setPageNumber(pageNumber + 2);
  };
  const handlePrevious = () => {
    setPrevious(previous + 1);
    setPageNumber(pageNumber - 2);
  };
  useEffect(() => {
    if (activeFile?.length) {
      setNumPages(activeFile.length);
    }
  }, [activeFile]);

  const fetchPdfFile = async () => {
    try {
      const response = await fetch(activeFile);
      const blob = await response.blob();
      setPdfFile(blob);
    } catch (error) {
      console.error("Error fetching PDF file:", error);
    }
  };
  useEffect(() => {
    // Simulating comment loading
    setTimeout(() => {
      const newComments = [...comments, ...getNewComments()];
      setComments(newComments);
      if (activeFile && isCommentsOpen && isVisible1) {
        // scrollToBottom();
      }
    }, 2000); // Adjust the delay as needed
  }, [comments]);
  const handleComments = () => {
    setIsCommentsOpen(!isCommentsOpen);
  };

  const image = document.getElementById("closeBtn");

  // Store the original and replacement image URLs

  if (image) {
    // Add event listeners for mouseover and mouseout
    image.addEventListener("mouseover", () => {
      // Change the image source to the replacement image
      image.src = icon1;
    });

    image.addEventListener("mouseout", () => {
      // Change the image source back to the original image
      image.src = icon;
    });
  }

  const Page = React.forwardRef((props, ref) => {
    return (
      <div className="page" ref={ref}>
        <div className="page-content">
          <h2 className="page-header">Page header - {props.number}</h2>
          <div className="page-image"></div>
          <div className="page-text">{props.children}</div>
          <div className="page-footer">{props.number + 1}</div>
        </div>
      </div>
    );
  });

  const getNewComments = () => {
    // Simulated function to fetch new comments
    return [
      // Array of new comments
    ];
  };
  const handleContextMenu = (e) => {
    e.preventDefault();

    const { clientX, clientY } = e;

    setShowZoomButton(true);
    setButtonPosition({ top: clientY, left: clientX - 300 });

    const contextMenuButton = document.getElementById("add-skill");
    const body = document.body;
    const clickHandler = (event) => {
      if (event.target !== contextMenuButton) {
        // Hide the context menu button if the click is outside the button
        setShowZoomButton(false);
        // Remove the click event listener
        body.removeEventListener("click", clickHandler);
      }
    };
    body.addEventListener("click", clickHandler);
  };
  const getBuzzList = async (pageNo) => {
    const data = await BuzzService.getBuzzList1(pageNo);
    let tempTotalPages = Math.max(1, Math.ceil(data.totalResults / 3));
    setTotalPages(tempTotalPages);

    let tempStart = 1;
    let tempEnd = Math.min(5, tempTotalPages);

    let tempPageNumbers = Array.from(
      { length: tempEnd - tempStart + 1 },
      (_, index) => tempStart + index
    );

    if (tempPageNumbers[0] !== 0) {
      setPageNumbers(tempPageNumbers);
    }
    dispatch(setBuzzList(data.results));
  };
  useEffect(() => {
    let user = localStorage.getItem("user");
    let validator = new licenceValidator(user, "buzz");

    setIsVisible(validator.isReadable("buzz_admin"));
    let isReadable = validator.isReadable("buzz_admin");
  }, []);
  const handleDragStart = (event) => {
    event.dataTransfer.effectAllowed = "move";
    event.dataTransfer.setData("text/plain", ""); // Required for dragging to work in some browsers
  };

  const handleDrag = (event) => {
    const { clientX, clientY } = event;
    setPosition((prevPosition) => ({
      x: prevPosition.x + clientX,
      y: prevPosition.y + clientY,
    }));
  };

  const getNewPageBuzzList = async (pageNo) => {
    const data = await BuzzService.getBuzzList1(pageNo);
    setCurrentPage(pageNo);

    dispatch(setNewPageBuzzList(data.results));
  };
  useEffect(() => {
    if (isCommentsOpen) {
      document
        .getElementById("comment_box")
        .addEventListener("keydown", function (event) {
          if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault();
          }
        });
    }
  }, [isCommentsOpen]);
  useEffect(() => {
    if (buzzList.length && selectedImage >= 0) {
      setScale(0.8);

      async function setPdf() {
        setPageNumber(1);
        let fileName = `${buzzList[selectedImage]?.month}'${buzzList[selectedImage]?.year}.pdf`;

        // const response = await fetch(
        //   ImageUtil?.transform(buzzList[selectedImage]?.path)
        // );
        // response.arrayBuffer().then(function (buffer) {
        // const url = window.URL.createObjectURL(new Blob([buffer]));
        // const link = document.createElement("a");
        // link.href = url;
        if (buzzList[selectedImage].likedBy.includes(user.id)) setIsLiked(true);
        else setIsLiked(false);
        setLikeCount(buzzList[selectedImage].likedBy.length);

        setActiveFile(buzzList[selectedImage]?.path);

        // link.setAttribute("download", `${fileName}.pdf`); //or any other extension
        // document.body.appendChild(link);
        // link.click();
        // });
        // setActiveFile(fileName)
        // console.log(buzzList[selectedImage]);
      }
      setPdf();
    }
  }, [selectedImage, buzzList]);
  useEffect(() => {
    setIsCommentsOpen(false);
    setIsCollapsed(true);
  }, [selectedImage]);
  const adjustPages = (currentPage) => {
    let tempStart = 1;
    let tempEnd = Math.min(5, totalPages);

    if (currentPage > 3) {
      tempStart = Math.max(1, currentPage - Math.floor(visiblePages / 2));
      tempEnd = Math.min(tempStart + visiblePages - 1, totalPages);
      setStartPage(tempStart);
      setEndPage(tempEnd);
    }
    let tempPageNumbers = Array.from(
      { length: tempEnd - tempStart + 1 },
      (_, index) => tempStart + index
    );

    if (tempPageNumbers[0] !== 0) {
      setPageNumbers(tempPageNumbers);
    }
  };
  // Initial scale value
  const handleLikeDislike = async () => {
    if (!buzzList[selectedImage].likedBy.includes(user.id)) {
      setIsLiked(true);
      const data = await BuzzService.getBuzzList1(1);
      // setCurrentPage(1);
      // dispatch(setNewPageBuzzList(data.results));
      let buzzData = buzzList[selectedImage];

      let likedBy = buzzData.likedBy;
      likedBy = [...likedBy, user.id];
      //  likedBy.push(user.id)
      let buzzId = buzzData.id;
      dispatch(updateSingleBuzzLikedBy({ buzzId, likedBy }));
      buzzService.updateBuzzLikedby(buzzId, likedBy);
    } else {
      const data = await BuzzService.getBuzzList1(1);

      let buzzData = buzzList[selectedImage];

      let likedBy = buzzData.likedBy.filter((like) => like != user.id);
      let buzzId = buzzData.id;
      dispatch(updateSingleBuzzLikedBy({ buzzId, likedBy }));
      buzzService.updateBuzzLikedby(buzzId, likedBy);
    }
  };
  const handleZoomIn = () => {
    setScale((prevScale) => prevScale + 0.2); // Increase the scale by 0.2
  };

  const handleZoomOut = () => {
    setScale((prevScale) => Math.max(prevScale - 0.2, 0.2)); // Decrease the scale by 0.2, with a minimum of 0.2
  };
  const handleAddComment = (e) => {
    if (document.getElementById("comment_box").value != "") {
      let buzzData = buzzList[selectedImage];

      let comments = buzzData.comments;
      let newComment = {
        userId: user.id,
        username: user.firstName + " " + user.lastName,
        comment: document.getElementById("comment_box").value,
        createdDate: new Date(),
      };
      comments = [...comments, newComment];
      //  likedBy.push(user.id)
      let buzzId = buzzData.id;
      dispatch(updateSingleBuzzComments({ buzzId, comments }));
      buzzService.updateBuzzComments(buzzId, comments);
      document.getElementById("comment_box").value = "";
    } else {
      toast.error("Please type something to add a comment");
    }
  };
  const handlePrevPage = () => {
    if (currentPage > 1) {
      getNewPageBuzzList(currentPage - 1);
      setCurrentPage(currentPage - 1);
      adjustPages(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      getNewPageBuzzList(currentPage + 1);
      setCurrentPage(currentPage + 1);
      adjustPages(currentPage + 1);
    }
  };
  useEffect(() => {
    let dashboardElement = document.querySelector(".dashboard");
    let buzzElement = document.querySelector(".buzz");
    const body = document.body;

    if (fullScreen) {
      if (dashboardElement) {
        // Remove the 'transform' property
        dashboardElement.style.transform = "none";

        // Remove the 'transform-origin' property
        // dashboardElement.style.transformOrigin = '';
      }
      windowWidth <= 1200
        ? setArrowMarginTop("0%")
        : windowWidth > 1200 && windowWidth <= 1300
        ? setArrowMarginTop("0%")
        : windowWidth > 1300 && windowWidth <= 1450
        ? setArrowMarginTop("0%")
        : windowWidth > 1450 && windowWidth <= 1600
        ? setArrowMarginTop("0%")
        : windowWidth > 1600 && windowWidth <= 1800
        ? setArrowMarginTop("0%")
        : windowWidth > 1800 && windowWidth <= 1950
        ? setArrowMarginTop("0%")
        : setArrowMarginTop("0%");
    } else {
      body.style.overflow = "hidden";
      buzzElement.style.height = "fit-content";

      windowWidth <= 600
        ? setArrowMarginTop("0%")
        : windowWidth > 600 && windowWidth <= 900
        ? setArrowMarginTop("0%")
        : windowWidth > 900 && windowWidth <= 1200
        ? setArrowMarginTop("0%")
        : windowWidth > 1200 && windowWidth <= 1500
        ? setArrowMarginTop("0%")
        : windowWidth > 1500 && windowWidth <= 1700
        ? setArrowMarginTop("0%")
        : setArrowMarginTop("0%");

      if (dashboardElement) {
      }
    }
  }, [fullScreen]);
  useEffect(() => {
    setCurrLocation("buzz");
    getBuzzList();
    function handleKeyDown(event) {
      if (event.key === "Escape") {
        setFullscreen(false);

        // setFullscreen(false);
        setIsCommentsOpen(false);
      }
    }

    // Add event listener when component mounts
    document.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener when component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  const handleDeleteComment = (comment) => {
    let buzzData = buzzList[selectedImage];
    let buzzId = buzzData.id;
    let comments = buzzData.comments;

    let index = comments.indexOf(comment);
    comments = comments.filter(
      (comment, index1) => comments.indexOf(comment) != index
    );
    dispatch(updateSingleBuzzComments({ buzzId, comments }));
    buzzService.updateBuzzComments(buzzId, comments);
  };
  useEffect(() => {
    setSelectedImage(0);
  }, [currentPage]);
  // buzz search
  useEffect(() => {
    console.log(windowWidth);
  }, [windowWidth]);
  return (
    <div
      className="buzz"
      style={{
        height: fullScreen ? "fit-content !important" : "1350px !important",
      }}
    >
      {fullScreen ? (
        <div
          className="img-popup d-flex top-0 position-absolute "
          style={{ overflow: "hidden" }}
        >
          <div
            style={{
              position: "absolute",
              right: "0px",
              zIndex: "1000000",
              marginRight: "5%",
              marginTop: "5%",
              width: "50px",
              cursor: "pointer",
            }}
          >
            <FaTimes
              style={{ width: "30px", height: "30px" }}
              onClick={() => {
                setFullscreen(false);
                setIsCommentsOpen(false);
                document.getElementsByTagName("html")[0].style.overflow =
                  "auto";
                document.exitFullscreen();
              }}
            />
          </div>
          <div
            className="d-flex align-items-center justify-content-center buzz-pdf-viewer"
            style={{ height: "fit-content" }}
          >
            {activeFile ? (
              <>
                <div
                  draggable
                  className="d-flex justify-content-between pdf-container align-items-center"
                  style={{
                    width: "100%",
                    height: "150%",
                    overflow: "hidden",
                    marginLeft: "60px",
                  }}
                >
                  {pageNumber != 1 ? (
                    <button
                      onClick={handlePrevious}
                      className="arrows"
                      style={{ fontSize: "30px", marginTop: arrowMarginTop }}
                    >
                      <IoIosArrowBack />
                    </button>
                  ) : (
                    <div style={{ width: "42px", padding: "6px" }}></div>
                  )}
                  <PdfViewer
                    height={
                      windowWidth <= 900
                        ? "650px"
                        : windowWidth > 900 && windowWidth <= 1300
                        ? "900px"
                        : windowWidth > 1300 && windowWidth <= 1450
                        ? "670px"
                        : windowWidth > 1450 && windowWidth <= 1600
                        ? "1150px"
                        : windowWidth > 1600 && windowWidth <= 1800
                        ? "1250px"
                        : windowWidth > 1800 && windowWidth <= 1950
                        ? "1500px"
                        : "1450px"
                    }
                    width={
                      windowWidth <= 600
                        ? "480px"
                        : windowWidth > 600 && windowWidth <= 900
                        ? "640px"
                        : windowWidth > 900 && windowWidth <= 1200
                        ? "1050px"
                        : windowWidth > 1200 && windowWidth <= 1500
                        ? "1270"
                        : windowWidth > 1500 && windowWidth <= 1700
                        ? "1450px"
                        : windowWidth > 1700 && windowWidth <= 1950
                        ? "1550px"
                        : "1750px"
                    }
                    setPageNumber={setPageNumber}
                    next={next}
                    previous={previous}
                    file={activeFile}
                  />
                  {(pageNumber == numPages && numPages % 2 == 0) ||
                  (numPages % 2 != 0 &&
                    (pageNumber + 1 == numPages || pageNumber == numPages)) ? (
                    <div style={{ width: "42px", padding: "6px" }}></div>
                  ) : (
                    <button
                      onClick={handleNext}
                      className="arrows"
                      style={{ fontSize: "30px", marginTop: arrowMarginTop }}
                    >
                      <IoIosArrowForward />
                    </button>
                  )}
                </div>

                {isCommentsOpen ? (
                  <div className="comment-container" ref={commentBoxRef}>
                    <div
                      className="comments-header mt-1 pb-2 d-flex justify-content-center align-itens-center"
                      style={{ height: "50px" }}
                      onClick={() => setIsCommentsOpen(false)}
                    >
                      <p className="text-centre m-0">Comments</p>
                      <p
                        style={{
                          float: "right",
                          right: "0",
                          position: "absolute",
                          paddingRight: "20px",
                          cursor: "pointer",
                        }}
                        onClick={() => setIsCommentsOpen(false)}
                      >
                        <FaTimes />
                      </p>
                    </div>
                    <div className="comments">
                      {buzzList[selectedImage].comments?.map(
                        (comment, index) => (
                          <div
                            className="comment d-flex align-items-center"
                            key={index}
                          >
                            <img
                              src="/assets/profile.jpg"
                              alt="Profile"
                              className="mx-2 me-3"
                              height="15"
                            />
                            <div style={{ width: "90%" }}>
                              <p
                                className="p-0 m-0 "
                                style={{ fontWeight: "500" }}
                              >
                                {comment.username}
                              </p>
                              <p className="p-0 m-0 ">{comment.comment}</p>
                            </div>
                            {comment.userId == user.id ? (
                              <BsTrash
                                onClick={() => {
                                  handleDeleteComment(comment);
                                }}
                                className="cursor-pointer"
                                height="30px"
                              />
                            ) : (
                              <></>
                            )}
                          </div>
                        )
                      )}
                    </div>
                    <div className="comment-input">
                      <input
                        type="text"
                        // value={}
                        className="addcomment"
                        placeholder="Add a comment..."
                        id="comment_box"
                        required
                        // onKeyDown={pressEnter}
                      />
                      <button
                        onClick={handleAddComment}
                        className="send-chat-btn"
                      >
                        <IoIosPaperPlane className="icon" />
                      </button>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="buzz-buttons">
            {" "}
            <Col
              xl="6"
              className="d-flex text-center flex-column justify-content-center element"
            >
              <button onClick={handleComments}>
                <LuMessageSquare className="icon" width="50px" height="50px" />
              </button>
              <p className="ms-3" style={{ height: "fit-content" }}>
                {buzzList[selectedImage]?.comments?.length}
              </p>
            </Col>
            <Col
              xl="6"
              className="d-flex text-center flex-column justify-content-center element"
            >
              {isLiked ? (
                <button
                  onClick={handleLikeDislike}
                  className=""
                  style={{ color: "red" }}
                >
                  <AiFillHeart className="icon" width="30px" height="30px" />
                </button>
              ) : (
                <button onClick={() => handleLikeDislike()} className="">
                  <AiOutlineHeart className="icon" width="30px" height="30px" />
                </button>
              )}
              <p className="ms-3" style={{ height: "fit-content" }}>
                {" "}
                {likeCount}
              </p>
            </Col>
          </div>
        </div>
      ) : (
        <></>
      )}
      {!fullScreen ? (
        <div
          className="memories-btn-group d-flex top-0 position-absolute my-2 "
          style={{ right: "35%" }}
        ></div>
      ) : (
        <></>
      )}
      <div className="indicator-container ">
        <div className="d-flex text">
          <MdSnippetFolder className="icon mx-2" /> The Buzz
        </div>
        <>
          <div className="d-flex align-items-center gap-3">
            {currLocation == "buzz" ? (
              <GradientBorderButton
                text={"Full Screen"}
                //  icon={<FiUpload />}

                clickHandler={() => {
                  toggleFullScreen();
                  document.getElementsByTagName("html")[0].style.overflow =
                    "hidden";
                }}
                additionalClass={"panel-toggle-btn"}
              />
            ) : (
              <></>
            )}
            {isVisible ? (
              <GradientBorderButton
                text={currLocation === "buzz" ? "Admin Panel" : "User Panel"}
                //  icon={<FiUpload />}

                clickHandler={() => {
                  handleNav();
                }}
                additionalClass={"panel-toggle-btn"}
                iconAdditionalClass={"icon-styles"}
              />
            ) : null}
          </div>
        </>
      </div>

      {showZoomButton && (
        <div
          id="button-container rounded-pill"
          style={{
            zIndex: "100",
            borderRadius: "15px",
            position: "absolute",
            border: "0.4px black solid",
            background: "white",
            top: buttonPosition.top,
            left: buttonPosition.left,
          }}
        >
          <button
            class="px-2 py-1 border-right-1"
            style={{ borderRight: "1px solid black" }}
            onClick={handleZoomOut}
          >
            {" "}
            <LiaSearchMinusSolid />
          </button>
          <button class="px-2 py-1" onClick={handleZoomIn}>
            {" "}
            <LiaSearchPlusSolid />
          </button>
        </div>
      )}
      {!fullScreen && buzzList && buzzList.length > 0 ? (
        <div className="outer_div mx-auto " style={{ marginTop: "1.5rem" }}>
          <div
            className="buzz_inner_background d-flex justify-content-center w-100 overflow-y-hidden overflow-x-visible"
            style={{ maxHeight: "100%", maxWidth: "100%", width: "100%" }}
          >
            {isCollapsed ? (
              <div
                className="buzz_pdf_view mt-3"
                style={{
                  maxWidth: "100%",
                  // width: "94%",
                  height: "100%",
                  maxHeight: "100%",
                  minWidth: "100%",
                  minHeight: "100%",
                }}
              >
                <div
                  className="d-flex align-items-center justify-content-center buzz-pdf-viewer"
                  style={{ height: activeFile?.length ? "100%" : "1350px" }}
                >
                  {activeFile ? (
                    <>
                      <div
                        style={{
                          height: "fit-content",
                          maxWidth: "100%",
                          width: "100%",
                          background: "transparent",
                        }}
                        className=" overflow-y-hidden overflow-x-visible  rounded  align-items-center justify-content-center"
                      >
                        <div
                          draggable
                          onDragStart={handleDragStart}
                          onDrag={handleDrag}
                          className="scrollable-div gy-0 rounded-1 "
                          // onContextMenu={handleContextMenu}
                          style={{
                            top: "0",
                            marginTop: isCollapsed ? "0" : "60px",
                            background: "transparent",
                            overflowY: "hidden",
                            display: "flex",
                            justifyContent: "center",
                            maxWidth: "100%",
                            width: "100%",
                            maxHeight: "100%",
                            height: "50%",
                            minHeight: "50%",
                            overflowX: "visible",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {isCollapsed ? (
                            <div
                              className="d-flex justify-content-center "
                              style={{
                                width: "90%",
                                minHeight: "50%",
                                height: "fit-content",
                                maxHeight: "fit-content",
                                overflow: "hidden",
                                marginTop: "2%",
                                alignItems: "center",
                              }}
                            >
                              <div
                                className="mx-3 mb-4"
                                style={{
                                  height: "70%",
                                  width: "100%",
                                  cursor: "pointer",
                                }}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                                onMouseMove={handleMouseMove}
                                onClick={() => {
                                  setFullscreen(true);
                                  setIsCommentsOpen(false);
                                  document.documentElement.requestFullscreen();
                                }}
                              >
                                <img
                                  className=" image-top-border feature-image"
                                  style={{
                                    height: "70%",
                                    width: "100%",
                                    cursor: "pointer",
                                  }}
                                  onMouseEnter={handleMouseEnter}
                                  onMouseLeave={handleMouseLeave}
                                  src={buzzList[selectedImage].featureImage}
                                  alt={buzzList[selectedImage].featureImage}
                                />
                                <div className="text-center text-bottom-border ">
                                  {`${buzzList[selectedImage].month
                                    .charAt(0)
                                    .toUpperCase()}${buzzList[
                                    selectedImage
                                  ].month
                                    .slice(1)
                                    .toLowerCase()}`}{" "}
                                  {buzzList[selectedImage].year}
                                </div>
                              </div>
                              {showMessage && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: messagePosition.y,
                                    left: messagePosition.x - 250,
                                    backgroundColor: "black",
                                    color: "white",
                                    padding: "5px",
                                    zIndex: 9999,
                                  }}
                                >
                                  Click to view
                                </div>
                              )}
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                        <ImageSelector
                          buzzList={buzzList}
                          setSelectedImage={setSelectedImage}
                          selectedImage={selectedImage}
                          className=""
                        />
                        {buzzList.length < 1 ? null : (
                          <div
                            className="pagination pb-3"
                            // style={{ width: "fit-content" }}
                          >
                            <button
                              onClick={handlePrevPage}
                              disabled={currentPage === 1}
                              className={currentPage === 1 ? "active" : ""}
                            >
                              <FaLessThan
                                className="arrow"
                                style={{
                                  marginBottom: "4px",
                                  marginRight: "2px",
                                  padding: "1px",
                                }}
                              />
                            </button>
                            {pageNumbers.map((page) => (
                              <button
                                key={page}
                                className={currentPage === page ? "active" : ""}
                                onClick={() => {
                                  getNewPageBuzzList(page);
                                  adjustPages(page);
                                }}
                              >
                                {page}
                              </button>
                            ))}
                            <button
                              onClick={handleNextPage}
                              disabled={currentPage === totalPages}
                              className={
                                currentPage === totalPages ? "active" : ""
                              }
                            >
                              <FaGreaterThan
                                className="arrow"
                                style={{
                                  marginBottom: "4px",
                                  marginLeft: "2px",
                                  padding: "1px",
                                }}
                              />
                            </button>
                          </div>
                        )}
                      </div>{" "}
                      {isCommentsOpen ? (
                        <div
                          className="comment-container-1"
                          style={{ margin: "0 auto" }}
                          ref={commentBoxRef}
                        >
                          <div
                            className="comments-header mt-1 pb-2"
                            onClick={() => setIsCommentsOpen(false)}
                          >
                            <p className="text-centre">Comments</p>
                          </div>
                          <div className="comments">
                            {buzzList[selectedImage].comments?.map(
                              (comment, index) => (
                                <div
                                  className="comment d-flex align-items-center"
                                  key={index}
                                >
                                  <img
                                    src="/assets/profile.jpg"
                                    alt="Profile"
                                    className="mx-2 me-3"
                                    height="15"
                                  />
                                  <div style={{ width: "90%" }}>
                                    <p
                                      className="p-0 m-0 "
                                      style={{ fontWeight: "500" }}
                                    >
                                      {comment.username}
                                    </p>
                                    <p className="p-0 m-0 ">
                                      {comment.comment}
                                    </p>
                                  </div>
                                  {comment.userId == user.id ? (
                                    <BsTrash
                                      onClick={() => {
                                        handleDeleteComment(comment);
                                      }}
                                      className="cursor-pointer"
                                      height="30px"
                                    />
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              )
                            )}
                          </div>
                          <div className="comment-input">
                            <input
                              type="text"
                              // value={}
                              className="addcomment"
                              placeholder="Add a comment..."
                              id="comment_box"
                              required
                              // onKeyDown={pressEnter}
                            />
                            <button
                              onClick={handleAddComment}
                              className="send-chat-btn"
                            >
                              <IoIosPaperPlane className="icon" />
                            </button>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}

            {!isCollapsed ? (
              <div
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  borderRadius: "0",
                  transitionDuration: "1.25s",
                  transition: "width 1s",
                }}
                className="buzz_inner_div mt-3 "
              >
                <img
                  id="closeBtn"
                  src={icon}
                  width="25px"
                  onClick={() => {
                    setIsCollapsed(true);
                    setScale(1);
                  }}
                  style={
                    !isCollapsed
                      ? { fontSize: "30px", textAlign: "left", fill: "#EFF5F3" }
                      : { fontSize: "30px", textAlign: "left", fill: "#ff914e" }
                  }
                  className="position-relative cursor-pointer align-self-left m-4 "
                />

                <div
                  className="upper-section d-flex align-items-center justify-content-between"
                  style={{ width: "90%" }}
                >
                  {/* <img
                    id="closeBtn"
                    src={icon}
                    width="25px"
                    onClick={() => {
                      setIsCollapsed(true);
                      setScale(1);
                    }}
                    style={
                      !isCollapsed
                        ? {
                            fontSize: "30px",
                            textAlign: "left",
                            fill: "#EFF5F3",
                          }
                        : {
                            fontSize: "30px",
                            textAlign: "left",
                            fill: "#ff914e",
                          }
                    }
                    className="position-relative cursor-pointer align-self-left m-4 "
                  />
                  <input
                    type="month"
                    className="buzz-search"
                    onChange={handleBuzzSearch}
                  /> */}
                </div>
              </div>
            ) : (
              <></>
              // <div className=" bg-white  collapsePdf  text-center">
              //   <IoIosArrowDropleft
              //     className="cursor-pointer"
              //     style={{ fontSize: "30px", fill: "#175572" }}
              //     onClick={() => {
              //       setIsCollapsed(false);
              //       setScale(1);
              //     }}
              //   />
              // </div>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Buzz;
