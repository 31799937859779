import React, { useCallback, useEffect, useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { FaTrashAlt } from "react-icons/fa";
import { MdSnippetFolder } from "react-icons/md";
import ClanIcon from "../../assets/clan-icon.svg";
import "react-tooltip/dist/react-tooltip.css";
import axios from "../../config/axios";
import clanMasterService from "../../services/clanMaster.service";
import { GiTrumpetFlag } from "react-icons/gi";
import GradientBorderButton from "../Widgets/GradientBorderButton";

function ClanScoreAdmin() {
  const monthsArray = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const monthOrder = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
  };

  const [isVisible, setIsVisible] = useState(false);
  const [table, setTable] = useState([]);
  const [errorNotific, setErrorNotific] = useState("");
  const [successNotific, setSuccessNotific] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const clanRef = useRef();
  const scoreRef = useRef();
  const dateRef = useRef(new Date().getMonth());

  const fetchData = async (teamName) => {
    try {
      const response = await clanMasterService.getClanPoints(teamName);
      const data = response.results;

      data.sort((a, b) => {
        const yearDiff = a.year - b.year;
        if (yearDiff !== 0) {
          return yearDiff; // Sort by year first
        }

        const monthA = monthOrder[a.month];
        const monthB = monthOrder[b.month];

        if (monthA < monthB) {
          return -1;
        }

        if (monthA > monthB) {
          return 1;
        }

        return 0;
      });

      setTable(data);
    } catch (error) {
      console.log("Something went wrong.");
    }
  };

  useEffect(() => {
    fetchData("Gladiators");
  }, []);

  const handleInputChange = () => {
    fetchData(clanRef.current.value);
  };

  const handleDelete = async (id) => {
    const shouldDelete = window.confirm(
      "Are you sure you want to delete this item?"
    );

    if (shouldDelete) {
      try {
        await clanMasterService.deleteClanPoint(id);

        setErrorNotific("");
        setSuccessNotific("Points deleted successfully!");
      } catch (error) {
        setErrorNotific("Points delete operation failed!");
        setSuccessNotific("");
      }

      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);

      fetchData(clanRef.current.value);
    }
  };

  const setDateRef = useCallback((node) => {
    dateRef.current = node;
  }, []);

  const checkIsPointsAlreadyAdded = () => {
    const inputDate = dateRef.current.value;
    const monthIndex = parseInt(inputDate.split("-")[1]) - 1;
    const year = parseInt(inputDate.split("-")[0]);
    const month = monthsArray[monthIndex];

    for (let item of table) {
      if (item.year === year && item.month === month) {
        return { score: item.score, id: item.id };
      }
    }

    return { score: 0, id: "" };
  };

  const handleUpdate = async (e, data) => {
    e.preventDefault();

    try {
      const totalScore =
        parseInt(scoreRef.current.value);

      await clanMasterService.updateClanPoints(data.id, totalScore);

      setErrorNotific("");
      setSuccessNotific("Points updated successfully!");
    } catch (error) {
      setErrorNotific("Points update operation failed!");
      setSuccessNotific("");
    }

    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
    scoreRef.current.value = ""
    dateRef.current.value = null
    fetchData(clanRef.current.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsVisible(true);

    const inputMonth = dateRef.current.value;

    // Convert input value (yyyy-mm) to month index
    const monthIndex = parseInt(inputMonth.split("-")[1]) - 1;

    const alreadyAddedScore = checkIsPointsAlreadyAdded();
    if (alreadyAddedScore.score > 0) {
      handleUpdate(e, alreadyAddedScore);
    } else {
      try {
        const res = await axios.post("/clanScoreMaster/createClanScoreMaster", {
          teamName: clanRef.current.value,
          score: scoreRef.current.value,
          month: monthsArray[monthIndex],
          year: parseInt(inputMonth.split("-")[0]),
        });
        scoreRef.current.value = ""
        dateRef.current.value = null
        setErrorNotific("");
        setSuccessNotific("Points added successfully!");
      } catch (err) {
        setErrorNotific("Points submition failed!");
        setSuccessNotific("");
      }
    }

    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);

    fetchData(clanRef.current.value);
  };

  return (
    <div className="clan-score-admin">
      <div className="indicator-container py-2 ">
        <div className="d-flex text">
          <img src={ClanIcon} height="25px" className="me-2" /> Clan Score
          Master
        </div>
      </div>

      <div className="clan-score-admin-outer">
        {showAlert && (
          <div className="alert-container">
            <div className="upper-section">
              <p>ALOIS</p>

              <span
                className="delete-btn"
                onClick={() => setShowAlert(!showAlert)}
              >
                <AiOutlineClose className="icon" />
              </span>
            </div>

            <hr />

            <div className="lower-section py-2">
              <p className="text-danger">{errorNotific}</p>
              <p className="text-success">{successNotific}</p>
            </div>
          </div>
        )}

        <div className="clan-score-admin-inner">
          <form>
            <div className="row mt-2">
              <div className="col">
                <div className="form-outline">
                  <label className="form-label" htmlFor="clan-name">
                    Clan:
                  </label>

                  <select
                    ref={clanRef}
                    id="clan-name"
                    className="form-select"
                    onChange={handleInputChange}
                    required
                  >
                    <option value="Administration" disabled className="p-5">
                      Select an option
                    </option>
                    <option value="Gladiators">Gladiators</option>
                    <option value="Knights">Knights</option>
                    <option value="Samurai">Samurai</option>
                    <option value="Immortals">Immortals</option>
                  </select>

                  <div className="arrow-down"></div>
                </div>
              </div>

              <div className="col">
                <div className="form-outline">
                  <label className="form-label" htmlFor="clan-score">
                    Score:
                  </label>

                  <input
                    type="number"
                    ref={scoreRef}
                    id="clan-score"
                    className="form-control"
                    required
                  />
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col">
                <div className="form-outline">
                  <label className="form-label" htmlFor="clan-date">
                    Date:
                  </label>

                  <input
                    type="month"
                    ref={setDateRef}
                    id="clan-date"
                    className="form-control"
                    required
                  />
                </div>
              </div>
              <div className="col"></div>
            </div>

            <div className="row d-flex justify-content-center mt-4 mb-2">
              <div className="col-xl-12 d-flex justify-content-center">
                <div className="form-outline">
                  <GradientBorderButton
                    text="Submit"
                    clickHandler={handleSubmit}
                  />
                </div>
              </div>
            </div>
          </form>

          <hr />

          <div className="clan-points-table">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col" className="clan-cell">
                    Clan
                  </th>
                  <th scope="col">Month</th>
                  <th scope="col">Year</th>
                  <th scope="col">Score</th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                </tr>
              </thead>

              <tbody>
                {table.sort((a, b) => {return b.year - a.year}).map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="clan-cell-col">{item.teamName}</td>
                      <td role="button">{item.month}</td>
                      <td>{item.year}</td>
                      <td>{item.score}</td>
                      <td role="button">
                        <FaTrashAlt
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Delete"
                          className="delete-icon"
                          onClick={() => handleDelete(item.id)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClanScoreAdmin;
