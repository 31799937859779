import React, { useEffect, useState } from "react";
import Button from "../../components/Widgets/Button";
import { NavLink, Route, Routes } from "react-router-dom";
import UserRegistrationContent from "../../components/UserRegistration/UserRegistrationContent";
import { useDispatch } from "react-redux";

import { FaUsersCog } from "react-icons/fa";

function UserRegistration() {
  const [activeLink, setActiveLink] = useState("");
  const dispatch = useDispatch();

  const changeActiveLink = () => {
    const pathname = window.location.pathname;
    if (pathname.includes("single-user")) {
      setActiveLink("single-user");
    } else if (pathname.includes("bulk-user")) {
      setActiveLink("bulk-user");
    } else if (pathname.includes("manage-user")) {
      setActiveLink("manage-user");
    } else {
      setActiveLink("single-user");
    }
  };
  useEffect(() => {
    changeActiveLink();
  }, [window.location.pathname]);
  return (
    <div className="d-flex user-registration-container flex-column">
        <div className="indicator-container ">
          <div className="d-flex text">
          <FaUsersCog className="icon mx-2" /> User Management
        </div>
          </div>
      <div className="d-flex justify-content-center button-list"></div>
      <div
        className="user-registration-outer mx-auto"
        style={{ marginTop: "1rem" }}
      >
        <div className="user-registration-inner mt-3">
        
          {activeLink === "single-user" && (
            <NavLink to={"/admin/user-registration/single-user"}>
              {/* <Button
            text={"Single User"}
            // bgColor="#B5D3D1"
          /> */}
            </NavLink>
          )}
          {activeLink === "bulk-user" && (
            <NavLink to={"/admin/user-registration/bulk-user"}>
              {/* <Button
            text={`Bulk User`}
            // bgColor="linear-gradient(90deg, #B3CFCE 0%, #A7B2B6 100%)"
            // className="user-regbtn"
          /> */}
              <span>Bulk User</span>
            </NavLink>
          )}
          {activeLink === "manage-user" && (
            <NavLink to={"/admin/user-registration/manage-user"}>
              {/* <Button
            text={"Manage User"}
            // bgColor="linear-gradient(90deg, #AEAFAD 0%, #FF914E 100%)"
          /> */}
              {/* <span>Manage User</span> */}
            </NavLink>
          )}
          <UserRegistrationContent />
        </div>
      </div>
    </div>
  );
}

export default UserRegistration;

//   const [activeLink, setActiveLink] = useState("");
//   const dispatch = useDispatch();

//   const changeActiveLink = () => {
//     const pathname = window.location.pathname;
//     if (pathname.includes("published")) {
//       dispatch(filterBuzzList("published"));
//       setActiveLink("published");
//     } else if (pathname.includes("draft")) {
//       dispatch(filterBuzzList("draft"));
//       setActiveLink("draft");
//     } else if (pathname.includes("trash")) {
//       dispatch(filterBuzzList("trash"));
//       setActiveLink("trash");
//     } else {
//       dispatch(filterBuzzList(""));
//       setActiveLink("");
//     }
//   };

//   useEffect(() => {
//     changeActiveLink();
//   }, [window.location.pathname]);

{
  /* <div className="d-flex buzz flex-column">
      <div className="d-flex justify-content-center button-list">
        <NavLink to={"/admin/single-user"}>
          <Button
            text={"Single User Management"}
            bgColor={activeLink === "" ? "#B5D3D1" : "#fff"}
          />
        </NavLink>
        <NavLink to={"/admin/multiple-user"}>
          <Button
            text={"Multiple User"}
            bgColor={
                activeLink === "multiple-user"
                  ? "linear-gradient(90deg, #AEAFAD 0%, #FF914E 100%)"
                  : "#fff"
              }
          />
        </NavLink>
       <NavLink to={"/buzz/admin/draft"}>
        <NavLink to={"/admin/manage-user"}>
          <Button
            text={"Manage User"}
            bgColor={
                activeLink === "manage-user"
                  ? "linear-gradient(90deg, #FF914E 0%, #FF914E 100%)"
                  : "#fff"
              }
          />
        </NavLink>
        
      </div>
      <div className="outer_div mx-auto h-75">
        <div className="buzz_inner_div mt-3">
          <UserRegistrationContent />
        </div>
      </div>
    </div> */
}
