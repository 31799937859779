import React, { useEffect, useRef, useState } from "react";
import FormData from "form-data";
import {
  AiOutlineClose,
  AiOutlineCloseCircle,
  AiOutlineDelete,
} from "react-icons/ai";
import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaAngleLeft,
  FaAngleRight,
} from "react-icons/fa";
import { FiDownload, FiUpload, FiSearch } from "react-icons/fi";
import { RiArrowGoBackFill } from "react-icons/ri";
import { TbBookUpload } from "react-icons/tb";
import GradientBorderButton from "../../components/Widgets/GradientBorderButton";
import licenceValidator from "../../permission/validate";

// import anniversaryTemplateBankService from "../../services/birthdayTemplateBank.service";
import anniversaryTemplateBankService from "../../services/anniversaryTemplateBank.service";
import masterDBService from "../../services/masterDB.service";

const AnniversaryTemplateBank = () => {
  const visiblePages = 5;
  const templatesPerPage = 9;
  const logsPerPage = 10;

  const [isVisible, setIsVisible] = useState(false);
  const [isdeletable, setIsDeletable] = useState(false);
  const [Images, setImages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [successNotific, setSuccessNotific] = useState("");
  const [errorNotific, setErrorNotific] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showUploadPopup, setShowUploadPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showPreviewPopup, setShowPreviewPopup] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentTemplateIndex, setCurrentTemplateIndex] = useState(0);
  const [templateImage, setTemplateImage] = useState(null);
  const [templateId, setTemplateId] = useState(null);
  const [templatePath, setTemplatePath] = useState(null);
  const [showActivityLogs, setShowActivityLogs] = useState(false);
  const [activityLogs, setActivityLogs] = useState([]);
  const [filteredActivityLogs, setFilteredActivityLogs] = useState([]);
  const [initialFilteredActivityLogs, setInitialFilteredActivityLogs] =
    useState([]);
  const [defaultCoordinates, setDefaultCoordinates] = useState({});
  const [shiftTimings, setShiftTimings] = useState([]);
  const [departments, setDepartments] = useState([]);
  const imgRef = useRef(null);
  const pptRef = useRef(null);
  const placeholderXRef = useRef(null);
  const placeholderYRef = useRef(null);
  const nameXRef = useRef(null);
  const nameYRef = useRef(null);
  const fontSizeRef = useRef(null);
  const tableRef = useRef(null);
  const statusRef = useRef(null);
  const employeeRef = useRef(null);
  useEffect(() => {
   
    async function getMasterDB() {
      const result = await masterDBService.getAllDepartments();
      setDepartments(result.results);
      const result1 = await masterDBService.getAllShiftTimings();
      setShiftTimings(result1.results);
      
    }
    getMasterDB();
  }, []);
  const call = async (pageNo) => {
    const response = await anniversaryTemplateBankService.getTemplates(
      pageNo,
      templatesPerPage
    );
    const results = response.data.results;
    setImages(results);

    const tempTotalPages = Math.max(
      1,
      Math.ceil(response.data.totalResults / templatesPerPage)
    );
    setTotalPages(tempTotalPages);

    const tempPageArr = [];
    for (let i = 1; i <= tempTotalPages; i++) {
      tempPageArr.push(i);
    }
    tempPageArr.length <= 5 && setPageNumbers(tempPageArr);
  };

  const adjustPages = (currentPage, tempTotalPages) => {
    let finalTotalPages = tempTotalPages || totalPages;
    let tempStart = 1;
    let tempEnd = Math.min(5, finalTotalPages);

    if (finalTotalPages > 5) {
      if (currentPage > 3 && currentPage < finalTotalPages - 1) {
        tempStart = Math.max(1, currentPage - Math.floor(visiblePages / 2));
        tempEnd = Math.min(tempStart + visiblePages - 1, finalTotalPages);
      } else if (currentPage >= finalTotalPages - 1) {
        // If it is the last or last second page, then show the last 5 page numbers
        tempStart = Math.max(1, finalTotalPages - visiblePages + 1);
        tempEnd = finalTotalPages;
      }
    }

    let tempPageNumbers = Array.from(
      { length: tempEnd - tempStart + 1 },
      (_, index) => tempStart + index
    );

    if (tempPageNumbers[0] !== 0) {
      setPageNumbers(tempPageNumbers);
    }
  };

  const handleActivityLogsPage = () => {
    setShowActivityLogs(true);
    getAllActivityLogs();
    setCurrentPage(1);
  };

  const handleBack = () => {
    setShowActivityLogs(false);
    call(1);
    adjustPages();
    setCurrentPage(1);
  };

  const getAllActivityLogs = async () => {
    try {
      const response =
        await anniversaryTemplateBankService.getAllActivityLogs();
      setActivityLogs(response.data);
      setFilteredActivityLogs(response.data);
      setInitialFilteredActivityLogs(response.data);

      const tempTotalPages = Math.max(
        1,
        Math.ceil(response.data.length / logsPerPage)
      );
      setTotalPages(tempTotalPages);

      const tempPageArr = [];
      for (let i = 1; i <= tempTotalPages; i++) {
        tempPageArr.push(i);
      }
      tempPageArr.length <= 5 && setPageNumbers(tempPageArr);

      adjustPages(1, tempTotalPages);
      paginate(1, response.data);
    } catch (error) {
      console.error("Error fetching activity logs:", error);
    }
  };

  const formatDate = (inputDate, includeTime) => {
    if (inputDate) {
      const date = new Date(inputDate);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");

      let formattedDate = `${day}/${month}/${year}`;

      if (includeTime) {
        let hours = date.getHours();
        const ampm = hours >= 12 ? "PM" : "AM";
        hours = hours % 12;
        hours = hours ? hours : 12; // 0 should be displayed as 12
        const minutes = String(date.getMinutes()).padStart(2, "0");
        formattedDate += `, ${hours}:${minutes} ${ampm}`;
      }

      return formattedDate;
    } else {
      return "-";
    }
  };

  const handleSearch = () => {
    const searchTerm = employeeRef.current.value.trim().toLowerCase();
    const updatedActivityLogs = initialFilteredActivityLogs.filter((item) =>
      item.name.toLowerCase().includes(searchTerm)
    );
    setFilteredActivityLogs(updatedActivityLogs);

    const tempTotalPages = Math.ceil(updatedActivityLogs.length / logsPerPage);
    setTotalPages(tempTotalPages);
    adjustPages(1, tempTotalPages);
    paginate(1, updatedActivityLogs);
    setCurrentPage(1);
  };

  const handleStatusChange = () => {
    employeeRef.current.value = "";
    let updatedActivityLogs = filteredActivityLogs;

    if (statusRef.current?.value && statusRef.current?.value === "default") {
      updatedActivityLogs = activityLogs;
    } else {
      updatedActivityLogs = activityLogs.filter((item) => {
        if (item.activityLogs && item.activityLogs.status) {
          const itemStatus = item.activityLogs.status.toLowerCase();
          const filterStatus = statusRef.current.value.toLowerCase();
          return itemStatus === filterStatus;
        }
      });
    }

    setFilteredActivityLogs(updatedActivityLogs);
    setInitialFilteredActivityLogs(updatedActivityLogs);

    const tempTotalPages = Math.ceil(updatedActivityLogs.length / logsPerPage);
    setTotalPages(tempTotalPages);
    adjustPages(1, tempTotalPages);
    paginate(1, updatedActivityLogs);
    setCurrentPage(1);
  };

  const getNewTemplateList = async (pageNo) => {
    const response = await anniversaryTemplateBankService.getTemplates(
      pageNo,
      templatesPerPage
    );
    const results = response.data.results;
    setImages(results);
    setCurrentPage(pageNo);
  };

  const handlDownloadPPT = async () => {
    try {
      setShowAlert(false);

      const response = await anniversaryTemplateBankService.getPPT();
      const pptLink = response?.data?.results?.[0]?.filePath;

      if (response?.data?.results.length) {
        // Create a temporary anchor element
        const link = document.createElement("a");
        link.href = pptLink;
        link.download = "birthday_templates.ppt";
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Remove the temporary anchor element
        document.body.removeChild(link);
      } else {
        setErrorNotific("There is no PPT to download.");
        setSuccessNotific("");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      }
    } catch (error) {
      setErrorNotific("PPT Download Failed.");
      setSuccessNotific("");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  };

  const handleUploadTemplate = () => {
    setShowAlert(false);
    setTemplateImage("");
    setShowUploadPopup(true);
  };

  // Attribute accept=".png, .jpeg, .jpg" is also accepting .csv, .mp4, .pdf, etc.
  // So, we need to have this below function.
  // Additionaly, on template upload/on change, we are storing it in the db.
  const handleTemplateChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (allowedTypes.includes(file.type)) {
        const reader = new FileReader();
        reader.onload = (event) => {
          setTemplateImage(event.target.result);
        };
        reader.readAsDataURL(file);

        try {
          const formData = new FormData();
          formData.append("template_image", imgRef.current.files[0]);

          const response = await anniversaryTemplateBankService.uploadTemplate(
            formData
          );
          
          // setDefaultCoordinates(
          //   response.data.coordinates ? response.data.coordinates : ""
          // );
          setDefaultCoordinates(response.data.coordinates);
          console.log("RESPONSE-----", response.data.coordinates);
          
          setTemplateId(response.data._id);
          setTemplatePath(response.data.filePath);
          if (response.data.coordinates) {
            const payload = {
              templateId: response.data._id,
              templatePath: response.data.filePath,
              coordinates: response.data.coordinates,
            };

            // console.log("payload",payload);
            try {
              const response =
                await anniversaryTemplateBankService.generatePreview(payload);
              setTemplateImage(response.data.url);
            } catch (error) {
              setErrorNotific("Generate Preview Failed.");
              setSuccessNotific("");
              setShowAlert(true);
              setTimeout(() => {
                setShowAlert(false);
              }, 3000);
            }
          }
        } catch (error) {
          setErrorNotific("Upload Failed.");
          setSuccessNotific("");
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
          }, 3000);
        }
      } else {
        setShowAlert(true);
        setErrorNotific("Please select a valid image file (JPEG, JPG, PNG).");
        setSuccessNotific("");
        setTimeout(() => {
          setShowAlert(false);
          setErrorNotific("");
        }, 3000);

        e.target.value = "";
      }
    }
  };

  const handlePPTChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const allowedTypes = [
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "application/mspowerpoint",
        "ppt",
        "pptx",
      ];

      if (
        allowedTypes.includes(file.type) &&
        allowedTypes.includes(file.name.split(".").pop())
      ) {
        try {
          const formData = new FormData();
          formData.append("template_ppt", pptRef.current.files[0]);
          formData.append("templateId", templateId);

          await anniversaryTemplateBankService.uploadTemplate(formData);
        } catch (error) {
          setErrorNotific("Upload Failed.");
          setSuccessNotific("");
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
          }, 3000);
        }
      } else {
        setShowAlert(true);
        setErrorNotific("Please select a valid PowerPoint file (PPT, PPTX).");
        setSuccessNotific("");
        setTimeout(() => {
          setShowAlert(false);
          setErrorNotific("");
        }, 3000);

        e.target.value = "";
      }
    }
  };

  const handlePreviewTemplate = (data, index) => {
    setShowAlert(false);
    setShowPreviewPopup(true);
    setSelectedTemplate(data);
    setCurrentTemplateIndex(index);
  };

  const handleSelectTemplate = async (item) => {
    setShowAlert(false);

    const isItemSelected = selectedRows.some(
      (selectedItem) => selectedItem.id === item.id
    );

    const updatedSelectedRows = isItemSelected
      ? selectedRows.filter((selectedItem) => selectedItem.id !== item.id)
      : [...selectedRows, item];

    setSelectedRows(updatedSelectedRows);
  };

  const handleDelete = async () => {
    if (selectedRows.length) {
      setShowDeletePopup(true);
    } else {
      setErrorNotific("No Template is selecetd to delete.");
      setSuccessNotific("");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  };

  const handleDeleteTemplate = async (data) => {
    try {
      console.log(data);
      
      let idsArray;
      if (Array.isArray(data)) {
        idsArray = data.map((item) => item.id);
        console.log(idsArray);
      } else {
        idsArray = [data.id];
      }

      await anniversaryTemplateBankService.deleteTemplate(idsArray);

      setErrorNotific("");
      setSuccessNotific("Deleted Successfully.");
    } catch (error) {
      setErrorNotific("Delete failed.");
      setSuccessNotific("");
    }

    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);

    setShowDeletePopup(false);
    setShowPreviewPopup(false);
    call(currentPage);
    setSelectedRows([]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const coordinates = {
      placeholderX: placeholderXRef.current.value,
      placeholderY: placeholderYRef.current.value,
      nameX: nameXRef.current.value,
      nameY: nameYRef.current.value,
      // descriptionX: descriptionXRef.current.value,
      // descriptionY: descriptionYRef.current.value,
      fontSize: fontSizeRef.current.value,
    };

    const payload = {
      templateId: templateId,
      templatePath: templatePath,
      coordinates: coordinates,
    };

    const clickedButton = e.nativeEvent.submitter.name;

    if (clickedButton === "generatePreview") {
      try {
        const response = await anniversaryTemplateBankService.generatePreview(
          payload
        );
        
        setTemplateImage(response.data.url);
      } catch (error) {
        setErrorNotific("Generate Preview Failed.");
        setSuccessNotific("");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      }
    } else if (clickedButton === "upload") {
      try {
        await anniversaryTemplateBankService.updateTemplate(payload);
      } catch (error) {
        setErrorNotific("Template upload Failed.");
        setSuccessNotific("");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);

        return;
      }

      call(currentPage);

      setErrorNotific("");
      setSuccessNotific("Uploaded Successfully.");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);

      setShowUploadPopup(false);
    }
  };

  const paginate = (currPage, data) => {
    // Scroll to the top of the table whenever page number is changed
    if (tableRef.current) {
      tableRef.current.scrollTop = 0;
    }

    const start = (currPage - 1) * logsPerPage;
    const end = start + logsPerPage;
    setFilteredActivityLogs(data.slice(start, end));
  };

  const handlePrevPage = () => {
    const setPageNumber = currentPage - 1;
    setCurrentPage(setPageNumber);
    adjustPages(setPageNumber);

    if (!showActivityLogs) {
      getNewTemplateList(setPageNumber);
    } else {
      paginate(setPageNumber, initialFilteredActivityLogs);
    }
  };

  const handleDualPrevPage = () => {
    const setPageNumber = currentPage - 5;
    setPageNumber <= 0 && (setPageNumber = 1);

    setCurrentPage(setPageNumber);
    adjustPages(setPageNumber);

    if (!showActivityLogs) {
      getNewTemplateList(setPageNumber);
    } else {
      paginate(setPageNumber, initialFilteredActivityLogs);
    }
  };

  const handleNextPage = () => {
    const setPageNumber = currentPage + 1;
    setCurrentPage(setPageNumber);
    adjustPages(setPageNumber);

    if (!showActivityLogs) {
      getNewTemplateList(setPageNumber);
    } else {
      paginate(setPageNumber, initialFilteredActivityLogs);
    }
  };

  const handleDualNextPage = () => {
    const setPageNumber = currentPage + 5;
    setPageNumber > totalPages && (setPageNumber = totalPages);

    setCurrentPage(setPageNumber);
    adjustPages(setPageNumber);

    if (!showActivityLogs) {
      getNewTemplateList(setPageNumber);
    } else {
      paginate(setPageNumber, initialFilteredActivityLogs);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Escape") {
      // Popup will get close on Esc key press
      setShowUploadPopup(false);
      setShowDeletePopup(false);
      setShowPreviewPopup(false);
    } else if (event.key === "ArrowLeft") {
      // Go to previous template while preview popup is open on Left key press
      currentTemplateIndex !== 0 &&
        setCurrentTemplateIndex(currentTemplateIndex - 1);
    } else if (event.key === "ArrowRight") {
      // Go to next template while preview popup is open on Right key press
      currentTemplateIndex !== Images.length - 1 &&
        setCurrentTemplateIndex(currentTemplateIndex + 1);
    }
  };

  useEffect(() => {
    let user = localStorage.getItem("user");
    let validator = new licenceValidator(user, "template_bank");

    setIsVisible(validator.isReadable("image"));
    setIsDeletable(validator.isDelete("image"));

    setImages([]);
    call(1);
    adjustPages();
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [currentTemplateIndex]);
 

  useEffect(() => {
    if (
      placeholderXRef.current &&
      placeholderYRef.current &&
      nameXRef.current &&
      nameYRef.current &&
      fontSizeRef.current
    ) {
      placeholderXRef.current.value = defaultCoordinates?.placeholderX;
      placeholderYRef.current.value = defaultCoordinates?.placeholderY;
      nameXRef.current.value = defaultCoordinates?.nameX;
      nameYRef.current.value = defaultCoordinates?.nameY;
      fontSizeRef.current.value = defaultCoordinates?.fontSize;
    }
  }, [defaultCoordinates]);
  return (
    <div className="birthday-templates-container">
      <div className="indicator-container ">
        <div className="d-flex text">
          <img
            src="/assets/social_template_bank_icon.svg"
            alt="social template bank icon"
            className="icon mx-2"
          />
          Anniversary Post Template Bank
        </div>

        {!showActivityLogs ? (
          <>
            <div className="d-flex align-items-center gap-4">
              <GradientBorderButton
                text="Download PPT"
                icon={<FiDownload />}
                clickHandler={handlDownloadPPT}
                additionalClass="z-i-0"
                iconAdditionalClass="icon-width"
                innerDivClass="inner-div-styles"
              />

              <GradientBorderButton
                text="Upload"
                icon={<FiUpload />}
                clickHandler={handleUploadTemplate}
                additionalClass="z-i-0"
                iconAdditionalClass="icon-width"
                innerDivClass="inner-div-styles"
              />

              <GradientBorderButton
                text="Delete"
                icon={<AiOutlineDelete />}
                clickHandler={handleDelete}
                additionalClass="z-i-0"
                iconAdditionalClass="icon-width"
                innerDivClass="inner-div-styles"
              />

              <TbBookUpload
                className="activity-logs-icon"
                onClick={handleActivityLogsPage}
              />
            </div>
          </>
        ) : (
          <div className="activity-logs-div">
            <RiArrowGoBackFill
              className="activity-logs-icon"
              onClick={handleBack}
            />
          </div>
        )}
      </div>

      <div className="d-flex">
        <div className="gradient-card mx-auto">
          <div className="card-bg mt-3">
            <div className="birthday-templates">
              {!showActivityLogs ? (
                <div className="templates-body">
                  {Images.length ? (
                    Images.map((item, index) => (
                      <div key={index} className="template-checkbox">
                        <input
                          type="checkbox"
                          checked={selectedRows.some(
                            (selectedItem) => selectedItem.id === item.id
                          )}
                          onChange={() => handleSelectTemplate(item)}
                        />

                        <img
                          src={item.filePath}
                          alt={`template_${item.id}`}
                          onClick={() => handlePreviewTemplate(item, index)}
                        />
                      </div>
                    ))
                  ) : (
                    <div className="no-templates">No Templates Found.</div>
                  )}
                </div>
              ) : (
                <>
                  <div className="upper-section">
                    <div className="search-box">
                      <div className="search-icon">
                        <FiSearch />
                      </div>

                      <input
                        type="text"
                        className="form-control search-input-user"
                        placeholder="Search"
                        aria-label="search an employee name"
                        aria-describedby=""
                        ref={employeeRef}
                        onChange={() => handleSearch()}
                      />
                    </div>

                    <div className="form-outline">
                      <select
                        id="department"
                        className="form-select"
                        ref={statusRef}
                        onChange={handleStatusChange}
                      >
                        <option value="default" className="p-5">
                          All
                        </option>

                        <option value="approved" className="p-5">
                          Approved
                        </option>

                        <option value="rejected" className="p-5">
                          Rejected
                        </option>
                      </select>
                    </div>
                  </div>

                  <div className="table-responsive" ref={tableRef}>
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Department</th>
                          <th scope="col">Shift Timing</th>
                          <th scope="col">Date of Joining</th>
                          <th scope="col">Status</th>
                          <th scope="col">Updated By</th>
                          <th scope="col">Updated At</th>
                        </tr>
                      </thead>

                      <tbody>
                        {filteredActivityLogs.map((item, index) => {
                          return (
                            <tr className="w-100" key={index}>
                              <td className="text-center fw-semibold">
                                {item.name}
                              </td>

                              
                              <td className="text-center">{departments.find(department => department.id === item.department) ? departments.find(department => department.id === item.department)?.department : "Department not found"}</td>

                     
                              <td className="text-center">{shiftTimings.find((shift) => shift.id == item.shiftTiming) ? shiftTimings.find((shift) => shift.id == item.shiftTiming).startTime + " - " + shiftTimings.find((shift) => shift.id == item.shiftTiming).endTime : "Shift timing not found"}</td>

                             

                              <td className="text-center">
                                {formatDate(item.dateOfJoining, false)}
                              </td>

                              <td
                                className={`text-center status ${
                                  item.activityLogs.status === "Approved"
                                    ? "status-green"
                                    : ""
                                }`}
                              >
                                {item.activityLogs.status}
                              </td>

                              <td className="text-center">
                                {item.activityLogs.updatedBy}
                              </td>

                              <td className="text-center">
                                {formatDate(item.activityLogs.updatedAt, true)}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </div>

            {totalPages && pageNumbers.length > 1 && (
              <div className="pagination">
                {currentPage !== 1 && totalPages > 5 && (
                  <button onClick={handleDualPrevPage}>
                    <FaAngleDoubleLeft className="arrow-icon left-arrow " />
                  </button>
                )}

                {currentPage !== 1 && (
                  <button onClick={handlePrevPage}>
                    <FaAngleLeft className="arrow-icon left-arrow" />
                  </button>
                )}

                {pageNumbers.map((page) => (
                  <button
                    key={page}
                    disabled={currentPage === page}
                    className={currentPage === page ? "active" : ""}
                    onClick={() => {
                      getNewTemplateList(page);
                      adjustPages(page);
                      paginate(page, initialFilteredActivityLogs);
                    }}
                  >
                    {page}
                  </button>
                ))}

                {currentPage !== totalPages && (
                  <button onClick={handleNextPage}>
                    <FaAngleRight className="arrow-icon" />
                  </button>
                )}

                {currentPage !== totalPages && totalPages > 5 && (
                  <button onClick={handleDualNextPage}>
                    <FaAngleDoubleRight className="arrow-icon" />
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {showUploadPopup && (
        <div className="upload-popup-container">
          <div className="popup">
            <div className="popup-header">
              Upload
              <button
                className="close-button"
                onClick={() => setShowUploadPopup(false)}
              >
                &#10005;
              </button>
            </div>

            <hr className="hr-border" />

            <div className="popup-content">
              <form onSubmit={handleSubmit} encType="multipart/form-data">
                <div className="form-fields-container">
                  <div className="row mt-3 pb-3">
                    <div className="col-6">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="upload-template">
                          Template
                        </label>

                        <input
                          type="file"
                          id="upload-template"
                          className="form-control"
                          ref={imgRef}
                          onChange={handleTemplateChange}
                          required
                        />
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="upload-ppt">
                          PPT
                        </label>

                        <input
                          type="file"
                          id="upload-ppt"
                          className="form-control"
                          ref={pptRef}
                          onChange={handlePPTChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="template-details">
                    <div className="preview-container">
                      <label className="form-label">Template Preview</label>

                      <div
                        className="outer-preview"
                        style={{ height: "fit-content", minHeight:"300px" }}
                      >
                        {templateImage ? (
                          <img
                            src={templateImage}
                            alt="uploaded-birthday-template"
                            className="inner-preview"
                          />
                        ) : (
                          <p className="font-italic">Please upload template</p>
                        )}
                      </div>
                    </div>

                    <div className="coordinates-container">
                      <label className="form-label" htmlFor="placeholder">
                        Template Coordinates
                      </label>

                      <div className="outer-coordinates">
                        <div className="d-flex flex-column">
                          <div
                            className="inner-coordinates"
                            style={{ marginTop: "-50%" }}
                          >
                            <div className="item"></div>

                            <div className="item">X</div>

                            <div className="item">Y</div>

                            <label htmlFor="placeholderX">Placeholder</label>

                            <div className="item">
                              <input
                                type="number"
                                id="placeholderX"
                                className="no-spinner"
                                ref={placeholderXRef}
                                required
                              />
                            </div>

                            <div className="item">
                              <input
                                type="number"
                                className="no-spinner"
                                ref={placeholderYRef}
                                required
                              />
                            </div>
                            <label htmlFor="nameX">Name</label>

<div className="item">
  <input
    type="number"
    id="nameX"
    className="no-spinner"
    ref={nameXRef}
    required
  />
</div>

<div className="item">
  <input
    type="number"
    className="no-spinner"
    ref={nameYRef}
    required
  />
</div>
                            <label htmlFor="fontSize">Font-size</label>

                            <div className="item">
                              <input
                                type="number"
                                className="no-spinner"
                                id="fontSize"
                                ref={fontSizeRef}
                                required
                              />
                            </div>
                          </div>

                          {/* We are not using GradientBorderButton here because we are using form, type-submit and name */}
                          <div className="upload-btn-container">
                            <button
                              name="generatePreview"
                              type="submit"
                              style={{ marginTop: "30%", marginBottom: "-50%" }}
                            >
                              <div className="outer-div">
                                <div className="inner-div">
                                  Generate Preview
                                </div>
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* We are not using GradientBorderButton here because we are using form, type-submit and name */}
                <div className="upload-btn-container">
                  <button name="upload" type="submit">
                    <div className="outer-div">
                      <div className="inner-div">
                        <FiUpload />
                        <span className="icon-width"></span>
                        Upload
                      </div>
                    </div>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {showDeletePopup && (
        <div className="delete-popup-container">
          <div className="popup">
            <div className="popup-header">
              Confirm Deletion
              <button
                className="close-button"
                onClick={() => setShowDeletePopup(false)}
              >
                &#10005;
              </button>
            </div>

            <hr className="hr-border" />

            <div className="popup-content">
              <div className="confirmation-text">
                Are you really sure you want to delete this template?
              </div>

              <div className="delete-buttons">
                <GradientBorderButton
                  text={"Cancel"}
                  icon={<AiOutlineCloseCircle />}
                  clickHandler={() => setShowDeletePopup(false)}
                  iconAdditionalClass={"icon-width"}
                  innerDivClass={"inner-div-styles"}
                />

                <GradientBorderButton
                  text={"Delete"}
                  icon={<AiOutlineDelete />}
                  clickHandler={() => handleDeleteTemplate(selectedRows)}
                  iconAdditionalClass={"icon-width"}
                  innerDivClass={"inner-div-styles"}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {showPreviewPopup && (
        <div className="preview-popup-container">
          <div className="popup">
            <div className="popup-header">
              <div>Template Preview</div>

              <div>
                <button
                  className="delete-button"
                  onClick={() =>
                    handleDeleteTemplate(Images[currentTemplateIndex])
                  }
                >
                  <AiOutlineDelete />
                </button>

                <button
                  className="close-button"
                  onClick={() => setShowPreviewPopup(false)}
                >
                  &#10005;
                </button>
              </div>
            </div>

            <hr className="hr-border" />

            <div className="popup-content preview-icons">
              <div>
                {currentTemplateIndex !== 0 && (
                  <button
                    onClick={() =>
                      setCurrentTemplateIndex(currentTemplateIndex - 1)
                    }
                  >
                    <FaAngleLeft className="arrow-icon left-arrow" />
                  </button>
                )}
              </div>

              <img
                src={Images[currentTemplateIndex].filePath}
                alt={`template_${selectedTemplate.id}`}
              />

              <div>
                {currentTemplateIndex !== Images.length - 1 && (
                  <button
                    onClick={() =>
                      setCurrentTemplateIndex(currentTemplateIndex + 1)
                    }
                  >
                    <FaAngleRight className="arrow-icon" />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {showAlert && (
        <div className="alert-container">
          <div className="upper-section">
            <p>ALOIS</p>

            <span
              className="delete-btn"
              onClick={() => setShowAlert(!showAlert)}
            >
              <AiOutlineClose className="icon" />
            </span>
          </div>

          <hr />

          <div className="lower-section py-2">
            {errorNotific && <p className="text-danger">{errorNotific}</p>}
            {successNotific && <p className="text-success">{successNotific}</p>}
          </div>
        </div>
      )}
    </div>
  );
};

export default AnniversaryTemplateBank;
