import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import ChatBot from "../ChatBot/ChatBot";
import ProfileSidebar from "../UserProfile/ProfileSidebar";
import { useDOMStatesContext } from "../../context/DOMStatesContext";
import Footer from "../Footer/Footer";

function Content(props) {
  const { showProfileSidebar } = useDOMStatesContext();

  return (
    <>
    <div className="dashboard">
      {showProfileSidebar ? <ProfileSidebar /> : null}

      <Outlet />
     
     
    </div>
     {/* <div style={{bottom:"0"}}>
     {<Footer />}
     </div> */}
     </>
  );
}

export default Content;
