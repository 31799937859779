import React, { useEffect, useRef, useState } from "react";
import GenerateBox from "../GenerateBox/GenerateBox";
import licenceValidator from "../../permission/validate";
import { Toast } from "bootstrap";
import essService from "../../services/ess.service";
import { AiOutlineClose } from "react-icons/ai";
import GradientBorderButton from "../Widgets/GradientBorderButton";
import DatePicker from "react-datepicker";
import { Spinner } from "react-bootstrap";
import minioService from "../../services/minio.service";

const CandidateSynopsis = () => {
  const [activeTab, setActiveTab] = useState("healthcare");
  const [generateComponents, setGenerateComponents] = useState(false);
  const remarksRef = useRef();
  const difficultyRef = useRef();

  const [showAlert, setShowAlert] = useState(false);
  const [successNotific, setSuccessNotific] = useState("");
  const [errorNotific, setErrorNotific] = useState("");
  const remRef = useRef();
  const noOfQuestionsRef = useRef();
  const [success, setSuceess] = useState("");
  const [error, setError] = useState("");
  const jobTitleRef = useRef();
  const unitRef = useRef();
  const experienceRef = useRef();
  const skillsRef = useRef();
  const typeOfHospitalRef = useRef();
  const [form1Filled, setForm1Filled] = useState(false);
  const [form2Filled, setForm2Filled] = useState(false);
  const traumaLevelRef = useRef();
  const emrExperienceRef = useRef();
  const availableFromRef = useRef();
  const returnToWorkRef = useRef();
  const covidStatusRef = useRef();
  const equipmentsUsedRef = useRef();
  const interviewAvailabilityRef = useRef();
  const [isVisible, setIsVisible] = useState(false);
  const [answer, setAnswer] = useState("");
  const [loader, setloader] = useState(false);
  const resRef = useRef(null);
  const intents = [
    "Witty",
    "Humourous",
    "Creative",
    "Professional",
    "Formal",
    "Informal",
    "Casual",
    "CTA based",
    "Insperational",
    "Enthusiastic",
    "Informative",
    "Story based",
  ];

  const handleDesChange = (e) => {
    let jdInput = document.getElementById("jd_file");

    if (e.target.value.length) {
      if (jdInput) {
        jdInput.disabled = true;
      }
    } else {
      if (jdInput) {
        jdInput.disabled = false;
      }
    }
  };
  const handleJDChange = (e) => {
    let desInput = document.getElementById("designation");

    if (e.target.files.length) {
      if (desInput) {
        desInput.disabled = true;
      }
    } else {
      if (desInput) {
        desInput.disabled = false;
      }
    }
  };
  useEffect(() => {
    let user = localStorage.getItem("user");
    let validator = new licenceValidator(user, "assist");
    setIsVisible(validator.isReadable("candidate_synopsis"));
  }, []);
  const handleReset = () => {
    setloader(false);
    setGenerateComponents(false);
    setAnswer("");
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setError("");
      setloader(true);
      setGenerateComponents(false);
      const formData = {
        resume: resRef.current.files[0],
        remarks: remarksRef.current.value,
      };
      const response = await minioService.UploadSingleFile(
        formData.resume,
        "candidate-resumes"
      );
      // Make a POST request to your backend endpoint to generate the candidate synopsis
      const res = await essService.candidateSynopsis({
        url: response.data.url,
        remarks: formData.remarks,
      });

      // Set the candidate synopsis based on the response
      setAnswer(res.data.answer);

      // Show the generated components
      setGenerateComponents(true);
    } catch (err) {
      // Handle errors
      setError("Server Error!");
      console.error(err);
    } finally {
      setloader(false);
    }
  };
  const handleSubmi1 = async (e) => {
    e.preventDefault();
    try {
      setError("");
      setloader(true);
      setGenerateComponents(false);
      const formData = {
        jobTitle: jobTitleRef.current.value,
        unit: unitRef.current.value,
        experience: experienceRef.current.value,
        skills: skillsRef.current.value,
        typeOfHospital: typeOfHospitalRef.current.value,
        traumaLevel: traumaLevelRef.current.value,
        emrExperience: emrExperienceRef.current.value,
        availableFrom: availableFromRef.current.value,
        returnToWork: returnToWorkRef.current.value,
        covidStatus: covidStatusRef.current.value,
        equipmentsUsed: equipmentsUsedRef.current.value,
        interviewAvailability: interviewAvailabilityRef.current.value,
      };
      // Make a POST request to your backend endpoint to generate the candidate synopsis
      const res = await essService.candidateSynopsis(formData);

      // Set the candidate synopsis based on the response
      setAnswer(res.data.answer);

      // Show the generated components
      setGenerateComponents(true);
    } catch (err) {
      // Handle errors
      setError("Server Error!");
      console.error(err);
    } finally {
      setloader(false);
    }
  };
  useEffect(() => {
    const targetDiv = document.querySelector("#generate-box");
    if (targetDiv) {
      targetDiv.scrollIntoView({ behavior: "smooth" });
    }
  }, [document.querySelector("#generate-box")]);
  const handleForm2Change = () => {
    let isForm2Filled =
      jobTitleRef.current?.value ||
      unitRef.current?.value ||
      experienceRef.current?.value ||
      skillsRef.current?.value ||
      typeOfHospitalRef.current?.value ||
      traumaLevelRef.current?.value ||
      emrExperienceRef.current?.value ||
      availableFromRef.current?.value ||
      returnToWorkRef.current?.value ||
      covidStatusRef.current?.value ||
      equipmentsUsedRef.current?.value ||
      interviewAvailabilityRef.current?.value;

    if (isForm2Filled == "default") {
      isForm2Filled = "";
    }
    setForm2Filled(isForm2Filled);
  };
  // Check if all fields in Form 1 are filled
  const handleForm1Change = () => {
    const isFilled = resRef.current?.value || remarksRef.current?.value;
    setForm1Filled(isFilled);
  };
  useEffect(() => {
    setForm1Filled(false);
    setForm2Filled(false);
    remarksRef.current.value = "";
    resRef.current.value = "";
  }, [activeTab]);
  let dashboardElement = document.querySelector(".dashboard");

  useEffect(() => {
    if (dashboardElement && generateComponents) {
      // If yes, set height to viewport height
    } else {
    }
  }, [generateComponents]);
  return (
    <div className="candidate-synopsis">
      <div className="tab-switches">
        <div
          onClick={() => {
            setActiveTab("healthcare");
          }}
          className={`tab ${activeTab == "healthcare" ? "active" : ""}`}
        >
          <p>HEALTHCARE</p>
        </div>
        <div
          onClick={() => {
            setActiveTab("non-healthcare");
          }}
          className={`tab ${activeTab == "non-healthcare" ? "active" : ""}`}
        >
          <p>NON HEALTHCARE</p>
        </div>
      </div>
      {showAlert ? (
        <div className="alert-container">
          <div className="upper-section">
            <p style={{ marginBottom: "0px", marginTop: "5px" }}>ALOIS</p>
            <span
              className="delete-btn"
              onClick={() => setShowAlert(!showAlert)}
            >
              <AiOutlineClose className="icon" style={{ fill: "black" }} />
            </span>
          </div>
          <hr
            style={{
              display: "block !important",
              height: "1px !important",
              border: "0 !important",
              borderTop: "1px solid gray !important",
            }}
          />
          <div className="lower-section pt-2">
            <p className="text-danger">{errorNotific}</p>
            <p className="text-success">{successNotific}</p>
          </div>
        </div>
      ) : null}
      <div>
        <div className="toasts-container position-absolute top-0 mt-3 mx-3">
          <div className="toast fade hide" data-autohide="true" id="toast-2">
            <div className="toast-header">
              <i className="far fa-bell text-muted me-2"></i>
              <strong className="me-auto">ALOIS</strong>
              <button
                type="button"
                id="toastclose_btn"
                className="btn-close"
                data-bs-dismiss="toast"
              ></button>
            </div>
            <div className="toast-body">
              {/* <p className="text-success">{success}</p> */}
              <p className="text-danger">{error}</p>
            </div>
          </div>
        </div>
      </div>
      <form>
        <div className="row align-items-center mt-2">
          <div className="col-xl-6">
            <div className="form-outline">
              <label className="form-label" htmlFor="customFile">
                Upload Resume
              </label>
              <input
                ref={resRef}
                accept=".pdf"
                onChange={handleForm1Change}
                type="file"
                disabled={form2Filled}
                className="form-control"
                id="jd_file"
              />
            </div>
          </div>
          <div className="col-xl-6">
            <div className="form-outline">
              <label className="form-label" htmlFor="form8Example1">
                Remarks
              </label>
              <input
                ref={remarksRef}
                onChange={handleForm1Change}
                disabled={form2Filled}
                type="text"
                id="designation"
                className="form-control"
              />
            </div>
          </div>
        </div>
        <div className="w-100 d-flex justify-content-center my-3">
          <GradientBorderButton
            text="Generate"
            clickHandler={handleSubmit}
            outerDivClass="height-34"
            innerDivClass="height-30"
          />
        </div>
        {activeTab == "healthcare" ? (
          <div className="row d-flex align-items-center justify-content-between mx-1 row-1">
            <div className="col-xl-5 line"></div>
            <div
              className="col-xl-1 text-center gx-0"
              style={{ width: "fit-content" }}
            >
              <b className=" mt-5 fs-5 " style={{ color: "#A4B3BA" }}>
                OR
              </b>
            </div>
            <div className="col-xl-5 line"></div>
          </div>
        ) : (
          <></>
        )}
      </form>
      {activeTab == "healthcare" ? (
        <form>
          {/* Additional Fields for Healthcare */}
          {activeTab === "healthcare" && (
            <div>
              <div className="row d-flex align-items-center justify-content-between row-1">
                <div className="col-xl-4">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="job-title">
                      Job Title
                    </label>
                    <input
                      ref={jobTitleRef}
                      type="text"
                      disabled={form1Filled}
                      onChange={handleForm2Change}
                      id="job-title"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-xl-4">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="unit">
                      Unit (Department)
                    </label>
                    <input
                      ref={unitRef}
                      disabled={form1Filled}
                      onChange={handleForm2Change}
                      type="text"
                      id="unit"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-xl-4">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="experience">
                      Experience
                    </label>
                    <input
                      ref={experienceRef}
                      disabled={form1Filled}
                      onChange={handleForm2Change}
                      type="text"
                      id="experience"
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="form-outline">
                    <label className="form-label pt-3" htmlFor="skills">
                      Skills (Comma Separated)
                    </label>
                    <input
                      ref={skillsRef}
                      disabled={form1Filled}
                      onChange={handleForm2Change}
                      type="text"
                      id="skills"
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <div className="row d-flex align-items-center justify-content-between mt-2 row-1">
                <div className="col-xl-4">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="type-of-hospital">
                      Type of hospital
                    </label>
                    <input
                      ref={typeOfHospitalRef}
                      disabled={form1Filled}
                      onChange={handleForm2Change}
                      type="text"
                      id="type-of-hospital"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-xl-4">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="trauma-level">
                      Trauma level
                    </label>
                    <select
                      ref={traumaLevelRef}
                      id="trauma-level"
                      disabled={form1Filled}
                      onChange={handleForm2Change}
                      className="form-select"
                    >
                      <option value="default">Select a level</option>
                      <option value="level-1">Level 1</option>
                      <option value="level-2">Level 2</option>
                      <option value="level-3">Level 3</option>
                      <option value="level-4">Level 4</option>
                      <option value="level-5">Level 5</option>
                    </select>
                  </div>
                </div>
                <div className="col-xl-4">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="emr-experience">
                      EMR Charting Experience
                    </label>
                    <select
                      ref={emrExperienceRef}
                      id="emr-experience"
                      disabled={form1Filled}
                      onChange={handleForm2Change}
                      className="form-select"
                    >
                      <option value="default">Select an option</option>
                      <option value="none">None</option>
                      <option value="basic">Basic</option>
                      <option value="intermediate">Intermediate</option>
                      <option value="advanced">Advanced</option>
                      <option value="expert">Expert</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row d-flex align-items-center justify-content-between mt-2 row-1">
                <div className="col-xl-4">
                  <div className="form-outline d-flex flex-column">
                    <label className="form-label" htmlFor="available-from">
                      Available From:
                    </label>
                    <input
                      min={new Date().toISOString().split("T")[0]}
                      ref={availableFromRef}
                      disabled={form1Filled}
                      onChange={handleForm2Change}
                      type="date"
                      id="dateInput"
                    />
                  </div>
                </div>
                <div className="col-xl-4">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="return-to-work">
                      Return to work
                    </label>
                    <input
                      ref={returnToWorkRef}
                      disabled={form1Filled}
                      onChange={handleForm2Change}
                      type="text"
                      id="return-to-work"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-xl-4">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="covid-status">
                      Covid Vaccination Status
                    </label>
                    <select
                      ref={covidStatusRef}
                      disabled={form1Filled}
                      onChange={handleForm2Change}
                      id="covid-status"
                      className="form-select"
                    >
                      <option value="default">Select an option</option>
                      <option value="fully-vaccinated">Fully Vaccinated</option>
                      <option value="partially-vaccinated">
                        Partially Vaccinated
                      </option>
                      <option value="not-vaccinated">Not Vaccinated</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="form-outline">
                    <label
                      className="form-label pt-3"
                      htmlFor="equipments-used"
                    >
                      Equipments Used (Comma Separated)
                    </label>
                    <input
                      ref={equipmentsUsedRef}
                      disabled={form1Filled}
                      onChange={handleForm2Change}
                      type="text"
                      id="equipments-used"
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-3 d-flex">
                <p>Available for Interview </p>
                <input
                  disabled={form1Filled}
                  onChange={handleForm2Change}
                  className="number-input"
                  type="number"
                  ref={interviewAvailabilityRef}
                />
                <p> days a week after </p>
                <input className="mx-2" type="time" />
              </div>
            </div>
          )}

          {/* Generate Button */}
          {loader ? (
            <div className="mx-auto mt-3 p-1">
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              &nbsp; Loading...
            </div>
          ) : isVisible ? (
            <div className="w-100 d-flex justify-content-center my-3">
              <GradientBorderButton
                clickHandler={handleSubmi1}
                text="Generate"
                outerDivClass="height-34"
                innerDivClass="height-30"
              />
            </div>
          ) : (
            <></>
          )}

          {/* Generated Content */}
          {generateComponents && (
            <div id="generate-box">
              <GenerateBox
                text={answer}
                showJdIcons={true}
                showSocialIcons={false}
                fileName={jobTitleRef.current?.value || ""}
                handleSubmit={handleSubmit}
                handleReset={handleReset}
              />
            </div>
          )}
        </form>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CandidateSynopsis;
