import React, { useEffect, useRef, useState } from "react";
import { MdSnippetFolder } from "react-icons/md";
import GradientBorderButton from "../../components/Widgets/GradientBorderButton";
import Switch from "../../components/Widgets/Switch";
import { FaEdit, FaTrashAlt, FaSearch } from "react-icons/fa";
import { IoMdAddCircle } from "react-icons/io";
import { AiOutlineClose } from "react-icons/ai";
import masterDBService from "../../services/masterDB.service";

function ShitftTimings() {
  const [searchInputFocused, setSearchInputFocused] = useState(false);
  const [showCreatePopup, setShowCreatePopup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [successNotific, setSuccessNotific] = useState("");
  const [errorNotific, setErrorNotific] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [shiftTimings, setShiftTimings] = useState([]);
  const [selectedShiftTiming, setSelectedShiftTiming] = useState(null);
  const shiftTimingRef = useRef(null);

  const handleToggle = async (data) => {
    try {
      const updatedShiftTiming = {
        startTime: data.startTime,
        endTime: data.endTime,
        status: !data.status,
      };
      const response = await masterDBService.updateShiftTiming(
        data.id,
        updatedShiftTiming
      );
      // Update the UI by fetching all shift timings again
      getAllShiftTimings();
    } catch (error) {
      setShowAlert(true);
      setErrorNotific("Error updating shift timing status");
      setSuccessNotific("");
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  };

  const handleClosePopup = () => {
    setShowCreatePopup(!showCreatePopup);
  };
  const handleEditPopup = (id) => {
    setShowEditPopup(!showEditPopup);
    const foundShiftTiming = shiftTimings.find((shift) => shift.id === id);
    setSelectedShiftTiming(foundShiftTiming);
  };

  const getAllShiftTimings = async () => {
    try {
      const result = await masterDBService.getAllShiftTimings();
      setShiftTimings(result.results);
    } catch (error) {
      console.error("Error fetching shift timings", error);
    }
  };

  useEffect(() => {
    getAllShiftTimings();
  }, []);

  const getAMPM = (time) => {
    // Create a Date object and set the time
    const date = new Date();
    date.setHours(Number(time.split(":")[0]));
    date.setMinutes(Number(time.split(":")[1]));

    // Get the hours in 12-hour format and the AM/PM indicator
    const hours = date.getHours() % 12 || 12;
    const ampm = date.getHours() >= 12 ? "PM" : "AM";

    return hours + ":" + ("0" + date.getMinutes()).slice(-2) + " " + ampm;
  };
  const handleAddShiftTiming = async () => {
    try {
      const data = {
        startTime: getAMPM(startTime),
        endTime: getAMPM(endTime),
        status: true,
      };
      const result = await masterDBService.createShiftTiming(data);
      setShowCreatePopup(false);
      setShowAlert(true);
      setErrorNotific("");
      setSuccessNotific("Shift Timing created successfully");
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
      getAllShiftTimings();
    } catch (error) {
      console.error("Error Creating shift timing", error);
      setShowAlert(true);
      setErrorNotific("Error creating shift timing");
      setSuccessNotific("");
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  };

  const handleUpdateShiftTiming = async () => {
    try {
      const updatedShiftTiming = {
        startTime: getAMPM(startTime),
        endTime: getAMPM(endTime),
        status: selectedShiftTiming.status,
      };
      const response = await masterDBService.updateShiftTiming(
        selectedShiftTiming.id,
        updatedShiftTiming
      );
      setShowEditPopup(false);
      setShowAlert(true);
      setErrorNotific("");
      setSuccessNotific("Shift Timing updated successfully");
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
      getAllShiftTimings();
    } catch (error) {
      console.error(error);
      setShowAlert(true);
      setErrorNotific("Error updating shift timing");
      setSuccessNotific("");
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  };

  const handleDelete = async (shiftTimingId) => {
    try {
      const shouldDelete = window.confirm(
        "Are you sure you want to delete this shift timing?"
      );
      if (shouldDelete) {
        const response = await masterDBService.deleteShiftTiming(shiftTimingId);
        setShowAlert(true);
        setErrorNotific("Shift Timing deleted successfully");
        setSuccessNotific("");
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
        getAllShiftTimings();
      }
    } catch (error) {
      setShowAlert(true);
      setErrorNotific("Error deleting shift timing");
      setSuccessNotific("");
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  };

  const filterShiftTimings = () => {
    const shiftTiming = shiftTimingRef.current.value;
    if (shiftTiming !== "") {
      const filteredData = shiftTimings.filter(
        (data) =>
          data.startTime.includes(shiftTiming) ||
          data.endTime.includes(shiftTiming)
      );
      setShiftTimings(filteredData);
    } else {
      getAllShiftTimings();
    }
  };

  return (
    <div className="d-flex shift-timings-container flex-column">
      <div className="indicator-container pt-2 pb-4 ">
        <div className="d-flex text">
          <MdSnippetFolder className="icon mx-2" /> Shift Timings
        </div>
      </div>
      {showCreatePopup && (
        <div className="shift-timings-popup-container">
          <div className="shift-timings-popup">
            <div className="shift-timings-popup-header">
              <span className="fs-6 fw-bold">Create Shift timings</span>
              <button className="close-button" onClick={handleClosePopup}>
                &#10005;
              </button>
            </div>

            <hr style={{ borderTop: "2px dashed black" }} />
            <div className="form-container">
              <form>
                <div className="date-container">
                  <label className="form-label">Start Time</label>
                  <input
                    type="time"
                    id="startTime"
                    onChange={(e) => setStartTime(e.target.value)}
                    required
                  />
                </div>
                <div className="date-container">
                  <label className="form-label">End Time</label>
                  <input
                    type="time"
                    id="endTime"
                    onChange={(e) => setEndTime(e.target.value)}
                    required
                  />
                </div>
              </form>
            </div>
            <div className="d-flex justify-content-center pt-2">
              <GradientBorderButton
                text="Confirm"
                clickHandler={handleAddShiftTiming}
              />
            </div>
          </div>
        </div>
      )}
      {showEditPopup && (
        <div className="shift-timings-popup-container">
          <div className="shift-timings-popup">
            <div className="shift-timings-popup-header">
              <span className="fs-6 fw-bold">Update Shift timings</span>
              <button className="close-button" onClick={handleEditPopup}>
                &#10005;
              </button>
            </div>

            <hr style={{ borderTop: "2px dashed black" }} />
            <div className="form-container">
              <form>
                <div className="date-container">
                  <label className="form-label d-block">
                    Current Shift Timing
                  </label>
                  <input
                    type="text"
                    defaultValue={
                      selectedShiftTiming.startTime +
                      " - " +
                      selectedShiftTiming.endTime
                    }
                    disabled
                  />
                </div>
                <div className="container">
                  <div className="date-container">
                    <label className="form-label">Start Time</label>
                    <input
                      type="time"
                      id="startTime"
                      onChange={(e) => setStartTime(e.target.value)}
                      required
                    />
                  </div>
                  <div className="date-container">
                    <label className="form-label">End Time</label>
                    <input
                      type="time"
                      id="endTime"
                      onChange={(e) => setEndTime(e.target.value)}
                      required
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="d-flex justify-content-center pt-2">
              <GradientBorderButton
                text="Confirm"
                clickHandler={handleUpdateShiftTiming}
              />
            </div>
          </div>
        </div>
      )}
      {showAlert ? (
        <div className="alert-container">
          <div className="upper-section">
            <p style={{ marginBottom: "0px", marginTop: "5px" }}>ALOIS</p>
            <span
              className="delete-btn"
              onClick={() => setShowAlert(!showAlert)}
            >
              <AiOutlineClose className="icon" style={{ fill: "black" }} />
            </span>
          </div>
          <hr
            style={{
              display: "block !important",
              height: "1px !important",
              border: "0 !important",
              borderTop: "1px solid gray !important",
            }}
          />
          <div className="lower-section py-2">
            <p className="text-danger">{errorNotific}</p>
            <p className="text-success">{successNotific}</p>
          </div>
        </div>
      ) : null}

      <div className="shift-timings-outer mx-auto">
        <div className="shift-timings-inner mt-3">
          <div className="d-flex justify-content-between align-items-center">
            <div className="heading"></div>
            <div className="d-flex align-items-center gap-3">
              <div className="input-group d-flex align-items-center">
                <button
                  className={`btn btn-outline-secondary search-btn ${
                    searchInputFocused && "search-input-focused"
                  }`}
                  type="button"
                  id="button-addon1"
                  onClick={() => shiftTimingRef.current.focus()}
                >
                  <FaSearch className="mb-1" />
                </button>
                <input
                  type="text"
                  className="form-control search-input-user"
                  placeholder="Search"
                  aria-label="Example text with button addon"
                  aria-describedby="button-addon1"
                  ref={shiftTimingRef}
                  onChange={() => filterShiftTimings()}
                  onFocus={() => setSearchInputFocused(true)}
                  onBlur={() => setSearchInputFocused(false)}
                />
              </div>
              <div className="create-btn">
                <GradientBorderButton
                  icon={<IoMdAddCircle />}
                  text="Create"
                  iconAdditionalClass="icon-width"
                  additionalClass="mt-0"
                  outerDivClass="height-34"
                  innerDivClass="height-30 gap-0"
                  clickHandler={handleClosePopup}
                />
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">
                    <div className="d-flex justify-content-center">#</div>
                  </th>
                  <th scope="col">
                    <div className="d-flex justify-content-center">
                      Start Time
                    </div>
                  </th>
                  <th scope="col">
                    <div className="d-flex justify-content-center">
                      End Time
                    </div>
                  </th>
                  <th scope="col">
                    <div className="d-flex justify-content-center">Status</div>
                  </th>
                  <th scope="col">
                    <div className="d-flex justify-content-center">Edit</div>
                  </th>
                </tr>
              </thead>

              <tbody>
                {shiftTimings.map((data, index) => (
                  <tr key={data.id}>
                    <td>
                      <div className="d-flex justify-content-center switch-container">
                        {index + 1}
                      </div>
                    </td>
                    <td>
                      <div className="d-flex justify-content-center">
                        {data.startTime}
                      </div>
                    </td>
                    <td>
                      <div className="d-flex justify-content-center">
                        {data.endTime}
                      </div>
                    </td>
                    <td>
                      <div className="d-flex justify-content-center switch-container">
                        <Switch
                          id={data.id}
                          isOn={data.status}
                          handleToggle={() => handleToggle(data)}
                          colorOne="#0FB215"
                          colorTwo="#DB0000"
                        />
                      </div>
                    </td>
                    <td>
                      <div className="d-flex justify-content-center gap-3">
                        <span>
                          <FaEdit
                            onClick={() => {
                              handleEditPopup(data.id);
                            }}
                          />
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShitftTimings;
