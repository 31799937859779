import React from 'react';

const Switch = ({ id, isOn, handleToggle, colorOne, colorTwo }) => {
  const handleChange = () => {
    handleToggle(id); // Pass the id to the handleToggle function
  };

  return (
    <>
      <input
        checked={isOn}
        onChange={handleChange} // Trigger handleChange when the input changes
        className="switch-checkbox"
        id={`switch-${id}`}
        type="checkbox"
      />
      <label
        style={{ background: isOn ? colorOne : colorTwo }}
        className="switch-label"
        htmlFor={`switch-${id}`} // Use the correct htmlFor value
      >
        <span className={`switch-button`} />
      </label>
    </>
  );
};

export default Switch;
