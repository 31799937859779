import React, { useRef, useState, useEffect } from "react";
import userRegistrationService from "../../services/userRegistration.service";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import licenceValidator from "../../permission/validate";
import { AiOutlineClose } from "react-icons/ai";
import masterDBService from "../../services/masterDB.service";
import { TbCameraPlus } from "react-icons/tb";
import minioService from "../../services/minio.service";
import GradientBorderButton from "../Widgets/GradientBorderButton";
import Select from "react-select";
import { IoIosArrowBack } from "react-icons/io";
import DatePicker from "react-datepicker";
function SingleUser() {
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const designtaionRef = useRef();
  const empIdRef = useRef();
  const dobRef = useRef(); //Date of birth Ref
  const genderRef = useRef();
  const residencyRef = useRef();

  const bloodGroupRef = useRef();
  const nationalityRef = useRef();
  const phoneNumberRef = useRef();
  const addressRef = useRef();
  const departmentRef = useRef();
  const shiftRef = useRef();
  const shiftTimingsRef = useRef();
  const dojRef = useRef(); //Date of Joining Ref
  const confirmationDateRef = useRef();
  const employmentTypeRef = useRef();
  const emailRef = useRef();

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [formType, setFormType] = useState("register");
  const [isVisible, setIsVisible] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [departments,setDepartments] = useState([])
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [selectedShift,setSelectedShift] = useState('');
  const [shiftTimings, setShiftTimings] = useState([]);
  const [shiftTime,setShiftTime] = useState('');
  const [successNotific, setSuccessNotific] = useState("");
  const [errorNotific, setErrorNotific] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [profileImage,setProfileImage] = useState("");
  const [userList,setUserList] = useState([])
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedDOB,setSelectedDOB] = useState("");
  const [selectedDOJ,setSelectedDOJ] = useState("");
  const [selectedConfirmation,setSelectedConfirmation] = useState("");
  const navigate = useNavigate();
  const [previousProfilePic,setPreviousProfilePic] = useState("");

  const getMasterDB = async()=>{
    const data = await masterDBService.getAllDepartments();
    const filteredDepartments = data.results.filter(department => {
      // Filter departments with at least one geography with status true
      const hasTrueGeography = department.geography.some(geo => geo.status === true);
  
      if (!hasTrueGeography) {
          return false; // Skip departments without any true geography
      }
  
      // Filter geographies with at least one shift timing with status true
      department.geography = department.geography.filter(geo => {
          const hasTrueShiftTiming = geo.shiftTiming.some(shift => shift.status === true);
          return hasTrueShiftTiming;
      });
  
      // Remove departments without any geography after filtering shift timings
      return department.geography.length > 0;
  });
  
    setDepartments(filteredDepartments)
  }


  const navToManage = () =>{
    navigate("/admin/user-registration/manage-user")
  }
  const handleDepartmentChange = () => {
    const selectedDepartmentName = departmentRef.current.value;
    setSelectedDepartment(selectedDepartmentName);
    shiftTimingsRef.current.value="default";
    shiftRef.current.value="default";
    setSelectedShift(''); 
  };

  const handleShiftChange = async () => {
    const selectedShiftName = shiftRef.current.value;
    setSelectedShift(selectedShiftName);
    const selectedDepartmentData = departments.find(department => department.id === selectedDepartment);
    const selectedShiftData = selectedDepartmentData?.geography.find(geo => geo.id === selectedShiftName);
    setShiftTimings(selectedShiftData?.shiftTiming || []);
    setShiftTime('')
    shiftTimingsRef.current.value="default";
  };

  const handleShiftTimingChange = async () =>{
    const selectedShiftTime = shiftTimingsRef.current.value;
    setShiftTime(selectedShiftTime);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const fieldsToValidate = [
        { ref: firstNameRef, name: "First Name" },
        { ref: lastNameRef, name: "Last Name" },
        { ref: designtaionRef, name: "Designation" },
        { ref: empIdRef, name: "Employee ID" },
        { ref: selectedDOB, name: "Date of Birth" },
        { ref: genderRef, name: "Gender" },
        { ref: residencyRef, name: "Residency" },
        { ref: bloodGroupRef, name: "Blood Group" },
        { ref: nationalityRef, name: "Nationality" },
        { ref: phoneNumberRef, name: "Phone Number" },
        { ref: addressRef, name: "Address" },
        { ref: departmentRef, name: "Department" },
        { ref: selectedDOJ, name: "Date of Joining" },
        { ref: shiftRef, name: "Shift" },
        { ref: employmentTypeRef, name: "Employment Type" },
        { ref: emailRef, name: "Email" },
        { ref: shiftTimingsRef, name: "Shift timings" },
    ];

    let emptyFields = [];

    fieldsToValidate.forEach(({ ref, name }) => {
      if(name != "Date of Joining" && name != "Date of Birth"){
      const value = ref.current.value.trim();
      if (value === "" || value === "default") {
          emptyFields.push(name);
      } 
    } else {
      if(!ref){
        emptyFields.push(name);
      }
    }
    });
 
    if(selectedUser===null){
      emptyFields.push("Report To")
    }
    if (emptyFields.length > 0) {
      setShowAlert(true);
      setErrorNotific(`${emptyFields.join(", ")} should not be empty.`);
      setSuccessNotific("");
      setTimeout(() => {
          setShowAlert(false);
      }, 3000);
      return;
  }
  if(!/^[a-zA-Z\s]*$/g.test(firstNameRef.current.value)){
    setShowAlert(true)
    setErrorNotific("First name must be alphabets only")
    setTimeout(() => {
      setErrorNotific("")
      setShowAlert(false)
    }, 2000);
    return
  }
  if(!/^[a-zA-Z\s]*$/g.test(lastNameRef.current.value)){
    setShowAlert(true)
    setErrorNotific("Last name must be alphabets only")
    setTimeout(() => {
      setErrorNotific("")
      setShowAlert(false)
    }, 2000);
    return
  }
  if(!/^[0-9]+$/
  .test(phoneNumberRef.current.value)){
      setShowAlert(true)
      setErrorNotific("Phone number must be numbers only")
      setTimeout(() => {
        setErrorNotific("")
        setShowAlert(false)
      }, 2000);
      return
    }
    if(phoneNumberRef.current.value.length > 10 || phoneNumberRef.current.value.length < 10){
      setShowAlert(true)
      setErrorNotific("Phone number must be of 10 digits")
      setTimeout(() => {
        setErrorNotific("")
        setShowAlert(false)
      }, 2000);
      return
    }
  if(!/^[a-zA-Z0-9]+$/
.test(empIdRef.current.value)){
    setShowAlert(true)
    setErrorNotific("Employee Id must be text and numbers only")
    setTimeout(() => {
      setErrorNotific("")
      setShowAlert(false)
    }, 2000);
    return
  }
if(!/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/.test(password) && formType === "register"){
  setShowAlert(true)
  setErrorNotific("Password must have atleast 8 characters 1 number and 1 special character.")
  setTimeout(() => {
    setErrorNotific("")
    setShowAlert(false)
  }, 2000);
  return
}

  if(new Date(dojRef.current.value) > new Date(confirmationDateRef.current.value) && confirmationDateRef.current.value){
    
    setShowAlert(true);
    setErrorNotific("Date of joining can't be greater than Date of confirmation");
    setSuccessNotific("");
    setTimeout(() => {
        setShowAlert(false);
    }, 3000);
    return;
  }
 

    setShowPopup(true);
};

  const handleUserRegistration = async () => {
    try {
  
  let response
  let user = {
   
    firstName:firstNameRef.current.value,
    lastName: lastNameRef.current.value,
    designation: designtaionRef.current.value,
    empId:empIdRef.current.value,
    dateOfBirth:selectedDOB,
    gender:genderRef.current.value,
    residence:residencyRef.current.value,
    bloodGroup:bloodGroupRef.current.value,
    nationality:nationalityRef.current.value,
    phoneNumber:phoneNumberRef.current.value,
    address:addressRef.current.value,
    department:departmentRef.current.value,
    shiftTiming: shiftTimingsRef.current.value,
    shift: shiftRef.current.value,
    dateOfJoining:selectedDOJ,
    confirmationDate:selectedConfirmation,
    employmentType:employmentTypeRef.current.value,
    email: emailRef.current.value,
    password:password,
    reportTo:selectedUser.value,
    license: [
      {
        "_id": "651588f225e52f2fdc7ea58b",
        "moduleName": "assist",
        "isAccessible": true,
        "permissions": [
          {
            "_id": "75683b4997c04b7891731c3401a07d79",
            "subModuleName": "jd_generator",
            "create": true,
            "read": true,
            "update": true,
            "delete": true
          },
          {
            "_id": "380a82ac81e846acadf5e4c23953d9ce",
            "subModuleName": "recruiter_social",
            "create": true,
            "read": true,
            "update": true,
            "delete": true
          },
          {
            "_id": "8b9f6ea51256437588c469cf587524c1",
            "subModuleName": "search_query_generator",
            "create": true,
            "read": true,
            "update": true,
            "delete": true
          },
          {
            "_id": "bebdd9ba91144359ac40eab5ec1",
            "subModuleName": "recruiter_outreach",
            "create": true,
            "read": true,
            "update": true,
            "delete": true
          },
          {
            "_id": "9700dec940b249b295911e3dd134e172",
            "subModuleName": "domain_identifier",
            "create": true,
            "read": true,
            "update": true,
            "delete": true
          },
          {
            "_id": "90561bdec9f94e87a3eb28e21a56554c",
            "subModuleName": "recommended_jobs",
            "create": true,
            "read": true,
            "update": true,
            "delete": true
          },
          {
            "_id": "5464b5460af448cbaced04a55e4a9545",
            "subModuleName": "relevant_candidate_finder",
            "create": true,
            "read": true,
            "update": true,
            "delete": true
          },
          {
            "_id": "daaa953aa304466c84d6f642943d7016",
            "subModuleName": "skill_highlighter",
            "create": true,
            "read": true,
            "update": true,
            "delete": true
          },
          {
            "_id": "daaa953aa304466c84d6f642943d8670",
            "subModuleName": "interview_q_and_a",
            "create": true,
            "read": true,
            "update": true,
            "delete": true
          }
        ]
      },
      {
        "_id": "649588f225e52f2fdc7ea58c",
        "moduleName": "memories",
        "isAccessible": true,
        "permissions": [
          {
            "_id": "75683b4197c04b7891731c3401a07d79",
            "subModuleName": "photo_upload",
            "create": false,
            "read": false,
            "update": false,
            "delete": false
          }
        ]
      },
      {
        "_id": "649588f225e52fg4fdc7ea58b",
        "moduleName": "buzz",
        "isAccessible": true,
        "permissions": [
          {
            "_id": "649288f235e52f2ddc7ea58c",
            "subModuleName": "buzz_admin",
            "create": false,
            "read": false,
            "update": false,
            "delete": false
          }
        ]
      },
      {
        "isAccessible": true,
        "moduleName": "home",
        "permissions": [
          {
            "_id": "eeac041c-c17f-4bd8-b5be-efebe2d9686e",
            "subModuleName": "alois_feed",
            "create": false,
            "read": true,
            "update": false,
            "delete": false
          },
          {
            "_id": "67f44650-1775-41ac-aca5-79f15eb65d7b",
            "subModuleName": "message",
            "create": false,
            "read": true,
            "update": false,
            "delete": false
          },
          {
            "_id": "a7a36623-1830-4340-b604-65ebf85435f3",
            "subModuleName": "images",
            "create": false,
            "read": true,
            "update": false,
            "delete": false
          },
          {
            "_id": "f19ee79e-3bcf-4e73-b2c7-6ad9603430e7",
            "subModuleName": "videos",
            "create": false,
            "read": true,
            "update": false,
            "delete": false
          },
          {
            "_id": "5170ca5a-0eaa-47f2-803d-ad58f827ae36",
            "subModuleName": "poll",
            "create": false,
            "read": true,
            "update": false,
            "delete": false
          },
          {
            "_id": "75683b4c04b7891731c3401a07a99",
            "subModuleName": "notice_board",
            "create": false,
            "read": true,
            "update": false,
            "delete": false
          }
        ]
      },
      {
        "_id": "ppd6df3c-po78-9030-kpo0-12fg35fyy56b",
        "moduleName": "admin",
        "isAccessible": false,
        "permissions": [
          {
            "_id": "47697bb0-6127-4d88-835c-5bed120c9b91",
            "moduleName": "user_registration",
            "isAccessible": false,
            "permissions": [
              {
                "_id": "649588f235e52f2fdc7ea58c",
                "subModuleName": "single_user",
                "create": false,
                "read": false,
                "update": false,
                "delete": false
              },
              {
                "_id": "649588f235e52f2fdc7ea58d",
                "subModuleName": "multi_users",
                "create": false,
                "read": false,
                "update": false,
                "delete": false
              },
              {
                "_id": "649588f235e52f2fma7ea58d",
                "subModuleName": "manage_user",
                "create": false,
                "read": false,
                "update": false,
                "delete": false
              }
            ]
          },
          {   
            "_id": "18533f25-94b2-4bbb-b966-32e7ee59a29c",
            "moduleName": "emailers",
            "isAccessible": false,
            "permissions": [
              {
                "_id": "bae1e553-9f21-43b5-bb1e-e744e5f03c03",
                "subModuleName": "bulk_mail",
                "create": false,
                "read": false,
                "update": false,
                "delete": false
              },
              {
                "_id": "fda0b0ae-c480-4711-86a0-244a5b3e48a2",
                "subModuleName": "previous_mail",
                "create": false,
                "read": false,
                "update": false,
                "delete": false
              },
           
            ]
          },
          {
            "_id": "90e8cb2c-eb60-4ae9-bf1a-644ac923a8c1",
            "moduleName": "template_bank",
            "isAccessible": false,
            "permissions": [
              {
                "_id": "34d33fdc-435a-4150-821a-907e665cb6f7",
                "subModuleName": "social_media_templates",
                "create": false,
                "read": false,
                "update": false,
                "delete": false
              },
              {
                "_id": "9d433b61-ff41-45b8-8c25-593a99f2a40c",
                "subModuleName": "anniversary_template_bank",
                "create": false,
            "read": false,
            "update": false,
            "delete": false
              },
              {
                "_id": "e0f7034a-cff1-4b9b-8002-fbc2b26dbbd7",
                "subModuleName": "birthday_template_bank",
                "create": false,
                "read": false,
                "update": false,
                "delete": false
              },
              {
                "_id": "75683b4197c04b7891731c3401a07d79",
                "subModuleName": "image",
                "create": false,
                "read": false,
                "update": false,
                "delete": false
              }
            ]
          },
          {
            "_id": "8a341493-7229-44b9-ab86-24a35a1a9c7d",
            "subModuleName": "jd_upload",
            "create": false,
            "read": false,
            "update": false,
            "delete": false
          },
          {
            "_id": "247ca95b-9a17-4047-8729-66c846de9097",
            "subModuleName": "boolean_quiz",
            "create": false,
            "read": false,
            "update": false,
            "delete": false
          },
          {
            "_id": "abed0c70-5214-4eea-a7c8-f301e7f70c98",
            "subModuleName": "quiz_result",
            "create": false,
            "read": false,
            "update": false,
            "delete": false
          },
          {
            "_id": "d456826a-b317-423b-b568-9c8417fd5a9a",
            "subModuleName": "quiz_evaluation",
            "create": false,
            "read": false,
            "update": false,
            "delete": false
          },
          {
            "_id": "75683b4c04b7891731c3401a07d79",
            "subModuleName": "role_based_access_control",
            "create": false,
            "read": false,
            "update": false,
            "delete": false
          },
          {
            "_id": "649588f225e52f1fdc7ea5we8b",
            "subModuleName": "clan_score_master",
            "create": false,
            "read": false,
            "update": false,
            "delete": false
          },
          {
            "_id": "c6fac7d3-bcf3-4da9-828c-2e0045bacd5c",
            "subModuleName": "birthday_post",
            "create": false,
            "read": false,
            "update": false,
            "delete": false
          },
          {
            "_id": "649588f225e52f1fdc7ea58b",
            "subModuleName": "activity_log",
            "create": false,
            "read": false,
            "update": false,
            "delete": false
          },  
          {
            "_id": "e93ed89e-ad89-41f3-b2aa-b22ebaece238",
            "subModuleName": "feedback",
            "create": false,
            "read": false,
            "update": false,
            "delete": false
          }
        ]
      }
    ],
  }
  if(profileImage){
     response = await minioService.UploadSingleFile(
      profileImage,
      "user_photo"
    );
    user.profilePic = response?.data?.url
  }
    
      const result= userRegistrationService.createSingleUser(user);
      navigate("/admin/user-registration/manage-user");

 
    } catch (error) {
      console.error(error);
    }
  };


  const getUsers = async () => {
    const data = await userRegistrationService.getUsers1();
    const formattedUserList = data.results.map(user => ({
      value: user.email,
      label: user.email
    }));
    setUserList(formattedUserList);
  }


  useEffect(() => {
    let user = localStorage.getItem("user");
    let validator = new licenceValidator(user, "user_registration");
    setIsVisible(validator.isReadable("single_user"));
    getMasterDB();
    getUsers();
  }, []);
  const handleUserUpdate = async () => {
    try {
      // const userId = JSON.parse(sessionStorage.user).id;
      if (/^[a-zA-Z\s]*$/g.test(firstNameRef.current.value) &&
            /^[a-zA-Z\s]*$/g.test(lastNameRef.current.value) &&
            /^[a-zA-Z0-9]+$/.test(empIdRef.current.value)) {
      let profilePicUrl = previousProfilePic;
      let userData = {
       
        firstName: firstNameRef.current.value ,
        lastName: lastNameRef.current.value ,
        designation: designtaionRef.current.value ,
        empId: empIdRef.current.value ,
        dateOfBirth: selectedDOB ,
        gender: genderRef.current.value ,
        residence: residencyRef.current.value ,
        bloodGroup: bloodGroupRef.current.value ,
        nationality: nationalityRef.current.value ,
        phoneNumber: phoneNumberRef.current.value ,
        address: addressRef.current.value ,
        department: departmentRef.current.value ,
        shiftTiming: shiftTimingsRef.current.value ,
        shift: shiftRef.current.value ,
        dateOfJoining: selectedDOJ ,
        confirmationDate: selectedConfirmation || "" ,
        employmentType: employmentTypeRef.current.value ,
        email: emailRef.current.value ,
        reportTo: selectedUser.value,
        password 
      };
      if (profileImage !== previousProfilePic && profileImage) {
          const response = await minioService.UploadSingleFile(
              profileImage,
              "user_photo"
          );
          profilePicUrl = response.data.url ;
          userData.profilePic = profilePicUrl
      }
      const userData1 = JSON.parse(sessionStorage.user);

      
      if (password === "") delete userData.password;

      let userId = userData1.id;
      const result = await userRegistrationService.updateUser(userId, userData);
      navigate("/admin/user-registration/manage-user");

    } else {
      if(!/^[a-zA-Z\s]*$/g.test(firstNameRef.current.value)){
        setShowAlert(true)
        setErrorNotific("First name must be alphabets only")
        setTimeout(() => {
          setErrorNotific("")
          setShowAlert(false)
        }, 2000);
      }
      if(!/^[a-zA-Z0-9]+$/
.test(empIdRef.current.value)){
        setShowAlert(true)
        setErrorNotific("Employee Id must be text and numbers only")
        setTimeout(() => {
          setErrorNotific("")
          setShowAlert(false)
        }, 2000);
      }
      if(!/^[a-zA-Z\s]*$/g.test(lastNameRef.current.value)){
        setShowAlert(true)
        setErrorNotific("Last name must be alphabets only")
        setTimeout(() => {
          setErrorNotific("")
          setShowAlert(false)
        }, 2000);
      }
    }
    } catch (error) {
      
      setShowAlert(true)
      setErrorNotific(error.response.data.message)
      setTimeout(() => {
        setShowAlert(false)
      }, 2000);
      console.error(error);
    }
  };

  const autoGeneratePassword = () => {
    const length = 12;
    const alphabets = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    const numbers = "0123456789";
    const specialCharacters = "!@#$%^&*()";

    let randomPassword = "";
    for (let i = 0; i < length / 3; i++) {
      const randomSequence = Math.floor(Math.random() * 3);
      const randomNumber = Math.floor(Math.random() * numbers.length);
      const randomAlphabet = Math.floor(Math.random() * alphabets.length);
      const randomCharacters = Math.floor(
        Math.random() * specialCharacters.length
      );
      if (randomSequence === 0) {
        randomPassword +=
          numbers[randomNumber] +
          alphabets[randomAlphabet] +
          specialCharacters[randomCharacters];
      } else if (randomSequence === 1) {
        randomPassword +=
          alphabets[randomAlphabet] +
          numbers[randomNumber] +
          specialCharacters[randomCharacters];
      } else {
        randomPassword +=
          specialCharacters[randomCharacters] +
          alphabets[randomAlphabet] +
          numbers[randomNumber];
      }
    }
    setPassword(randomPassword);
  };


  useEffect(() => {
    if (window.location.pathname.includes("single-user/update")) {
      setFormType("update");
      const userData = JSON.parse(sessionStorage.user);
      setPreviousProfilePic(userData.profilePic);
      setImagePreview(userData.profilePic);
      setProfileImage(userData.profilePic);
      firstNameRef.current.value = userData.firstName;
      lastNameRef.current.value = userData.lastName;
      designtaionRef.current.value = userData.designation;
      empIdRef.current.value = userData.empId;
      genderRef.current.value=userData.gender;
      residencyRef.current.value=userData.residence;

      bloodGroupRef.current.value=userData.bloodGroup;
      nationalityRef.current.value=userData.nationality;
      phoneNumberRef.current.value=userData.phoneNumber;
      addressRef.current.value=userData.address;
      departmentRef.current.value = userData.department;
      employmentTypeRef.current.value=userData.employmentType;
      setSelectedUser({label:userData.reportTo,value:userData.reportTo});
      shiftRef.current.value = userData.shift;
      emailRef.current.value = userData.email;
      if(departments.length){
        const selectedDepartmentData = departments.find(department => department.id === userData.department);
        const selectedShiftData = selectedDepartmentData?.geography.find(shift => shift.id === userData.shift);
  
        setSelectedDepartment(userData.department);
        setSelectedShift(userData.shift);
        setShiftTimings(selectedShiftData?.shiftTiming || []);
        setShiftTime(userData.shiftTiming);
      } else {
        setSelectedDepartment("default");
        setSelectedShift("default");
        setShiftTime("default");
      }
 
      
      
      let dateOfBirth = userData.dateOfBirth
      console.log(typeof dateOfBirth)
      if(typeof dateOfBirth == "string" && !dateOfBirth.includes("T") && dateOfBirth){
        const dateParts = dateOfBirth.split(/[-\/]/);
        dateOfBirth =  dateParts[1]+"/"+dateParts[0]+"/"+dateParts[2]
      } else if(dateOfBirth) {
        let date1 = new Date(dateOfBirth);
        dateOfBirth =  `${date1.getMonth() + 1}/${date1.getDate()}/${date1.getFullYear()}`
      } else if(!dateOfBirth) {
        dateOfBirth = null
      }
      if(dateOfBirth){
        dobRef.current.value=new Date(dateOfBirth);
        setSelectedDOB(dobRef.current.value);

      } else {

      }
      
      let dateOfJoining = userData.dateOfJoining;
      
      if(typeof dateOfJoining == "string" && !dateOfJoining.includes("T") && dateOfJoining){
        const dateParts = dateOfJoining.split(/[-\/]/);
        dateOfJoining =  dateParts[1]+"/"+dateParts[0]+"/"+dateParts[2]
      } else if (dateOfJoining) {
        let date1 = new Date(dateOfJoining);
        dateOfJoining =  `${date1.getMonth() + 1}/${date1.getDate()}/${date1.getFullYear()}`
      } else if(!dateOfJoining) {
        dateOfJoining = null
      }
      if(dateOfJoining){
        dojRef.current.value= new Date(dateOfJoining);
        setSelectedDOJ(dojRef.current.value);
      }
      
      
      let confirmationDate = userData.confirmationDate;
      if(typeof confirmationDate == "string" && !confirmationDate.includes("T") && confirmationDate){
        const dateParts = confirmationDate.split(/[-\/]/);
        confirmationDate =  dateParts[1]+"/"+dateParts[0]+"/"+dateParts[2]
      } else if(confirmationDate) {
        let date1 = new Date(confirmationDate);
       confirmationDate =  `${date1.getMonth() + 1}/${date1.getDate()}/${date1.getFullYear()}`
      } else if(!confirmationDate) {
        confirmationDate = null
      }
      if(confirmationDate){
        confirmationDateRef.current.value=new Date(confirmationDate);
        setSelectedConfirmation(confirmationDateRef.current.value);
      }
      
    }
  }, [departments]);
  
  
  useEffect(() => {
    if (shiftTime) {
      shiftTimingsRef.current.value = shiftTime;
    }else{
      shiftTimingsRef.current.value="default";
    }
  }, [shiftTimings]);
  
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setProfileImage(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setImagePreview(null);
    }
  };

  const handleUserChange = (selectedOption) => {
    setSelectedUser(selectedOption);
  };

  const convertToYearMonthDateFormat = (dateString) => {
    if (!dateString) {
        return null; 
    }
    if(typeof dateString == String){
      const dateParts = dateString.split(/[-\/]/);
      dateString =  dateParts[1]+"/"+dateParts[0]+"/"+dateParts[2]
    } 
    
    let date = new Date(dateString);
    
// Extract the day, month, and year
let day = String(date.getDate()).padStart(2, '0');
let month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
let year = date.getFullYear();

// Format the date as dd-mm-yyyy
let formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  };

  const convertToDateMonthYearFormat = (dateString) => {
    if (!dateString) {
      return null; 
  }
  if(typeof dateString == String){
    const dateParts = dateString.split(/[-\/]/);
    dateString =  dateParts[1]+"/"+dateParts[0]+"/"+dateParts[2]
  }
  
  let date = new Date(dateString);

  // Extract the day, month, and year
  let day = String(date.getDate()).padStart(2, '0');
  let month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  let year = date.getFullYear();
  
  // Format the date as dd-mm-yyyy
  let formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
}



  const handleDOBChange = () =>{
    const dob = dobRef.current.value;
    if (dob) {
        const [year, month, day] = dob.split("-");
        const formattedDOB = `${day}-${month}-${year}`;
        setSelectedDOB(dob);
    } else {
        console.log("Invalid date of birth");
    }
  }
  const handleDOJChange = () =>{
    const doj = dojRef.current.value;
    if (doj) {
        const [year, month, day] = doj.split("-");
        const formattedDOJ = `${day}-${month}-${year}`;
        setSelectedDOJ(doj);
    } else {
        console.log("Invalid date of joining");
    }
  }

  const handleConfirmationChange = () =>{
    const confirmationDate = confirmationDateRef.current.value;
    if (confirmationDate) {
        const [year, month, day] = confirmationDate.split("-");
        const formattedConfirmation = `${day}-${month}-${year}`;
        setSelectedConfirmation(confirmationDate);
    } else {
        console.log("Invalid date of confirmation");
    }
  }
 
  useEffect(()=>{
    let dashboardElement = document.querySelector(".dashboard");
    const body = document.body;
    
    if (dashboardElement && showPopup) {
      // Remove the 'transform' property
      
      

      // Remove the 'transform-origin' property
      // dashboardElement.style.transformOrigin = '';
    } else if(!showPopup && dashboardElement){
  

    }
  },[showPopup])

  return (
    <form className="single-user-form" onSubmit={handleSubmit}>
          {showAlert ? (
        <div className="alert-container">
          <div className="upper-section">
            <p style={{ marginBottom: "0px", marginTop: "5px" }}>ALOIS</p>
            <span  className="delete-btn" onClick={() => setShowAlert(!showAlert)}>
              <AiOutlineClose className="icon" style={{ fill: "black" }} />
            </span>
          </div>
          <hr
            style={{
              display: "block !important",
              height: "1px !important",
              border: "0 !important",
              borderTop: "1px solid gray !important",
            }}
          />
          <div className="lower-section py-2">
            <p className="text-danger">{errorNotific}</p>
            <p className="text-success">{successNotific}</p>
          </div>
        </div>
      ) : null}
<div className="d-flex justify-content-start align-items-center">{formType == "update" ?  <div

      className="back-btn p-0 my-0 mx-2"
    ><span className="w-100">
        <IoIosArrowBack
          className="icon "
          onClick={navToManage}
          style={{ marginBottom: "10px", cursor: "pointer" }}
        />
      </span> </div> :<></>} <p style={{ fontSize: "1.3rem", color: "#a4b3ba", margin:0 }}>
        {formType === "register" ? "Single User" : "User Update"}
      </p></div>


      {showPopup ? (
        <div className="user-reg-popup-container">
          <div className="single-user-reg-popup">
            <div className="user-reg-popup-header">
              <span className="fs-6 fw-bold">Details</span>

              <button
                className="close-button"
                onClick={() => setShowPopup(!showPopup)}
              >
                &#10005;
              </button>
            </div>

            <hr style={{ borderTop: "2px dashed black" }} />

            <div className="user-reg-popup-content">
              <div className="form-fields-container">
                <div className="d-flex flex-column">
                  <div className="d-flex" style={{justifyContent:"center"}}>
                  <div className="profile-picture-upload">
                    <label htmlFor="profilePicture">
                      {imagePreview && (
                        <div className="preview" style={imagePreview ? { backgroundImage: `url(${imagePreview})`, backgroundSize: 'cover', backgroundPosition: 'top', backgroundRepeat: 'no-repeat' } : null}></div>
                      )}
                    </label>
                  </div>
                  </div>
                  <div className="d-flex">
                    <p className="labels">First Name:</p>
                    <p className="values">{
                      firstNameRef.current.value.charAt(0).toUpperCase() +
                      firstNameRef.current.value.slice(1)
                    }</p>
                  </div>

                  <div className="d-flex">
                    <p className="labels">Last Name:</p>
                    <p className="values">{
                    lastNameRef.current.value.charAt(0).toUpperCase() +
                    lastNameRef.current.value.slice(1)
                    }</p>
                  </div>

                  <div className="d-flex">
                    <p className="labels">Designation:</p>
                    <p className="values">{
                    designtaionRef.current.value.charAt(0).toUpperCase() +
                    designtaionRef.current.value.slice(1)
                    }</p>
                  </div>

                  <div className="d-flex">
                    <p className="labels">Employee ID:</p>
                    <p className="values">{empIdRef.current.value}</p>
                  </div>

                 
                  <div className="d-flex">
                    <p className="labels">Date of Birth:</p>
                    <p className="values">{convertToDateMonthYearFormat(selectedDOB)}</p>
                  </div>

                  <div className="d-flex">
                    <p className="labels">Gender:</p>
                    <p className="values">{
                    genderRef.current.value.charAt(0).toUpperCase() +
                    genderRef.current.value.slice(1)
                    }</p>
                  </div>
                  <div className="d-flex">
                    <p className="labels">Residence:</p>
                    <p className="values">{
                    residencyRef.current.value.charAt(0).toUpperCase() +
                    residencyRef.current.value.slice(1)
                    }</p>
                  </div>
                  <div className="d-flex">
                    <p className="labels">Blood Group:</p>
                    <p className="values">{
                      bloodGroupRef.current.value.charAt(0).toUpperCase() +
                      bloodGroupRef.current.value.slice(1)}
                      </p>
                  </div>  

                  <div className="d-flex">
                    <p className="labels">Nationality:</p>
                    <p className="values">{
                    nationalityRef.current.value.charAt(0).toUpperCase() +
                    nationalityRef.current.value.slice(1)}</p>
                  </div>
                  
                  <div className="d-flex">
                    <p className="labels">Phone Number:</p>
                    <p className="values">{
                     phoneNumberRef.current.value.charAt(0).toUpperCase() +
                     phoneNumberRef.current.value.slice(1)
                    }</p>
                  </div>
                  
                  <div className="d-flex">
                    <p className="labels">Address:</p>
                    <p className="values">{
                     addressRef.current.value.charAt(0).toUpperCase() +
                     addressRef.current.value.slice(1)
                    }</p>
                  </div>

                  <div className="d-flex">
                    <p className="labels">Date of Joining:</p>
                    <p className="values">{convertToDateMonthYearFormat(selectedDOJ)}</p>
                  </div>

                  <div className="d-flex">
                    <p className="labels">Email:</p>
                    <p className="values">{emailRef.current.value}</p>
                  </div>

                  <div className="d-flex">
                    <p className="labels">Department:</p>
                    <p className="values">{departments.find(department => department.id === departmentRef.current.value).department}</p>
                  </div>

                  <div className="d-flex">
                    <p className="labels">Shift :</p>
                    <p className="values">{departments.find(department => department.id === departmentRef.current.value)?.geography.find(shift => shift.id === shiftRef.current.value).geography}</p>
                  </div>

                  <div className="d-flex">
                    <p className="labels">Shift timings:</p>
                    <p className="values">{departments.find(department => department.id === departmentRef.current.value)?.geography.find(shift => shift.id === shiftRef.current.value).shiftTiming.find(shift => shift.id === shiftTimingsRef.current.value).startTime + " - " + departments.find(department => department.id === departmentRef.current.value)?.geography.find(shift => shift.id === shiftRef.current.value).shiftTiming.find(shift => shift.id === shiftTimingsRef.current.value).endTime}</p>
                  </div>

                  {selectedConfirmation && (     <div className="d-flex">
                 <p className="labels">Confirmation Date:</p>
               <p className="values">{convertToDateMonthYearFormat(selectedConfirmation)}</p>
                  </div>)}

                  <div className="d-flex">
                    <p className="labels">Employment Type:</p>
                    <p className="values">{employmentTypeRef.current.value}</p>
                  </div>

                  <div className="d-flex">
                    <p className="labels">Report To:</p>
                    <p className="values">{selectedUser.value}</p>
                  </div>

                </div>
              </div>

              <div className="d-flex justify-content-center pt-2 gap-3">
              <GradientBorderButton
                     text="Confirm"
                     clickHandler={formType === "register"
                     ? handleUserRegistration
                     : handleUserUpdate}
                    
                   />
                   <GradientBorderButton
                     text="Edit"
                     clickHandler={() => setShowPopup(false)}
                    
                   />
               
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <div className="row">
     <div className="col-md-3 profile-image-container">
          <div className="profile-picture-upload">
            <label htmlFor="profilePicture">
              {imagePreview ? (
                <div className="preview" style={imagePreview ? { backgroundImage: `url(${imagePreview})`, backgroundSize: 'cover', backgroundPosition: 'top', backgroundRepeat: 'no-repeat' } : null}></div>
              ) : (
                <TbCameraPlus className="camera-icon" />
              )}
            </label>
            <input type="file" id="profilePicture" className="profilePicture-input" onChange={handleImageChange} accept="image/*" />
          </div>
        </div>
        <div className="col-md-9">
          <div className="row">
            <div className="col">
              <div className="form-outline">
                <label className="form-label" htmlFor="firstName">
                  First Name*
                </label>
                <input
                  type="text"
                  ref={firstNameRef}
                  id="firstName"
                  name="First Name"
                  className="form-control"
                  required
                />
              </div>
            </div>
            <div className="col">
              <div className="form-outline">
                <label className="form-label" htmlFor="lastName">
                  Last Name*
                </label>
                <input
                  type="text"
                  ref={lastNameRef}
                  id="lastName"
                  name="Last Name"
                  className="form-control"
                  required
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="form-outline">
                <label className="form-label" htmlFor="designation">
                  Designation*
                </label>
                <input
                  type="text"
                  ref={designtaionRef}
                  id="designation"
                  name="Designation"
                  className="form-control"
                  required
                />
              </div>
            </div>
            <div className="col">
              <div className="form-outline">
                  <div className="form-outline ">
                    <label className="form-label" htmlFor="empId">
                      Employee ID*
                    </label>
                    <input
                      type="text"
                      ref={empIdRef}
                      id="empId"
                      name="Employee ID"
                      className="form-control"
                      required
                    />
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr style={{marginTop:"4%"}}/>
      <p style={{ fontSize: "1.3rem", color: "#a4b3ba" }}>Personal Information</p>
      <div className="row">
        <div className="col-md-4">
          <div className="form-outline"> 
            <label className="form-label" htmlFor="DOB">
              Date of birth*
            </label>
            <div className="input-group">
            <DatePicker
                      type="date"
                      className="form-control w-100"
                      id="dateOfBirthInput"
                      ref={dobRef}
                      selected={selectedDOB}
                      dateFormat="dd/MM/yyyy"
                      onChange={(e) => setSelectedDOB(e)}
                      placeholderText="Date Of Birth"
              
                    
                    />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-outline">
            <label className="form-label" htmlFor="gender">
              Gender*
            </label>
            <select id="gender" className="form-select" ref={genderRef} name="Gender" required>
              <option value="">Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
              <option value="prefer-not-to-say">Prefer not to answer</option>
            </select>
            <div className="arrow-down"></div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-outline">
            <label className="form-label" htmlFor="bloodGroup">
              Blood Group*
            </label>
            <select id="bloodGroup" className="form-select"  ref={bloodGroupRef} name="Blood Group"  required>
              <option value="">Select Blood Group</option>
              <option value="A+">A+</option>
              <option value="A-">A-</option>
              <option value="B+">B+</option>
              <option value="B-">B-</option>
              <option value="O+">O+</option>
              <option value="O-">O-</option>
              <option value="AB+">AB+</option>
              <option value="AB-">AB-</option>
            </select>
            <div className="arrow-down"></div>
          </div>
        </div>      
      </div>
      <div className="row">
        <div className="col-md-4">
          <div className="form-outline">
            <label className="form-label" htmlFor="nationality" >
              Nationality*
            </label>
            <select name="Nationality" id="nationality" className="form-select" ref={nationalityRef}>
              <option value="">-- select one --</option>
              <option value="afghan">Afghan</option>
              <option value="albanian">Albanian</option>
              <option value="algerian">Algerian</option>
              <option value="american">American</option>
              <option value="andorran">Andorran</option>
              <option value="angolan">Angolan</option>
              <option value="antiguans">Antiguans</option>
              <option value="argentinean">Argentinean</option>
              <option value="armenian">Armenian</option>
              <option value="australian">Australian</option>
              <option value="austrian">Austrian</option>
              <option value="azerbaijani">Azerbaijani</option>
              <option value="bahamian">Bahamian</option>
              <option value="bahraini">Bahraini</option>
              <option value="bangladeshi">Bangladeshi</option>
              <option value="barbadian">Barbadian</option>
              <option value="barbudans">Barbudans</option>
              <option value="batswana">Batswana</option>
              <option value="belarusian">Belarusian</option>
              <option value="belgian">Belgian</option>
              <option value="belizean">Belizean</option>
              <option value="beninese">Beninese</option>
              <option value="bhutanese">Bhutanese</option>
              <option value="bolivian">Bolivian</option>
              <option value="bosnian">Bosnian</option>
              <option value="brazilian">Brazilian</option>
              <option value="british">British</option>
              <option value="bruneian">Bruneian</option>
              <option value="bulgarian">Bulgarian</option>
              <option value="burkinabe">Burkinabe</option>
              <option value="burmese">Burmese</option>
              <option value="burundian">Burundian</option>
              <option value="cambodian">Cambodian</option>
              <option value="cameroonian">Cameroonian</option>
              <option value="canadian">Canadian</option>
              <option value="cape verdean">Cape Verdean</option>
              <option value="central african">Central African</option>
              <option value="chadian">Chadian</option>
              <option value="chilean">Chilean</option>
              <option value="chinese">Chinese</option>
              <option value="colombian">Colombian</option>
              <option value="comoran">Comoran</option>
              <option value="congolese">Congolese</option>
              <option value="costa rican">Costa Rican</option>
              <option value="croatian">Croatian</option>
              <option value="cuban">Cuban</option>
              <option value="cypriot">Cypriot</option>
              <option value="czech">Czech</option>
              <option value="danish">Danish</option>
              <option value="djibouti">Djibouti</option>
              <option value="dominican">Dominican</option>
              <option value="dutch">Dutch</option>
              <option value="east timorese">East Timorese</option>
              <option value="ecuadorean">Ecuadorean</option>
              <option value="egyptian">Egyptian</option>
              <option value="emirian">Emirian</option>
              <option value="equatorial guinean">Equatorial Guinean</option>
              <option value="eritrean">Eritrean</option>
              <option value="estonian">Estonian</option>
              <option value="ethiopian">Ethiopian</option>
              <option value="fijian">Fijian</option>
              <option value="filipino">Filipino</option>
              <option value="finnish">Finnish</option>
              <option value="french">French</option>
              <option value="gabonese">Gabonese</option>
              <option value="gambian">Gambian</option>
              <option value="georgian">Georgian</option>
              <option value="german">German</option>
              <option value="ghanaian">Ghanaian</option>
              <option value="greek">Greek</option>
              <option value="grenadian">Grenadian</option>
              <option value="guatemalan">Guatemalan</option>
              <option value="guinea-bissauan">Guinea-Bissauan</option>
              <option value="guinean">Guinean</option>
              <option value="guyanese">Guyanese</option>
              <option value="haitian">Haitian</option>
              <option value="herzegovinian">Herzegovinian</option>
              <option value="honduran">Honduran</option>
              <option value="hungarian">Hungarian</option>
              <option value="icelander">Icelander</option>
              <option value="indian">Indian</option>
              <option value="indonesian">Indonesian</option>
              <option value="iranian">Iranian</option>
              <option value="iraqi">Iraqi</option>
              <option value="irish">Irish</option>
              <option value="israeli">Israeli</option>
              <option value="italian">Italian</option>
              <option value="ivorian">Ivorian</option>
              <option value="jamaican">Jamaican</option>
              <option value="japanese">Japanese</option>
              <option value="jordanian">Jordanian</option>
              <option value="kazakhstani">Kazakhstani</option>
              <option value="kenyan">Kenyan</option>
              <option value="kittian and nevisian">Kittian and Nevisian</option>
              <option value="kuwaiti">Kuwaiti</option>
              <option value="kyrgyz">Kyrgyz</option>
              <option value="laotian">Laotian</option>
              <option value="latvian">Latvian</option>
              <option value="lebanese">Lebanese</option>
              <option value="liberian">Liberian</option>
              <option value="libyan">Libyan</option>
              <option value="liechtensteiner">Liechtensteiner</option>
              <option value="lithuanian">Lithuanian</option>
              <option value="luxembourger">Luxembourger</option>
              <option value="macedonian">Macedonian</option>
              <option value="malagasy">Malagasy</option>
              <option value="malawian">Malawian</option>
              <option value="malaysian">Malaysian</option>
              <option value="maldivan">Maldivan</option>
              <option value="malian">Malian</option>
              <option value="maltese">Maltese</option>
              <option value="marshallese">Marshallese</option>
              <option value="mauritanian">Mauritanian</option>
              <option value="mauritian">Mauritian</option>
              <option value="mexican">Mexican</option>
              <option value="micronesian">Micronesian</option>
              <option value="moldovan">Moldovan</option>
              <option value="monacan">Monacan</option>
              <option value="mongolian">Mongolian</option>
              <option value="moroccan">Moroccan</option>
              <option value="mosotho">Mosotho</option>
              <option value="motswana">Motswana</option>
              <option value="mozambican">Mozambican</option>
              <option value="namibian">Namibian</option>
              <option value="nauruan">Nauruan</option>
              <option value="nepalese">Nepalese</option>
              <option value="new zealander">New Zealander</option>
              <option value="ni-vanuatu">Ni-Vanuatu</option>
              <option value="nicaraguan">Nicaraguan</option>
              <option value="nigerien">Nigerien</option>
              <option value="north korean">North Korean</option>
              <option value="northern irish">Northern Irish</option>
              <option value="norwegian">Norwegian</option>
              <option value="omani">Omani</option>
              <option value="pakistani">Pakistani</option>
              <option value="palauan">Palauan</option>
              <option value="panamanian">Panamanian</option>
              <option value="papua new guinean">Papua New Guinean</option>
              <option value="paraguayan">Paraguayan</option>
              <option value="peruvian">Peruvian</option>
              <option value="polish">Polish</option>
              <option value="portuguese">Portuguese</option>
              <option value="qatari">Qatari</option>
              <option value="romanian">Romanian</option>
              <option value="russian">Russian</option>
              <option value="rwandan">Rwandan</option>
              <option value="saint lucian">Saint Lucian</option>
              <option value="salvadoran">Salvadoran</option>
              <option value="samoan">Samoan</option>
              <option value="san marinese">San Marinese</option>
              <option value="sao tomean">Sao Tomean</option>
              <option value="saudi">Saudi</option>
              <option value="scottish">Scottish</option>
              <option value="senegalese">Senegalese</option>
              <option value="serbian">Serbian</option>
              <option value="seychellois">Seychellois</option>
              <option value="sierra leonean">Sierra Leonean</option>
              <option value="singaporean">Singaporean</option>
              <option value="slovakian">Slovakian</option>
              <option value="slovenian">Slovenian</option>
              <option value="solomon islander">Solomon Islander</option>
              <option value="somali">Somali</option>
              <option value="south african">South African</option>
              <option value="south korean">South Korean</option>
              <option value="spanish">Spanish</option>
              <option value="sri lankan">Sri Lankan</option>
              <option value="sudanese">Sudanese</option>
              <option value="surinamer">Surinamer</option>
              <option value="swazi">Swazi</option>
              <option value="swedish">Swedish</option>
              <option value="swiss">Swiss</option>
              <option value="syrian">Syrian</option>
              <option value="taiwanese">Taiwanese</option>
              <option value="tajik">Tajik</option>
              <option value="tanzanian">Tanzanian</option>
              <option value="thai">Thai</option>
              <option value="togolese">Togolese</option>
              <option value="tongan">Tongan</option>
              <option value="trinidadian or tobagonian">Trinidadian or Tobagonian</option>
              <option value="tunisian">Tunisian</option>
              <option value="turkish">Turkish</option>
              <option value="tuvaluan">Tuvaluan</option>
              <option value="ugandan">Ugandan</option>
              <option value="ukrainian">Ukrainian</option>
              <option value="uruguayan">Uruguayan</option>
              <option value="uzbekistani">Uzbekistani</option>
              <option value="venezuelan">Venezuelan</option>
              <option value="vietnamese">Vietnamese</option>
              <option value="welsh">Welsh</option>
              <option value="yemenite">Yemenite</option>
              <option value="zambian">Zambian</option>
              <option value="zimbabwean">Zimbabwean</option>
            </select>
            <div className="arrow-down"></div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-outline">
            <label className="form-label" htmlFor="phoneNo">
              Phone Number*
            </label>
            <input
              type="text"
              id="phoneNo"
              ref={phoneNumberRef}
              name="Phone Number"
              className="form-control"
              required
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-outline">
            <label className="form-label" htmlFor="address">
              Address*
            </label>
            <input
              type="text"
              id="address"
              ref={addressRef}
              name="Address"
              className="form-control"
              required
            />
          </div>
        </div>
      </div>
      <div className="row">
      <div className="col-md-4">
          <div className="form-outline">
            <label className="form-label" htmlFor="gender">
            Residence*
            </label>
            <select id="residence" className="form-select" ref={residencyRef} name="Residence" required>
              <option value="">Select residence</option>
              <option value="NON-US">NON-US</option>
              <option value="US">US</option>
            </select>
            <div className="arrow-down"></div>
          </div>
        </div>
        </div>
      <hr style={{marginTop:"4%"}}/>
      <p style={{ fontSize: "1.3rem", color: "#a4b3ba" }}>Employment Information</p>
      <div className="row">
        <div className="col-md-4">
          <div className="form-outline">
            <label className="form-label" htmlFor="department">
              Department*
            </label>
            <select id="department" value={selectedDepartment} className="form-select" ref={departmentRef} name="Department" onChange={handleDepartmentChange}>
              <option value="default">Select Department</option>
              {departments.map((department, index) => (
                <option key={index} value={department.id}>
                  {department.department}
                </option>
              ))}
            </select>
            <div className="arrow-down"></div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-outline"> 
              <label className="form-label" htmlFor="DOJ">
                Date of Joining*
              </label>
              <div className="input-group">
              <DatePicker
                      type="date"
                      className="form-control w-100"
                      id="dateOfBirthInput"
                      ref={dojRef}
                      selected={selectedDOJ}
                      dateFormat="dd/MM/yyyy"
                      onChange={(e) => setSelectedDOJ(e)}
                      placeholderText="Date Of Joining"
              
                    
                    />
              </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-outline"> 
              <label className="form-label" htmlFor="cDate">
                Confirmation Date
              </label>
              <div className="input-group">
              <DatePicker
                      type="date"
                      className="form-control w-100"
                      id="dateOfBirthInput"
                      ref={confirmationDateRef}
                      selected={selectedConfirmation}
                      dateFormat="dd/MM/yyyy"
                      onChange={(e) => setSelectedConfirmation(e)}
                      placeholderText="Date Of Confirmation"
              
                    
                    />
              </div>
          </div>
        </div>
      </div>
      <div className="row">  
        <div className="col-md-4">
          <div className="form-outline">
            <label className="form-label" htmlFor="region">
              Shift*
            </label>
              <div>
              <select id="shift" className="form-select" ref={shiftRef} value={selectedShift} name="Shift" onChange={handleShiftChange} required>
                <option value="default">Select Shift</option>
                {departments
    .find(department => department.id === selectedDepartment)
    ?.geography
    .filter(geo => {
        // Filter geographies with status true and at least one shift with status true
        return geo.status === true && geo.shiftTiming.some(shift => shift.status === true);
    })
    .map((geo, index) => (
        <option key={index} value={geo.id}>
            {geo.geography}
        </option>
    ))
}
              </select>
              </div>
          </div>
        </div>     
        <div className="col-md-4">
          <div className="form-outline">
            <label className="form-label" htmlFor="employmentType">
              Employment Type*
            </label>
            <select id="employmentType" className="form-select"  required name="Employment Type" ref={employmentTypeRef}>
              <option value="default">Select Employment Type</option>
              <option value="Full time">Full Time</option>
              <option value="Intern">Intern</option>
              <option value="Probational">Probational</option>
              <option value="Freelancer">Freelancer</option>
              <option value="Contractual">Contractual</option>
              <option value="Remote">Remote</option>
            </select>
            <div className="arrow-down"></div>
          </div>
        </div>  
        <div className="col-md-4">
          <div className="form-outline" >
            <label className="form-label" htmlFor="reportTo">
              Report To*
            </label>
            <Select
              className="h-50 mt-1"
              styles={{
                height:"10px",
                menuList: (provided) => ({
                  ...provided,
                  maxHeight: '150px',
                }),
                menu: (provided) => ({
                  ...provided,
                  zIndex: 999999,
                  minHeight: '10px'
                })
              }}
              options={userList}
              isSearchable={true}
              placeholder="All users"
              value={selectedUser}
              autoComplete="off"
              onChange={handleUserChange}
            />
            <div className="arrow-down"></div>
          </div>
        </div>
         
                
      </div>               
      <div className="row" style={{marginBottom:"35px"}}>
        <div className="col-md-4">
          <div className="form-outline">
            <label className="form-label" htmlFor="shift">
              Shift Timings*
            </label>
            <select id="shiftTimings" className="form-select" ref={shiftTimingsRef} name="Shift timings" onChange={handleShiftTimingChange} required>
              <option value="default">Select Shift timings</option>
              {shiftTimings.map((timing, index) => (
                <option key={index} value={timing.id}>
                  {timing.startTime + " - " + timing.endTime}
                </option>
              ))}
            </select>

            <div className="arrow-down"></div>
          </div>
        </div>             
        <div className="col-md-4">
          <div className="form-outline">
              <div className="form-outline ">
                <label className="form-label" htmlFor="email">
                  Email*
                </label>
                <input
                  type="email"
                  ref={emailRef}
                  name="Email"
                  id="email"
                  className="form-control"
                  required
                  autoComplete="off"
                />
              </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-outline">
            <label className="form-label" htmlFor="password">
              Password*
            </label>
            <div className="input-group">
              <input
                type={showPassword ? "text" : "password"}
                className="form-control"
                placeholder="Password"
                aria-label="Password"
                aria-describedby="button-addon2"
                autoComplete="off"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                className="btn"
                type="button"
                id="button-addon2"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <BsEyeSlash /> : <BsEye />}
              </button>
              <button
                className="btn btn-outline-secondary"
                type="button"
                id="generate-btn"
                onClick={autoGeneratePassword}
              >
                Generate
              </button>
            </div>
          </div>
        </div>
        
        
      </div>          
      
      <div className="create-btn">
        {isVisible && (
          (formType === "register" ? (
            <GradientBorderButton
              text="Create"
              additionalClass="mt-0"
              outerDivClass="height-34"
              innerDivClass="height-30"
              clickHandler={handleSubmit}
            />
          ) : (
            <GradientBorderButton
              text="Update"
              additionalClass="mt-0"
              outerDivClass="height-34"
              innerDivClass="height-30"
              clickHandler={handleSubmit}
            />
          ))
        )}
      </div>
    </form>
  );
}

export default SingleUser;
