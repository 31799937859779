import React, { useEffect, useState } from "react";
import QueryGenerateBox from "../QueryGenerateBox/QueryGenerateBox";
import Spinner from "react-bootstrap/Spinner";
import { useRef } from "react";
import axios from "../../config/axios";
import * as FiIcons from "react-icons/fi";
import {
  FaGreaterThan,
  FaEdit,
  FaLessThan,
  FaTrashAlt,
  FaUserAlt,
  FaSearch,
} from "react-icons/fa";
import Notification from "../Notification/Notification";
import { Toast } from "bootstrap";
import licenceValidator from "../../permission/validate";
import { useDispatch, useSelector } from "react-redux";
import { setSkillsList } from "../../features/skillSlice";
import { MdSnippetFolder } from "react-icons/md";
import { MdOutlineQuiz } from "react-icons/md";
import quizEvaluationService from "../../services/quizEvaluation.service";
import { useParams } from "react-router-dom";
import booleanQueryService from "../../services/booleanQuery.service";
import { AiOutlineClose } from "react-icons/ai";
import quizResultService from "../../services/quizResult.service";
import CopyToClipboard from "react-copy-to-clipboard";
const QuizEvaluation = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [showAlert, setShowAlert] = useState(false);
  const [successNotific, setSuccessNotific] = useState("");
  const [errorNotific, setErrorNotific] = useState("");
  const [hideRightArrow, sethideRideArrow] = useState(false);
  const [hideLeftArrow, sethideLeftArrow] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [domain, setDomain] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [evaluations, setEvaluations] = useState()
  const [openedBrackets, setOpenedBrackets] = useState(0)
  const [closedBrackets, setClosedBrackets] = useState(0)
  const [openedBrackets1, setOpenedBrackets1] = useState(0)
  const [closedBrackets1, setClosedBrackets1] = useState(0)
  const [actualAnswerLength, setActualAnswerlength] = useState(0)
  let actualRef = useRef();
  const countBrackets = (input) => {
    const open = [];
    const close = [];
    const openBrackets = '([{';
    const closeBrackets = ')]}';
    const bracketPairs = {
      ')': '(',
      ']': '[',
      '}': '{'
    };
 
  
    for (let char of input) {
      if (openBrackets.includes(char)) {
        open.push(char);
        
      } else if (closeBrackets.includes(char)) {
      close.push(char)
      }
    }
    
  
  
    return {
      open:open.length,
      close:close.length,
    };
  };
  useEffect(()=>{
    if(evaluations?.booleanString){
      let counts = countBrackets(evaluations?.booleanString)
      setOpenedBrackets(counts.open)
      setClosedBrackets(counts.close)
    }
  
  },[evaluations?.booleanString])
useEffect(()=>{
  actualRef.current.value =  evaluations?.actualAnswer ? evaluations.actualAnswer : ""
  if(evaluations?.actualAnswer ){ setActualAnswerlength(evaluations?.actualAnswer.length) }
  
},[evaluations])
useEffect(()=>{
 
  if(actualRef.current?.value){
    let counts = countBrackets(actualRef.current?.value)
    setOpenedBrackets1(counts.open)
    setClosedBrackets1(counts.close)
  } else {
    setOpenedBrackets1(0)
    setClosedBrackets1(0)
  }
},[actualRef.current?.value ])
  const { id } = useParams()
  const handleoptionChange = (e) => {
    setSelectedOption(e.target.value);
  };
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   setEvaluationResult('Select Value: selectedOption);
  // };


  const handleNextPage = async () => {
    // setLoadingNextPage(true);
    // setTimeout(() => {
    //   setLoadingNextPage(false);
    // }, 2000);
    let result
    if (currentPage + 1 == totalPages) {
      sethideRideArrow(true);
    } else {
      sethideRideArrow(false);
    }
    if (currentPage + 1 == 1) {
      sethideLeftArrow(true);
    } else {
      sethideLeftArrow(false);
    }
    if (currentPage < pageNumbers[pageNumbers.length - 1]) {
      // console.log(memories);
       result = await booleanQueryService.getBooleanQueries(
        id,
        currentPage + 1
      );

      let evaluation = await quizEvaluationService.getEvaluations(result.results[0].testId, result.results[0].id)
      
          if(evaluation.results[0]){
            
            setEvaluations(evaluation.results[0])
            
          setSelectedOption(evaluation.results[0].marks)
      
          } else {
            result.results[0].actualAnswer = ""
      
            
            setEvaluations(result.results[0])
            setSelectedOption(result.results[0].marks)
          }
   

      // paginate(currentPage + 1, memories[window.location.pathname]);
      setCurrentPage(currentPage + 1);
    } else if (totalPages > pageNumbers[4]) {
      let tempPageArr = [];
      const endPage = Math.min(totalPages, pageNumbers[4] + 4);
      for (let page = pageNumbers[4] + 1; page <= endPage; page++) {
        tempPageArr.push(page);
      }
       result = await booleanQueryService.getBooleanQueries(
        id,
        tempPageArr[0]
      );

      let evaluation = await quizEvaluationService.getEvaluations(result.results[0].testId, result.results[0].id)
      
          if(evaluation.results[0]){
            
            setEvaluations(evaluation.results[0])
            
          setSelectedOption(evaluation.results[0].marks)
      
          } else {
            result.results[0].actualAnswer = ""
      
            
            setEvaluations(result.results[0])
            setSelectedOption(result.results[0].marks)
          }
  
      setCurrentPage(currentPage + 1);
      setPageNumbers(tempPageArr);
    }
  };
  const handlePrevPage = async () => {
    if (currentPage - 1 == totalPages) {
      sethideRideArrow(true);
    } else {
      sethideRideArrow(false);
    }
    if (currentPage - 1 == 1) {
      sethideLeftArrow(true);
    } else {
      sethideLeftArrow(false);
    }
    // setLoadingNextPage(true);
    
    if (currentPage > pageNumbers[0]) {
      // console.log(memories);
      const result = await booleanQueryService.getBooleanQueries(
        id,
        currentPage - 1
      );
      let evaluation = await quizEvaluationService.getEvaluations(result.results[0].testId, result.results[0].id)

    if(evaluation.results[0]){
      
      setEvaluations(evaluation.results[0])
      
    setSelectedOption(evaluation.results[0].marks)

    } else {
      result.results[0].actualAnswer = ""

      
      setEvaluations(result.results[0])
      setSelectedOption(result.results[0].marks)
    }
   
      // paginate(currentPage + 1, memories[window.location.pathname]);
      setCurrentPage(currentPage - 1);
    } else if (currentPage > 1) {
      let tempPageArr = [];
      // const endPage = Math.min(totalPages, pageNumbers[0] - 1);
      for (let page = pageNumbers[0] - 5; page <= pageNumbers[0] - 1; page++) {
        tempPageArr.push(page);
      }
      const result = await booleanQueryService.getBooleanQueries(
       id,
        tempPageArr[tempPageArr.length - 1]
      );

      let evaluation = await quizEvaluationService.getEvaluations(result.results[0].testId, result.results[0].id)
      
          if(evaluation.results[0]){
            
            setEvaluations(evaluation.results[0])
            
          setSelectedOption(evaluation.results[0].marks)
      
          } else {
            result.results[0].actualAnswer = ""
      
            
            setEvaluations(result.results[0])
            setSelectedOption(result.results[0].marks)
          }
    
      setCurrentPage(currentPage - 1);
      setPageNumbers(tempPageArr);
    }
  };
  const handleCopy = async (e) => {
    setSuccessNotific("Copied Successfully!");
    setErrorNotific("")
    setShowAlert(true)
    setTimeout(() => {
      setShowAlert(false)
    }, 2000);
  };
  const paginate = async (currPage) => {
    // console.log(memories);

    const result = await booleanQueryService.getBooleanQueries(
      id,
      currPage
    );
    let evaluation = await quizEvaluationService.getEvaluations(result.results[0].testId, result.results[0].id)
    
        if(evaluation.results[0]){
          
          setEvaluations(evaluation.results[0])
          
          
        setSelectedOption(evaluation.results[0].marks)
    
        } else {
          
          result.results[0].actualAnswer = ""
          setEvaluations(result.results[0])
          setSelectedOption(result.results[0].marks)
        }
      
  
    setCurrentPage(currPage);

    if (currPage == totalPages) {
      sethideRideArrow(true);
    } else {
      sethideRideArrow(false);
    }
    if (currPage == 1) {
      sethideLeftArrow(true);
    } else {
      sethideLeftArrow(false);
    }

  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Selected Value", selectedOption);
    try {
      
      let previousMarks = evaluations.marks
        let newMarks =JSON.parse(selectedOption);
      
      if(evaluations.booleanStringId){
        let changeinTotal = newMarks - previousMarks
        const response = await quizEvaluationService.updateQuizEvalution(evaluations.id ,{
          marks: selectedOption,
          actualAnswer: actualRef.current.value
        });
        const res = await quizResultService.updateQuizResult(evaluations.testId, { newMarks : changeinTotal})
        setShowAlert(true);
        setErrorNotific("");
        setSuccessNotific("Quiz evaluation updated successfully");
        setTimeout(() => {
          setShowAlert(false);
        }, 2000);
      
      } else {
        const response = await quizEvaluationService.createQuizEvalution({
          jobDescription: evaluations.jobDescription,
          domain: evaluations.domain,
          marks: selectedOption,
          userId: evaluations.userId,
          booleanString: evaluations.booleanString,
          testId:evaluations.testId,
          booleanStringId:evaluations.id,
          actualAnswer :actualRef.current.value
        });
        console.log(typeof newMarks)
        
        const res = await quizResultService.updateQuizResult(evaluations.testId, { newMarks : newMarks})
        setShowAlert(true);
        setErrorNotific("");
        setSuccessNotific("Quiz evaluation submitted successfully");
        setTimeout(() => {
          setShowAlert(false);
        }, 2000);
      }
     
    await  handleNextPage()
    } catch {
      console.error(`Error while creating quiz evaluation`);
    }
  };
 useEffect(()=>{
  async function setEvaluation(){
    let result =await booleanQueryService.getBooleanQueries(id, 1)
    
setDomain(result.results[0].domain)
    let evaluation = await quizEvaluationService.getEvaluations(result.results[0].testId, result.results[0].id)

    if(evaluation.results[0]){
      
      setEvaluations(evaluation.results[0])
      
    setSelectedOption(evaluation.results[0].marks)

    } else {
      result.results[0].actualAnswer = ""

      
      setEvaluations(result.results[0])
      setSelectedOption(result.results[0].marks)
    }
  

    let tempTotalPages = Math.max(1, result.totalPages);
    setTotalPages(tempTotalPages);
    if (tempTotalPages <= 1) {
      sethideRideArrow(true);
    } else {
      sethideRideArrow(false);
    }
    if (tempTotalPages > 5) {
      tempTotalPages = 5;
    }
    let tempPageArr = [];
    for (let i = 1; i <= tempTotalPages; i++) {
      tempPageArr.push(i);
    }
    setPageNumbers(tempPageArr);
    setCurrentPage(1);
    sethideLeftArrow(true);
  }
     setEvaluation()
    
 },[])
  return (
    <>
      <div
        className="outer_div mx-auto h-75 clan-score-admin"
        style={{ paddingTop: "1px", marginTop: "1.5rem" }}
      >
        <div className="indicator-container ">
          <div>
            <MdOutlineQuiz className="icon" />
          </div>
          <div className="text">Quiz Evaluation</div>
        </div>
        <div className="buzz_inner_div mt-3" style={{ borderRadius: "10px" }}>
        <div className="upper-section">
     <div className="d-flex p-3 align-items-center">  <p className="form-label" style={{  marginRight:"10px"}}>Domain: {" "}</p>  <p className="mt-1" style={{ fontSize: "1.3rem", color: "#a4b3ba"}}>{domain}</p></div> 
      </div>
        {showAlert ? (
        <div className="alert-container">
          <div className="upper-section">
            <p style={{ marginBottom: "0px", marginTop: "5px" }}>ALOIS</p>
            <span  className="delete-btn" onClick={() => setShowAlert(!showAlert)}>
              <AiOutlineClose className="icon" style={{ fill: "black" }} />
            </span>
          </div>
          <hr
            style={{
              display: "block !important",
              height: "1px !important",
              border: "0 !important",
              borderTop: "1px solid gray !important",
            }}
          />
          <div className="lower-section pt-2">
            <p className="text-danger">{errorNotific}</p>
            <p className="text-success">{successNotific}</p>
          </div>
        </div>
      ) : null}
        <div className="pagination">
                {!hideLeftArrow ? (
                  <button
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                    className={currentPage === 1 ? "active" : ""}
                  >
                    <FaLessThan
                      className="arrow"
                      style={{
                        marginBottom: "4px",
                        marginRight: "2px",
                        padding: "1px",
                      }}
                    />
                  </button>
                ) : (
                  <></>
                )}
                {pageNumbers.map((page) => (
                  <button
                    key={page}
                    disabled={currentPage === page}
                    className={currentPage === page ? "active" : ""}
                    onClick={() => {
                      paginate(page);
                      // adjustPages(page);
                    }}
                  >
                    {page}
                  </button>
                ))}
                {!hideRightArrow ? (
                  <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    className={currentPage === totalPages ? "active" : ""}
                  >
                    <FaGreaterThan
                      className="arrow"
                      style={{
                        marginBottom: "4px",
                        marginLeft: "2px",
                        padding: "1px",
                      }}
                    />
                  </button>
                ) : (
                  <></>
                )}
              </div>
          <div className="search-query">
            <form className="form-group p-4" onSubmit={handleSubmit}>
              <textarea
                readOnly
                // onContextMenu={handleContextMenu}
                //   ref={jdRef}
                value={evaluations?.jobDescription}
                className="jd-textarea form-control d-flex w-100"
                placeholder="JD will come here"
                required
              ></textarea>
              <label className="form-label" style={{ marginTop: "10px" }} htmlFor="">
                Boolean String :{" "}
              </label>
              <div className="row">
              <div className="col-xl-11">
                <input
                  className="form-control"
                  type="text"
                  name="domain"
                value={evaluations?.booleanString}

                  // value={inputData.domain}
                  // onChange={handleChange}
                  placeholder="Boolean string Will come Here"
                  required
                  style={{ marginBottom: "10px", borderRadius: "5px" }}
                  readOnly
                />
              </div>
              <div className="col-xl-1 mt-1">
     <CopyToClipboard text={evaluations?.booleanString}>
            <button type="button">
              <FiIcons.FiCopy
                onClick={handleCopy}
                className="recruit-icons"
              />
            </button>
          </CopyToClipboard>
     </div> 

     </div>
     <div className="d-flex flex-column " style={{float:"Left"}}>
              <span style={{float:"Left"}}> {openedBrackets ?" No of open brackets: "+openedBrackets:""}</span>
                <span style={{float:"Left"}}> {closedBrackets ?" No of closed brackets: "+closedBrackets:""}</span>
                </div>
     <span style={{float:"right"}}> {evaluations?.booleanString ?" No of characters: "+evaluations?.booleanString.length:""}</span>
<div className="mt-5" style={{  position:"relative", left:"0", height:"fit-content"}} >
              <label className="form-label"  htmlFor="">
                Actual Answer :{" "}
              </label>
              
                <input
                  className="form-control"
                  type="text"
                  name="domain"
                  onChange={e => {setActualAnswerlength(e.target.value.length)}}
                  ref={actualRef}
                  placeholder="Enter the actual answer"
                  required
                  style={{ borderRadius: "5px", marginBottom:"10px" }}
                />
                
              </div>
              <div className="d-flex flex-column" style={{float:"Left"}}>
              <span style={{float:"Left"}}> {openedBrackets1 ?" No of open brackets: "+openedBrackets1:""}</span>
                <span style={{float:"Left"}}> {closedBrackets1 ?" No of closed brackets: "+closedBrackets1:""}</span>
                </div>
              <span className="mb-2  gx-0 "  style={{float:"right", width:"fit-content"}}> {actualAnswerLength ?" No of characters: "+actualAnswerLength:""}</span> 
             
             <div className=" gx-0 text-center d-flex flex-column justify-content-center w-100 mt-5" style={{margin:"0 auto", marginTop:"50px"}} >
             <label
                className="radio-center mt-2 gx-0 form-label"
                
                htmlFor=""
                style={{ marginTop: "50px", textAlign:"center" }}
              >
                Evaluate Marks
              </label>
              <div className="radio-center">
                {[...Array(11).keys()].map((value) => (
                  <label className="radio-label" key={value}>
                    <input
                      className="radio-input"
                      type="radio"
                      name="radioGroup"
                      value={value}
                      checked={parseInt(selectedOption) == value}
                      onChange={handleoptionChange}
                    />
                    {value}
                  </label>
                ))}
              </div>
             </div>
              <button type="submit" className="generate-btn mx-auto  p-1" style={{marginTop: "7px"}}>
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuizEvaluation;
