import TokenService from "./token.service";
import axios from "../config/axios";

class AnniversaryTemplateBankService {
  uploadTemplate = async (payload) => {
    const response = await axios.post(
      `/anniversaryPost/uploadAnniversaryTemplate`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
        },
      }
    );

    return response;
  };

  generatePreview = async (payload) => {
    const response = await axios.post(
      `/anniversaryPost/generatePreview`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
        },
      }
    );

    return response;
  };

  updateTemplate = async (payload) => {
    const response = await axios.post(
      `/anniversaryPost/updateAnniversaryTemplate`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
        },
      }
    );

    return response;
  };

  deleteTemplate = async (payload) => {
    const response = await axios.post(
      `/anniversaryPost/deleteAnniversaryTemplate`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
        },
      }
    );

    return response;
  };

  getTemplates = async (pageNo, templatesPerPage) => {
    const response = await axios.get(
      `/anniversaryPost/showAnniversaryTemplates?page=${pageNo}&limit=${templatesPerPage}`,
      {
        headers: {
          Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
        },
      }
    );

    return response;
  };

  getPPT = async () => {
    const response = await axios.get(`/anniversaryPost/getAnniversaryPPT`, {
      headers: {
        Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
      },
    });

    return response;
  };

  getUserImage = async (empId) => {
    const response = await axios.get(`/anniversaryPost/getUserImage?empId=${empId}`, {
      headers: {
        Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
      },
    });

    return response;
  };

  getAnniversaryPosts = async (pageNo) => {
    const response = await axios.get(
      `/anniversaryPost?page=${pageNo}&limit=5000`,
      {
        headers: {
          Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
        },
      }
    );

    return response;
  };

  updateAnniversaryPost = async (payload, id) => {
    const response = await axios.post(`/anniversaryPost/${id}`, payload, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
      },
    });

    return response;
  };
  zoomUserImage = async (payload, id) => {
    const response = await axios.post(`/anniversaryPost/zoom/${id}`, payload, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
      },
    });

    return response;
  };
  createAnniversaryPost = async () => {
    const response = await axios.post(`/anniversaryPost/create`, {}, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
      },
    });

    return response;
  };
  sendMail = async () => {
    const response = await axios.post(`/anniversaryPost/mail`, {
      headers: {
        Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
      },
    }
    )};
  getAllActivityLogs = async () => {
    const response = await axios.get(`/anniversaryPost/activity`, {
      headers: {
        Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
      },
    });

    return response;
  };
}

export default new AnniversaryTemplateBankService();
