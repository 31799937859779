import React from "react";
import utilities from "../../pages/Ess/EssData";
const EssNavbar = ({ activeCard, handleCardClick }) => {
  return (
    <div className="ess-navbar" style={{ height: "30px" }}>
      {utilities.map((utility, index) => {
        if (activeCard === `card${index + 1}`) {
          return (
            <div className="indicator-container " key={index}>
              <div className="d-flex text">
                <div>
                  <img src={utility.img} alt="image" className="icon" />
                </div>
                <div className="text ms-1">{utility.title}</div>
              </div>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

export default EssNavbar;
