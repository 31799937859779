import React, { useEffect, useRef, useState } from "react";
import { AiFillCloseCircle, AiFillCheckCircle } from "react-icons/ai";
import GradientBorderButton from "../../components/Widgets/GradientBorderButton";
import { BiDownload } from "react-icons/bi";
// import * as XLSX from "xlsx";
import Papa from "papaparse";
import DatePicker from "react-datepicker";
import Datasheet from "../../tmp/dataSheet.csv";
import * as xlsx from "xlsx";
import {
  // FaGreaterThan,
  FaEdit,
  // FaLessThan,
  FaTrashAlt,
  FaUserAlt,
  // FaSearch,
} from "react-icons/fa";
import { CgDanger } from "react-icons/cg";
import { IoIosInformationCircle } from "react-icons/io";
import userRegistrationService from "../../services/userRegistration.service";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import licenceValidator from "../../permission/validate";
import masterDBService from "../../services/masterDB.service";
import UserRegistrationPopup from "./UserRegistrationPopup";
import Select from "react-select";

function MultiUser() {
  const [showPopup, setShowPopup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showViewPopup, setShowViewPopup] = useState(false);
  const [shiftList, setShifts] = useState(["9:00 AM - 6:00 PM"]);
  const region = ["IND", "APAC", "CRP", "EU", "US"];

  const [showPassword, setShowPassword] = useState(false);
  // eslint-disable-next-line
  const [password, setPassword] = useState("");
  const [selectedUser, setSelectedUser] = useState();
  // const [successNotific, setSuccess] = useState("");
  // const [errorNotific, setError] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const limRef = useRef();
  const emailRef = useRef(null);
  const firstNameRef = useRef(null);
  const empIdRef = useRef(null);
  const lastNameRef = useRef(null);
  const departmentRef = useRef(null);
  const shiftRef = useRef(null);
  const shiftTimingsRef = useRef(null);
  const dojRef = useRef(null);
  const dobRef = useRef(null);
  const designationRef = useRef(null);
  const genderRef = useRef(null);
  const bloodGroupRef = useRef(null);
  const nationalityRef = useRef(null);
  const phoneNumberRef = useRef(null);
  const addressRef = useRef(null);
  const confirmationDateRef = useRef(null);
  const employmentTypeRef = useRef(null);
  let [users, setUsers] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [showCounter, setShowCounter] = useState(false);
  const [userUploadCounter, setUserUploadCounter] = useState(0);
  const [showUploadCounter, setShowUploadCounter] = useState(false);
  const [uploadedSuccessCounter, setUploadedSuccessCounter] = useState(0);
  const [uploadedFailedCounter, setUploadedFailedCounter] = useState(0);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [dateOfJoining, setDateOfJoining] = useState("");
  const [confiramtionDate, setConfirmationDate] = useState("");
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedShift, setSelectedShift] = useState("");
  const [shiftTimings, setShiftTimings] = useState([]);
  const [shiftTime, setShiftTime] = useState("");
  const [viewUser, setViewUser] = useState(null);
  const [userList, setUserList] = useState([]);
  const [reportTo, setReportTo] = useState(null);

  useEffect(() => {
    getMasterDB();
  }, []);

  const getMasterDB = async () => {
    try {
      const response = await masterDBService.getAllDepartments();
      setDepartments(response.results);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDepartmentChange = () => {
    const selectedDepartmentName = departmentRef.current.value;
    setSelectedDepartment(selectedDepartmentName);
    shiftTimingsRef.current.value = "default";
    shiftRef.current.value = "default";
    setSelectedShift("");
  };

  const handleShiftChange = async () => {
    const selectedShiftName = shiftRef.current.value;
    setSelectedShift(selectedShiftName);
    const selectedDepartmentData = departments.find(
      (department) => department.id === selectedDepartment
    );
    const selectedShiftData = selectedDepartmentData?.geography.find(
      (shift) => shift.id === selectedShiftName
    );
    setShiftTimings(selectedShiftData?.shiftTiming || []);
    setShiftTime("");
    shiftTimingsRef.current.value = "default";
  };

  const handleShiftTimingChange = async () => {
    const selectedShiftTime = shiftTimingsRef.current.value;
    setShiftTime(selectedShiftTime);
  };
  const convertToDateMonthYearFormat = (dateString) => {
    if (!dateString) {
      return null;
    }
    const parts = dateString.split("-");
    // Check if the dateString is already in the desired format
    if (
      parts.length === 3 &&
      parts[0].length === 2 &&
      parts[1].length === 2 &&
      parts[2].length === 4
    ) {
      return dateString; // Return the dateString as it is in the desired format
    }

    // Otherwise, convert to the desired format
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  };

  const handleUserRegistration = async () => {
    setUserUploadCounter(0);
    setShowCounter(true);
    setShowUploadCounter(true);
    const response = await userRegistrationService.getUsers();
    const data = response.results;
    console.log("Data fetched successfully:", data);

    for (const user of users) {
      let errors = [];

      try {
        if (!/^[a-zA-Z\s]*$/g.test(user.firstName) || !user.firstName) {
          errors.push("first name");
        }

        if (!/^[a-zA-Z\s]*$/g.test(user.lastName) || !user.lastName) {
          errors.push("last name");
        }
        if (
          !/^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/.test(user.email) ||
          !user.email
        ) {
          errors.push("email");
        }
        if (
          !departments.find(
            (department) => department.department === user.department
          )
        ) {
          errors.push("department");
        } else {
          let userDepartment = departments.find(
            (department) => department.department === user.department
          );
          user.department = userDepartment.id;
        }

        const selectedDepartmentData = departments.find(
          (department) => department.id === user.department
        );

        if (selectedDepartmentData) {
          const selectedShiftData = selectedDepartmentData.geography.find(
            (shift) => shift.geography === user.shift
          );

          if (!selectedShiftData) {
            errors.push("Shift");
          } else {
            user.shift = selectedShiftData.id;
            if (
              !selectedShiftData.shiftTiming.find(
                (item) =>
                  item.startTime + " - " + item.endTime == user.shiftTiming
              )
            ) {
              errors.push("Shift Timing");
            } else {
              let selectedShiftTimingData = selectedShiftData.shiftTiming.find(
                (item) =>
                  item.startTime + " - " + item.endTime == user.shiftTiming
              );
              user.shiftTiming = selectedShiftTimingData.id;
            }
          }
        }
        const phoneNoRegex = /\b(\d{10}|\d{12})\b/

        if (!phoneNoRegex.test(user.phoneNumber)) {
          errors.push("Phone Number");
        }

        const dobRegex = /^\d{2}\/\d{2}\/\d{4}$/;
        if (!user.dateOfBirth) {
          errors.push("Date of birth");
        } 
        
        if (!user.dateOfJoining){
          errors.push("Date of joining");
        } 

        if (
          !/^(?=.*[A-Za-z])(?=.*\d).{8,}$/.test(user.password) ||
          !user.password
        ) {
          errors.push("password");
        }

        if (!/^[a-zA-Z0-9]+$/.test(user.empId) || !user.empId) {
          errors.push("empId");
        }
        const dojParts = user.dateOfJoining.split(/[-\/]/);
    
        const dobParts = user.dateOfBirth.split(/[-\/]/);
        const conParts = user.confirmationDate.split(/[-\/]/);
        let dateOfBirth = user.dateOfBirth ? new Date(`${dobParts[1]}-${dobParts[0]}-${dobParts[2]}`) : null;
        if (dateOfBirth && isNaN(dateOfBirth)) {
          errors.push("Date of Birth  ");
    
            dateOfBirth = null;
        }
        
        let dateOfJoining = user.dateOfJoining ? new Date(`${dojParts[1]}-${dojParts[0]}-${dojParts[2]}`) : null;
        if (dateOfJoining && isNaN(dateOfJoining)) {
          errors.push("Date of Joining ");
    
            dateOfJoining = null;
        }
        
        let confirmationDate = user.confirmationDate ? new Date(`${conParts[1]}-${conParts[0]}-${conParts[2]}`) : null;
        if (confirmationDate && isNaN(confirmationDate)) {
          errors.push("Date of Confirmation ");
    
            confirmationDate = null;
        }
        if (errors.length) {
          user.error = "Invalid " + errors.join(", ") + ".";
          throw new Error(user.error);
        }
        user.license = [
          {
            _id: "651588f225e52f2fdc7ea58b",
            moduleName: "assist",
            isAccessible: true,
            permissions: [
              {
                _id: "75683b4997c04b7891731c3401a07d79",
                subModuleName: "jd_generator",
                create: true,
                read: true,
                update: true,
                delete: true,
              },
              {
                _id: "380a82ac81e846acadf5e4c23953d9ce",
                subModuleName: "recruiter_social",
                create: true,
                read: true,
                update: true,
                delete: true,
              },
              {
                _id: "8b9f6ea51256437588c469cf587524c1",
                subModuleName: "search_query_generator",
                create: true,
                read: true,
                update: true,
                delete: true,
              },
              {
                _id: "bebdd9ba91144359ac40eab5ec1",
                subModuleName: "recruiter_outreach",
                create: true,
                read: true,
                update: true,
                delete: true,
              },
              {
                _id: "9700dec940b249b295911e3dd134e172",
                subModuleName: "domain_identifier",
                create: true,
                read: true,
                update: true,
                delete: true,
              },
              {
                _id: "90561bdec9f94e87a3eb28e21a56554c",
                subModuleName: "recommended_jobs",
                create: true,
                read: true,
                update: true,
                delete: true,
              },
              {
                _id: "5464b5460af448cbaced04a55e4a9545",
                subModuleName: "relevant_candidate_finder",
                create: true,
                read: true,
                update: true,
                delete: true,
              },
              {
                _id: "daaa953aa304466c84d6f642943d7016",
                subModuleName: "skill_highlighter",
                create: true,
                read: true,
                update: true,
                delete: true,
              },
              {
                _id: "daaa953aa304466c84d6f642943d8670",
                subModuleName: "interview_q_and_a",
                create: true,
                read: true,
                update: true,
                delete: true,
              },
            ],
          },
          {
            _id: "649588f225e52f2fdc7ea58c",
            moduleName: "memories",
            isAccessible: true,
            permissions: [
              {
                _id: "75683b4197c04b7891731c3401a07d79",
                subModuleName: "photo_upload",
                create: false,
                read: false,
                update: false,
                delete: false,
              },
            ],
          },
          {
            _id: "649588f225e52fg4fdc7ea58b",
            moduleName: "buzz",
            isAccessible: true,
            permissions: [
              {
                _id: "649288f235e52f2ddc7ea58c",
                subModuleName: "buzz_admin",
                create: false,
                read: false,
                update: false,
                delete: false,
              },
            ],
          },
          {
            isAccessible: true,
            moduleName: "home",
            permissions: [
              {
                _id: "eeac041c-c17f-4bd8-b5be-efebe2d9686e",
                subModuleName: "alois_feed",
                create: false,
                read: true,
                update: false,
                delete: false,
              },
              {
                _id: "67f44650-1775-41ac-aca5-79f15eb65d7b",
                subModuleName: "message",
                create: false,
                read: true,
                update: false,
                delete: false,
              },
              {
                _id: "a7a36623-1830-4340-b604-65ebf85435f3",
                subModuleName: "images",
                create: false,
                read: true,
                update: false,
                delete: false,
              },
              {
                _id: "f19ee79e-3bcf-4e73-b2c7-6ad9603430e7",
                subModuleName: "videos",
                create: false,
                read: true,
                update: false,
                delete: false,
              },
              {
                _id: "5170ca5a-0eaa-47f2-803d-ad58f827ae36",
                subModuleName: "poll",
                create: false,
                read: true,
                update: false,
                delete: false,
              },
              {
                _id: "75683b4c04b7891731c3401a07a99",
                subModuleName: "notice_board",
                create: false,
                read: true,
                update: false,
                delete: false,
              },
            ],
          },
          {
            _id: "ppd6df3c-po78-9030-kpo0-12fg35fyy56b",
            moduleName: "admin",
            isAccessible: false,
            permissions: [
              {
                _id: "47697bb0-6127-4d88-835c-5bed120c9b91",
                moduleName: "user_registration",
                isAccessible: false,
                permissions: [
                  {
                    _id: "649588f235e52f2fdc7ea58c",
                    subModuleName: "single_user",
                    create: false,
                    read: false,
                    update: false,
                    delete: false,
                  },
                  {
                    _id: "649588f235e52f2fdc7ea58d",
                    subModuleName: "multi_users",
                    create: false,
                    read: false,
                    update: false,
                    delete: false,
                  },
                  {
                    _id: "649588f235e52f2fma7ea58d",
                    subModuleName: "manage_user",
                    create: false,
                    read: false,
                    update: false,
                    delete: false,
                  },
                ],
              },
              {
                _id: "18533f25-94b2-4bbb-b966-32e7ee59a29c",
                moduleName: "emailers",
                isAccessible: false,
                permissions: [
                  {
                    _id: "bae1e553-9f21-43b5-bb1e-e744e5f03c03",
                    subModuleName: "bulk_mail",
                    create: false,
                    read: false,
                    update: false,
                    delete: false,
                  },
                  {
                    _id: "fda0b0ae-c480-4711-86a0-244a5b3e48a2",
                    subModuleName: "previous_mail",
                    create: false,
                    read: false,
                    update: false,
                    delete: false,
                  },
                ],
              },
              {
                _id: "90e8cb2c-eb60-4ae9-bf1a-644ac923a8c1",
                moduleName: "template_bank",
                isAccessible: false,
                permissions: [
                  {
                    _id: "34d33fdc-435a-4150-821a-907e665cb6f7",
                    subModuleName: "social_media_templates",
                    create: false,
                    read: false,
                    update: false,
                    delete: false,
                  },
                  {
                    _id: "9d433b61-ff41-45b8-8c25-593a99f2a40c",
                    subModuleName: "anniversary_template_bank",
                    create: false,
                    read: false,
                    update: false,
                    delete: false,
                  },
                  {
                    _id: "e0f7034a-cff1-4b9b-8002-fbc2b26dbbd7",
                    subModuleName: "birthday_template_bank",
                    create: false,
                    read: false,
                    update: false,
                    delete: false,
                  },
                  {
                    _id: "75683b4197c04b7891731c3401a07d79",
                    subModuleName: "image",
                    create: false,
                    read: false,
                    update: false,
                    delete: false,
                  },
                ],
              },
              {
                _id: "8a341493-7229-44b9-ab86-24a35a1a9c7d",
                subModuleName: "jd_upload",
                create: false,
                read: false,
                update: false,
                delete: false,
              },
              {
                _id: "247ca95b-9a17-4047-8729-66c846de9097",
                subModuleName: "boolean_quiz",
                create: false,
                read: false,
                update: false,
                delete: false,
              },
              {
                _id: "abed0c70-5214-4eea-a7c8-f301e7f70c98",
                subModuleName: "quiz_result",
                create: false,
                read: false,
                update: false,
                delete: false,
              },
              {
                _id: "d456826a-b317-423b-b568-9c8417fd5a9a",
                subModuleName: "quiz_evaluation",
                create: false,
                read: false,
                update: false,
                delete: false,
              },
              {
                _id: "75683b4c04b7891731c3401a07d79",
                subModuleName: "role_based_access_control",
                create: false,
                read: false,
                update: false,
                delete: false,
              },
              {
                _id: "649588f225e52f1fdc7ea5we8b",
                subModuleName: "clan_score_master",
                create: false,
                read: false,
                update: false,
                delete: false,
              },
              {
                _id: "c6fac7d3-bcf3-4da9-828c-2e0045bacd5c",
                subModuleName: "birthday_post",
                create: false,
                read: false,
                update: false,
                delete: false,
              },
              {
                _id: "649588f225e52f1fdc7ea58b",
                subModuleName: "activity_log",
                create: false,
                read: false,
                update: false,
                delete: false,
              },
              {
                _id: "90e8cb2c-eb60-4ae9-dx12-644ac923a8c1",
                moduleName: "emailers",
                isAccessible: false,
                permissions: [
                  {
                    _id: "c11439b9-89cd-4999-bd5a-4ec71ea251f3",
                    subModuleName: "bulk_mail",
                    create: false,
                    read: false,
                    update: false,
                    delete: false,
                  },
                  {
                    _id: "391057ed-aff2-46cf-a02e-4fadbc893b2f",
                    subModuleName: "previous_mail",
                    create: false,
                    read: false,
                    update: false,
                    delete: false,
                  },
                ],
              },
              {
                _id: "e93ed89e-ad89-41f3-b2aa-b22ebaece238",
                subModuleName: "feedback",
                create: false,
                read: false,
                update: false,
                delete: false,
              },
            ],
          },
        ];
        delete user.isUploaded;
        delete user.error;
        let result;

        const existingUser = userList.find(
          (userFromList) => userFromList.value === user.email
        );
        let ogDateOfBirth = user.dateOfBirth;
        let ogDateOfJoining = user.dateOfJoining;
       
        user.dateOfBirth = new Date(`${dobParts[2]}-${dobParts[1]}-${dobParts[0]}`);
        
        user.dateOfJoining = new Date(`${dojParts[2]}-${dojParts[1]}-${dojParts[0]}`);
        user.confirmationDate = new Date(`${conParts[2]}-${conParts[1]}-${conParts[0]}`);
        
        
        console.log(existingUser);
        if (existingUser) {
          console.log("User exists, updating...");
          result = await userRegistrationService.updateUser(
            existingUser.id,
            user
          );
          
          user.dateOfJoining = `${user.dateOfJoining.getDate()}/${user.dateOfJoining.getMonth() + 1}/${user.dateOfJoining.getFullYear()}`
        user.dateOfBirth =  `${user.dateOfBirth.getDate()}/${user.dateOfBirth.getMonth() + 1}/${user.dateOfBirth.getFullYear()}`
        user.confirmationDate = `${user.confirmationDate.getDate()}/${user.confirmationDate.getMonth() + 1}/${user.confirmationDate.getFullYear()}`
          setSuccess("User Updated Successfully!");
        } else {
          console.log("User does not exist, creating...");
          result = await userRegistrationService.createSingleUser(user);
          user.dateOfJoining = `${user.dateOfJoining.getDate()}/${user.dateOfJoining.getMonth()}/${user.dateOfJoining.getFullYear()}`
          user.dateOfBirth =  `${user.dateOfBirth.getDate()}/${user.dateOfBirth.getMonth()}/${user.dateOfBirth.getFullYear()}`
          setSuccess("User Created Successfully!");
        }
        user.isUploaded = true;
        setUserUploadCounter((prev) => {
          return prev + 1;
        });
        setUploadedSuccessCounter((prev) => {
          return prev + 1;
        });
        console.log(result);
        setError("");
        await getUsers();
      } catch (error) {
        if (error?.response?.data.message == "Email already taken") {
          user.error = error.response.data.message;
        } else if (!errors.length) {
          user.error = "Server error";
        }
        setUserUploadCounter((prev) => {
          return prev + 1;
        });
        console.log("Error creating user:", error);
        setUploadedFailedCounter((prev) => {
          return prev + 1;
        });
        user.isUploaded = false;
        setError("Error Creating User!");
        setSuccess("");
        continue;
      }
    }
    // setShowPopup(!showPopup);
    for (const user of users) {
      const selectedDepartmentData = departments.find(
        (department) => department.id === user.department
      );

      if (selectedDepartmentData) {
        user.department = selectedDepartmentData.department;
        const selectedShiftData = selectedDepartmentData.geography.find(
          (shift) => shift.id === user.shift
        );

        if (!selectedShiftData) {
        } else {
          user.shift = selectedShiftData.geography;
          if (
            !selectedShiftData.shiftTiming.find(
              (item) => item.id == user.shiftTiming
            )
          ) {
          } else {
            let selectedShiftTimingData = selectedShiftData.shiftTiming.find(
              (item) => item.id == user.shiftTiming
            );
            user.shiftTiming =
              selectedShiftTimingData.startTime +
              " - " +
              selectedShiftTimingData.endTime;
          }
        }
      }
    }
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
      setError("");
      setSuccess("");
    }, [2000]);
    const fileInput = document.getElementById("customFile");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleUploadReportDownload = () => {
    const tempUsers = users;
    tempUsers.map((item) => (item.license = "object"));
    const jsonUsers = JSON.stringify(tempUsers);
    console.log(tempUsers);
    const csv = Papa.unparse(jsonUsers);
    const blob = new Blob([csv], { type: "text/csv" });

    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "usersUploadStatusReport.csv";

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  useEffect(() => {
    let user = localStorage.getItem("user");
    let validator = new licenceValidator(user, "user_registration");
    setIsVisible(validator.isReadable("multi_users"));
    getUsers();
  }, []);
  useEffect(() => {
    setTimeout(() => {
      if (showAlert) {
        setShowAlert(false);
      }
    }, 2000);
  }, [showAlert]);
  const handleEditSubmit = () => {
    const fieldsToValidate = [
      { ref: firstNameRef, name: "First Name" },
      { ref: lastNameRef, name: "Last Name" },
      { ref: designationRef, name: "Designation" },
      { ref: empIdRef, name: "Employee ID" },
      { ref: dateOfBirth, name: "Date of Birth" },
      { ref: genderRef, name: "Gender" },
      { ref: bloodGroupRef, name: "Blood Group" },
      { ref: nationalityRef, name: "Nationality" },
      { ref: phoneNumberRef, name: "Phone Number" },
      { ref: addressRef, name: "Address" },
      { ref: departmentRef, name: "Department" },
      { ref: dateOfJoining, name: "Date of Joining" },
      { ref: shiftRef, name: "Shift" },
      { ref: employmentTypeRef, name: "Employment Type" },
      { ref: emailRef, name: "Email" },
      { ref: shiftTimingsRef, name: "Shift timings" },
    ];
    let emptyFields = [];

    fieldsToValidate.forEach(({ ref, name }) => {
      if(name != "Date of Joining" && name != "Date of Birth"){
        const value = ref.current.value ? ref.current.value.trim() : "";
        if (value === "" || value === "default") {
          emptyFields.push(name);
        }
      } else {
        if(!ref){
          emptyFields.push(name);
        }
      }
    
    });
    if (reportTo === null) {
      emptyFields.push("Report To");
    }
    const phoneNoRegex = /\b(\d{10}|\d{12})\b/

    if (!phoneNoRegex.test(phoneNumberRef.current.value)) {
      emptyFields.push("Phone Number");
    }
    if (emptyFields.length > 0) {
      setShowAlert(true);
      setError("Invalid " + emptyFields.join("\n"));

      setTimeout(() => {
        setShowAlert(false);
        setSuccess("");
      }, [2000]);
      return;
    }
    const updatedUser = {
      firstName: firstNameRef.current.value,
      lastName: lastNameRef.current.value,
      designation: designationRef.current.value,
      empId: empIdRef.current.value,
      dateOfBirth: dateOfBirth ?`${dateOfBirth.getDate()}/${dateOfBirth.getMonth() + 1}/${dateOfBirth.getFullYear()}`: null,
      gender: genderRef.current.value,
      bloodGroup: bloodGroupRef.current.value,
      nationality: nationalityRef.current.value,
      phoneNumber: phoneNumberRef.current.value,
      address: addressRef.current.value,
      department: departmentRef.current.value,
      shiftTiming: shiftTimingsRef.current.value,
      shift: shiftRef.current.value,
      dateOfJoining: dateOfJoining ? `${dateOfJoining.getDate()}/${dateOfJoining.getMonth() + 1}/${dateOfJoining.getFullYear()}`: null,
      confirmationDate:  confiramtionDate ? `${confiramtionDate.getDate()}/${confiramtionDate.getMonth() + 1}/${confiramtionDate.getFullYear()}`: null,
      employmentType: employmentTypeRef.current.value,
      email: emailRef.current.value,
      password: password,
      reportTo: reportTo.value,
      license: [
        {
          _id: "651588f225e52f2fdc7ea58b",
          moduleName: "assist",
          isAccessible: true,
          permissions: [
            {
              _id: "75683b4997c04b7891731c3401a07d79",
              subModuleName: "jd_generator",
              create: true,
              read: true,
              update: true,
              delete: true,
            },
            {
              _id: "380a82ac81e846acadf5e4c23953d9ce",
              subModuleName: "recruiter_social",
              create: true,
              read: true,
              update: true,
              delete: true,
            },
            {
              _id: "8b9f6ea51256437588c469cf587524c1",
              subModuleName: "search_query_generator",
              create: true,
              read: true,
              update: true,
              delete: true,
            },
            {
              _id: "bebdd9ba91144359ac40eab5ec1",
              subModuleName: "recruiter_outreach",
              create: true,
              read: true,
              update: true,
              delete: true,
            },
            {
              _id: "9700dec940b249b295911e3dd134e172",
              subModuleName: "domain_identifier",
              create: true,
              read: true,
              update: true,
              delete: true,
            },
            {
              _id: "90561bdec9f94e87a3eb28e21a56554c",
              subModuleName: "recommended_jobs",
              create: true,
              read: true,
              update: true,
              delete: true,
            },
            {
              _id: "5464b5460af448cbaced04a55e4a9545",
              subModuleName: "relevant_candidate_finder",
              create: true,
              read: true,
              update: true,
              delete: true,
            },
            {
              _id: "daaa953aa304466c84d6f642943d7016",
              subModuleName: "skill_highlighter",
              create: true,
              read: true,
              update: true,
              delete: true,
            },
            {
              _id: "daaa953aa304466c84d6f642943d8670",
              subModuleName: "interview_q_and_a",
              create: true,
              read: true,
              update: true,
              delete: true,
            },
          ],
        },
        {
          _id: "649588f225e52f2fdc7ea58c",
          moduleName: "memories",
          isAccessible: true,
          permissions: [
            {
              _id: "75683b4197c04b7891731c3401a07d79",
              subModuleName: "photo_upload",
              create: false,
              read: false,
              update: false,
              delete: false,
            },
          ],
        },
        {
          _id: "649588f225e52fg4fdc7ea58b",
          moduleName: "buzz",
          isAccessible: true,
          permissions: [
            {
              _id: "649288f235e52f2ddc7ea58c",
              subModuleName: "buzz_admin",
              create: false,
              read: false,
              update: false,
              delete: false,
            },
          ],
        },
        {
          isAccessible: true,
          moduleName: "home",
          permissions: [
            {
              _id: "eeac041c-c17f-4bd8-b5be-efebe2d9686e",
              subModuleName: "alois_feed",
              create: false,
              read: true,
              update: false,
              delete: false,
            },
            {
              _id: "67f44650-1775-41ac-aca5-79f15eb65d7b",
              subModuleName: "message",
              create: false,
              read: true,
              update: false,
              delete: false,
            },
            {
              _id: "a7a36623-1830-4340-b604-65ebf85435f3",
              subModuleName: "images",
              create: false,
              read: true,
              update: false,
              delete: false,
            },
            {
              _id: "f19ee79e-3bcf-4e73-b2c7-6ad9603430e7",
              subModuleName: "videos",
              create: false,
              read: true,
              update: false,
              delete: false,
            },
            {
              _id: "5170ca5a-0eaa-47f2-803d-ad58f827ae36",
              subModuleName: "poll",
              create: false,
              read: true,
              update: false,
              delete: false,
            },
            {
              _id: "75683b4c04b7891731c3401a07a99",
              subModuleName: "notice_board",
              create: false,
              read: true,
              update: false,
              delete: false,
            },
          ],
        },
        {
          _id: "ppd6df3c-po78-9030-kpo0-12fg35fyy56b",
          moduleName: "admin",
          isAccessible: false,
          permissions: [
            {
              _id: "47697bb0-6127-4d88-835c-5bed120c9b91",
              moduleName: "user_registration",
              isAccessible: false,
              permissions: [
                {
                  _id: "649588f235e52f2fdc7ea58c",
                  subModuleName: "single_user",
                  create: false,
                  read: false,
                  update: false,
                  delete: false,
                },
                {
                  _id: "649588f235e52f2fdc7ea58d",
                  subModuleName: "multi_users",
                  create: false,
                  read: false,
                  update: false,
                  delete: false,
                },
                {
                  _id: "649588f235e52f2fma7ea58d",
                  subModuleName: "manage_user",
                  create: false,
                  read: false,
                  update: false,
                  delete: false,
                },
              ],
            },
            {
              _id: "18533f25-94b2-4bbb-b966-32e7ee59a29c",
              moduleName: "emailers",
              isAccessible: false,
              permissions: [
                {
                  _id: "bae1e553-9f21-43b5-bb1e-e744e5f03c03",
                  subModuleName: "bulk_mail",
                  create: false,
                  read: false,
                  update: false,
                  delete: false,
                },
                {
                  _id: "fda0b0ae-c480-4711-86a0-244a5b3e48a2",
                  subModuleName: "previous_mail",
                  create: false,
                  read: false,
                  update: false,
                  delete: false,
                },
              ],
            },
            {
              _id: "90e8cb2c-eb60-4ae9-bf1a-644ac923a8c1",
              moduleName: "template_bank",
              isAccessible: false,
              permissions: [
                {
                  _id: "34d33fdc-435a-4150-821a-907e665cb6f7",
                  subModuleName: "social_media_templates",
                  create: false,
                  read: false,
                  update: false,
                  delete: false,
                },
                {
                  _id: "9d433b61-ff41-45b8-8c25-593a99f2a40c",
                  subModuleName: "anniversary_template_bank",
                  create: false,
                  read: false,
                  update: false,
                  delete: false,
                },
                {
                  _id: "e0f7034a-cff1-4b9b-8002-fbc2b26dbbd7",
                  subModuleName: "birthday_template_bank",
                  create: false,
                  read: false,
                  update: false,
                  delete: false,
                },
                {
                  _id: "75683b4197c04b7891731c3401a07d79",
                  subModuleName: "image",
                  create: false,
                  read: false,
                  update: false,
                  delete: false,
                },
              ],
            },
            {
              _id: "8a341493-7229-44b9-ab86-24a35a1a9c7d",
              subModuleName: "jd_upload",
              create: false,
              read: false,
              update: false,
              delete: false,
            },
            {
              _id: "247ca95b-9a17-4047-8729-66c846de9097",
              subModuleName: "boolean_quiz",
              create: false,
              read: false,
              update: false,
              delete: false,
            },
            {
              _id: "abed0c70-5214-4eea-a7c8-f301e7f70c98",
              subModuleName: "quiz_result",
              create: false,
              read: false,
              update: false,
              delete: false,
            },
            {
              _id: "d456826a-b317-423b-b568-9c8417fd5a9a",
              subModuleName: "quiz_evaluation",
              create: false,
              read: false,
              update: false,
              delete: false,
            },
            {
              _id: "75683b4c04b7891731c3401a07d79",
              subModuleName: "role_based_access_control",
              create: false,
              read: false,
              update: false,
              delete: false,
            },
            {
              _id: "649588f225e52f1fdc7ea5we8b",
              subModuleName: "clan_score_master",
              create: false,
              read: false,
              update: false,
              delete: false,
            },
            {
              _id: "c6fac7d3-bcf3-4da9-828c-2e0045bacd5c",
              subModuleName: "birthday_post",
              create: false,
              read: false,
              update: false,
              delete: false,
            },
            {
              _id: "649588f225e52f1fdc7ea58b",
              subModuleName: "activity_log",
              create: false,
              read: false,
              update: false,
              delete: false,
            },
            {
              _id: "e93ed89e-ad89-41f3-b2aa-b22ebaece238",
              subModuleName: "feedback",
              create: false,
              read: false,
              update: false,
              delete: false,
            },
          ],
        },
      ],
    };

    try {
      let user1 = updatedUser;
      const selectedDepartmentData = departments.find(
        (department) => department.id === user1.department
      );

      if (selectedDepartmentData) {
        user1.department = selectedDepartmentData.department;
        const selectedShiftData = selectedDepartmentData.geography.find(
          (shift) => shift.id === user1.shift
        );

        if (!selectedShiftData) {
        } else {
          user1.shift = selectedShiftData.geography;
          if (
            !selectedShiftData.shiftTiming.find(
              (item) => item.id == user1.shiftTiming
            )
          ) {
          } else {
            let selectedShiftTimingData = selectedShiftData.shiftTiming.find(
              (item) => item.id == user1.shiftTiming
            );
            user1.shiftTiming =
              selectedShiftTimingData.startTime +
              " - " +
              selectedShiftTimingData.endTime;
          }
        }
      }
      const userIndex = users.findIndex((user) => user.email === user1.email);
      if (userIndex !== -1) {
        const updatedUsers = [...users];
        updatedUsers[userIndex] = user1;
        setUsers(updatedUsers);
        setSuccess("Edited Successfully!");
        setError("");
        console.log(updatedUser);
        setShowEditPopup(!showEditPopup);
        setTimeout(() => {
        setSuccess("");
     
          
        }, 1000);
      } else {
        setError("User not found for editing.");
      }
    } catch (err) {
      setError("Editing failed!");
    }
  };

  function handleUpdate(item) {
    setSelectedUser(item);
  
    const errors = [];
    console.log(item);
    if (item.firstName === "") {
      errors.push("First Name");
    }
    if (item.lastName === "") {
      errors.push("Last Name");
    }
    if (item.designation === "") {
      errors.push("designation");
    }
    if (item.empId === "") {
      errors.push("Employee Id");
    }
    if (item.gender === "" || item.gender === "default") {
      errors.push("Gender");
    }
    if (item.bloodGroup === "" || item.bloodGroup === "default") {
      errors.push("Blood group");
    }
    if (item.nationality === "" || item.nationality === "default") {
      errors.push("Nationality");
    }
    const phoneNoRegex = /\b(\d{10}|\d{12})\b/

    if (!phoneNoRegex.test(item.phoneNumber)) {
      errors.push("Phone Number");
    }
    if (item.address === "") {
      errors.push("Address");
    }
    if (item.reportTo === "") {
      errors.push("Report To");
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(item.email)) {
      errors.push("Email Format");
    }
    if(!item.dateOfBirth){
      errors.push("Date of Birth  ");
    }
    if(!item.dateOfJoining){
      errors.push("Date of Joining ");
    }
  
    const selectedDepartmentData = departments.find(
      (department) => department.department === item.department
    );
  
    if (!selectedDepartmentData) {
      errors.push("Department");
      errors.push("Shift");
      errors.push("Shift timings");
    } else {
      setSelectedDepartment(selectedDepartmentData.id);
  
      item.department = selectedDepartmentData.id;
      const selectedShiftData = selectedDepartmentData?.geography.find(
        (shift) => shift.geography === item.shift
      );
      if (!selectedShiftData) {
        errors.push("Shift");
      } else {
        item.shift = selectedShiftData.id;
  
        setShiftTimings(selectedShiftData?.shiftTiming || []);
        const selectedShiftTimingData = selectedShiftData.shiftTiming.find(
          (timing) =>
            timing.startTime + " - " + timing.endTime === item.shiftTiming
        );
        if (!selectedShiftTimingData) {
          errors.push("Shift Timing");
        } else {
          item.shiftTiming = selectedShiftTimingData.id;
        }
  
        setSelectedShift(selectedShiftData.id);
        setShiftTime(selectedShiftTimingData.id);
      }
    }
    setSelectedUser(item);
    
    const dojParts = item.dateOfJoining.split(/[-\/]/);
    
    const dobParts = item.dateOfBirth.split(/[-\/]/);
    const conParts = item.confirmationDate.split(/[-\/]/);
    let dateOfBirth = item.dateOfBirth ? new Date(`${dobParts[1]}-${dobParts[0]}-${dobParts[2]}`) : null;
    if (dateOfBirth && isNaN(dateOfBirth)) {
      errors.push("Date of Birth  ");

        dateOfBirth = null;
    }
    
    let dateOfJoining = item.dateOfJoining ? new Date(`${dojParts[1]}-${dojParts[0]}-${dojParts[2]}`) : null;
    if (dateOfJoining && isNaN(dateOfJoining)) {
      errors.push("Date of Joining ");

        dateOfJoining = null;
    }
    
    let confirmationDate = item.confirmationDate ? new Date(`${conParts[1]}-${conParts[0]}-${conParts[2]}`) : null;
    if (confirmationDate && isNaN(confirmationDate)) {
      errors.push("Date of Confirmation ");

        confirmationDate = null;
    }
    debugger
    if (errors.length > 0) {
      setShowAlert(true);
      setError(`Invalid ${errors.join(", ")}`);
      setTimeout(() => {
        setShowAlert(false);
        setError("");
        setSuccess("");
      }, 5000);
    } else {
      setShowAlert(false);
      setError("");
      setSuccess("");
    }
    setPassword(item.password);
    setReportTo({ label: item.reportTo, value: item.reportTo });

    
   
  debugger
    setDateOfBirth(dateOfBirth);
    setDateOfJoining(dateOfJoining);
    setConfirmationDate(confirmationDate);
    setShowEditPopup(!showEditPopup);
  }
  
  function handleCloseUpdate() {
    setShowEditPopup(!showEditPopup);
    let user1 = selectedUser;
    const selectedDepartmentData = departments.find(
      (department) => department.id === user1.department
    );

    if (selectedDepartmentData) {
      user1.department = selectedDepartmentData.department;
      const selectedShiftData = selectedDepartmentData.geography.find(
        (shift) => shift.id === user1.shift
      );

      if (!selectedShiftData) {
      } else {
        user1.shift = selectedShiftData.geography;
        if (
          !selectedShiftData.shiftTiming.find(
            (item) => item.id == user1.shiftTiming
          )
        ) {
        } else {
          let selectedShiftTimingData = selectedShiftData.shiftTiming.find(
            (item) => item.id == user1.shiftTiming
          );
          user1.shiftTiming =
            selectedShiftTimingData.startTime +
            " - " +
            selectedShiftTimingData.endTime;
        }
      }
      setSelectedUser(user1);
    }
  }
  useEffect(()=>{
    if(selectedUser?.dateOfBirth && dobRef.current.value){
      dobRef.current.value = new Date(selectedUser.dateOfBirth)
      debugger
    }
    if(selectedUser?.dateOfJoining && dojRef.current){
      dojRef.current.value = new Date(selectedUser.dateOfJoining)
      debugger
    }
  },[selectedUser])
  function handleDelete(item) {
    let newUsers = users.filter((ele) => ele.email !== item.email);
    if (!newUsers.length) {
      setShowPopup(!showPopup);
    }
    setUsers(newUsers);
    setShowAlert(false);
    setTimeout(() => {
      setShowAlert(true);
      setError("");
      setSuccess("Item Deleted Succesfully");
    }, [2000]);
  }

  const handleSubmit = (event) => {
    let file = document.getElementById("customFile").files[0];

    if (file) {
      const fileName = file.name;
      const extension = fileName.split(".").pop().toLowerCase();
      let hasError = false;
      let missingFields = [];

      let targetRow = {
        firstName: "",
        lastName: "",
        designation: "",
        empId: "",
        dateOfBirth: "",
        gender: "",
        bloodGroup: "",
        nationality: "",
        phoneNumber: "",
        address: "",
        department: "",
        shift: "",
        shiftTiming: "",
        dateOfJoining: "",
        confirmationDate: "",
        reportTo: "",
        email: "",
        password: "",
      };

      if (extension === "xlsx" || extension === "xls") {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = xlsx.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const jsonData = xlsx.utils.sheet_to_json(worksheet);

          const json = jsonData.map((row) => {
            if (row.empId) {
              row.empId = row.empId.toString();
            }
            return row;
          });
          setUsers(json);
          setShowPopup(!showPopup);

          missingFields = Object.keys(targetRow).filter((field) => {
            return jsonData.every((row) => row[field] === undefined);
          });

          if (missingFields.length === 0) {
            console.log("All fields in the targetRow exist in the data.");
          } else {
            hasError = false;
            // console.error("There are missing row here", missingFields)
            setError(`This Field is missing: ${missingFields.join(", ")}`);
            setShowAlert(true);
            setTimeout(() => {
              setShowAlert(false);
              setError("");
              setSuccess("");
            }, 2000);

            console.error(
              `${missingFields.join(", ")} ${
                missingFields.length > 1 ? "are" : "is"
              } missing in the data.`
            );
          }
        };
        reader.readAsArrayBuffer(file);
      } else if (extension === "csv") {
        Papa.parse(file, {
          header: true,
          skipEmptyLines: true,
          complete: function (results) {
            setUsers(results.data);
            setShowPopup(!showPopup);

            // Check which fields are missing in the targetRow
            missingFields = Object.keys(targetRow).filter((field) => {
              return !results.data.some((row) => row[field] !== undefined);
            });

            if (missingFields.length === 0) {
              console.log("All fields in the targetRow exist in the data.");
            } else {
              hasError = false;
              setError(`This Field is missing: ${missingFields.join(", ")}`);
              // console.error("There are missing row here", missingFields)
              setShowAlert(true);
              setTimeout(() => {
                setShowAlert(false);
                setError("");
                setSuccess("");
              }, 2000);
              return;

              // console.error(`${missingFields.join(", ")} ${missingFields.length > 1 ? "are" : "is"} missing in the data.`);
            }
          },
        });
      }

      // Check for errors before submitting
      if (!hasError) {
        // Submit the form or take other appropriate action
      }
    } else {
      setError("No files selected!");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        setError("");
        setSuccess("");
      }, 2000);
    }
  };

  const autoGeneratePassword = () => {
    const length = 10;
    const letterCharset =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    const numberCharset = "0123456789";
    const specialCharset = "!@#$%^&*()";
    const charset = letterCharset + numberCharset + specialCharset;

    // Generate at least one letter and one number
    let randomPassword = "";

    // Add a random letter
    const randomLetterIndex = Math.floor(Math.random() * letterCharset.length);
    randomPassword += letterCharset[randomLetterIndex];

    // Add a random number
    const randomNumberIndex = Math.floor(Math.random() * numberCharset.length);
    randomPassword += numberCharset[randomNumberIndex];

    // Generate the remaining characters
    for (let i = 2; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      randomPassword += charset[randomIndex];
    }

    setPassword(randomPassword);
    let user = selectedUser;
    user.password = randomPassword;
    setSelectedUser(user);
  };

  const handleDOBChange = () => {
    const selectedDate = dobRef.current.value;
    const [year, month, day] = selectedDate.split("-");
    const formattedDate = `${day}/${month}/${year}`;
    setDateOfBirth(formattedDate);
  };


  const handleConfiramtionDateChange = () => {
    const selectedDate = confirmationDateRef.current.value;
    const [year, month, day] = selectedDate.split("-");
    const formattedDate = `${day}/${month}/${year}`;
    setConfirmationDate(formattedDate);
  };

  function convertDateFormat(dateString) {
    
    if (dateString) {
      
      return new Date(dateString);
    }
  }

  const handleViewUser = (item) => {
    setViewUser(item);
    setShowViewPopup(!showViewPopup);
  };

  const getUsers = async () => {
    const data = await userRegistrationService.getUsers1();
    const formattedUserList = data.results.map((user) => ({
      value: user.email,
      label: user.email,
      id: user.id,
    }));
    console.log(formattedUserList);
    setUserList(formattedUserList);
  };

  const handleReporToChange = (selectedOption) => {
    setReportTo(selectedOption);
  };

  return (
    <>
      {showPopup ? (
        <div className="user-reg-popup-container">
          <div
            className="user-reg-popup w-75"
            style={{ maxHeight: "120vh", height: "120vh" }}
          >
            <div className="user-reg-popup-header">
              <span className="fs-6 fw-bold">Details</span>
              {showCounter ? (
                <div className="upload-counter my-2 fw-bold">
                  <span className="mx-2">
                    Total Records: {userUploadCounter} / {users.length}
                  </span>
                  <span className="text-success mx-2">
                    Success: {uploadedSuccessCounter}
                  </span>
                  <span className="text-danger mx-2">
                    Failed: {uploadedFailedCounter}
                  </span>
                  <span
                    className="ms-4"
                    role="button"
                    data-toggle="tooltip"
                    title="download"
                    onClick={handleUploadReportDownload}
                  >
                    <BiDownload
                      style={{
                        width: "22px",
                        height: "22px",
                        paddingBottom: "3px",
                      }}
                    />
                  </span>
                </div>
              ) : null}

              <button
                className="close-button"
                onClick={() => {
                  setShowPopup(!showPopup);
                  setUserUploadCounter(0);
                  setUploadedFailedCounter(0);
                  setUploadedSuccessCounter(0);
                  setShowCounter(false);
                }}
              >
                &#10005;
              </button>
            </div>

            <hr style={{ borderTop: "2px dashed black" }} />
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col" style={{ color: "#175572" }}>
                      Email
                    </th>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Department</th>
                    <th scope="col">Shift timing</th>
                    <th scope="col">Date of Birth</th>
                    <th scope="col">View all Details</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col">Upload Status</th>

                    <th scope="col" style={{ textAlign: "center" }}>
                      Error
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {users.length > 0
                    ? users.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td style={{ color: "#175572" }}>{item.email}</td>
                            <td>{item.firstName}</td>
                            <td>{item.lastName}</td>
                            <td>{item.department}</td>
                            <td>{item.shiftTiming}</td>
                            <td>{item.dateOfBirth}</td>
                            <td onClick={() => handleViewUser(item)}>
                              <FaUserAlt
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="View User"
                                className="user-icon"
                                style={{ color: "#175572" }}
                              />
                            </td>
                            {item.email ? (
                              <>
                                <td onClick={() => handleUpdate(item)}>
                                  <FaEdit style={{ color: "#175572" }} />
                                </td>
                                <td
                                  onClick={() => {
                                    const confirmBox = window.confirm(
                                      "Are you sure you want to delete this item?"
                                    );
                                    if (confirmBox === true) {
                                      console.log("Entry Deleted!!!!");
                                      handleDelete(item);
                                    } else {
                                      console.log("Entry Not Deleted");
                                    }
                                  }}
                                >
                                  <FaTrashAlt style={{ fill: "red" }} />
                                </td>
                              </>
                            ) : (
                              <td
                                data-toggle="tooltip"
                                title="Email is Required to Edit"
                              >
                                <CgDanger style={{ color: "#FF0000" }} />
                              </td>
                            )}
                            {/* <td
                        onClick={() => {
                          setSelectedDetails(item);
                          setShowDetails(!showDetails);
                        }}
                      >
                        <FaUserAlt style={{ color: "#175572" }} />
                      </td> */}

                            <td style={{ textAlign: "center" }}>
                              {item.isUploaded === false ? (
                                <AiFillCloseCircle
                                  style={{
                                    fill: "red",
                                    width: "20px",
                                    height: "20px",
                                  }}
                                  data-toggle="tooltip"
                                  title="upload failed"
                                />
                              ) : item.isUploaded === undefined ? (
                                "-"
                              ) : (
                                <AiFillCheckCircle
                                  data-toggle="tooltip"
                                  title="upload success"
                                  style={{
                                    fill: "green",
                                    width: "20px",
                                    height: "20px",
                                  }}
                                />
                              )}
                            </td>
                            {item.error ? (
                              <td style={{ textAlign: "center" }}>
                                {item.error}
                              </td>
                            ) : (
                              <></>
                            )}
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-center pt-2">
              <GradientBorderButton
                text="Confirm"
                clickHandler={handleUserRegistration}
              />

              {/* <button onClick={() => setShowPopup(false)}>Edit</button> */}
            </div>
            {/* <div className="pagination my-2">
        <button
          onClick={handlePrevPage}
          disabled={currentPage === 1}
          className={currentPage === 1 ? "active" : ""}
        >
          <FaLessThan className="arrow" />
        </button>
        {pageNumbers.map((page) => (
          <button
            key={page}
            disabled={currentPage === page}
            className={currentPage === page ? "active" : ""}
            onClick={() => {
              paginate(page);
              adjustPages(page);
            }}
          >
            {page}
          </button>
        ))}
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className={currentPage === totalPages ? "active" : ""}
        >
          <FaGreaterThan className="arrow" />
        </button>
      </div> */}
          </div>
        </div>
      ) : null}

      {/* Edit Popup */}
      {showEditPopup && selectedUser ? (
        <div className="user-reg-popup-container">
          <div
            className="user-reg-popup "
            style={{ maxHeight: "120vh", overflow: "hidden" }}
          >
            <div className="user-reg-popup-header">
              <span className="fs-6 fw-bold">Details</span>
              <button
                className="close-button"
                onClick={() => {
                  handleCloseUpdate();
                }}
              >
                &#10005;
              </button>
            </div>
            <div className="form-container">
              <div className="row">
                <div className="col">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="firstName">
                      First Name
                    </label>
                    <input
                      type="text"
                      ref={firstNameRef}
                      defaultValue={selectedUser.firstName}
                      id="firstName"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="lastName">
                      Last Name
                    </label>
                    <input
                      type="text"
                      ref={lastNameRef}
                      defaultValue={selectedUser.lastName}
                      id="lastName"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="designation">
                      Designation
                    </label>
                    <input
                      type="text"
                      ref={designationRef}
                      defaultValue={selectedUser.designation}
                      id="designation"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="empId">
                      Employee ID
                    </label>
                    <input
                      type="text"
                      ref={empIdRef}
                      defaultValue={selectedUser.empId}
                      id="empId"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="gender">
                      Gender
                    </label>
                    <select
                      id="gender"
                      className="form-select"
                      ref={genderRef}
                      name="Gender"
                      defaultValue={selectedUser.gender}
                      required
                    >
                      <option value="">Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                      <option value="prefer-not-to-say">
                        Prefer not to answer
                      </option>
                    </select>
                    <div className="arrow-down"></div>
                  </div>
                </div>
                <div className="col">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="bloodGroup">
                      Blood Group
                    </label>
                    <select
                      id="bloodGroup"
                      className="form-select"
                      ref={bloodGroupRef}
                      name="Blood Group"
                      defaultValue={selectedUser.bloodGroup}
                      required
                    >
                      <option value="">Select Blood Group</option>
                      <option value="A+">A+</option>
                      <option value="A-">A-</option>
                      <option value="B+">B+</option>
                      <option value="B-">B-</option>
                      <option value="O+">O+</option>
                      <option value="O-">O-</option>
                      <option value="AB+">AB+</option>
                      <option value="AB-">AB-</option>
                    </select>
                    <div className="arrow-down"></div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="nationality">
                      Nationality*
                    </label>
                    <select
                      name="Nationality"
                      id="nationality"
                      className="form-select"
                      defaultValue={selectedUser.nationality}
                      ref={nationalityRef}
                    >
                      <option value="">-- select one --</option>
                      <option value="afghan">Afghan</option>
                      <option value="albanian">Albanian</option>
                      <option value="algerian">Algerian</option>
                      <option value="american">American</option>
                      <option value="andorran">Andorran</option>
                      <option value="angolan">Angolan</option>
                      <option value="antiguans">Antiguans</option>
                      <option value="argentinean">Argentinean</option>
                      <option value="armenian">Armenian</option>
                      <option value="australian">Australian</option>
                      <option value="austrian">Austrian</option>
                      <option value="azerbaijani">Azerbaijani</option>
                      <option value="bahamian">Bahamian</option>
                      <option value="bahraini">Bahraini</option>
                      <option value="bangladeshi">Bangladeshi</option>
                      <option value="barbadian">Barbadian</option>
                      <option value="barbudans">Barbudans</option>
                      <option value="batswana">Batswana</option>
                      <option value="belarusian">Belarusian</option>
                      <option value="belgian">Belgian</option>
                      <option value="belizean">Belizean</option>
                      <option value="beninese">Beninese</option>
                      <option value="bhutanese">Bhutanese</option>
                      <option value="bolivian">Bolivian</option>
                      <option value="bosnian">Bosnian</option>
                      <option value="brazilian">Brazilian</option>
                      <option value="british">British</option>
                      <option value="bruneian">Bruneian</option>
                      <option value="bulgarian">Bulgarian</option>
                      <option value="burkinabe">Burkinabe</option>
                      <option value="burmese">Burmese</option>
                      <option value="burundian">Burundian</option>
                      <option value="cambodian">Cambodian</option>
                      <option value="cameroonian">Cameroonian</option>
                      <option value="canadian">Canadian</option>
                      <option value="cape verdean">Cape Verdean</option>
                      <option value="central african">Central African</option>
                      <option value="chadian">Chadian</option>
                      <option value="chilean">Chilean</option>
                      <option value="chinese">Chinese</option>
                      <option value="colombian">Colombian</option>
                      <option value="comoran">Comoran</option>
                      <option value="congolese">Congolese</option>
                      <option value="costa rican">Costa Rican</option>
                      <option value="croatian">Croatian</option>
                      <option value="cuban">Cuban</option>
                      <option value="cypriot">Cypriot</option>
                      <option value="czech">Czech</option>
                      <option value="danish">Danish</option>
                      <option value="djibouti">Djibouti</option>
                      <option value="dominican">Dominican</option>
                      <option value="dutch">Dutch</option>
                      <option value="east timorese">East Timorese</option>
                      <option value="ecuadorean">Ecuadorean</option>
                      <option value="egyptian">Egyptian</option>
                      <option value="emirian">Emirian</option>
                      <option value="equatorial guinean">
                        Equatorial Guinean
                      </option>
                      <option value="eritrean">Eritrean</option>
                      <option value="estonian">Estonian</option>
                      <option value="ethiopian">Ethiopian</option>
                      <option value="fijian">Fijian</option>
                      <option value="filipino">Filipino</option>
                      <option value="finnish">Finnish</option>
                      <option value="french">French</option>
                      <option value="gabonese">Gabonese</option>
                      <option value="gambian">Gambian</option>
                      <option value="georgian">Georgian</option>
                      <option value="german">German</option>
                      <option value="ghanaian">Ghanaian</option>
                      <option value="greek">Greek</option>
                      <option value="grenadian">Grenadian</option>
                      <option value="guatemalan">Guatemalan</option>
                      <option value="guinea-bissauan">Guinea-Bissauan</option>
                      <option value="guinean">Guinean</option>
                      <option value="guyanese">Guyanese</option>
                      <option value="haitian">Haitian</option>
                      <option value="herzegovinian">Herzegovinian</option>
                      <option value="honduran">Honduran</option>
                      <option value="hungarian">Hungarian</option>
                      <option value="icelander">Icelander</option>
                      <option value="indian">Indian</option>
                      <option value="indonesian">Indonesian</option>
                      <option value="iranian">Iranian</option>
                      <option value="iraqi">Iraqi</option>
                      <option value="irish">Irish</option>
                      <option value="israeli">Israeli</option>
                      <option value="italian">Italian</option>
                      <option value="ivorian">Ivorian</option>
                      <option value="jamaican">Jamaican</option>
                      <option value="japanese">Japanese</option>
                      <option value="jordanian">Jordanian</option>
                      <option value="kazakhstani">Kazakhstani</option>
                      <option value="kenyan">Kenyan</option>
                      <option value="kittian and nevisian">
                        Kittian and Nevisian
                      </option>
                      <option value="kuwaiti">Kuwaiti</option>
                      <option value="kyrgyz">Kyrgyz</option>
                      <option value="laotian">Laotian</option>
                      <option value="latvian">Latvian</option>
                      <option value="lebanese">Lebanese</option>
                      <option value="liberian">Liberian</option>
                      <option value="libyan">Libyan</option>
                      <option value="liechtensteiner">Liechtensteiner</option>
                      <option value="lithuanian">Lithuanian</option>
                      <option value="luxembourger">Luxembourger</option>
                      <option value="macedonian">Macedonian</option>
                      <option value="malagasy">Malagasy</option>
                      <option value="malawian">Malawian</option>
                      <option value="malaysian">Malaysian</option>
                      <option value="maldivan">Maldivan</option>
                      <option value="malian">Malian</option>
                      <option value="maltese">Maltese</option>
                      <option value="marshallese">Marshallese</option>
                      <option value="mauritanian">Mauritanian</option>
                      <option value="mauritian">Mauritian</option>
                      <option value="mexican">Mexican</option>
                      <option value="micronesian">Micronesian</option>
                      <option value="moldovan">Moldovan</option>
                      <option value="monacan">Monacan</option>
                      <option value="mongolian">Mongolian</option>
                      <option value="moroccan">Moroccan</option>
                      <option value="mosotho">Mosotho</option>
                      <option value="motswana">Motswana</option>
                      <option value="mozambican">Mozambican</option>
                      <option value="namibian">Namibian</option>
                      <option value="nauruan">Nauruan</option>
                      <option value="nepalese">Nepalese</option>
                      <option value="new zealander">New Zealander</option>
                      <option value="ni-vanuatu">Ni-Vanuatu</option>
                      <option value="nicaraguan">Nicaraguan</option>
                      <option value="nigerien">Nigerien</option>
                      <option value="north korean">North Korean</option>
                      <option value="northern irish">Northern Irish</option>
                      <option value="norwegian">Norwegian</option>
                      <option value="omani">Omani</option>
                      <option value="pakistani">Pakistani</option>
                      <option value="palauan">Palauan</option>
                      <option value="panamanian">Panamanian</option>
                      <option value="papua new guinean">
                        Papua New Guinean
                      </option>
                      <option value="paraguayan">Paraguayan</option>
                      <option value="peruvian">Peruvian</option>
                      <option value="polish">Polish</option>
                      <option value="portuguese">Portuguese</option>
                      <option value="qatari">Qatari</option>
                      <option value="romanian">Romanian</option>
                      <option value="russian">Russian</option>
                      <option value="rwandan">Rwandan</option>
                      <option value="saint lucian">Saint Lucian</option>
                      <option value="salvadoran">Salvadoran</option>
                      <option value="samoan">Samoan</option>
                      <option value="san marinese">San Marinese</option>
                      <option value="sao tomean">Sao Tomean</option>
                      <option value="saudi">Saudi</option>
                      <option value="scottish">Scottish</option>
                      <option value="senegalese">Senegalese</option>
                      <option value="serbian">Serbian</option>
                      <option value="seychellois">Seychellois</option>
                      <option value="sierra leonean">Sierra Leonean</option>
                      <option value="singaporean">Singaporean</option>
                      <option value="slovakian">Slovakian</option>
                      <option value="slovenian">Slovenian</option>
                      <option value="solomon islander">Solomon Islander</option>
                      <option value="somali">Somali</option>
                      <option value="south african">South African</option>
                      <option value="south korean">South Korean</option>
                      <option value="spanish">Spanish</option>
                      <option value="sri lankan">Sri Lankan</option>
                      <option value="sudanese">Sudanese</option>
                      <option value="surinamer">Surinamer</option>
                      <option value="swazi">Swazi</option>
                      <option value="swedish">Swedish</option>
                      <option value="swiss">Swiss</option>
                      <option value="syrian">Syrian</option>
                      <option value="taiwanese">Taiwanese</option>
                      <option value="tajik">Tajik</option>
                      <option value="tanzanian">Tanzanian</option>
                      <option value="thai">Thai</option>
                      <option value="togolese">Togolese</option>
                      <option value="tongan">Tongan</option>
                      <option value="trinidadian or tobagonian">
                        Trinidadian or Tobagonian
                      </option>
                      <option value="tunisian">Tunisian</option>
                      <option value="turkish">Turkish</option>
                      <option value="tuvaluan">Tuvaluan</option>
                      <option value="ugandan">Ugandan</option>
                      <option value="ukrainian">Ukrainian</option>
                      <option value="uruguayan">Uruguayan</option>
                      <option value="uzbekistani">Uzbekistani</option>
                      <option value="venezuelan">Venezuelan</option>
                      <option value="vietnamese">Vietnamese</option>
                      <option value="welsh">Welsh</option>
                      <option value="yemenite">Yemenite</option>
                      <option value="zambian">Zambian</option>
                      <option value="zimbabwean">Zimbabwean</option>
                    </select>
                    <div className="arrow-down"></div>
                  </div>
                </div>
                <div className="col">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="phoneNumber">
                      Phone Number
                    </label>
                    <input
                      type="text"
                      ref={phoneNumberRef}
                      defaultValue={selectedUser.phoneNumber}
                      id="phoneNumber"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="address">
                      Address
                    </label>
                    <input
                      type="text"
                      ref={addressRef}
                      defaultValue={selectedUser.address}
                      id="address"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="confirmationDate">
                      Confirmation Date
                    </label>
                    <div className="input-group">
                      <DatePicker
                      
                        className="form-control w-100"
                        ref={confirmationDateRef}
                        type="date"
                        selected={confiramtionDate}
                      dateFormat="dd/MM/yyyy"
                        id="dateOfBirthInput"
                        
                        onChange={(e) => setConfirmationDate(e)}
                        placeholderText="Date Of Confirmation"
                        
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="employmentType">
                      Employment Type*
                    </label>
                    <select
                      id="employmentType"
                      className="form-select"
                      required
                      name="Employment Type"
                      ref={employmentTypeRef}
                      defaultValue={selectedUser.employmentType}
                    >
                      <option value="default">Select Employment Type</option>
                      <option value="Full time">Full Time</option>
                      <option value="Intern">Intern</option>
                      <option value="Probational">Probational</option>
                      <option value="Freelancer">Freelancer</option>
                      <option value="Contractual">Contractual</option>
                      <option value="Remote">Remote</option>
                    </select>
                    <div className="arrow-down"></div>
                  </div>
                </div>
                <div className="col">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="reportTo">
                      Report To*
                    </label>
                    <Select
                      className="h-50 mt-1"
                      styles={{
                        height: "10px",
                        menuList: (provided) => ({
                          ...provided,
                          maxHeight: "150px",
                        }),
                        menu: (provided) => ({
                          ...provided,
                          zIndex: 999999,
                          minHeight: "10px",
                        }),
                      }}
                      options={userList}
                      isSearchable={true}
                      placeholder="All users"
                      autoComplete="off"
                      value={reportTo}
                      onChange={handleReporToChange}
                    />
                    <div className="arrow-down"></div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="department">
                      Department
                    </label>
                    <select
                      id="department"
                      className="form-select"
                      ref={departmentRef}
                      onChange={handleDepartmentChange}
                      defaultValue={selectedUser.department}
                    >
                      <option value="default">Select Department</option>
                      {departments.map((department, index) => (
                        <option key={index} value={department.id}>
                          {department.department}
                        </option>
                      ))}
                    </select>
                    <div className="arrow-down"></div>
                  </div>
                </div>
                <div className="col">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="DOB">
                      Date of birth
                    </label>
                    <div className="input-group">
                    <DatePicker
                      type="date"
                      className="form-control w-100"
                      id="dateOfBirthInput"
                      ref={dobRef}
                      selected={dateOfBirth}
                      dateFormat="dd/MM/yyyy"
                      onChange={(e) => setDateOfBirth(e)}
                      placeholderText="Date Of Birth"
              
                    
                    />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="region">
                      Shift
                    </label>
                    <select
                      id="shift"
                      className="form-select"
                      ref={shiftRef}
                      onChange={handleShiftChange}
                      defaultValue={selectedUser.shift}
                      required
                    >
                      <option value="default">Select Shift</option>
                      {departments
                        .find(
                          (department) => department.id === selectedDepartment
                        )
                        ?.geography.map((shift, index) => (
                          <option key={index} value={shift.id}>
                            {shift.geography}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="shift">
                      Shift timing
                    </label>
                    <select
                      id="shiftTimings"
                      className="form-select"
                      ref={shiftTimingsRef}
                      onChange={handleShiftTimingChange}
                      defaultValue={selectedUser.shiftTiming}
                      required
                    >
                      <option value="default">Select Shift timings</option>
                      {shiftTimings.map((timing, index) => (
                        <option key={index} value={timing.id}>
                          {timing.startTime + " - " + timing.endTime}
                        </option>
                      ))}
                    </select>
                    <div className="arrow-down"></div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="DOJ">
                      Date of Joining
                    </label>
                    <div className="input-group ">
                     <DatePicker
                      type="date"
                      className="form-control w-100"
                      id="dateOfBirthInput"
                      ref={dojRef}
                      selected={dateOfJoining}
                      dateFormat="dd/MM/yyyy"
                      onChange={(e) => setDateOfJoining(e)}
                      placeholderText="Date Of Joining"
              
                    
                    />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="email">
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      ref={emailRef}
                      defaultValue={selectedUser.email}
                      className="form-control"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="password">
                      Password
                    </label>
                    <div className="input-group mb-3">
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        placeholder="Password"
                        aria-label="Password"
                        aria-describedby="button-addon2"
                        autoComplete="off"
                        value={password}
                        defaultValue={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <button
                        className="btn"
                        type="button"
                        id="button-addon2"
                        value={password}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <BsEyeSlash /> : <BsEye />}
                      </button>

                      <button
                        className="btn btn-outline-secondary"
                        type="button"
                        id="generate-btn"
                        onClick={autoGeneratePassword}
                      >
                        Generate
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col"></div>
              </div>
              <center>
                {/* submit functionality */}
                <GradientBorderButton
                  text="Submit"
                  clickHandler={handleEditSubmit}
                />
              </center>
            </div>
          </div>
        </div>
      ) : null}

      <form className="multi-user-form mt-3 d-flex flex-column mx-2">
        <div className="form-outline">
          <label className="form-label" htmlFor="customFile">
            Upload excel/csv file
          </label>
          <input
            type="file"
            className="form-control"
            id="customFile"
            accept=".xlsx, .csv"
            required
          />
        </div>
        <div className="down-link d-flex justify-content-start">
          <a
            href={Datasheet}
            download
            className="text-primary mt-1"
            style={{ textDecoration: "underline" }}
          >
            <h6>Sample User Data Sheet</h6>{" "}
          </a>
          <span className=" ml-2">
            <IoIosInformationCircle
              data-toggle="tooltip"
              title="Email is mandatory for each user, Refer to sample datasheet for format"
            />{" "}
          </span>
        </div>

        <div className="upload-down-btn">
          {isVisible ? (
            <div className="addbtn d-flex justify-content-center align-items-center">
              <GradientBorderButton
                text={"Add"}
                additionalClass={"album-delete-btn mx-2 higher-z-index"}
                clickHandler={handleSubmit}
              />
            </div>
          ) : null}
        </div>
      </form>

      {showAlert ? (
        <div className="alert-container">
          <div className="upper-section">
            <p>ALOIS</p>
            <span
              className="delete-btn"
              onClick={() => setShowAlert(!showAlert)}
            >
              <AiOutlineClose className="icon" />
            </span>
          </div>
          <hr />
          <div className="lower-section pt-2">
            <p className="text-danger">{error}</p>
            <p className="text-success">{success}</p>
          </div>
        </div>
      ) : null}

      {showViewPopup && (
        <UserRegistrationPopup
          toggleBtn={showViewPopup}
          toggleFunction={setShowViewPopup}
        >
          <div className="d-flex flex-column">
            <div className="d-flex">
              <p className="labels">First Name:</p>
              <p className="values">
                {viewUser.firstName
                  ? viewUser.firstName.charAt(0).toUpperCase() +
                    viewUser.firstName.slice(1)
                  : "NA"}
              </p>
            </div>
            <div className="d-flex">
              <p className="labels">Last Name:</p>
              <p className="values">
                {viewUser.lastName
                  ? viewUser.lastName.charAt(0).toUpperCase() +
                    viewUser.lastName.slice(1)
                  : "NA"}
              </p>
            </div>

            <div className="d-flex">
              <p className="labels">Designation:</p>
              <p className="values">
                {viewUser.designation
                  ? viewUser.designation.charAt(0).toUpperCase() +
                    viewUser.designation.slice(1)
                  : "NA"}
              </p>
            </div>

            <div className="d-flex">
              <p className="labels">Employee ID:</p>
              <p className="values">{viewUser.empId ? viewUser.empId : "NA"}</p>
            </div>

            <div className="d-flex">
              <p className="labels">Date of Birth:</p>
              <p className="values">
                {viewUser.dateOfBirth ? viewUser.dateOfBirth : "NA"}
              </p>
            </div>

            <div className="d-flex">
              <p className="labels">Gender:</p>
              <p className="values">
                {viewUser.gender
                  ? viewUser.gender.charAt(0).toUpperCase() +
                    viewUser.gender.slice(1)
                  : "NA"}
              </p>
            </div>

            <div className="d-flex">
              <p className="labels">Blood Group:</p>
              <p className="values">
                {viewUser.bloodGroup
                  ? viewUser.bloodGroup.charAt(0).toUpperCase() +
                    viewUser.bloodGroup.slice(1)
                  : "NA"}
              </p>
            </div>

            <div className="d-flex">
              <p className="labels">Nationality:</p>
              <p className="values">
                {viewUser.nationality
                  ? viewUser.nationality.charAt(0).toUpperCase() +
                    viewUser.nationality.slice(1)
                  : "NA"}
              </p>
            </div>

            <div className="d-flex">
              <p className="labels">Phone Number:</p>
              <p className="values">
                {viewUser.phoneNumber ? viewUser.phoneNumber : "NA"}
              </p>
            </div>

            <div className="d-flex">
              <p className="labels">Address:</p>
              <p className="values">
                {viewUser.address ? viewUser.address : "NA"}
              </p>
            </div>

            <div className="d-flex">
              <p className="labels">Date of Joining:</p>
              <p className="values">
                {viewUser.dateOfJoining ? viewUser.dateOfJoining : "NA"}
              </p>
            </div>

            <div className="d-flex">
              <p className="labels">Email:</p>
              <p className="values">{viewUser.email ? viewUser.email : "NA"}</p>
            </div>

            <div className="d-flex">
              <p className="labels">Department:</p>
              <p className="values">
                {viewUser.department ? viewUser.department : "NA"}
              </p>
            </div>

            <div className="d-flex">
              <p className="labels">Shift timings:</p>
              <p className="values">
                {viewUser.shiftTiming ? viewUser.shiftTiming : "NA"}
              </p>
            </div>

            <div className="d-flex">
              <p className="labels">Shift:</p>
              <p className="values">{viewUser.shift ? viewUser.shift : "NA"}</p>
            </div>

            <div className="d-flex">
              <p className="labels">Confirmation Date:</p>
              <p className="values">
                {viewUser.confirmationDate ? viewUser.confirmationDate : "NA"}
              </p>
            </div>

            <div className="d-flex">
              <p className="labels">Employment Type:</p>
              <p className="values">
                {viewUser.employmentType ? viewUser.employmentType : "NA"}
              </p>
            </div>

            <div className="d-flex">
              <p className="labels">Report To:</p>
              <p className="values">
                {viewUser.reportTo ? viewUser.reportTo : "NA"}
              </p>
            </div>
          </div>
        </UserRegistrationPopup>
      )}
    </>
  );
}

export default MultiUser;
