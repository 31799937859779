import TokenService from "./token.service";
import axios from "../config/axios";

class DSTService {
  uploadDST = async (payload) => {
    const response = await axios.post(
      `/daylightsavings/createDayLightSavings`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
        },
      }
    );

    return response;
  };


 

  deleteDST = async (id, payload) => {
    const response = await axios.delete(
      `/daylightsavings/${id}`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
        },
      }
    );

    return response;
  };
  updateDST = async (id, payload) => {
    const response = await axios.post(
      `/daylightsavings/${id}`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
        },
      }
    );

    return response;
  };
  getDST = async (geography) => {
    let url = `/daylightsavings?`
    if(geography){
      url+=`&geography=${geography}`
    }
  
    const response = await axios.get(
      url,
      {
        headers: {
          Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
        },
      }
    );

    return response;
  };

 

  



 
}

export default new DSTService();
