import React, { useEffect, useState } from "react";
import QueryGenerateBox from "../QueryGenerateBox/QueryGenerateBox";
import Spinner from "react-bootstrap/Spinner";
import { useRef } from "react";
import axios from "../../config/axios";
import Notification from "../Notification/Notification";
import { Toast } from "bootstrap";
import licenceValidator from "../../permission/validate";
import { useDispatch, useSelector } from "react-redux";
import { setSkillsList } from "../../features/skillSlice";
import { toast } from "react-hot-toast";
import { AiOutlineClose } from "react-icons/ai";
import GradientBorderButton from "../Widgets/GradientBorderButton";

const SearchQueryGenerator = () => {
  let skillsList = useSelector((state)=>state.skills.skillsList)
  const jdRef = useRef(null);
  const [queryBox, setQueryBox] = useState(false);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");
  const [domain, setDomain] = useState(skillsList);
  const [showAlert, setShowAlert] = useState(false);
  const [successNotific, setSuccessNotific] = useState("");
  const [errorNotific, setErrorNotific] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [selectedText, setSelectedText] = useState('');
    const [selectedTextArray, setSelectedTextArray] = useState([]);
    const [showAddButton, setShowAddButton] = useState(false);
    const [buttonPosition, setButtonPosition] = useState({ top: 0, left: 0 });
    let dispatch = new useDispatch()
  
    const handleContextMenu = (e) => {
      e.preventDefault();
      const text = window.getSelection().toString();
      const { clientX, clientY } = e;
      setSelectedText(text);
      setShowAddButton(true);
      setButtonPosition({ top: clientY - 200, left: clientX -300 });
      
      const contextMenuButton = document.getElementById("add-skill");
      const body = document.body;
      const clickHandler = (event) => {
        if (event.target !== contextMenuButton) {
          // Hide the context menu button if the click is outside the button
          setShowAddButton(false)
          // Remove the click event listener
          body.removeEventListener("click", clickHandler);
        }
      };
      body.addEventListener("click", clickHandler);
    };
  
    const handleAddText = () => {
      let skillExists = skillsList.some(skill => skill.toLowerCase().trim() == selectedText.toLowerCase().trim())
      
      if(skillExists){
        setErrorNotific("Skill already exists")
        setShowAlert(true)
        setShowAddButton(false)
        setTimeout(() => {
          setShowAlert(false)
        }, 2000);
        return
      }
      if (selectedText) {
        setSelectedTextArray([...selectedTextArray, selectedText]);
        setSelectedText('');
        dispatch(setSkillsList([...skillsList, selectedText]))
        setShowAddButton(false);
      }
    };
  const onGenerate = async (e) => {
    e.preventDefault();
    setLoader(true);
    setQueryBox(false);
  
    try {
      const res = await axios.post("/utility/searchStringGenerator", {
        prompt: jdRef.current.value,
      });
      let data = res.data.value;
        
      if (data.length > 0) {
        setDomain(data);
    
        dispatch(setSkillsList(data))
        setQueryBox(true);
      } else {
        setError(
          "Seems like there are no skills in the given job decription. If there are any skills missing in the job description, you can add them to the feedback"
        );
        const toast1 = document.getElementById("toast-2");
        const toast = new Toast(toast1);
        toast.show();
      }
    } catch (err) {
      
      setQueryBox(false);
    }
    setLoader(false);
  };
  useEffect(() => {
    let user = localStorage.getItem("user");
    let validator = new licenceValidator(user, "assist");
    setIsVisible(validator.isReadable("search_query_generator"));
  }, []);
  let dashboardElement = document.querySelector(".dashboard");

  useEffect(()=>{
    
  
    if (dashboardElement && queryBox) {
            // If yes, set height to viewport height
       
        } else {
       
    
        }
    },[queryBox])
  return (
    <div className="search-query">
       {showAlert ? (
        <div className="alert-container">
          <div className="upper-section">
            <p style={{ marginBottom: "0px", marginTop: "5px" }}>ALOIS</p>
            <span  className="delete-btn" onClick={() => setShowAlert(!showAlert)}>
              <AiOutlineClose className="icon" style={{ fill: "black" }} />
            </span>
          </div>
          <hr
            style={{
              display: "block !important",
              height: "1px !important",
              border: "0 !important",
              borderTop: "1px solid gray !important",
            }}
          />
          <div className="lower-section pt-2">
            <p className="text-danger">{errorNotific}</p>
            <p className="text-success">{successNotific}</p>
          </div>
        </div>
      ) : null}
      <div>
        <div className="toasts-container position-absolute top-0 mt-3 mx-3">
          <div className="toast fade hide" data-autohide="true" id="toast-2">
            <div className="toast-header">
              <i className="far fa-bell text-muted me-2"></i>
              <strong className="me-auto">ALOIS</strong>
              <button
                type="button"
                id="toastclose_btn"   
                className="btn-close"
                data-bs-dismiss="toast"
              ></button>
            </div>
            <div className="toast-body">
              {/* <p className="text-success">{success}</p> */}
              <p className="text-danger">{error}</p>
            </div>
          </div>
        </div>
      </div>
      {showAddButton && (
          <button
          className="mt-3 ms-0"
          id="add-skill"
            style={{ position: 'absolute',border:"0.4px black solid",background:"white",width:"150px", top: buttonPosition.top, left: buttonPosition.left }}
            onClick={handleAddText}
          >
            Add as a skill
          </button>
        )}
        
      <Notification error={error} />
      <form className="form-group p-4">
        <textarea
        onContextMenu={handleContextMenu}
          ref={jdRef}
          className="jd-textarea form-control d-flex w-100 mb-4"
          placeholder="Paste your JD here"
          required
        ></textarea>
        {loader ? (
          <div className="mx-auto mt-3 p-1">
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            &nbsp; Loading...
          </div>
        ) : isVisible ? (
          <div className="d-flex justify-content-center gap-5">
             <div className="d-flex justify-content-center">
          <GradientBorderButton clickHandler={onGenerate} text={"Submit"} />
          </div>
             <div className="d-flex justify-content-center ">
          <GradientBorderButton clickHandler={() => {
                jdRef.current.value = "";
              }} text={"Clear"} />
          </div>
          
           
          
          </div>
        ) : null}

        {queryBox && (
          <div>
            <QueryGenerateBox skill={skillsList} />
          </div>
        )}
      </form>
    </div>
  );
};

export default SearchQueryGenerator;
