import React, { useEffect, useState } from "react";
import {
  useResolvedPath,
  useMatch,
  NavLink,
  useLocation,
  matchPath,
} from "react-router-dom";
import SideArrow from "../../assets/mdi_menu-right.svg";
import DownArrow from "../../assets/mdi_menu-down_dark.svg";
import { useSelector, useDispatch } from "react-redux";
import menus from "./../../config/app-menu";
import * as MdIcons from "react-icons/md";
import { BiSolidRightArrow } from "react-icons/bi";
import licenceValidator from "../../permission/validate";
import ClanIcon1 from "../../assets/clanIcon.svg";
import AccessIcon from "../../assets/Property 1=Variantwhite.svg";
import EmailerIcon from "../../assets/Property 1=VariantBlue (1).svg";
import { setActivePanel } from "../../features/userSlice";

function NavItem({ menu, validator, ...props }) {
  const path = menu.path ? menu.path : "";
  const resolved = useResolvedPath(path);
  const match = useMatch({ path: resolved.pathname });
  const location = useLocation();
  const [showChildren, setShowChildren] = useState(false);
  const [hasVisibleChildren, setHasVisibleChildren] = useState(false);
  const parts = location.pathname.split("/");
  let user = localStorage.getItem("user");
  const lastPart = parts[parts.length - 1];
  useEffect(() => {
    // let user =localStorage.getItem("user")
    const hasVisibleChildren1 = (menu, user, validator) => {
      let validator1;
      // Check if the menu has children and if the user has access to them
      const hasAccessToChildren =
        menu.children &&
        menu.children.length > 0 &&
        menu.children.some((child) => {
          validator1 = new licenceValidator(user, child.titleSlug);

          return (
            ((validator.isVisible(child.titleSlug) || child.type == "Public") &&
              validator.isAccessible()) ||
            hasVisibleChildren1(child, user, validator1)
          );
        });

      return hasAccessToChildren;
    };

    const hasVisible = hasVisibleChildren1(menu, user, validator);

    // const hasVisibleChildren1 = menu.children && menu.children.length > 0 && (menu.children.some((child) =>{
    //
    //   let childValidator = new licenceValidator(user , child.titleSlug)
    //   (validator.isVisible(child.titleSlug) || child.type == "Public" ) && validator.isAccessible()}));

    setHasVisibleChildren(hasVisible);
  }, []);

  const hasVisibleChildren1 = (menu, user, validator) => {
    let validator1;
    // Check if the menu has children and if the user has access to them
    const hasAccessToChildren =
      menu.children &&
      menu.children.length > 0 &&
      menu.children.some((child) => {
        validator1 = new licenceValidator(user, child.titleSlug);

        return (
          ((validator.isVisible(child.titleSlug) || child.type == "Public") &&
            validator.isAccessible()) ||
          hasVisibleChildren1(child, user, validator1)
        );
      });

    return hasAccessToChildren;
  };

  const hasVisible = hasVisibleChildren1(menu, user, validator);

  let [activeTab, setActiveTab] = useState(lastPart);

  useEffect(() => {
    const parts = location.pathname.split("/");
    const lastPart = parts[parts.length - 1];
    setActiveTab(`/${lastPart}`);
  }, [location.pathname]);
  if (menu.is_header) {
    return <div className="menu-header">{menu.text}</div>;
  }
  if (menu.titleSlug == "single_user") {
  }

  const match2 = matchPath({ path: path, end: false }, location.pathname);
  const isActive = match || match2;

  const handleClick = () => {
    if (menu.children) {
      setShowChildren(!showChildren);
    }
  };

  const renderChildren = (children, activeChild) => {
    if (children && children.length) {
      let user = localStorage.getItem("user");
      return (
        <ul className="menu-children">
          {children.map((childMenu, index) => {
            let activeIcon = childMenu.icon;
            if (activeTab.includes("clan") && childMenu.title == "Clan Score") {
              activeIcon = <img src={ClanIcon1} height="17px" />;
            } else if (
              activeTab.includes("access-control") &&
              childMenu.title == "Access Management"
            ) {
              activeIcon = <img src={AccessIcon} height="17px" />;
            }

            let childValidator = new licenceValidator(
              user,
              childMenu.titleSlug
            );

            let isVisible = validator.isVisible(childMenu.titleSlug);
            let isActive2 = location.pathname.includes(childMenu.path);
            const parts = location.pathname.split("/");
            const lastPart = `/${parts[parts.length - 1]}`;

            if (childMenu.children) {
              return childValidator.isAccessible() ||
                childMenu.type == "Public" ? (
                <NavItem
                  key={index}
                  menu={childMenu}
                  parentPath={menu.path}
                  validator={childValidator}
                />
              ) : (
                <></>
              );
            } else if (props.parentPath) {
              return (validator.isVisible(childMenu.titleSlug) &&
                validator.isAccessible()) ||
                childMenu.type == "Public" ? (
                <div
                  className={`menu-item ${
                    lastPart == childMenu.path ? " active" : ""
                  }`}
                >
                  {" "}
                  <NavLink
                    className="menu-link"
                    to={`${props.parentPath}${menu.path}${childMenu.path}`}
                    {...props}
                  >
                    {childMenu.icon}{" "}
                    <div
                      className="menu-text fst-normal"
                      style={{ fontWeight: "450", fontSize: "20px" }}
                    >
                      {childMenu.title}
                    </div>
                  </NavLink>
                </div>
              ) : (
                <></>
              );
            } else {
              return (validator.isVisible(childMenu.titleSlug) &&
                validator.isAccessible()) ||
                childMenu.type == "Public" ? (
                <div
                  className={`menu-item ${
                    lastPart == childMenu.path ? " active" : ""
                  }`}
                >
                  <NavLink
                    className={`menu-link `}
                    to={`${menu.path}${childMenu.path}`}
                    {...props}
                  >
                    {activeIcon}{" "}
                    <div className="menu-text">{childMenu.title}</div>
                  </NavLink>
                </div>
              ) : (
                <></>
              );
            }
          })}
        </ul>
      );
    }
    return null;
  };
  const isAccessible = validator.isAccessible();

  if (menu.type === "Private") {
    if (menu.children && isAccessible) {
      let location = window.location.pathname;

      return (
        <div className={`menu-item${isActive ? " active" : ""}`}>
          <div className="menu-item">
            <div
              className={`menu-link${
                menu.title === "User Management" ||
                menu.title === "Access Management" ||
                menu.title === "Social Template Bank"
                  ? " pointer"
                  : ""
              }`}
              onClick={handleClick}
            >
              {menu.icon}

              <div className="menu-text">{menu.title}</div>
              {showChildren ? (
                <img
                  src={DownArrow}
                  style={{ color: "#678498", height: "1.5rem" }}
                />
              ) : (
                // <
                //   className="active-arrow"
                //   style={{ height: '1.5rem', width: '1.5rem' }}
                // />
                <></>
              )}
              {hasVisible && !showChildren ? (
                <img
                  src={SideArrow}
                  style={{ color: "#678498", height: "1.5rem" }}
                />
              ) : (
                <></>
              )}
            </div>
            {showChildren && renderChildren(menu.children)}
          </div>
        </div>
      );
    } else if (isAccessible || validator.isVisible(menu.titleSlug)) {
      let location = window.location.pathname;
      // if(location.pathname.includes("clan")){
      //
      //   icon = <img src={ClanIcon1} height="17px" />
      // }
      return (
        <div className={`menu-item${isActive ? " active" : ""}`}>
          <NavLink className="menu-link" to={menu.path} {...props}>
            {menu.icon} <div className="menu-text">{menu.title}</div>
          </NavLink>
        </div>
      );
    }
  } else if (menu.type === "Partial") {
    if (menu.children && hasVisible && isAccessible) {
      return (
        <div className={`menu-item${isActive ? " active" : ""}`}>
          <div className="menu-item">
            <div
              className={`menu-link${
                menu.title === "User Management" ||
                menu.title === "Access Management" ||
                menu.title === "Social Template Bank"
                  ? " pointer"
                  : ""
              }`}
              onClick={handleClick}
            >
              {menu.icon} <div className="menu-text">{menu.title}</div>
              {showChildren ? (
                <img
                  src={DownArrow}
                  style={{ color: "#678498", height: "1.5rem" }}
                />
              ) : (
                <></>
              )}
              {hasVisible && !showChildren ? (
                <img
                  src={SideArrow}
                  style={{ color: "#678498", height: "1.5rem" }}
                />
              ) : (
                <></>
              )}
            </div>
            {showChildren && renderChildren(menu.children)}
          </div>
        </div>
      );
    } else if (!menu.children) {
      return (
        <div className={`menu-item${isActive ? " active" : ""}`}>
          <NavLink className="menu-link" to={menu.path} {...props}>
            {menu.icon} <div className="menu-text">{menu.title}</div>
            {hasVisible && !showChildren && (
              <img
                src={SideArrow}
                style={{ color: "#678498", height: "1.5rem" }}
              />
            )}
          </NavLink>
        </div>
      );
    }
  } else if (menu.type === "Public") {
    if (menu.children) {
      return (
        <div className={`menu-item${isActive ? " active" : ""}`}>
          <div className="menu-item">
            <div
              className={`menu-link${
                menu.title === "User Management" ||
                menu.title === "Access Management" ||
                menu.title === "Social Template Bank"
                  ? " pointer"
                  : ""
              }`}
              onClick={handleClick}
            >
              {menu.icon} <div className="menu-text">{menu.title}</div>
              {showChildren ? (
                <img
                  src={DownArrow}
                  style={{ color: "#678498", height: "1.5rem" }}
                />
              ) : (
                <></>
              )}
              {hasVisible && !showChildren ? (
                <img
                  src={SideArrow}
                  style={{ color: "#678498", height: "1.5rem" }}
                />
              ) : (
                <></>
              )}
            </div>
            {showChildren && renderChildren(menu.children, activeTab)}
          </div>
        </div>
      );
    } else {
      return (
        <div className={`menu-item${isActive ? " active" : ""}`}>
          <NavLink className="menu-link" to={menu.path} {...props}>
            {menu.icon} <div className="menu-text">{menu.title}</div>
            {/* {isActive && <BiSolidRightArrow className="active-arrow fs-3" />} */}
          </NavLink>
        </div>
      );
    }
  }
}

function SidebarNav() {
  const activePanel = useSelector((state) => state.user.activePanel);
  const user = localStorage.getItem("user");
  const dispatch = useDispatch();
  const handleSetActivePanel = (dispatch, activeCheck) => {
    dispatch(setActivePanel(activeCheck));
  };
  useEffect(() => {
    let activeCheck = localStorage.getItem("activePanel");

    if (!activeCheck) {
      activeCheck = "user";
      localStorage.setItem("activePanel", "user");
    }

    if (activeCheck !== activePanel) {
      handleSetActivePanel(dispatch, activeCheck);
    }
  }, [activePanel, dispatch]);

  return (
    <div className="menu" style={{ marginTop: "2.5rem" }}>
      {menus
        .filter((menu) => {
          if (activePanel === "user") {
            return menu.titleSlug !== "admin";
          }
          if (activePanel === "admin") {
            return (
              menu.titleSlug === "admin" ||
              menu.title === "Home" ||
              menu.title === "Navigation"
            );
          }
        })
        .map((menu, i) => (
          <NavItem
            key={i}
            menu={menu}
            validator={new licenceValidator(user, menu.titleSlug)}
          />
        ))}
    </div>
  );
}

export default SidebarNav;
