import React, { useEffect, useState, useRef } from "react";
import Button from "../../components/Widgets/Button";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import minioService from "../../services/minio.service";
import BuzzContent from "../../components/Buzz/BuzzContent";
import BuzzService from "../../services/buzz.service";
import { useDispatch, useSelector } from "react-redux";
import { MdSnippetFolder } from "react-icons/md";
import {
  filterBuzzList,
  setBuzzList,
  setFilteredBuzzList,
  setNewPageBuzzList,
} from "../../features/buzzSlice";
import { useDOMStatesContext } from "../../context/DOMStatesContext";
import { FiUpload } from "react-icons/fi";
import GradientBorderButton from "../../components/Widgets/GradientBorderButton";
import { FaGreaterThan, FaLessThan } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";

function BuzzAdmin() {
  const currentDate = new Date();
  const currentMonth = currentDate.toLocaleString("default", { month: "long" });
  const currentYear = currentDate.getFullYear();
  const [activeLink, setActiveLink] = useState("");
  const [thumbnail, setThumbNail] = useState([]);
  const [content, setContent] = useState([]);

  const [featureImage, setFeatureImage] = useState();
  const [currLocation, setCurrLocation] = useState("");
  const [currentStatus, setCurrentStatus] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(0);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const visiblePages = 5;
  const dispatch = useDispatch();
  const user = (state) => state.user.user;
  const navigate = useNavigate();
  const location = useLocation();
  const [showUploadForm, setShowUploadForm] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const editionRef = useRef();
  const buzzPdfRef = useRef();
  const buzzImageRef = useRef();
  const [month, setMonth] = useState(currentMonth);
  const [year, setYear] = useState(currentYear);
  const [successNotific, setSuccessNotific] = useState("");
  const [errorNotific, setErrorNotific] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showUploadFormButton, setShowUploadFormButton] = useState(false);

  const changeActiveLink = () => {
    let pathname = location.pathname;
    if (pathname.includes("published")) {
      // dispatch(filterBuzzList(1));
      setActiveLink("published");
      getBuzzList(1, 1);
      setCurrentStatus(1);
    } else if (pathname.includes("draft")) {
      // dispatch(filterBuzzList(0));
      setActiveLink("draft");
      getBuzzList(1, 0);
      setCurrentStatus(0);
    } else if (pathname.includes("trash")) {
      // dispatch(filterBuzzList(2));
      setActiveLink("trash");
      getBuzzList(1, 2);
    } else {
      // dispatch(filterBuzzList(1));
      // setActiveLink("published");
    }
  };

  const getBuzzList = async (pageNo, status) => {
    const data = await BuzzService.getBuzzList(pageNo, status);
    let tempTotalPages = Math.max(1, Math.ceil(data.totalResults / 6));
    setTotalPages(tempTotalPages);
    let tempPageArr = [];
    for (let i = 1; i <= tempTotalPages; i++) {
      tempPageArr.push(i);
    }
    setPageNumbers(tempPageArr);

    dispatch(setFilteredBuzzList(data.results));
  };

  const getNewPageBuzzList = async (pageNo, status) => {
    const data = await BuzzService.getBuzzList(pageNo, status);
    setCurrentPage(pageNo);
    dispatch(setNewPageBuzzList(data.results));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const user = JSON.parse(localStorage.getItem("user"));
    const editionValue = editionRef.current.value;
    const match = editionValue.match(/E-(\d+)/i);
    let buzzNo = 0;
    if (match && match[1]) {
      buzzNo = parseInt(match[1]);
    } else {
      console.log("Number not found");
    }

    const regex = /[\\/]([^\\/]+)$/;

    setShowUploadForm(!showUploadForm);
    try {
      console.log("check", thumbnail, content);
      let urls = [];
      for (let i = 0; i < content.length; i++) {
        urls.push(
          `https://bucket.akinolabs.com:443/${process.env.REACT_APP_BUCKET_NAME}/buzz/pdfs/${editionValue}/` +
            content[i].name.replace(/(\(|\))/gi, "_").replace(/ /g, "_")
        );
      }

      try {
        urls.sort(function (a, b) {
          // Extract the numeric part from the file names using regular expressions
          var indexA = parseInt(a.match(/-(\d+)\./)[1]);
          var indexB = parseInt(b.match(/-(\d+)\./)[1]);

          // Compare the extracted indexes
          return indexA - indexB;
        });
      } catch (err) {
        setSuccessNotific("");
        setErrorNotific(
          "Please follow the name convention for buzz images: 'imagename-1, imagename-2....'"
        );
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, [4000]);
        return;
      }
      for (let i = 0; i < content.length; i++) {
        const result = await minioService.UploadFile(
          null,
          content[i],
          "buzz",
          editionValue
        ); //Bucketname is hardcoded till the localstorage setup is not done for company

        console.log("etag", result);
      }
      const result1 = await minioService.UploadFile(
        thumbnail,
        null,
        "buzz",
        editionValue
      );
      let uploadInfo = {};

      // let data = result.data
      // for (const key in data) {

      //   if (data.hasOwnProperty(key)) {
      //     const value = data[key];
      //     console.log(`Key: ${key}, Value: ${value}`);
      //     const resp = await minioService.SearchFile('assist-production',value);

      //     if(resp.data.fileName.endsWith('.pdf') && uploadInfo.pdfUrl===undefined){
      //       uploadInfo.pdfUrl = resp.data.url;

      //     }else{

      //       if(uploadInfo.imgUrl===undefined){
      //         uploadInfo.imgUrl = resp.data.url;
      //       }
      //     }
      //   }
      // }

      const formData = {};
      console.log("REACT_APP_BUCKET_NAME", process.env.REACT_APP_BUCKET_NAME);
      console.log("REACT_APP_API_URL", process.env.REACT_APP_API_URL);

      formData.edition = editionValue;
      formData.month = month;
      formData.year = year;
      let ext = thumbnail.name.slice(
        ((thumbnail.name.lastIndexOf(".") - 1) >>> 0) + 2
      );
      let thumbnailName = "thumbnail-buzz-" + editionValue + "." + ext;
      formData.featureImage =
        `https://bucket.akinolabs.com:443/${process.env.REACT_APP_BUCKET_NAME}/buzz/feature-images/` +
        thumbnailName;
      // formData.buzzFile= `https://bucket.akinolabs.com:443/${process.env.REACT_APP_BUCKET_NAME}/buzz/pdfs/`+content.name.replace(/(\(|\))/gi, '_').replace(/ /g, '_');

      formData.buzzFile = JSON.stringify(urls);
      formData.user = user.id;
      formData.buzzName = `${month}'${year}`;
      formData.buzzNo = parseInt(editionValue);
      formData.likedBy = [];
      formData.comments = [];

      setShowUploadFormButton(false);

      await BuzzService.uploadBuzz(formData);
      setErrorNotific("");
      setSuccessNotific("Buzz uploaded successfully");
      getBuzzList(currentPage, currentStatus);
    } catch (err) {
      setSuccessNotific("");
      setErrorNotific("Buzz upload failed");
    }

    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, [4000]);
  };

  const adjustPages = (currentPage) => {
    let tempStart = 1;
    let tempEnd = Math.min(5, totalPages);
    if (currentPage > 3) {
      tempStart = Math.max(1, currentPage - Math.floor(visiblePages / 2));
      tempEnd = Math.min(tempStart + visiblePages - 1, totalPages);
    }
    let tempPageNumbers = Array.from(
      { length: tempEnd - tempStart + 1 },
      (_, index) => tempStart + index
    );
    if (tempPageNumbers[0] !== 0) {
      setPageNumbers(tempPageNumbers);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      getBuzzList(currentPage - 1, currentStatus);
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      getBuzzList(currentPage + 1, currentStatus);
      setCurrentPage(currentPage + 1);
    }
  };

  const handleDraftToggle = (pathEndpoint) => {
    let pathname = pathEndpoint;
    if (pathname.includes("published")) {
      // dispatch(filterBuzzList(1));
      setActiveLink("published");
      getBuzzList(1, 1);
      setCurrentStatus(1);
    } else if (pathname.includes("draft")) {
      // dispatch(filterBuzzList(0));
      setActiveLink("draft");
      getBuzzList(1, 0);
      setCurrentStatus(0);
    } else {
      console.log("NOTHING");
    }
  };

  const getBuzzUploadFiles = (e) => {
    if (buzzPdfRef.current.value && buzzPdfRef.current.value.search(/\.pdf$/)) {
      setContent(buzzPdfRef.current.files);
      console.log("pdf value", buzzPdfRef.current.value);
    } else if (
      buzzPdfRef.current.value &&
      !buzzPdfRef.current.value.search(/\.png$|\.jpg$|\.jpeg$|\.webp$/)
    ) {
      buzzPdfRef.current.value = "";
      setErrorNotific('Only "pdf" files are supported.');
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, [3000]);
    }
    if (
      buzzImageRef.current.value &&
      buzzImageRef.current.value.search(/\.png$|\.jpg$|\.jpeg$|\.webp$/)
    ) {
      setThumbNail(buzzImageRef.current.files[0]);
      console.log("img value", buzzImageRef.current.value);
    } else if (
      buzzImageRef.current.value &&
      !buzzImageRef.current.value.search(/\.png$|\.jpg$|\.jpeg$|\.webp$/)
    ) {
      buzzImageRef.current.value = "";
      setErrorNotific('Only "image" files are supported.');
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, [3000]);
    }
  };

  const handleFormChange = () => {
    const editionInputValue = editionRef.current.value;
    const buzzPdfInputValue = buzzPdfRef.current.value;
    const buzzImageInputValue = buzzImageRef.current.value;
    if (editionInputValue && buzzPdfInputValue && buzzImageInputValue) {
      setShowUploadFormButton(true);
    } else {
      setShowUploadFormButton(false);
    }

    setCurrLocation("/buzz/buzz_admin/published");
    dispatch(filterBuzzList(1));
    getBuzzList();
    adjustPages();
  };
  function handleNav() {
    currLocation === "buzz"
      ? navigate("/buzz/admin/published")
      : navigate("/buzz");
  }
  useEffect(() => {
    changeActiveLink();
  }, [window.location.pathname]);

  useEffect(() => {
    let dashboardElement = document.querySelector(".dashboard");
    const body = document.body;

    if (dashboardElement && showUploadForm) {
      // Remove the 'transform' property
      // Remove the 'transform-origin' property
      // dashboardElement.style.transformOrigin = '';
    } else if (!showUploadForm && dashboardElement) {
    }
  }, [showUploadForm]);
  return (
    <div className="buzz-admin">
      {!showUploadForm ? (
        <div className="indicator-container ">
          <div className="d-flex text">
            <MdSnippetFolder className="icon mx-2" /> Buzz Admin
          </div>
          <>
            <div className="d-flex align-items-center gap-3">
              <GradientBorderButton
                text={"Upload"}
                icon={<FiUpload />}
                clickHandler={() => {
                  setShowUploadForm(!showUploadForm);
                }}
                additionalClass={"buzz-upload-btn mx-1"}
                iconAdditionalClass={"icon-styles"}
              />
              <GradientBorderButton
                text={currLocation === "buzz" ? "Admin Panel" : "User Panel"}
                //  icon={<FiUpload />}

                clickHandler={() => {
                  handleNav();
                }}
                additionalClass={"panel-toggle-btn "}
                iconAdditionalClass={"icon-styles"}
              />

              {/* <TbBookUpload
                className="activity-logs-icon"
                onClick={handleActivityLogsPage}
              /> */}
            </div>
          </>
        </div>
      ) : (
        <></>
      )}

      <div className="d-flex buzz flex-column">
        {showAlert ? (
          <div className="alert-container">
            <div className="upper-section">
              <p style={{ marginBottom: "0px", marginTop: "5px" }}>ALOIS</p>
              <span
                className="delete-btn"
                onClick={() => setShowAlert(!showAlert)}
              >
                <AiOutlineClose className="icon" style={{ fill: "black" }} />
              </span>
            </div>
            <hr
              style={{
                display: "block !important",
                height: "1px !important",
                border: "0 !important",
                borderTop: "1px solid gray !important",
              }}
            />
            <div className="lower-section pt-2">
              <p className="text-danger">{errorNotific}</p>
              <p className="text-success">{successNotific}</p>
            </div>
          </div>
        ) : null}
        {!showUploadForm ? (
          <div className="d-flex justify-content-center button-list">
            <NavLink to={"/buzz/admin/published"}>
              <Button
                style={{ marginTop: "10px" }}
                text={"Published"}
                bgColor={
                  activeLink === "published"
                    ? "linear-gradient(90deg, #B3CFCE 0%, #A7B2B6 100%)"
                    : "#fff"
                }
              />
            </NavLink>
            <NavLink to={"/buzz/admin/draft"}>
              <Button
                style={{ marginTop: "10px" }}
                text={"Draft"}
                bgColor={
                  activeLink === "draft"
                    ? "linear-gradient(90deg, #B3CFCE 0%, #A7B2B6 100%)"
                    : "#fff"
                }
              />
            </NavLink>
          </div>
        ) : (
          <></>
        )}
        {!showUploadForm ? (
          <div
            className="outer_div border mx-auto"
            style={{ marginTop: "10px" }}
          >
            <div className="buzz_inner_div mt-3" style={{ background: "#fff" }}>
              <BuzzContent />
              <div className="pagination pb-2">
                <button onClick={handlePrevPage} disabled={currentPage === 1}>
                  <FaLessThan
                    className="arrow"
                    style={{
                      marginBottom: "4px",
                      marginRight: "2px",
                      padding: "1px",
                    }}
                  />
                </button>
                {pageNumbers.map((page) => (
                  <button
                    key={page}
                    className={currentPage === page ? "active" : ""}
                    onClick={() => {
                      getBuzzList(page, currentStatus);
                      setCurrentPage(page);

                      // getNewPageBuzzList(page, currentStatus);
                      adjustPages(page);
                    }}
                  >
                    {page}
                  </button>
                ))}
                <button
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  <FaGreaterThan
                    className="arrow"
                    style={{
                      marginBottom: "4px",
                      marginLeft: "2px",
                      padding: "1px",
                    }}
                  />
                </button>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        {/* upload form */}
        {showUploadForm ? (
          <div className="popup-container">
            <div className="popup">
              <div className="popup-header">
                <span className="fs-6 fw-bold">Upload New Buzz</span>
                <button
                  className="close-button"
                  onClick={() => {
                    setShowUploadForm(!showUploadForm);
                    setShowUploadFormButton(false);
                  }}
                >
                  &#10005;
                </button>
              </div>
              <hr style={{ borderTop: "2px dashed black" }} />
              <div className="popup-content">
                <form onChange={handleFormChange} encType="multipart/form-data">
                  <div className="form-fields-container">
                    <div className="row pt-3">
                      <div className="col">
                        <div className="form-outline">
                          <label className="form-label" htmlFor="month">
                            Month
                          </label>
                          <select
                            id="month"
                            className="form-select form-control"
                            value={month}
                            onChange={(e) => setMonth(e.target.value)}
                            required
                          >
                            <option className="p-5" value="January">
                              January
                            </option>
                            <option value="February">February</option>
                            <option value="March">March</option>
                            <option value="April">April</option>
                            <option value="May">May</option>
                            <option value="June">June</option>
                            <option value="July">July</option>
                            <option value="August">August</option>
                            <option value="September">September</option>
                            <option value="October">October</option>
                            <option value="November">November</option>
                            <option value="December">December</option>
                          </select>
                          <div className="arrow-down"></div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-outline">
                          <label className="form-label" htmlFor="year">
                            Year
                          </label>
                          <input
                            type="text"
                            id="year"
                            className="form-control"
                            value={year}
                            onChange={(e) => setYear(e.target.value)}
                            autoComplete="off"
                            maxLength={4}
                            required
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-outline">
                          <label className="form-label" htmlFor="edition">
                            Edition
                          </label>
                          <input
                            type="text"
                            id="edition"
                            className="form-control"
                            ref={editionRef}
                            autoComplete="off"
                            required
                            style={{ textTransform: "uppercase" }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col">
                        <div className="form-outline">
                          <label className="form-label" htmlFor="buzzPdf">
                            Buzz Images
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            id="buzzPdf"
                            ref={buzzPdfRef}
                            onChange={(e) => getBuzzUploadFiles(e)}
                            accept="image/*"
                            multiple
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3 pb-3">
                      <div className="col">
                        <div className="form-outline">
                          <label className="form-label" htmlFor="featureImage">
                            Feature Image
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            id="featureImage"
                            ref={buzzImageRef}
                            onChange={(e) => getBuzzUploadFiles(e)}
                            accept="image/*"
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="upload-btn-container mt-2">
                    <GradientBorderButton
                      text="Confirm"
                      clickHandler={handleSubmit}
                      additionalClass={`${
                        showUploadFormButton ? "" : "btn disabled border-none"
                      }`}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default BuzzAdmin;
