import RecruiterSocial from "../../components/RecruiterSocial/RecruiterSocial";
import SearchQueryGenerator from "../../components/SearchQueryGenerator/SearchQueryGenerator";
// import RecriterOutreach from "../../components/RecruiterOutreach/RecruiterOutreach";
import RecruiterOutreach from "../../components/RecruiterOutreach/RecruiterOutreach";
import DomainClassification from "../../components/DomainClassification/DomainClassification";
import RecommendJobs from "../../components/RecommendJobs/RecommendJobs";
import ReleventCandidateFinder from "../../components/ReleventCandidateFinder/ReleventCandidateFinder";
import JdGenerator from "../../components/JdGenerator/JdGenerator";
import SkillHighlighter from "../../components/SkillHighlighter/SkillHighlighter";
import licenceValidator from "../../permission/validate";
import InterviewQAndA from "../../components/InterviewQ&A/interviewQ&A";
import BirthdayPostGenerator from "../../components/BirthdayPostGenerator/BirthdayPostGenerator";
import CandidateSynopsis from "../../components/CandidateSynopsis/CandidateSynopsis";
import BrandStarterKit from "../../components/BrandStarterKit/BrandStarterKit";


let utilities = [
  {
    title: "JD\nGenerator",
    titleSlug: "jd_generator",
    
    img: "/assets/jd_generator.png",
    link: "/assist/jd-generator",
    component: JdGenerator,
  },
  {
    title: "Recruiter\nSocial",
    titleSlug: "recruiter_social",
    img: "/assets/recruiter_social.png",
    link: "/assist/recruiter-social",
    component: RecruiterSocial,
  },

  {
    title: "Search Query\nGenerator",
    titleSlug: "search_query_generator",
    img: "/assets/search_query_generator.png",
    link: "/assist/search-query-generator",
    component: SearchQueryGenerator,
  },
  {
    title: "Recruiter\nOutreach",
    titleSlug: "recruiter_outreach",
    img: "/assets/outreach arrow.svg",
    link: "/assist/recruiter-outreach",
    component: RecruiterOutreach,
  },
  {
    title: "Domain\nIdentifier",
    titleSlug: "domain_identifier",
    img: "/assets/domain_identifier.png",
    link: "/assist/domain-classification",
    component: DomainClassification,
  },
  {
    title: "Recommended\nJobs",
    titleSlug: "recommended_jobs",
    img: "/assets/fluentdocumentpercent20regular7710-ynp.svg",
    link: "/assist/recommended-jobs",
    component: RecommendJobs,
  },
  {
    title: "Relevant\nCandidate\nFinder",
    titleSlug: "relevant_candidate_finder",
    img: "/assets/Relevant_candidate_finder.svg",
    link: "/assist/relevant-candidate-finder",
    component: ReleventCandidateFinder,
  },
  {
    title: "Skills\nHighlighter",
    titleSlug: "skill_highlighter",
    img: "/assets/skill_highlighter.svg",
    link: "/assist/skill-highlighter",
    component: SkillHighlighter,
  },
  {
    title: "Interview \nQ & A",
    titleSlug: "interview_q_and_a",
    img: "/assets/question list.svg",
    link: "/assist/interview-questions-and-answers",
    component: InterviewQAndA,
  }, 
  // {
  //   title: "Candidate \nSynopsis",
  //   titleSlug: "candidate_synopsis",
  //   img: "/assets/User.svg",
  //   link: "/assist/candidate-synopsis",
  //   component: CandidateSynopsis,
  // },
  {
    title: "Brand Starter \Kit",
    titleSlug: "candidate_synopsis",
    img: "/assets/User.svg",
    link: "/assist/brand-starter-kit",
    component: BrandStarterKit
  },
];
let user = localStorage.getItem("user")
if (user) {
  utilities = utilities.filter(utility => {
    let validator = new licenceValidator(user, "assist")
    if (validator.isVisible(utility.titleSlug)) {
      
      return true
    }
  })
}


export default utilities;
