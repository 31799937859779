import React, { useEffect, useRef, useState } from "react";
import { MdSnippetFolder } from "react-icons/md";
import GradientBorderButton from "../../components/Widgets/GradientBorderButton";
import Switch from "../../components/Widgets/Switch";
import {
  FaTrashAlt,
  FaSearch,
  FaPencilAlt,
  FaAngleDoubleLeft,
  FaAngleLeft,
  FaAngleRight,
  FaAngleDoubleRight,
} from "react-icons/fa";
import { IoMdAddCircle } from "react-icons/io";
import { AiOutlineClose } from "react-icons/ai";
import masterDBService from "../../services/masterDB.service";

function Geography() {
  const visiblePages = 5;
  const geographiesPerPage = 10;
  const [status, setStatus] = useState(false);
  const [searchInputFocused, setSearchInputFocused] = useState(false);
  const [showCreatePopup, setShowCreatePopup] = useState(false);
  const [showAssignPopup, setShowAssignPopup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showManagePopup, setShowManagePopup] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [successNotific, setSuccessNotific] = useState("");
  const [errorNotific, setErrorNotific] = useState("");
  const [selectedGeographyForStatus, setSelectedGeography] = useState();

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [geography, setGeography] = useState("");
  const [shiftTimingList, setShiftTimingList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [geographyList, setGeographyList] = useState([]);
  const shiftTimingRef = useRef();
  const oldGeoRef = useRef();
  const updatedGeographyRef = useRef();
  const [allGeographies, setAllGeographies] = useState([]);
  const [uniqueGeographies, setUniqueGeographies] = useState([]);
  const [assignedCount, setAssisgnedCount] = useState(0);
  const [assignedGeography, setAssignedGeography] = useState("");
  const [geoFilter, setGeoFilter] = useState("");
  const [shiftFilter, setShiftFilter] = useState("");
  const handleStatusPopup = async (data) => {
    setSelectedGeography(data);
  };
  const handleToggle = async (data) => {
    try {
      const updatedGeography = {
        status: !data.status,
      };
      const response = await masterDBService.updateGeography(
        data.id,
        updatedGeography
      );
      // Update the UI by fetching all shift timings again
      filterGeography(1);
      handleStatusPopup();
    } catch (error) {
      setShowAlert(true);
      setErrorNotific("Error updating geography status");
      setSuccessNotific("");
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  };
  const updateGeography = async () => {
    if (!oldGeoRef.current.value) {
      setShowAlert(true);
      setErrorNotific("Old geography name can't be empty");
      setSuccessNotific("");
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
      return;
    }
    if (!updatedGeographyRef.current.value) {
      setShowAlert(true);
      setErrorNotific("New geography name can't be empty");
      setSuccessNotific("");
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
      return;
    }
    const result = await masterDBService.getAllGeographies();
    let response = result.results;
    const foundResult0 = response.filter(
      (item) => item.geography === updatedGeographyRef.current.value
    );
    if (foundResult0.length) {
      setShowAlert(true);
      setErrorNotific("Geography with this name already exists");
      setSuccessNotific("");
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
      return;
    }
    const foundResult = response.filter(
      (item) => item.id === oldGeoRef.current.value
    );
    const promises = foundResult.map(async (data) => {
      const updatedGeography = {
        geography: updatedGeographyRef.current.value,
      };

      const response = await masterDBService.updateGeography(
        data.id,
        updatedGeography
      );
    });

    const results = await Promise.all(promises);
    setShowAlert(true);
    setErrorNotific("");
    setSuccessNotific("Geography updated successfully");
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);

    // Update the UI by fetching all shift timings again
    await filterGeography(1);
    setShowEditPopup(false);
  };

  const handlePrevPage = () => {
    const setPageNumber = currentPage - 1;
    setCurrentPage(setPageNumber);
    adjustPages(setPageNumber);

    filterGeography(setPageNumber);
  };
  const handleNextPage = () => {
    const setPageNumber = currentPage + 1;
    setCurrentPage(setPageNumber);
    adjustPages(setPageNumber);

    filterGeography(setPageNumber);
  };
  const getAllShiftTimings = async () => {
    try {
      const result = await masterDBService.getAllShiftTimings();
      setShiftTimingList(result.results);
    } catch (error) {
      console.error("Error fetching shift timings", error);
    }
  };
  const paginate = (currPage, data) => {
    // Scroll to the top of the table whenever page number is changed

    const start = (currPage - 1) * geographiesPerPage;
    const end = start + geographiesPerPage;
    setGeographyList(data.slice(start, end));
  };
  const handleDualPrevPage = () => {
    const setPageNumber = currentPage - 5;
    setPageNumber <= 0 && (setPageNumber = 1);

    setCurrentPage(setPageNumber);
    adjustPages(setPageNumber);

    filterGeography(setPageNumber);
  };

  const handleDualNextPage = () => {
    const setPageNumber = currentPage + 5;
    setPageNumber > totalPages && (setPageNumber = totalPages);

    setCurrentPage(setPageNumber);
    adjustPages(setPageNumber);

    filterGeography(setPageNumber);
  };

  const handleCreatePopup = () => {
    setShowCreatePopup(!showCreatePopup);
  };

  const handleAssignPopup = () => {
    setShowAssignPopup(!showAssignPopup);
    setAssignedGeography();
  };
  const handleUpdatePopup = () => {
    setShowEditPopup(!showEditPopup);
  };

  const handleManagePopup = () => {
    setShowManagePopup(!showManagePopup);
  };
  const handleCreateGeography = async () => {
    try {
      const results = await masterDBService.getAllGeographies();
      setGeographyList(results.results);
      let unique = new Set();
      results.results.forEach((data) => {
        unique.add(data.geography);
      });
      setUniqueGeographies([...unique]);

      let geo = geography;
      if (
        [...unique].find((item) => item.toLowerCase() == geography.toLowerCase)
      ) {
        setShowAlert(true);
        setErrorNotific("Geography already exists");
        setSuccessNotific("");
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
        return;
      }
      const data = {
        geography: geography,
        shiftTiming: [shiftTimingRef.current.value],
        status: true,
      };
      console.log(data);
      const result = await masterDBService.createGeography(data);
      await filterGeography(1);
      setShowCreatePopup(false);
      setShowAlert(true);
      setErrorNotific("");
      setSuccessNotific("Geography created successfully");
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    } catch (error) {
      setShowAlert(true);
      setErrorNotific("Error creating geography");
      setSuccessNotific("");
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  };
  const AssignShift = async (e) => {
    e.preventDefault();
    if (assignedGeography) {
      try {
        const selectedShifts = shiftTimingList
          .filter((data, index) => {
            const checkbox = document.getElementById(`timing-${index}`);
            return checkbox?.checked;
          })
          .map((data) => data.id);

        let selectedGeo = assignedGeography;

        const existingGeography = geographyList.find(
          (item) => item.geography === selectedGeo
        );
        if (existingGeography) {
        } else {
          return null;
        }
        // If geography already exists, return null
        const data = {
          shiftTiming: selectedShifts,
        };
        setShowAssignPopup(false);
        await masterDBService.updateGeography(existingGeography.id, data);

        await filterGeography(1);
        setAssignedGeography("");
        setShowCreatePopup(false);
        setShowAlert(true);
        setErrorNotific("");
        setSuccessNotific("Shift timings assigned successfully");
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      } catch (error) {
        setShowAlert(true);
        setErrorNotific("Error creating geographies");
        setSuccessNotific("");
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      }
    } else {
      setShowAlert(true);
      setErrorNotific("Select a geography to assign shifts!");
      setSuccessNotific("");
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  };
  useEffect(() => {
    getAllShiftTimings();
    filterGeography(1);
  }, []);
  useEffect(() => {
    const assignedShiftCount = shiftTimingList.reduce((count, data) => {
      if (
        geographyList.find(
          (item) =>
            item.shiftTiming === data.id && assignedGeography == item.geography
        )
      ) {
        return count + 1;
      }
      return count;
    }, 0);
    setAssisgnedCount(assignedShiftCount);
  }, [assignedGeography]);
  const adjustPages = (currentPage, tempTotalPages) => {
    let finalTotalPages = tempTotalPages || totalPages;
    let tempStart = 1;
    let tempEnd = Math.min(5, finalTotalPages);

    if (finalTotalPages > 5) {
      if (currentPage > 3 && currentPage < finalTotalPages - 1) {
        tempStart = Math.max(1, currentPage - Math.floor(visiblePages / 2));
        tempEnd = Math.min(tempStart + visiblePages - 1, finalTotalPages);
      } else if (currentPage >= finalTotalPages - 1) {
        // If it is the last or last second page, then show the last 5 page numbers
        tempStart = Math.max(1, finalTotalPages - visiblePages + 1);
        tempEnd = finalTotalPages;
      }
    } else {
      tempStart = 1;
      tempEnd = finalTotalPages;
    }

    let tempPageNumbers = Array.from(
      { length: tempEnd - tempStart + 1 },
      (_, index) => tempStart + index
    );

    if (tempPageNumbers[0] !== 0) {
      setPageNumbers(tempPageNumbers);
    }

    setCurrentPage(currentPage);
  };
  const filterGeography = async (page = 1) => {
    try {
      const result = await masterDBService.getAllGeographies();
      setLoading(true);

      setTimeout(() => {
        let filteredResult = result.results;
        setAllGeographies(filteredResult);
        // Apply filtering based on geoFilter
        if (geoFilter.trim() !== "") {
          filteredResult = filteredResult.filter((item) =>
            item.geography.toLowerCase().includes(geoFilter.toLowerCase())
          );
        }

        // Apply filtering based on shiftFilter
        if (shiftFilter.trim() !== "") {
          filteredResult = filteredResult.filter((item) =>
            item.shiftTiming.find((item1) => item1.id == shiftFilter)
          );
        }

        // Pagination logic

        const totalRecords = filteredResult.length;
        const totalPages = Math.ceil(totalRecords / geographiesPerPage);

        // Adjust page if necessary
        page = Math.min(page, totalPages); // Ensure page is within range
        page = Math.max(page, 1); // Ensure page is not less than 1

        // Calculate page range to display
        // Maximum number of pages to display
        let startPage = Math.max(1, page - Math.floor(visiblePages / 2));
        let endPage = Math.min(totalPages, startPage + visiblePages - 1);

        // Adjust startPage and endPage if necessary
        if (endPage - startPage + 1 < visiblePages) {
          startPage = Math.max(1, endPage - visiblePages + 1);
        }

        // Paginate the filtered result for the current page
        // Paginate the filtered result for the current page
        const startIdx = (page - 1) * geographiesPerPage;
        const endIdx = Math.min(startIdx + geographiesPerPage, totalRecords);

        // Paginate the filtered result for the current page
        const paginatedResult = filteredResult.slice(startIdx, endIdx);

        // Update state with paginated result and pagination parameters
        setGeographyList(paginatedResult);
        const tempTotalPages = Math.max(
          1,
          Math.ceil(filteredResult.length / geographiesPerPage)
        );
        setTotalPages(tempTotalPages);

        const tempPageArr = [];
        for (let i = 1; i <= tempTotalPages; i++) {
          tempPageArr.push(i);
        }
        tempPageArr.length <= 5 && setPageNumbers(tempPageArr);
        setLoading(false);
      }, 2000);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  let filters = `${shiftFilter}${geoFilter}`;
  useEffect(() => {
    filterGeography(1);
  }, [filters]);
  return (
    <div className="d-flex geography-container flex-column">
      <div className="indicator-container pt-2 pb-4 ">
        <div className="d-flex text">
          <MdSnippetFolder className="icon mx-2" /> Geography
        </div>
      </div>

      {showAlert ? (
        <div className="alert-container" style={{ zIndex: "10000000" }}>
          <div className="upper-section">
            <p style={{ marginBottom: "0px", marginTop: "5px" }}>ALOIS</p>
            <span
              className="delete-btn"
              onClick={() => setShowAlert(!showAlert)}
            >
              <AiOutlineClose className="icon" style={{ fill: "black" }} />
            </span>
          </div>
          <hr
            style={{
              display: "block !important",
              height: "1px !important",
              border: "0 !important",
              borderTop: "1px solid gray !important",
            }}
          />
          <div className="lower-section py-2">
            <p className="text-danger">{errorNotific}</p>
            <p className="text-success">{successNotific}</p>
          </div>
        </div>
      ) : null}
      {selectedGeographyForStatus && (
        <div className="geography-popup-container">
          <div className="geography-popup">
            <div className="geography-popup-header">
              <span className="fs-6 fw-bold">Change Geography Status</span>
              <button
                className="close-button"
                onClick={() => {
                  handleStatusPopup();
                }}
              >
                &#10005;
              </button>
            </div>

            <hr style={{ borderTop: "2px dashed black" }} />
            <p>
              This action may affect some existing departments.Are you sure you
              want to proceed?
            </p>
            <div className="d-flex justify-content-center pt-2 gap-3">
              <GradientBorderButton
                text="Confirm"
                clickHandler={() => {
                  handleToggle(selectedGeographyForStatus);
                }}
              />
              <GradientBorderButton
                text="Cancel"
                clickHandler={() => {
                  handleStatusPopup();
                }}
              />
            </div>
          </div>
        </div>
      )}
      {showCreatePopup && (
        <div className="geography-popup-container">
          <div className="geography-popup">
            <div className="geography-popup-header">
              <span className="fs-6 fw-bold">Create Geography</span>
              <button className="close-button" onClick={handleCreatePopup}>
                &#10005;
              </button>
            </div>

            <hr style={{ borderTop: "2px dashed black" }} />
            <div className="form-container">
              <form>
                <div className="date-container ">
                  <label className="form-label d-block">Geography</label>
                  <input
                    type="text"
                    id="geography"
                    onChange={(e) => {
                      setGeography(e.target.value);
                    }}
                  />
                </div>
                <div className="date-container">
                  <label className="form-label">Shift Timing</label>
                  <select
                    name="timings"
                    id="timings"
                    className="form-select dropdown-styles"
                    placeholder="Shift timings"
                    ref={shiftTimingRef}
                    defaultValue={
                      shiftTimingList.length > 0
                        ? `${shiftTimingList[0].startTime}-${shiftTimingList[0].endTime}`
                        : ""
                    }
                  >
                    {shiftTimingList.map((data, index) => (
                      <option key={index} value={`${data.id}`}>
                        {`${data.startTime} - ${data.endTime}`}
                      </option>
                    ))}
                  </select>
                </div>
              </form>
            </div>
            <div className="d-flex justify-content-center pt-2">
              <GradientBorderButton
                text="Confirm"
                clickHandler={handleCreateGeography}
              />
            </div>
          </div>
        </div>
      )}
      {showAssignPopup && (
        <div className="geography-popup-container">
          <div className="geography-popup">
            <div className="geography-popup-header">
              <span className="fs-6 fw-bold">Assign Shift Timing</span>
              <button className="close-button" onClick={handleAssignPopup}>
                &#10005;
              </button>
            </div>

            <hr style={{ borderTop: "2px dashed black" }} />
            <div className="form-container">
              <form>
                <div className="date-container ">
                  <label className="form-label d-block">Geography</label>
                  <select
                    name="timings"
                    id="assign-geography"
                    className="form-select dropdown-styles"
                    onChange={(e) => {
                      setAssignedGeography(e.target.value);
                    }}
                    placeholder="Shift timings"
                  >
                    <option key="key" value="">
                      Select a geography
                    </option>
                    {allGeographies.map((data, index) => (
                      <option key={index} value={data.geography}>
                        {data.geography}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="date-container">
                  {assignedGeography ? (
                    <label className="form-label mt-1">Shift Timing</label>
                  ) : (
                    <></>
                  )}

                  <div>
                    {assignedGeography &&
                      shiftTimingList.map((data, index) => {
                        return (
                          <div key={index}>
                            <input
                              type="checkbox"
                              id={`timing-${index}`}
                              name={`timing-${index}`}
                              value={data.id}
                              defaultChecked={geographyList.find((item) => {
                                return (
                                  item.shiftTiming.find(
                                    (data1) => data1.id == data.id
                                  ) && item.geography == assignedGeography
                                );
                              })}
                              className="form-checkbox checkbox-styles mr-2"
                            />
                            <label htmlFor={`timing${index}`}>
                              {data.startTime + " - " + data.endTime}
                            </label>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </form>
            </div>
            <div className="d-flex justify-content-center pt-2">
              <GradientBorderButton text="Confirm" clickHandler={AssignShift} />
            </div>
          </div>
        </div>
      )}
      {showEditPopup && (
        <div className="geography-popup-container">
          <div className="geography-popup">
            <div className="geography-popup-header">
              <span className="fs-6 fw-bold">Update Geography</span>
              <button className="close-button" onClick={handleUpdatePopup}>
                &#10005;
              </button>
            </div>

            <hr style={{ borderTop: "2px dashed black" }} />
            <div className="form-container">
              <form>
                <div className="date-container ">
                  <label className="form-label d-block">Geography</label>
                  <select
                    name="timings"
                    id="timings"
                    className="form-select dropdown-styles"
                    ref={oldGeoRef}
                    placeholder="Shift timings"
                  >
                    <option key="key" value="">
                      Select a geography
                    </option>
                    {allGeographies.map((data, index) => (
                      <option key={index} value={data.id}>
                        {data.geography}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="date-container">
                  <label className="form-label d-block">
                    Updated Geography
                  </label>
                  <input type="text" ref={updatedGeographyRef} />
                </div>
              </form>
            </div>
            <div className="d-flex justify-content-center pt-2">
              <GradientBorderButton
                text="Confirm"
                clickHandler={() => updateGeography()}
              />
            </div>
          </div>
        </div>
      )}

      <div className="geography-outer mx-auto">
        <div className="geography-inner mt-3">
          <div className="d-flex justify-content-between align-items-center">
            <div className="heading"></div>
            <div className="d-flex align-items-center gap-3">
              <div className="input-group d-flex align-items-center">
                <button
                  className={`btn btn-outline-secondary search-btn ${
                    searchInputFocused && "search-input-focused"
                  }`}
                  type="button"
                  id="button-addon1"
                  // onClick={() => userRef.current.focus()}
                >
                  <FaSearch className="mb-1" />
                </button>
                <input
                  type="text"
                  className="form-control search-input-user"
                  placeholder="Search Geography"
                  aria-label="Example text with button addon"
                  aria-describedby="button-addon1"
                  // ref={userRef}
                  value={geoFilter}
                  onChange={(e) => setGeoFilter(e.target.value)}
                  onFocus={() => setSearchInputFocused(true)}
                  onBlur={() => setSearchInputFocused(false)}
                />
              </div>
              <div className="form-outline filter-btn">
                <select
                  name="timings"
                  id="timings"
                  className="form-select dropdown-styles"
                  value={shiftFilter}
                  onChange={(e) => {
                    setShiftFilter(e.target.value);
                  }}
                  placeholder="Shift timings"
                >
                  <option key="key" value="">
                    Select a shift timing
                  </option>
                  {shiftTimingList.map((data, index) => (
                    <option key={index} value={data.id}>
                      {data.startTime + " - " + data.endTime}
                    </option>
                  ))}
                </select>
              </div>
              <div className="create-btn">
                <GradientBorderButton
                  text="Clear"
                  iconAdditionalClass="icon-width"
                  additionalClass="mt-0"
                  outerDivClass="height-34"
                  innerDivClass="height-30 gap-0"
                  clickHandler={() => {
                    setShiftFilter("");
                    setGeoFilter("");
                  }}
                />
              </div>
              <div className="create-btn">
                <GradientBorderButton
                  icon={<FaPencilAlt />}
                  text="Update"
                  iconAdditionalClass="icon-width"
                  additionalClass="mt-0"
                  outerDivClass="height-34"
                  innerDivClass="height-30 gap-0"
                  clickHandler={handleUpdatePopup}
                />
              </div>
              <div className="create-btn">
                <GradientBorderButton
                  icon={<IoMdAddCircle />}
                  text="Assign"
                  iconAdditionalClass="icon-width"
                  additionalClass="mt-0"
                  outerDivClass="height-34"
                  innerDivClass="height-30 gap-0"
                  clickHandler={handleAssignPopup}
                />
              </div>
              <div className="create-btn">
                <GradientBorderButton
                  icon={<IoMdAddCircle />}
                  text="Create"
                  iconAdditionalClass="icon-width"
                  additionalClass="mt-0"
                  outerDivClass="height-34"
                  innerDivClass="height-30 gap-0"
                  clickHandler={handleCreatePopup}
                />
              </div>
            </div>
          </div>
          {loading == false ? (
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">
                      <div className="d-flex justify-content-center">#</div>
                    </th>
                    <th scope="col">
                      <div className="d-flex justify-content-center">
                        Geography
                      </div>
                    </th>
                    <th scope="col">
                      <div className="d-flex justify-content-center">
                        Shift timings
                      </div>
                    </th>
                    <th scope="col">
                      <div className="d-flex justify-content-center">
                        Status
                      </div>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {geographyList &&
                    geographyList.map((data, index) => (
                      <tr key={data.id}>
                        <td>
                          <div className="d-flex justify-content-center switch-container">
                            {index + 1}
                          </div>
                        </td>
                        <td>
                          <div className="d-flex justify-content-center">
                            {data.geography}
                          </div>
                        </td>
                        <td>
                          <div className="d-flex justify-content-center">
                            {data.shiftTiming.map((shift, index) => {
                              if (shift.status) {
                                return index === data.shiftTiming.length - 1
                                  ? shift.startTime + " - " + shift.endTime
                                  : shift.startTime +
                                      " - " +
                                      shift.endTime +
                                      ", ";
                              }
                            })}
                          </div>
                        </td>
                        <td>
                          <div className="d-flex justify-content-center switch-container">
                            <Switch
                              id={data.id}
                              isOn={data.status}
                              handleToggle={() => handleStatusPopup(data)}
                              colorOne="#0FB215"
                              colorTwo="#DB0000"
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p style={{ color: "#175572" }}>Loading...</p>
          )}
          {totalPages && geographyList.length > 0 ? (
            <div className="pagination mt-2">
              {currentPage !== 1 && totalPages > 5 && (
                <button onClick={handleDualPrevPage}>
                  <FaAngleDoubleLeft className="arrow-icon left-arrow " />
                </button>
              )}

              {currentPage !== 1 && (
                <button onClick={handlePrevPage}>
                  <FaAngleLeft className="arrow-icon left-arrow" />
                </button>
              )}

              {totalPages &&
                pageNumbers.map((page) => (
                  <button
                    key={page}
                    disabled={currentPage === page}
                    className={currentPage === page ? "active" : ""}
                    onClick={() => {
                      filterGeography(page);
                      adjustPages(page);
                      paginate(page, geographyList);
                    }}
                  >
                    {page}
                  </button>
                ))}

              {currentPage !== totalPages && (
                <button onClick={handleNextPage}>
                  <FaAngleRight className="arrow-icon" />
                </button>
              )}

              {currentPage !== totalPages && totalPages > 5 && (
                <button onClick={handleDualNextPage}>
                  <FaAngleDoubleRight className="arrow-icon" />
                </button>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

export default Geography;
