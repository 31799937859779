import React from "react";
import App from "../app";
import { Navigate } from "react-router-dom";
import Dashboard from "../pages/Dashboard/Dashboard";
import Login from "../pages/Login/Login";
import Logout from "../components/Logout/Logout";
import Buzz from "../pages/Buzz/Buzz";
import BuzzAdmin from "../pages/Buzz/BuzzAdmin";
import Assist from "../pages/Ess/Ess";
import PhotoSharing from "../pages/PhotoSharing/PhotoSharing";
import EssDashboard from "../components/EssDashboard/EssDashboard";
import UserRegistration from "../pages/UserRegistration/UserRegistration";
import SocialTemplateBank from "../pages/SocialTemplateBank/SocialTemplateBank";
import ErrorPage from "../pages/ErrorPage";
import ClanScoreAdmin from "../components/ClanScoreMaster/ClanScoreAdmin";

import AccessManagementByRoles from "../components/AccessManagement/AccessManagementByRoles";
import BirthdayTemplateBank from "../pages/SocialTemplateBank/BirthdayTemplateBank";
import AnniversaryTemplateBank from "../pages/SocialTemplateBank/AnniversaryTemplateBank";
import BrandStarterKitTemplateBank from "../pages/SocialTemplateBank/BrandStarterKitTemplateBank.jsx";
import ActivityLog from "../components/ActivityLog/ActivityLog";
import ForgotPassword from "../pages/UserRegistration/ForgotPassword";
import SearchQueryGenerator from "../components/SearchQueryGenerator/SearchQueryGenerator";
import BooleanQueryGeneratorComponent from "../components/BooleanQueryGenerator/BooleanQuery.js";
import JdUpload from "../components/BooleanQueryGenerator/Jdupload";
import QuizEvaluation from "../components/BooleanQueryGenerator/QuizEvaluation";
import QuizResult from "../components/BooleanQueryGenerator/QuizResult";
import BulkMail from "../components/BulkMailManagment/BulkMail";
import PreviousMail from "../components/BulkMailManagment/PreviousMail";
import FeedBack from "../components/Feedback/feedback";
import AdminDashboard from "../components/DashBoard/AdminDashboard";
import BirthdayPostGenerator from "../components/BirthdayPostGenerator/BirthdayPostGenerator.jsx";
import AnniversaryPostGenerator from "../components/AnniversaryPostGenerator/AnniversaryPostGenerator.jsx";
import UserProfile from "../components/UserRegistration/UserProfile.jsx";
import ShitftTimings from "../pages/MasterDB/ShiftTimings.jsx";
import Geography from "../pages/MasterDB/Geography.jsx";
import Departments from "../pages/MasterDB/Departments.jsx";
import DayLightSaving from "../pages/DayLightSaving/DayLightSaving.jsx";
const Approute = [
  {
    path: "/*",
    element: <App />,
    children: [
      {
        path: "",
        element: <Navigate to="/dashboard" />,
        title: "Home",
        titleSlug: "home",
        type: "Public",
        isInSideBar: true,
      },
      {
        path: "dashboard",
        element: <Dashboard />,
        title: "Dashboard",
        titleSlug: "dashboard",
        type: "Public",
        isInSideBar: true,
      },
      {
        path: "my-profile",
        element: <UserProfile />,
        title: "My Profile",
        type: "Public",
        isInSideBar: true,
        titleSlug: "my_profile",
      },
      {
        path: "assist/*",
        type: "Public",
        children: [
          {
            path: "",
            element: <Assist />,
            title: "Assist",
            isInSideBar: true,
            titleSlug: "assist",
            type: "Public",
          },
          {
            path: "recruiter-social",
            element: <EssDashboard />,
            title: "Recruiter Social",
            titleSlug: "recruiter_social",

            type: "Private",
            isInSideBar: false,
          },
          {
            path: "search-query-generator",
            element: <EssDashboard />,
            title: "Search Query Generator",
            titleSlug: "search_query_generator",

            isInSideBar: false,
          },
          {
            path: "jd-generator",
            element: <EssDashboard />,
            title: "JD Generator",
            titleSlug: "jd_generator",
            type: "Public",

            isInSideBar: false,
          },
          {
            path: "recruiter-outreach",
            element: <EssDashboard />,
            title: "Recruiter Outreach",
            titleSlug: "recruiter_outreach",
          },
          {
            path: "domain-classification",
            element: <EssDashboard />,
            title: "Domain Identifier",
            titleSlug: "domain_identifier",
          },
          {
            path: "recommended-jobs",
            element: <EssDashboard />,
            title: "Recommended Jobs",
            titleSlug: "recommended_jobs",
          },
          {
            path: "relevant-candidate-finder",
            element: <EssDashboard />,
            title: "Relevant Candidate Finder",
            titleSlug: "relevant_candidate_finder",
          },
          {
            path: "skill-highlighter",
            element: <EssDashboard />,
            title: "Skill Highlighter",
            titleSlug: "skill_highlighter",
          },
          {
            path: "interview-questions-and-answers",
            element: <EssDashboard />,
            title: "Interview Q & A",
            titleSlug: "interview_q_and_a",
          },
          {
            path: "candidate-synopsis",
            element: <EssDashboard />,
            title: "Candidate Synopsis",
            titleSlug: "candidate_synopsis",
          },  {
            path: "brand-starter-kit",
            element: <EssDashboard />,
            title: "Brand Starter Kit",
            titleSlug: "candidate_synopsis",
          },

          { path: "*", element: <ErrorPage />, title: "Error Page" },
        ],
      },
      {
        path: "memories/*",
        type: "Public",
        children: [
          {
            path: "*",
            element: <PhotoSharing />,
            title: "Memories",
            type: "Public",
            titleSlug: "memories",

            isInSideBar: true,
          },
        ],
      },
      {
        path: "auth/*",
        type: "Public",
        children: [
          {
            path: "login",
            element: <Login />,
            title: "Login",
            titleSlug: "login",
          },
          {
            path: "forgot-password",
            element: <ForgotPassword />,
            title: "Forgot Password",
            titleSlug: "forgot_password",

            type: "Public",
          },
          {
            path: "logout",
            element: <Logout />,
            title: "Logout",
            titleSlug: "logout",
          },
          {
            path: "*",
            element: <ErrorPage />,
            title: "Error Page",
            titleSlug: "error_page",
          },
        ],
      },
      {
        path: "buzz/*",
        type: "Public",
        children: [
          {
            path: "",
            element: <Buzz />,
            title: "Buzz",
            titleSlug: "buzz",
            isInSideBar: true,
          },
          {
            path: "admin/*",
            title: "Buzz Admin",
            titleSlug: "buzz_admin",
            type: "Private",
            children: [
              { path: "", element: <BuzzAdmin /> },
              { path: "published", element: <BuzzAdmin /> },
              { path: "draft", element: <BuzzAdmin /> },
              { path: "trash", element: <BuzzAdmin /> },
              { path: "*", element: <ErrorPage />, title: "Error Page" },
            ],
          },
          { path: "*", element: <ErrorPage />, title: "Error Page" },
        ],
      },
      {
        path: "admin/*",
        titleSlug: "admin",

        type: "Partial",
        children: [
          {
            path: "template-bank/*",
            titleSlug: "template_bank",
            isInSideBar: true,
            type: "Partial",
            children: [
              {
                path: "social-media-templates",
                element: <SocialTemplateBank />,
                title: "Social Template Bank",
                titleSlug: "social_media_templates",
                type: "Private",
                isInSideBar: true,
              },
              {
                path: "birthday-templates",
                element: <BirthdayTemplateBank />,
                title: "Birthday Template Bank",
                titleSlug: "birthday_template_bank",
                type: "Private",
                isInSideBar: true,
              },
              {
                path: "anniversary-templates",
                element: <AnniversaryTemplateBank />,
                titleSlug: "anniversary_template_bank",
                title: "Anniversary Template Bank",
                type: "Private",
                isInSideBar: true,
              },
              {
                path: "brandStarterKit-templates",
                element: <BrandStarterKitTemplateBank />,
                titleSlug: "brand_starter_kit",
                title: "Brand Starter Kit",
                type: "Public",
                isInSideBar: true,
              }
        ]},
        {
          path: "master-records/*",
          titleSlug: "master_records",
          isInSideBar: true,
          type: "Partial",
          children: [
            {
              path: "departments",
              element: <Departments />,
              title: "Departments",
              titleSlug: "departments",
              type: "Private",
              isInSideBar: true,
            },
            {
              path: "geography",
              element: <Geography/>,
              title: "Geography",
              titleSlug: "geography",
              type: "Private",
              isInSideBar: true,
            },
            {
              path: "shift-timings",
              element: <ShitftTimings />,
              titleSlug: "shift_timings",
              title: "Shift timings",
              type: "Private",
              isInSideBar: true,
            }
      ]},
        {
          path : "mail/*",
           title : "Emailers",
           titleSlug : "emailers",
           isInSideBar: true,
           type : "Public",
           children:[
            { path: "bulk-mail", isInSideBar: true,  type : "Public", element:  <BulkMail />, title: "Bulk Mail ", titleSlug:"bulk_mail" },
            { path: "previous-mail",  isInSideBar: true, type : "Public",element:  <PreviousMail />, title: "Previous Mail ",  titleSlug:"previous_mail" },
          ]
          
          }, 
          {
            path: "user-registration/*",
            titleSlug: "user_registration",
            isInSideBar: true,
            type: "Partial",
            children: [
              {
                path: "single-user",
                element: <UserRegistration />,
                title: "Single User",
                type: "Private",
                isInSideBar: true,
                titleSlug: "single_user",
              },
              {
                path: "single-user/update",
                element: <UserRegistration />,
                title: "Single User",
                type: "Private",

                titleSlug: "single_user",
              },
              {
                path: "bulk-user",
                element: <UserRegistration />,
                title: "Multi Users",
                type: "Private",
                isInSideBar: true,
                titleSlug: "multi_users",
              },
              {
                path: "manage-user",
                element: <UserRegistration />,
                title: "Manage User",
                type: "Private",
                isInSideBar: true,
                titleSlug: "manage_user",
              },
            ],
          },
          {
            path: "feedbacks",
            element: <FeedBack />,
            title: "Feedback Management",
            type: "Private",
            titleSlug: "feedback",
          },

          {
            path: "clan-score-master",
            element: <ClanScoreAdmin />,
            title: "Clan Score Master",
            titleSlug: "clan_score_master",
            type: "Private",
            isInSideBar: true,
          },
          {
            path: "birthday-post",
            element: <BirthdayPostGenerator />,
            title: "Birthday Post",
            titleSlug: "birthday_post",
            type: "Private",
            isInSideBar: true,
          },
          {
            path: "day-light-saving",
            element: <DayLightSaving/>,
            title: "Day Light Saving",
            titleSlug: "day_light_saving",
            type: "Private",
            isInSideBar: true,
          },
          {
            path: "anniversary-post",
            element: <AnniversaryPostGenerator />,
            title: "Anniversary Post",
            titleSlug: "birthday_post",
            type: "Private",
            isInSideBar: true,
          },
          {
            path: "quiz-boolean",
            element: <BooleanQueryGeneratorComponent />,
            title: "Quiz",
            titleSlug: "boolean_quiz",
            type: "Private",
            isInSideBar: true,
          },
          {
            path: "jd-upload",
            element: <JdUpload />,
            title: "JD Upload",
            titleSlug: "jd_upload",
            type: "Private",
            isInSideBar: true,
          },
          {
            path: "quiz-evaluation/:id",
            element: <QuizEvaluation />,
            title: "Quiz Evaluation",
            type: "Private",
            titleSlug: "quiz_evaluation",
            isInSideBar: true,
          },
          {
            path: "quiz-result",
            element: <QuizResult />,
            title: "Quiz Result",
            type: "Private",
            titleSlug: "quiz_result",
            isInSideBar: true,
          },
          // {
          //   path: "mail",
          //   element: <BulkMail />,
          //   title: "Mail",
          //   type: "Public",
          //   titleSlug: "bulk_mail",
          //   isInSideBar: true,
          // },
          // {
          //   path: "previous-mail",
          //   element: <PreviousMail />,
          //   title : "Previous Mail",
          //   type: "Private",
          //   titleSlug: "previous_mail",
          //   isInSideBar: false
          // },
          {
            path: "access-control",
            element: <AccessManagementByRoles />,
            title: "Role Based Access Control",
            type: "Private",
            titleSlug: "role_based_access_control",
          },
          {
            path: "activity-log",
            element: <ActivityLog />,
            title: "Activity Log",
            type: "Private",
            titleSlug: "activity_log",
            isInSideBar: true,
          },
          { path: "*", element: <ErrorPage />, title: "Error Page" },
        ],
      },
      // {
      //   path: "clan-score-master/*",
      //   type: "Private",
      //   titleSlug: "clan_score_master",

      //   children: [
      //     {
      //       path: "",
      //       element: <ClanScoreAdmin />,
      //       title: "Clan Score Master",
      //       titleSlug: "clan_score_master",
      //       isInSideBar: true,
      //     },
      //   ],
      // },

      // {
      //   path: "activity-log/*",
      //   type: "Private",
      //   titleSlug: "activity_log",

      //   children: [
      //     {
      //       path: "",
      //       element: <ActivityLog />,
      //       title: "Activity Log",
      //       titleSlug: "activity_log",
      //       isInSideBar: true,
      //     },
      //   ],
      // },

      // {
      //   path: "boolean-query-generator/*",
      //   type:"Public",
      //   titleSlug: "boolean_query_generator",

      //   children: [

      //   ],
      // },

      {
        path: "quiz/*",
        type: "Private",
        titleSlug: "quiz",
        children: [
          {
            path: "quiz-boolean",
            element: <BooleanQueryGeneratorComponent />,
            title: "Quiz",
            titleSlug: "boolean_quiz",
            isInSideBar: true,
          },
        ],
      },

      {
        path: "*",
        element: <ErrorPage />,
        title: "Error Page",
        titleSlug: "error_page",
      },
    ],
  },
];

export default Approute;
