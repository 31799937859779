import React, { useEffect, useState } from "react";
// import MinioService from "../../libs/MinioService";

import BuzzCard from "../Widgets/BuzzCard";
import StatusCard from "../Widgets/StatusCard";
import BuzzService from "../../services/buzz.service";
import { useSelector, useDispatch } from "react-redux";
import {
  updateSingleBuzz,
  filterBuzzList,
  setFilteredBuzzList,
  setBuzzList,
} from "../../features/buzzSlice";
import buzzService from "../../services/buzz.service";
import { toast } from "react-hot-toast";
import { Toast } from "bootstrap";
import GradientBorderButton from "../Widgets/GradientBorderButton";
import { useNavigate } from "react-router-dom";
// import { Toast } from "reactstrap";

function BuzzContent() {
  const buzzList = useSelector((state) => state.buzz.filteredBuzzList);
  const dispatch = useDispatch();
  const [currentBuzz, setCurrentBuzz] = useState();
  const [showDeleteForm, setShowDeleteForm] = useState(false);
  const [showPopUp, setShowPopup] = useState(false);
  let navigate = useNavigate()
  const [publishedBuzz, setPublishedBuzz] = useState();
  const handleBuzzEmail = async () => {
    await buzzService.sendBuzzEmail(currentBuzz);
    setShowPopup(false);
    setPublishedBuzz();
    const toast1 = document.getElementById("toast-2");
    const toast = new Toast(toast1);
    toast.show();
  };
  const getBuzzList = async (pageNo, status) => {
    const data = await BuzzService.getBuzzList(pageNo, status);

    // adjustPages(currentPage);
    dispatch(setFilteredBuzzList(data.results));
    // dispatch(filterBuzzList(1));
  };
  const handleBuzzStatusUpdate = async (
    buzzId,
    status,
    buzzNo,
    featureImage
  ) => {
    if (status === "published") status = 1;
    else if (status === "draft") status = 0;
    else status = 2;
    // update buzz status for client.
    if (status != 2) {
      dispatch(updateSingleBuzz({ buzzId, status }));
    }
    let currentLocation = window.location.pathname.split("/").pop();
    let filter = -1;
    if (currentLocation === "published") filter = 1;
    else if (currentLocation === "draft") filter = 0;
    else if (currentLocation === "trash") filter = 2;
    else filter = -1;

    if (status == 1) {
      navigate("/buzz/admin/published")
      setPublishedBuzz();
      setShowPopup(true);
      const result = await BuzzService.updateBuzzStatus(buzzId, status);
    }

    if (filter !== -1) dispatch(filterBuzzList(filter));
    // api call to update buzz status.
    if (status != 2 && status != 1) {
      navigate("/buzz/admin/draft")

      const result = await BuzzService.updateBuzzStatus(buzzId, status);
    } else if(status != 1) {
      setCurrentBuzz({ buzzNo, featureImage, id: buzzId });
      setShowDeleteForm(true);
    }
  };
  const handleBuzzPublished = async () => {

  }
  const handleBuzzDelete = async (buzzId) => {
    let currentLocation = window.location.pathname.split("/").pop();
    let filter = -1;
    if (currentLocation === "published") filter = 1;
    else if (currentLocation === "draft") filter = 0;
    await buzzService.removeBuzz(buzzId);
    setShowDeleteForm(false);
    getBuzzList(1, filter);
  };
  let popups = `${showDeleteForm}${showPopUp}`;

  useEffect(() => {
    let dashboardElement = document.querySelector(".dashboard");

    if (dashboardElement && popups.includes("true")) {
      // Remove the 'transform' property
      // Remove the 'transform-origin' property
      // dashboardElement.style.transformOrigin = '';
    } else if (!popups.includes("true") && dashboardElement) {
    }
  }, [popups]);
  if (window.innerWidth > 768) {
    return (
      <div className="d-flex justify-content-space-between buzz-container">
        <div className="toasts-container position-absolute top-0 mt-3 mx-3">
          <div className="toast fade hide" data-autohide="true" id="toast-2">
            <div className="toast-header">
              <i className="far fa-bell text-muted me-2"></i>
              <strong className="me-auto">ALOIS</strong>
              <button
                type="button"
                id="toastclose_btn"
                className="btn-close"
                data-bs-dismiss="toast"
              ></button>
            </div>
            <div className="toast-body">
              <p className="text-success fw-bold">Email sent!</p>
            </div>
          </div>
        </div>
        <div className="buzz-list-container">
          <p className="form-label text-center">Buzz</p>
          {showPopUp ? (
            <div className="popup-container">
              <div className="popup">
                <div className="popup-header">
                  <span className="fs-6 fw-bold">Send Email</span>
                  <button
                    className="close-button"
                    onClick={() => {
                      setShowPopup(false);
                    }}
                  >
                    &#10005;
                  </button>
                </div>
                <hr style={{ borderTop: "2px dashed black" }} />
                <div className="popup-content mb-1">
                  <h5>
                    Do you want to send email to everyone about this buzz?
                  </h5>
                  <div className="d-flex justify-content-center gap-3 mt-2">
                    <GradientBorderButton
                      text="Yes"
                      clickHandler={handleBuzzEmail}
                    />
                    <GradientBorderButton
                      text="No"
                      clickHandler={() => setShowPopup(false)}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
           {publishedBuzz ? (
            <div className="popup-container">
              <div className="popup">
                <div className="popup-header">
                  <span className="fs-6 fw-bold">Publish Buzz</span>
                  <button
                    className="close-button"
                    onClick={() => {
                      setPublishedBuzz();
                    }}
                  >
                    &#10005;
                  </button>
                </div>
                <hr style={{ borderTop: "2px dashed black" }} />
                <div className="popup-content mb-1">
                  <h5>Are you sure you want to publish this buzz?</h5>
                  <div className="d-flex justify-content-center gap-3 mt-2">
                    <GradientBorderButton
                      text="Yes"
                      clickHandler={() => {
                        handleBuzzStatusUpdate(currentBuzz.id, "published", currentBuzz.buzzNo, currentBuzz.featureImage);
                      }}
                    />
                    <GradientBorderButton
                      text="No"
                      clickHandler={() => setPublishedBuzz()}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {showDeleteForm ? (
            <div className="popup-container">
              <div className="popup">
                <div className="popup-header">
                  <span className="fs-6 fw-bold">Delete Buzz</span>
                  <button
                    className="close-button"
                    onClick={() => {
                      setShowDeleteForm(false);
                    }}
                  >
                    &#10005;
                  </button>
                </div>
                <hr style={{ borderTop: "2px dashed black" }} />
                <div className="popup-content mb-1">
                  <h5>Are you sure you want to delete this buzz?</h5>
                  <div className="d-flex justify-content-center gap-3 mt-2">
                    <GradientBorderButton
                      text="Yes"
                      clickHandler={() => {
                        handleBuzzDelete(currentBuzz.id);
                      }}
                    />
                    <GradientBorderButton
                      text="No"
                      clickHandler={() => setShowDeleteForm(false)}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {buzzList.map((item, index) => {
            const camelCaseMonth =
              item.month.charAt(0).toUpperCase() +
              item.month.slice(1).toLowerCase();
            return (
              <React.Fragment key={index}>
                <BuzzCard
                  month={camelCaseMonth}
                  year={item.year}
                  image={item.featureImage}
                />
              </React.Fragment>
            );
          })}
        </div>
        <div className="vertical-line"></div>
        <div className="status-container">
          <p className="form-label text-center">Status</p>
          {buzzList.map((item, index) => {
            return (
              <div className="status-card-container" key={index}>
                <StatusCard
                  text={"published"}
                  status={item.status}
                  buzzId={item.id}
                  buzzNo={item.buzzNo}
                  handleBuzzStatusUpdate={() => {
                    setCurrentBuzz({ buzzNo:item.buzzNo,featureImage: item.featureImage, id: item.id });
                    setPublishedBuzz(item.id);
                  }}
                />
                <StatusCard
                  text={"draft"}
                  status={item.status}
                  buzzId={item.id}
                  handleBuzzStatusUpdate={handleBuzzStatusUpdate}
                />
                <StatusCard
                  text={"trash"}
                  status={item.status}
                  buzzId={item.id}
                  handleBuzzStatusUpdate={() => {
                    handleBuzzStatusUpdate(
                      item.id,
                      "trash",
                      item.buzzNo,
                      item.featureImage
                    );
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  } else {
    return (
      <div className="d-flex justify-content-center">
        <div className="toasts-container position-absolute top-0 mt-3 mx-3">
          <div className="toast fade hide" data-autohide="true" id="toast-2">
            <div className="toast-header">
              <i className="far fa-bell text-muted me-2"></i>
              <strong className="me-auto">ALOIS</strong>
              <button
                type="button"
                id="toastclose_btn"
                className="btn-close"
                data-bs-dismiss="toast"
              ></button>
            </div>
            <div className="toast-body">
              <p className="text-success fw-bold">Email sent!</p>
            </div>
          </div>
        </div>
        <div>
          {buzzList.map((item, index) => {
            return (
              <div key={index} className="buzz-card-with-status">
                <BuzzCard
                  month={item.month}
                  year={item.year}
                  image={item.featureImage}
                />
                <div className="status-container">
                  <div className="status-card-container">
                    <StatusCard
                      text={"published"}
                      status={item.status}
                      buzzId={item.id}
                      buzzNo={item.buzzNo}
                      handleBuzzStatusUpdate={() => {
                        setCurrentBuzz({ buzzNo:item.buzzNo,featureImage: item.featureImage, id: item.id });
                        setPublishedBuzz(item.id);
                      }}
                    />
                    <StatusCard
                      text={"draft"}
                      status={item.status}
                      buzzId={item.id}
                      handleBuzzStatusUpdate={handleBuzzStatusUpdate}
                    />
                    <StatusCard
                      text={"trash"}
                      status={item.status}
                      buzzId={item.id}
                      handleBuzzStatusUpdate={() => {
                      setCurrentBuzz({})
                        handleBuzzStatusUpdate(
                          item.id,
                          "trash",
                          item.buzzNo,
                          item.featureImage
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default BuzzContent;
