import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FaGreaterThan,
  FaEdit,
  FaLessThan,
  FaTrashAlt,
  FaUserAlt,
  FaSearch,
} from "react-icons/fa";
import { RiFilter2Fill } from "react-icons/ri";
// import UserRegistrationPopup from "./UserRegistrationPopup";
import { NavLink, useNavigate } from "react-router-dom";
import licenceValidator from "../../permission/validate";
import { MdOutlineQuiz } from "react-icons/md";
import jobDescriptionService from "../../services/jobDescription.service";
import quizResultService from "../../services/quizResult.service";
import booleanQueryService from "../../services/booleanQuery.service";

const QuizResult = () => {
  const navigate = useNavigate();

  const [selectedDomain, setSelectedDomain] = useState("");
  const [userData, setUserData] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [userList, setUserList] = useState([]);
  // const [filteredUserList, setFilteredUserList] = useState([]);
  const [quizResults, setQuizResults] = useState([]);
  const [domains, setDomains] = useState([]);
  // const jdRef = useRef(null);
  const [queryBox, setQueryBox] = useState(false);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");
  const [booleanQuery, setBooleanQuery] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [successNotific, setSuccessNotific] = useState("");
  const [errorNotific, setErrorNotific] = useState("");
  const [selectedText, setSelectedText] = useState("");
  const [selectedTextArray, setSelectedTextArray] = useState([]);
  const [showAddButton, setShowAddButton] = useState(false);
  const [buttonPosition, setButtonPosition] = useState({ top: 0, left: 0 });

  const handleSearch = async (e) => {
    const response = await quizResultService.getQuizResults();
    const questions = await booleanQueryService.getAllBooleanQueries();

    // Use the filter method to create a new array with filtered results
    const filteredResults = response.results.filter((result) => {
      // Check if the result's id exists in the question array
      return questions.results.some(
        (question) =>
          question.testId === result.id &&
          result.fullName.toLowerCase().includes(e.target.value.toLowerCase())
      );
    });

    setQuizResults(filteredResults);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await quizResultService.getQuizResults();
        const questions = await booleanQueryService.getAllBooleanQueries();

        // Use the filter method to create a new array with filtered results
        const filteredResults = response.results.filter((result) => {
          // Check if the result's id exists in the question array
          return questions.results.some(
            (question) => question.testId === result.id
          );
        });

        setQuizResults(filteredResults);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []);

  function handleStartEvaluation(id) {
    navigate(`/admin/quiz-evaluation/${id}`);
  }

  return (
    <div className="user-registration-container ">
      <div
        className="user-registration-outer mx-auto h-75 clan-score-admin"
        style={{ paddingTop: "1px", marginTop: "1.7rem" }}
      >
        <div className="indicator-container ">
          <div>
            <MdOutlineQuiz className="icon" />
          </div>

          <div className="text">Quiz Result</div>
        </div>

        <div
          className="user-registration-inner mt-3"
          style={{ borderRadius: "10px" }}
        >
          <div className="manage-user-table">
            <div className="upper-section">
              <p style={{ fontSize: "1.3rem", color: "#a4b3ba" }}>
                Manage User Result
              </p>

              <div className="d-flex">
                {/* <span className="filter-btn mx-2" role="button">
                  <RiFilter2Fill style={{ fill: "#175572" }} /> Filter
                </span> */}
                <div className="input-group mb-3">
                  <button
                    className="btn btn-outline-secondary search-btn"
                    type="button"
                    id="button-addon1"
                  >
                    <FaSearch className="mb-1" />
                  </button>

                  <input onChange={handleSearch} type="text" />
                </div>
              </div>
            </div>

            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col" style={{ color: "#175572" }}>
                      Full Name
                    </th>
                    <th scope="col">Domain</th>
                    <th scope="col">Marks</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  {quizResults.map((result, index) => (
                    <tr key={index}>
                      <td>{result.fullName}</td>
                      <td>{result.domain}</td>
                      <td>{result.marks}</td>
                      <td>
                        <button
                          onClick={() => handleStartEvaluation(result.id)}
                          className="generate-btn m-0 ms-2 w-50 p-1"
                        >
                          Start Evaluation
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuizResult;
