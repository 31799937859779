import React from "react";
import { useEffect, useRef, useState } from "react";
import { AiFillCloseCircle, AiOutlineClose } from "react-icons/ai";
import { MdAdminPanelSettings } from "react-icons/md";
import JoditEditor from "../Jodit/JoditEditor";
import licenceValidator from "../../permission/validate";
import mailerService from "../../services/mailer.service";
import userRegistrationService from "../../services/userRegistration.service";
import GradientBorderButton from "../Widgets/GradientBorderButton";
import { FiUpload } from "react-icons/fi";
import masterDBService from "../../services/masterDB.service";

function BulkMail() {
  const [departments, setDepartments] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [shiftTimings, setShiftTimings] = useState([]);
  const [filteredShiftTimings, setFilteredShiftTimings] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [successNotific, setSuccessNotific] = useState("");
  const [errorNotific, setErrorNotific] = useState("");
  const [users, setUsers] = useState([]);
  const [editorText, setEditorText] = useState("");
  const [schedulePopup, setSchedulePopUp] = useState(false);
  const [userList, setUserList] = useState([]);
  const [initialFilteredUserList, setInitialFilteredUserList] = useState([]);
  const [selectedUserdata, setSelectedUserData] = useState([]);
  const [usersCountByDepartment, setUsersCountByDepartment] = useState({});
  const [isvisible, setIsVisible] = useState(false);

  const searchedUserRef = useRef(null);
  const departmentRef = useRef();
  const shiftRef = useRef();
  const shiftTimingRef = useRef(null);
  const dateRef = useRef();
  const timeRef = useRef();
  const ccRef = useRef();
  const bccRef = useRef();
  const subjectRef = useRef();
  const initialFilteredUserListCalled = useRef(false);
  useEffect(() => {
    let user = localStorage.getItem("user");
    let validator = new licenceValidator(user, "emailers");
    setIsVisible(validator.isReadable("bulk_mail"));
  }, []);
  const dropdown = async () => {
    try {
      const data = await masterDBService.getAllDepartments();
      setDepartments(data.results);

      const results = await masterDBService.getAllGeographies();
      setShifts(results.results);
      const result1 = await masterDBService.getAllShiftTimings();
      setShiftTimings(result1.results);
    } catch (error) {
      console.error("Error Fetching data ", error);
    }
  };

  const getUsers = async () => {
    const data = await userRegistrationService.getUsers1();
    setUserList(data.results);
  };

  const handleDeparmentChange = async (e) => {
    const { value } = e.target;

    if (value === "default") {
      const uniqueShifts = [...new Set(userList.map((item) => item.shift))];
      const result1 = await masterDBService.getAllShiftTimings();
      const results = await masterDBService.getAllGeographies();

      setShifts(results.results);

      shiftRef.current.value = "default";

      setShiftTimings(result1.results);
      shiftTimingRef.current.value = "default";
    }

    if (departments.find((item) => item.id == value)) {
      let shiftsArray = [];

      shiftsArray =
        departments.find((department) => department.id === value)?.geography ||
        [];

      setShifts(shiftsArray);
      shiftRef.current.value = "default";

      let shiftTimingsArray = [];
      const allShiftTimings = shiftsArray.flatMap((obj) => obj.shiftTiming);
      shiftTimingsArray = allShiftTimings;
      setShiftTimings(shiftTimingsArray);
      let filteredShifts = departments.flatMap((obj) => obj.geography);
      let filterShiftTiming = filteredShifts.flatMap((obj) => obj.shiftTiming);
      const uniqueShiftTimings = [...new Set(filterShiftTiming)];

      setFilteredShiftTimings(uniqueShiftTimings);
      shiftTimingRef.current.value = "default";
    }

    initialFilteredUserListCalled.current = false;
    getUserData(e);
  };

  const handleShiftChange = async (e) => {
    const { value } = e.target;

    if (value == "default") {
      setShiftTimings(filteredShiftTimings);
    } else {
      let shiftTimingsArray = [];
      shiftTimingsArray =
        shifts.find((shift) => shift.id === value)?.shiftTiming || [];

      const uniqueShiftTimings = [...new Set(shiftTimingsArray)];
      setShiftTimings(uniqueShiftTimings);
    }

    shiftTimingRef.current.value = "default";
    getUserData(e);
  };

  const handleShiftTimingChange = async (e) => {
    searchedUserRef.current.value = "no-user";
    getUserData(e);
  };

  const getUserData = (e) => {
    let updatedUserList = [...userList];

    if (e.target.value === "no-user") {
      return;
    }

    if (departmentRef.current?.value !== "default") {
      updatedUserList = updatedUserList.filter(
        (item) =>
          item.department &&
          item.department.toLowerCase() ==
            departmentRef.current.value.toLowerCase()
      );
    }

    if (shiftRef.current?.value !== "default") {
      updatedUserList = updatedUserList.filter(
        (item) =>
          item.shift &&
          item.shift.toLowerCase() == shiftRef.current.value.toLowerCase()
      );
    }

    if (shiftTimingRef.current?.value !== "default") {
      updatedUserList = updatedUserList.filter(
        (item) =>
          item.shiftTiming &&
          item.shiftTiming.toLowerCase() ==
            shiftTimingRef.current.value.toLowerCase()
      );
    }

    // Search Dropdown
    const selectElement = document.getElementById("user-search");
    const selectedUserId = selectElement.value;
    const selectedUser = userList.find((user) => user.id === selectedUserId);
    const isUserAlreadySelected = selectedUser
      ? users.some((user) => user.id === selectedUser.id)
      : true;

    if (selectedUser !== undefined) {
      if (isUserAlreadySelected) {
        return;
      } else {
        if (
          departmentRef.current?.value === "default" &&
          shiftRef.current?.value === "default" &&
          shiftTimingRef.current?.value === "default"
        ) {
          updatedUserList = [selectedUser];
        } else {
          updatedUserList = [...updatedUserList, selectedUser];
        }
      }
    }

    // If we have selected a department and also adding a user from search dropdown
    updatedUserList = selectedUserdata.length
      ? !isUserAlreadySelected
        ? [...selectedUserdata, selectedUser]
        : updatedUserList
      : updatedUserList;
    setSelectedUserData(updatedUserList);

    // We need user list according to the department we have selected and independent of shift change.
    if (!initialFilteredUserListCalled.current) {
      setInitialFilteredUserList(updatedUserList);
      initialFilteredUserListCalled.current = true;
    }

    setUsers(updatedUserList);
  };

  const clearAllRecipients = async () => {
    setUsers([]);
    setSelectedUserData([]);

    searchedUserRef.current.value = "no-user";
    departmentRef.current.value = "default";
    shiftRef.current.value = "default";
    shiftTimingRef.current.value = "default";
  };

  const handleDeleteReciepient = (index) => {
    let data = [...users];
    data.splice(index, 1);

    setUsers(data);
    setSelectedUserData(data);
    searchedUserRef.current.value = "no-user";
  };

  const handleTextChange = (text) => {
    // Update the parent component's state with the text value
    setEditorText(text);
  };

  const handleScheduleSubmit = async () => {
    let users1 = JSON.stringify(
      users.map((user) => {
        delete user.license;
        return user;
      })
    );

    let errorMessage = "";

    if (!dateRef.current.value) {
      errorMessage = "Schedule date cannot be empty";
    }
    if (!timeRef.current.value) {
      errorMessage = "Schedule Time cannot be empty";
    }
    if (!subjectRef.current.value) {
      errorMessage = "Email subject cannot be empty";
    }
    if (!users.length) {
      errorMessage = "Recipients cannot be empty";
    }

    if (errorMessage) {
      setShowAlert(true);
      setErrorNotific(errorMessage);
      setSuccessNotific("");
      setTimeout(() => {
        setShowAlert(false);
        setErrorNotific("");
      }, 2000);
      return;
    }

    let data = {
      ccMail: ccRef.current.value,
      bccMail: bccRef.current.value,
      department: departmentRef.current.value,
      message: editorText,
      users: users1,
      date: dateRef.current.value,
      subject: subjectRef.current.value,
      time: timeRef.current.value,
      mailType: "Scheduled",
    };

    await mailerService.createMailer(data);

    searchedUserRef.current.value = "no-user";
    ccRef.current.value = "";
    bccRef.current.value = "";
    subjectRef.current.value = "";

    setUsers([]);
    setSelectedUserData([]);
    setEditorText("");
    setSchedulePopUp(false);

    setShowAlert(true);
    setErrorNotific("");
    setSuccessNotific("Email scheduled successfully!");
    setTimeout(() => {
      setShowAlert(false);
      setSuccessNotific("");
    }, 2000);
  };

  const handleSchedulePopUp = () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    let errorMessage = "";

    if (!editorText) {
      errorMessage = "Email body cannot be empty";
    }
    if (!subjectRef.current?.value) {
      errorMessage = "Email subject cannot be empty";
    }
    if (!emailRegex.test(ccRef.current.value) && ccRef.current.value) {
      errorMessage = "CC: Not a vaild email";
    }
    if (!emailRegex.test(bccRef.current.value) && bccRef.current.value) {
      errorMessage = "BCC: Not a vaild email";
    }
    if (!users.length) {
      errorMessage = "Recipients cannot be empty";
    }

    if (errorMessage) {
      setShowAlert(true);
      setErrorNotific(errorMessage);
      setSuccessNotific("");
      setTimeout(() => {
        setShowAlert(false);
        setErrorNotific("");
      }, 2000);

      return;
    }

    setSchedulePopUp(true);
  };

  const handleSubmit = async () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    let errorMessage = "";

    if (!editorText) {
      errorMessage = "Email body cannot be empty";
    }
    if (!subjectRef.current.value) {
      errorMessage = "Email subject cannot be empty";
    }
    if (!emailRegex.test(ccRef.current.value) && ccRef.current.value) {
      errorMessage = "CC: Not a vaild email";
    }
    if (!emailRegex.test(bccRef.current.value) && bccRef.current.value) {
      errorMessage = "BCC: Not a vaild email";
    }
    if (!users.length) {
      errorMessage = "Recipients cannot be empty";
    }

    if (errorMessage) {
      setShowAlert(true);
      setErrorNotific(errorMessage);
      setSuccessNotific("");
      setTimeout(() => {
        setShowAlert(false);
        setErrorNotific("");
      }, 2000);

      return;
    }

    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();

    // Format the current time as "hh:mm"
    const currentTime = `${String(hours).padStart(2, "0")}:${String(
      minutes
    ).padStart(2, "0")}`;

    let users1 = JSON.stringify(
      users.map((user) => {
        delete user.license;
        return user;
      })
    );

    // Use case: If we press "enter" in jodit, then it is adding a <br> which is extra line in the email body.
    const trimmedEditorText = editorText.replace(/<p>\s*<br>\s*<\/p>/g, "");

    let data = {
      ccMail: ccRef.current.value,
      bccMail: bccRef.current.value,
      department: departmentRef.current.value,
      message: trimmedEditorText,
      users: users1,
      time: currentTime,
      date: new Date(),
      subject: subjectRef.current.value,
      mailType: "Sent",
    };

    await mailerService.createMailer(data);

    searchedUserRef.current.value = "no-user";
    ccRef.current.value = "";
    bccRef.current.value = "";
    subjectRef.current.value = "";

    setUsers([]);
    setSelectedUserData([]);
    setEditorText("");

    setShowAlert(true);
    setErrorNotific("");
    setSuccessNotific("Email sent successfully!");
    setTimeout(() => {
      setShowAlert(false);
      setSuccessNotific("");
    }, 2000);
  };

  const handleEscapeKey = (event) => {
    if (event.key === "Escape") {
      setSchedulePopUp(false);
    }
  };

  useEffect(() => {
    let user = localStorage.getItem("user");
    let validator = new licenceValidator(user, "emailers");
    setIsVisible(validator.isReadable("bulk_mail"));

    dropdown();
    getUsers();

    // Popup will get close on Esc key press
    document.addEventListener("keydown", handleEscapeKey);
    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, []);

  useEffect(() => {
    if (userList.length !== 0) {
      const counts = {};

      userList.forEach((user) => {
        const department = user.department;
        counts[department] = (counts[department] || 0) + 1;
      });

      setUsersCountByDepartment(counts);
    }
  }, [userList]);
  useEffect(() => {
    let dashboardElement = document.querySelector(".dashboard");
    const body = document.body;

    if (schedulePopup && dashboardElement) {
      // Remove the 'transform' property
      // Remove the 'transform-origin' property
      // dashboardElement.style.transformOrigin = '';
    } else if (!schedulePopup && dashboardElement) {
    }
  }, [schedulePopup]);
  return (
    <div
      className="d-flex bulkmail-container flex-column clan-score-admin"
      style={
        true
          ? { marginBottom: "1500px", bottom: "-100px" }
          : { marginBottom: "100px" }
      }
    >
      <div className="indicator-container ">
        <div className="d-flex text">
          <MdAdminPanelSettings className="icon" />
          Bulk Emails
        </div>
      </div>
      {schedulePopup ? (
        <div className="bulkmail-popup-container">
          <div className="bulkmail-popup">
            <div className="bulkmail-popup-header">
              <span className="fs-6 fw-bold">Schedule Emails</span>

              <button
                className="close-button"
                onClick={() => {
                  setSchedulePopUp(false);
                }}
              >
                &#10005;
              </button>
            </div>

            <hr className="popup-hr" />

            <label className="form-label mt-0">
              Recipients ({users.length}):
            </label>

            <div className="row mx-2 p-2 align-items-center border border-1 recipients-box">
              <div className="col-xl-12">
                {users.length ? (
                  users.map((user, index) => (
                    <button
                      draggable="true"
                      type="button"
                      className="button email-btn"
                      value={user}
                    >
                      {user.email}
                      <>
                        <AiFillCloseCircle
                          className="icon ms-2 cursor-pointer"
                          onClick={() => {
                            handleDeleteReciepient(index);
                          }}
                        />
                      </>
                    </button>
                  ))
                ) : (
                  <p className="m-3 d-flex justify-content-center ">
                    No Recipient Found
                  </p>
                )}
              </div>
            </div>

            <label className="form-label mt-3" htmlFor="department">
              Email Subject:
            </label>

            <div className="row mx-2 align-items-center">
              <textarea
                className="form-control w-100 overflow-y-auto"
                type="text"
                rows="1"
                onChange={(e) => {
                  subjectRef.current.value = e.target.value;
                }}
                defaultValue={subjectRef.current.value}
                placeholder="Enter Your Email Subject"
                required
              ></textarea>
            </div>

            <label className="form-label mt-3">Email Body:</label>

            <div className="row align-items-center jodit-editor">
              <JoditEditor
                onTextChange={handleTextChange}
                editorText={editorText}
              />
            </div>

            <div className="row mt-2">
              <div className="col-xl-6">
                <label className="form-label" htmlFor="dateInput">
                  Date:
                </label>

                <div className="row mx-2 align-items-center">
                  <input
                    min={new Date().toISOString().split("T")[0]}
                    ref={dateRef}
                    type="date"
                    id="dateInput"
                  />
                </div>
              </div>

              <div className="col-xl-6">
                <label className="form-label" htmlFor="timeInput">
                  Time:
                </label>

                <div className="row mx-2 align-items-center">
                  <input ref={timeRef} type="time" name="time" id="timeInput" />
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-center pt-3 mt-4">
              <GradientBorderButton
                text={"Confirm"}
                clickHandler={handleScheduleSubmit}
              />
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      {showAlert && (
        <div className="alert-container">
          <div className="upper-section">
            <p>ALOIS</p>

            <span onClick={() => setShowAlert(!showAlert)}>
              <AiOutlineClose className="icon" />
            </span>
          </div>

          <hr />

          <div className="lower-section py-2">
            {errorNotific && <p className="text-danger">{errorNotific}</p>}
            {successNotific && <p className="text-success">{successNotific}</p>}
          </div>
        </div>
      )}

      <div className="bulkmail-outer">
        <div className="bulkmail-inner mt-3">
          <label className="form-label" htmlFor="user-search">
            Recipient Filters:
          </label>

          <div className="d-flex flex-wrap gap-3 mx-2">
            <div className="d-flex flex-column me-2">
              <div className="form-outline">
                <select
                  id="user-search"
                  onChange={getUserData}
                  className="form-select dropdown-styles"
                  ref={searchedUserRef}
                >
                  <>
                    <option value="no-user">Select User</option>
                    {userList.map((user) => (
                      <option value={user.id} key={user.id}>
                        {user.firstName} {user.lastName}
                      </option>
                    ))}
                  </>
                </select>
              </div>
            </div>

            <div className="d-flex flex-column me-2">
              <div className="form-outline">
                <select
                  id="department"
                  className="form-select dropdown-styles"
                  ref={departmentRef}
                  onChange={handleDeparmentChange}
                >
                  <option value="default" className="p-5">
                    All Departments
                  </option>
                  {departments.map(
                    (item, index) => (
                      <option value={item.id} key={index}>
                        {item.department}
                      </option>
                    ),
                    []
                  )}
                </select>
              </div>
            </div>

            <div className="d-flex flex-column me-2">
              <div className="form-outline">
                <select
                  id="shift"
                  className="form-select dropdown-styles"
                  ref={shiftRef}
                  onChange={handleShiftChange}
                >
                  <option value="default">All Shifts</option>
                  {shifts.map(
                    (item, index) => (
                      <option value={item.id} key={index}>
                        {item.geography}
                      </option>
                    ),
                    []
                  )}
                </select>
              </div>
            </div>

            <div className="d-flex flex-column me-2">
              <div className="form-outline">
                <select
                  id="shift timing"
                  className="form-select dropdown-styles"
                  ref={shiftTimingRef}
                  onChange={handleShiftTimingChange}
                >
                  <option value="default">All Shift timings</option>
                  {shiftTimings.map(
                    (item, index) => (
                      <option value={item.id} key={index}>
                        {item.startTime + " - " + item.endTime}
                      </option>
                    ),
                    []
                  )}
                </select>
              </div>
            </div>
            <div className="d-flex flex-column me-2">
              <div className="form-outline">
                <GradientBorderButton
                  text={"Select All"}
                  additionalClass={"template-upload-btn mt-0"}
                  clickHandler={() => {
                    setUsers(userList);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="mt-2">
            <div className="d-flex justify-content-between">
              <label className="form-label">Recipients ({users.length}):</label>

              <div className="d-flex align-items-center">
                <button className="form-label" onClick={clearAllRecipients}>
                  Clear All Recipients
                </button>
              </div>
            </div>

            <div className="row mx-2 p-2 align-items-center border border-1 recipients-box">
              <div className="col-xl-12">
                {users.length ? (
                  users.map((user, index) => {
                    return (
                      <button
                        draggable="true"
                        type="button"
                        className="email-btn"
                        value={user}
                        key={index}
                      >
                        {user?.email}
                        <>
                          <AiFillCloseCircle
                            className="icon ms-2 cursor-pointer"
                            onClick={() => {
                              handleDeleteReciepient(index);
                            }}
                          />
                        </>
                      </button>
                    );
                  })
                ) : (
                  <p className="m-3 d-flex justify-content-center">
                    No Recipient Found
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className="d-flex gap-3 mt-3 mx-2">
            <input
              placeholder="CC"
              type="text"
              ref={ccRef}
              id="form8Example1"
              className="form-control"
              required
            />

            <input
              placeholder="BCC"
              type="text"
              ref={bccRef}
              id="form8Example1"
              className="form-control"
              required
            />
          </div>

          <div className="mt-2">
            <label className="form-label" htmlFor="subject">
              Email Subject:
            </label>

            <div className="row mx-2 align-items-center">
              <textarea
                id="subject"
                className="form-control w-100 overflow-y-auto"
                type="text"
                rows="1"
                ref={subjectRef}
                placeholder="Enter Your Email Subject"
                required
              ></textarea>
            </div>
          </div>

          <div className="mt-2" style={{ maxHeight: "40%" }}>
            <label className="form-label">Email Body:</label>

            <div className="row align-items-center jodit-editor h-100">
              <JoditEditor
                onTextChange={handleTextChange}
                editorText={editorText}
              />
            </div>
          </div>

          {isvisible && (
            <div className=" gap-3 d-flex justify-content-center mt-5">
            
                <GradientBorderButton
                  text={"Send Now"}
                  clickHandler={handleSubmit}
                />
            

             
                <GradientBorderButton
                  text={"Schedule"}
                  clickHandler={handleSchedulePopUp}
                />
             
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default BulkMail;
