import TokenService from "./token.service";
import axios from "../config/axios";

class MasterDBService {

  getMasterDB = async () => {
    let url = `/masterDB?limit=5000`;
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
      },
    });
    return response.data;
  };
  createShiftTiming = async(data)=>{
    let url = `/masterDB/createShiftTiming`;
    const response = await axios.post(url,data);
    return response.data;
  };

   getAllShiftTimings=async()=> {
    try {
      let url = `/masterDB/getShiftTimings?limit=5000`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  };

   updateShiftTiming= async(shiftTimingId, data)=> {
    console.log(shiftTimingId);
    let url = `/masterDB/shiftTiming/${shiftTimingId}`;
    const response = await axios.put(url, data, {
      headers: {
        Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
      },
    });
    sessionStorage.clear();
    return response.data;
  };

   deleteShiftTiming = async(shiftTimingId)=>{
    const response = await axios.delete(`/masterDB/shiftTiming/${shiftTimingId}`, {
      headers: {
        Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
      },
    });
    return response.data;
  };
  createGeography = async(data)=>{
    let url = `/masterDB/createGeography`;
    const response = await axios.post(url,data);
    return response.data;
  };
  
  getAllGeographies=async()=> {
    try {
      let url = `/masterDB/getGeographies?limit=5000`;
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  };
  updateGeography= async(geographyId, data)=> {
    console.log(geographyId);
    let url = `/masterDB/geography/${geographyId}`;
    const response = await axios.put(url, data, {
      headers: {
        Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
      },
    });
    sessionStorage.clear();
    return response.data;
  };

   deleteGeography = async(geographyId)=>{
    const response = await axios.delete(`/masterDB/geography/${geographyId}`, {
      headers: {
        Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
      },
    });
    return response.data;
  };
  deleteDepartment = async(id)=>{
    const response = await axios.delete(`/masterDB/department/${id}`, {
      headers: {
        Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
      },
    });
    return response.data;
  };
  createDepartment = async(data)=>{
    let url = `/masterDB/createDepartment`;
    const response = await axios.post(url,data);
    return response.data;
  };

   getAllDepartments=async()=> {
    try {
      let url = `/masterDB/getDepartments?limit=5000`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  };

   updateDepartment= async(departmentId, data)=> {
    console.log(departmentId);
    let url = `/masterDB/department/${departmentId}`;
    const response = await axios.put(url, data, {
      headers: {
        Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
      },
    });
    sessionStorage.clear();
    return response.data;
  };
}

export default new MasterDBService();