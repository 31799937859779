import React, { useEffect, useRef, useState } from "react";
import {
  AiOutlineClose,
  AiOutlineCloseCircle,
  AiOutlineDelete,
} from "react-icons/ai";
import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaAngleLeft,
  FaAngleRight,
  FaPencilAlt,
  FaSearch,
} from "react-icons/fa";
import Select from "react-select";
import { CgAdd, CgTrash } from "react-icons/cg";
import JoditEditor from "../../components/Jodit/JoditEditor";
import { FiUpload } from "react-icons/fi";
import GradientBorderButton from "../../components/Widgets/GradientBorderButton";
import licenceValidator from "../../permission/validate";
import bstService from "../../services/bst.service";
import minioService from "../../services/minio.service";

const BrandStarterKitTemplateBank = () => {
  const visiblePages = 5;
  const templatesPerPage = 9;
  const logsPerPage = 10;
  const [activeTab, setActiveTab] = useState("logo");
  const [isVisible, setIsVisible] = useState(false);
  const [isdeletable, setIsDeletable] = useState(false);
  const [selectedBio, setSelectedBio] = useState({})
  const [Images, setImages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [successNotific, setSuccessNotific] = useState("");
  const [errorNotific, setErrorNotific] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showUploadPopup, setshowUploadPopup] = useState(false);
  const [showCreateCompanyBioPopup, setshowCreateCompanyBioPopup] =
    useState(false);
  const [selectedOrganization, setSelectedOrganiztion] = useState(null)
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showPreviewPopup, setShowPreviewPopup] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentTemplateIndex, setCurrentTemplateIndex] = useState(0);
  const [editorText, setEditorText] = useState("");
  const [showActivityLogs, setShowActivityLogs] = useState(false);
  const [filteredActivityLogs, setFilteredActivityLogs] = useState([]);
  const [initialFilteredActivityLogs, setInitialFilteredActivityLogs] =
    useState([]);
  const [selectedType, setSelectedType] = useState("logo")
  const imgRef = useRef(null);
  const tableRef = useRef(null);
  const Organizations = [
    { value:"ALOIS Solutions", label:"ALOIS Solutions"},
    { value:"ALOIS Healthcare", label:"ALOIS Healthcare"},
    { value:"ALOIS Exposites", label:"ALOIS Exposites"},
    { value:"ALOIS Exports", label:"ALOIS Exports"},
    { value:"Adriva Services", label:"Adriva Services"}
  ]
  // Change section
  const changeSection = (section) => {
    if(section){
      setActiveTab(section);

    }
  };
  const handleTextChange = (text) => {
    // Update the parent component's state with the text value
    setEditorText(text);
  };
  const handleCreate = () => {
    setshowCreateCompanyBioPopup(true);
  };

  const call = async (pageNo) => {
    const response = await bstService.getKits(
      pageNo,
      templatesPerPage,
      activeTab,
      selectedOrganization?.value
    );
    
    const results = response.data.results;
    setImages(results);

    const tempTotalPages = Math.max(
      1,
      Math.ceil(response.data.totalResults / templatesPerPage)
    );
    setTotalPages(tempTotalPages);

    const tempPageArr = [];
    for (let i = 1; i <= tempTotalPages; i++) {
      tempPageArr.push(i);
    }
    tempPageArr.length <= 5 && setPageNumbers(tempPageArr);
  };

  const adjustPages = (currentPage, tempTotalPages) => {
    let finalTotalPages = tempTotalPages || totalPages;
    let tempStart = 1;
    let tempEnd = Math.min(5, finalTotalPages);

    if (finalTotalPages > 5) {
      if (currentPage > 3 && currentPage < finalTotalPages - 1) {
        tempStart = Math.max(1, currentPage - Math.floor(visiblePages / 2));
        tempEnd = Math.min(tempStart + visiblePages - 1, finalTotalPages);
      } else if (currentPage >= finalTotalPages - 1) {
        // If it is the last or last second page, then show the last 5 page numbers
        tempStart = Math.max(1, finalTotalPages - visiblePages + 1);
        tempEnd = finalTotalPages;
      }
    }

    let tempPageNumbers = Array.from(
      { length: tempEnd - tempStart + 1 },
      (_, index) => tempStart + index
    );

    if (tempPageNumbers[0] !== 0) {
      setPageNumbers(tempPageNumbers);
    }
  };

  const getNewTemplateList = async (pageNo) => {
    const response = await bstService.getKits(
      pageNo,
      templatesPerPage,
      activeTab,
      selectedOrganization?.value
    );
    const results = response.data.results;
    setImages(results);
    setCurrentPage(pageNo);
  };

  const handlePreviewTemplate = (data, index) => {
    setShowAlert(false);
    setShowPreviewPopup(true);
    setSelectedTemplate(data);
    setCurrentTemplateIndex(index);
  };

  const handleSelectTemplate = async (item) => {
    setShowAlert(false);

    const isItemSelected = selectedRows.some(
      (selectedItem) => selectedItem.id === item.id
    );

    const updatedSelectedRows = isItemSelected
      ? selectedRows.filter((selectedItem) => selectedItem.id !== item.id)
      : [...selectedRows, item];

    setSelectedRows(updatedSelectedRows);
  };

  const handleDelete = async () => {
    if (selectedRows.length) {
      setShowDeletePopup(true);
    } else {
      setErrorNotific(`No ${activeTab} is selecetd to delete.`);
      setSuccessNotific("");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  };

  const handleDeleteTemplate = async (data) => {
    try {
      let idsArray;
      if (Array.isArray(data)) {
        idsArray = data.map((item) => item.id);
      } else {
        idsArray = [data.id];
      }

      await bstService.deleteKit(idsArray);

      setErrorNotific("");
      setSuccessNotific("Deleted Successfully.");
    } catch (error) {
      console.log(error)
      setErrorNotific("Delete failed.");
      setSuccessNotific("");
    }

    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);

    setShowDeletePopup(false);
    setShowPreviewPopup(false);
    call(currentPage);
    setSelectedRows([]);
  };

  const handleUploadPopup = () => {
    setshowUploadPopup(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(!imgRef.current.files.length){
      setErrorNotific("Select an image to proceed.");
      setSuccessNotific("");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
      return
    }
    ;
    if(!selectedOrganization){
      setErrorNotific("Please select an organization to proceed.");
      setSuccessNotific("");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
      return
    }

    const response = await minioService.UploadSingleFile(
      imgRef.current.files[0],
      activeTab
  );

  let imgUrl = response.data.url ;
   let formData = {
    name:imgRef.current.files[0].name,
    filePath:imgUrl,
    companyName:selectedOrganization.value,
    type: activeTab,
    status:1

  }
  if(activeTab == "company-bio"){
    if(!editorText){
      setErrorNotific("Company bio can't be empty.");
      setSuccessNotific("");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
      return
    }
    formData.bio = editorText.replace(/<p>\s*<br>\s*<\/p>/g, "")
  }

  const res = await bstService.uploadKit(formData)
setshowCreateCompanyBioPopup(false)
setshowUploadPopup(false)
  setErrorNotific("");
  setSuccessNotific("Uploaded Succesfully!");
  setShowAlert(true);
  setTimeout(() => {
    setShowAlert(false);
  }, 3000);
    setEditorText("")
  getNewTemplateList(1);

    
  };
  const handleBioUpdate = async (e)=>{

    e.preventDefault();

 
 
    if(!selectedOrganization){
      setErrorNotific("Please select an organization to proceed.");
      setSuccessNotific("");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
      return
    }
   let formData = {
    companyName:selectedOrganization.value,
    type: activeTab,
    status:1

  }
  if(imgRef.current.files.length){
    const response = await minioService.UploadSingleFile(
      imgRef.current.files[0],
      activeTab
  );
  formData.filePath = response.data.url ;
formData.name = imgRef.current.files[0].name
  }
    if(!editorText){
      setErrorNotific("Company bio can't be empty.");
      setSuccessNotific("");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
      return
    }
    formData.bio = editorText.replace(/<p>\s*<br>\s*<\/p>/g, "")
  
  const res = await bstService.updateKit(selectedBio.id, formData)

  setshowCreateCompanyBioPopup(false)
  setshowUploadPopup(false)
    setErrorNotific("");
    setSuccessNotific("Updated Succesfully!");
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
      setEditorText("")
    getNewTemplateList(1);
  }
const handleOrgChange = async (org) => {
  setSelectedOrganiztion(org)
  if(activeTab == "company-bio" && showCreateCompanyBioPopup){
  
    setEditorText(" ")

    const response = await bstService.getKits(
      1,
      templatesPerPage,
      activeTab,
      org.value
    );
    if(response.data.results.length){
      setSelectedBio(response.data.results[0])
    }
  } else {
    setSelectedBio(null)
    setEditorText(" ")
  }
 
}
  const paginate = (currPage, data) => {
    // Scroll to the top of the table whenever page number is changed
    if (tableRef.current) {
      tableRef.current.scrollTop = 0;
    }

    const start = (currPage - 1) * logsPerPage;
    const end = start + logsPerPage;
    setFilteredActivityLogs(data.slice(start, end));
  };

  const handlePrevPage = () => {
    const setPageNumber = currentPage - 1;
    setCurrentPage(setPageNumber);
    adjustPages(setPageNumber);

    if (!showActivityLogs) {
      getNewTemplateList(setPageNumber);
    } else {
      paginate(setPageNumber, initialFilteredActivityLogs);
    }
  };

  const handleDualPrevPage = () => {
    const setPageNumber = currentPage - 5;
    setPageNumber <= 0 && (setPageNumber = 1);

    setCurrentPage(setPageNumber);
    adjustPages(setPageNumber);

    if (!showActivityLogs) {
      getNewTemplateList(setPageNumber);
    } else {
      paginate(setPageNumber, initialFilteredActivityLogs);
    }
  };

  const handleNextPage = () => {
    const setPageNumber = currentPage + 1;
    setCurrentPage(setPageNumber);
    adjustPages(setPageNumber);

    if (!showActivityLogs) {
      getNewTemplateList(setPageNumber);
    } else {
      paginate(setPageNumber, initialFilteredActivityLogs);
    }
  };

  const handleDualNextPage = () => {
    const setPageNumber = currentPage + 5;
    setPageNumber > totalPages && (setPageNumber = totalPages);

    setCurrentPage(setPageNumber);
    adjustPages(setPageNumber);

    if (!showActivityLogs) {
      getNewTemplateList(setPageNumber);
    } else {
      paginate(setPageNumber, initialFilteredActivityLogs);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Escape") {
      // Popup will get close on Esc key press
      setshowUploadPopup(false);
      setShowDeletePopup(false);
      setShowPreviewPopup(false);
    } else if (event.key === "ArrowLeft") {
      // Go to previous template while preview popup is open on Left key press
      currentTemplateIndex !== 0 &&
        setCurrentTemplateIndex(currentTemplateIndex - 1);
    } else if (event.key === "ArrowRight") {
      // Go to next template while preview popup is open on Right key press
      currentTemplateIndex !== Images.length - 1 &&
        setCurrentTemplateIndex(currentTemplateIndex + 1);
    }
  };

  useEffect(() => {
    let user = localStorage.getItem("user");
    let validator = new licenceValidator(user, "template_bank");

    setIsVisible(validator.isReadable("image"));
    setIsDeletable(validator.isDelete("image"));

    setImages([]);
    call(1);
    adjustPages();
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [currentTemplateIndex]);
  useEffect(()=>{
    setSelectedOrganiztion(null)
    call(1)
  },[activeTab])
  useEffect(()=>{
    if(selectedBio?.bio){
      setSelectedOrganiztion({label:selectedBio.companyName, value:selectedBio.companyName})
      setEditorText(selectedBio.bio.replace(/<[^>]*>/g, ' '))
    }
   
  },[selectedBio])
  useEffect(()=>{
    if(selectedOrganization && !showUploadPopup && !showCreateCompanyBioPopup){
     call(1)
    }
   
  },[selectedOrganization])
  useEffect(()=>{
    if(!showUploadPopup){
      setSelectedOrganiztion(null)

    }
  },[showUploadPopup])
  return (
    <div className="brandstarterkit-templates-container">
      <div className="indicator-container  ">
        <div className="d-flex text">
          <img
            src="/assets/social_template_bank_icon.svg"
            alt="social template bank icon"
            className="icon mx-2"
          />
          Brand Starter Kit
        </div>
      </div>
      <div className="d-flex" >
        <div className="gradient-card mx-auto">
          <div className="card-bg mt-3">
            <div className="options">
              <div className="row d-flex justify-content-center gap-2 mt-4 mb-2">
                <div className="col-4" style={{width:"fit-content"}}>
                  <div className="form-outline">
                    <button
                      type="submit"
                      onClick={() => changeSection("logo")}
                      className={`generate-btn ${
                        activeTab === "logo" ? "active-tab-button" : ""
                      }`}
                    >
                      Logo
                    </button>
                  </div>
                </div>
                <div className="col-4" style={{width:"fit-content"}}>
                  <div className="form-outline">
                    <button
                      type="submit"
                      onClick={() => changeSection("cover-page")}
                      className={`generate-btn ${
                        activeTab === "cover-page" ? "active-tab-button" : ""
                      }`}
                    >
                      Cover Page
                    </button>
                  </div>
                </div>
                <div className="col-4" style={{width:"fit-content"}}>
                  <div className="form-outline">
                    <button
                      type="submit"
                      onClick={() => changeSection("company-bio")}
                      className={`generate-btn ${
                        activeTab === "company-bio" ? "active-tab-button" : ""
                      }`}
                    >
                      Company Bio
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {activeTab === "logo" ? (
              <>
                <div className="row d-flex justify-content-between mt-3 mx-3">
                  <div className="col-xl-3 w-25">
                    <div
                      className="form-outline"
                      style={{ width: "100%", minWidth: "100%" }}
                    >
                      <Select
                        className="h-50 mt-1 "
                        styles={{ height: "10px", width: "20px" }}
                        isSearchable={true}
                        onChange={handleOrgChange}
                        options={Organizations}
                        
                        placeholder="Select an Organization"
                      />
                      <div className="arrow-down"></div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="d-flex justify-content-end gap-4">
                      <GradientBorderButton
                        text="Upload"
                        icon={<FiUpload />}
                        clickHandler={handleUploadPopup}
                        additionalClass="z-i-0"
                        iconAdditionalClass="icon-width"
                        innerDivClass="inner-div-styles"
                      />

                      <GradientBorderButton
                        text="Delete"
                        icon={<AiOutlineDelete />}
                        clickHandler={handleDelete}
                        additionalClass="z-i-0"
                        iconAdditionalClass="icon-width"
                        innerDivClass="inner-div-styles"
                      />
                    </div>
                  </div>
                </div>
                <div className="brandstarterkit-logo-templates">
                  <div className="templates-body">
                    {Images.length ? (
                      Images.map((item, index) => (
                        <div key={index} className="template-checkbox">
                          <input
                            type="checkbox"
                            checked={selectedRows.some(
                              (selectedItem) => selectedItem.id === item.id
                            )}
                            onChange={() => handleSelectTemplate(item)}
                          />

                          <img
                            src={item.filePath}
                            alt={`template_${item.id}`}
                            onClick={() => handlePreviewTemplate(item, index)}
                          />
                        </div>
                      ))
                    ) : (
                      <div className="no-templates">No Templates Found.</div>
                    )}
                  </div>
                </div>
              </>
            ) : activeTab === "cover-page" ? (
              <>
                <div className="row d-flex justify-content-between mt-3 mx-3">
                  <div className="col-xl-3 w-25">
                    <div
                      className="form-outline"
                      style={{ width: "100%", minWidth: "100%" }}
                    >
                      <Select
                        className="h-50 mt-1 "
                        styles={{ height: "10px", width: "20px" }}
                        isSearchable={true}
                        onChange={handleOrgChange}
                        options={Organizations}
                        placeholder="Select an Organization"
                      />
                      <div className="arrow-down"></div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="d-flex justify-content-end gap-4">
                      <GradientBorderButton
                        text="Upload"
                        icon={<FiUpload />}
                        additionalClass="z-i-0"
                        clickHandler={handleUploadPopup}
                        iconAdditionalClass="icon-width"
                        innerDivClass="inner-div-styles"
                      />

                      <GradientBorderButton
                        text="Delete"
                        icon={<AiOutlineDelete />}
                        clickHandler={handleDelete}
                        additionalClass="z-i-0"
                        iconAdditionalClass="icon-width"
                        innerDivClass="inner-div-styles"
                      />
                    </div>
                  </div>
                </div>
                <div className="brandstarterkit-cover-templates">
                  <div className="templates-cover-body">
                    {Images.length ? (
                      Images.map((item, index) => (
                        <div key={index} className="template-cover-checkbox">
                          <input
                            type="checkbox"
                            checked={selectedRows.some(
                              (selectedItem) => selectedItem.id === item.id
                            )}
                            onChange={() => handleSelectTemplate(item)}
                          />

                          <img
                            src={item.filePath}
                            alt={`template_${item.id}`}
                            onClick={() => handlePreviewTemplate(item, index)}
                          />
                        </div>
                      ))
                    ) : (
                      <div className="no-templates">No Templates Found.</div>
                    )}
                  </div>
                </div>
              </>
            ) : activeTab === "company-bio" ? (
              <>
                <div className="row d-flex justify-content-between mt-3 mx-3" style={{position:"relative", zIndex:"0"}}>
                  <div className="col-xl-3 w-25">
                  <div
                      className="form-outline"
                      style={{ width: "100%", minWidth: "100%" }}
                    >
                      <Select
                        className="h-50 mt-1 "
                        styles={{ height: "10px", width: "20px" }}
                        isSearchable={true}
                        onChange={handleOrgChange}
                        options={Organizations}
                        placeholder="Select an Organization"
                      />
                      <div className="arrow-down"></div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="d-flex justify-content-end gap-4">
                      <GradientBorderButton
                        text={"Create"}
                        clickHandler={handleCreate}
                        icon={<CgAdd />}
                      />
                    </div>
                  </div>
                </div>
                <div className="brandstarterkit-company-bio">
                  <div className="templates-cover-body">
                  {Images.length ? (
    Images.map((item, index) => (
        <div key={index} className="template-cover-checkbox">
            <div className="company-logo">
                <img
                    src={item.filePath}
                    alt={`template_${item.id}`}
                    height="80%"
                    onClick={() => handlePreviewTemplate(item, index)}
                />
            </div>
            <div className="company-bio">
                {/* Display HTML content */}
                <div dangerouslySetInnerHTML={{ __html: item.bio }} />
            </div>
            <div className="bio-buttons">
                <GradientBorderButton
                    text={"Edit"}
                    icon={<FaPencilAlt />}
                    clickHandler={() =>{ setshowCreateCompanyBioPopup(true); setSelectedBio(item)}}
                    iconAdditionalClass={"icon-width"}
                    innerDivClass={"inner-div-styles"}
                />
                <GradientBorderButton
                    text={"Delete"}
                    icon={<CgTrash />}
                    clickHandler={() => {setShowDeletePopup(true); setSelectedRows([{id:item.id}])}}
                    iconAdditionalClass={"icon-width"}
                    innerDivClass={"inner-div-styles"}
                />
            </div>
        </div>
    ))
) : (
    <div className="no-templates">No Templates Found.</div>
)}

                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
            {showUploadPopup && (
              <div className="upload-popup-container">
                <div className="popup">
                  <div className="popup-header">
                    Upload New Logo
                    <button
                      className="close-button"
                      onClick={() => setshowUploadPopup(false)}
                    >
                      &#10005;
                    </button>
                  </div>

                  <hr className="hr-border" />

                  <div className="popup-content">
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                      <div className="form-fields-container">
                        <div className="row mt-3 pb-3">
                          <div className="col-3 w-25">
                            <div className="form-outline">
                              <label
                                className="form-label"
                                htmlFor="upload-template"
                              >
                                Select an Organization
                              </label>

                              <div
                                className="form-outline"
                                style={{
                                  width: "100%",
                                  minWidth: "100%",
                                }}
                              >
                                <Select
                                  className="h-50 mt-1 "
                                  styles={{
                                    height: "10px",
                                    width: "20px",
                                  }}
                                  onChange={handleOrgChange}
                                  isSearchable={true}
                                  options={Organizations}
                                  placeholder="Select an Organization"
                                />
                                <div className="arrow-down"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {activeTab === "logo" ? (
                          <>
                            <div className="row mt-3 pb-3">
                              <div className="col-12">
                                <div className="form-outline">
                                  <label
                                    className="form-label"
                                    htmlFor="upload-template"
                                  >
                                    Upload Logo
                                  </label>
                                  <input
                                    type="file"
                                    id="upload-template"
                                    className="form-control"
                                    ref={imgRef}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        ) : activeTab === "cover-page" ? (
                          <>
                            {" "}
                            <div className="row mt-3 pb-3">
                              <div className="col-12">
                                <div className="form-outline">
                                  <label
                                    className="form-label"
                                    htmlFor="upload-template"
                                  >
                                    Upload Cover Page
                                  </label>
                                  <input
                                    type="file"
                                    id="upload-template"
                                    className="form-control"
                                    ref={imgRef}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="upload-btn-container">
                        <button name="upload" type="submit">
                          <div className="outer-div">
                            <div className="inner-div">
                              <FiUpload />
                              <span className="icon-width"></span>
                              Upload
                            </div>
                          </div>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}

            {showCreateCompanyBioPopup && (
              <div className="upload-popup-container">
                <div className="popup">
                  <div className="popup-header">
                    Upload New Logo
                    <button
                      className="close-button"
                      onClick={() =>{ setshowCreateCompanyBioPopup(false); setEditorText(""); setSelectedBio({}); setSelectedOrganiztion(null)}}
                    >
                      &#10005;
                    </button>
                  </div>
                  <hr className="hr-border" />
                  <div className="popup-content">
                    <form onSubmit={(e)=>{!selectedBio ? handleSubmit(e):handleBioUpdate(e) }} encType="multipart/form-data">
                      <div className="form-fields-container">
                        <div className="row mt-3 pb-3">
                          <div className="col-12">
                          <div className="form-outline">
                              <label
                                className="form-label"
                                htmlFor="upload-template"
                              >
                                Select an Organization
                              </label>

                              <div
                                className="form-outline"
                                style={{
                                  width: "100%",
                                  minWidth: "100%",
                                }}
                              >
                                <Select
                                  className="h-50 mt-1 "
                                  styles={{
                                    height: "10px",
                                    width: "20px",
                                  }}
                                  onChange={handleOrgChange}
                                  isSearchable={true}
                                  value={selectedOrganization}
                                  options={Organizations}
                                  isDisabled={selectedBio?.filePath}
                                  placeholder="Select an Organization"
                                />
                                <div className="arrow-down"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-3 pb-3">
                          <div className="col-12">
                            <div className="form-outline">
                              <label
                                className="form-label"
                                htmlFor="upload-template"
                              >
                                Upload Company Logo
                              </label>
                              <input
                                type="file"
                                id="upload-template"
                                className="form-control"
                                ref={imgRef}
                                
                              />
                               {selectedBio && (
        <a className="p-1 " href={selectedBio.filePath} download target="_blank" >
          Previously uploaded logo
        </a>
      )}
                            </div>
                          </div>
                        </div>

                        <div className="row align-items-center jodit-editor">
                          <JoditEditor
                            onTextChange={handleTextChange}
                            editorText={editorText}
                          />
                        </div>
                      </div>

                      <div className="upload-btn-container">
                        <button name="upload" type="submit">
                          <div className="outer-div">
                            <div className="inner-div">
                              <FiUpload />
                              <span className="icon-width"></span>
                              Upload
                            </div>
                          </div>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
            {totalPages && pageNumbers.length > 1 && (
              <div className="pagination">
                {currentPage !== 1 && totalPages > 5 && (
                  <button onClick={handleDualPrevPage}>
                    <FaAngleDoubleLeft className="arrow-icon left-arrow " />
                  </button>
                )}

                {currentPage !== 1 && (
                  <button onClick={handlePrevPage}>
                    <FaAngleLeft className="arrow-icon left-arrow" />
                  </button>
                )}

                {pageNumbers.map((page) => (
                  <button
                    key={page}
                    disabled={currentPage === page}
                    className={currentPage === page ? "active" : ""}
                    onClick={() => {
                      getNewTemplateList(page);
                      adjustPages(page);
                      paginate(page, initialFilteredActivityLogs);
                    }}
                  >
                    {page}
                  </button>
                ))}

                {currentPage !== totalPages && (
                  <button onClick={handleNextPage}>
                    <FaAngleRight className="arrow-icon" />
                  </button>
                )}

                {currentPage !== totalPages && totalPages > 5 && (
                  <button onClick={handleDualNextPage}>
                    <FaAngleDoubleRight className="arrow-icon" />
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {showDeletePopup && (
        <div className="delete-popup-container">
          <div className="popup">
            <div className="popup-header">
              Confirm Deletion
              <button
                className="close-button"
                onClick={() => setShowDeletePopup(false)}
              >
                &#10005;
              </button>
            </div>

            <hr className="hr-border" />

            <div className="popup-content">
              <div className="confirmation-text">
                Are you really sure you want to delete this template?
              </div>

              <div className="delete-buttons">
                <GradientBorderButton
                  text={"Cancel"}
                  icon={<AiOutlineCloseCircle />}
                  clickHandler={() => setShowDeletePopup(false)}
                  iconAdditionalClass={"icon-width"}
                  innerDivClass={"inner-div-styles"}
                />

                <GradientBorderButton
                  text={"Delete"}
                  icon={<AiOutlineDelete />}
                  clickHandler={() => handleDeleteTemplate(selectedRows)}
                  iconAdditionalClass={"icon-width"}
                  innerDivClass={"inner-div-styles"}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {showPreviewPopup && (
        <div className="preview-popup-container">
          <div className="popup">
            <div className="popup-header">
              <div>Template Preview</div>

              <div>
                <button
                  className="delete-button"
                  onClick={() =>
                    handleDeleteTemplate(Images[currentTemplateIndex])
                  }
                >
                  <AiOutlineDelete />
                </button>

                <button
                  className="close-button"
                  onClick={() => setShowPreviewPopup(false)}
                >
                  &#10005;
                </button>
              </div>
            </div>

            <hr className="hr-border" />

            <div className="popup-content preview-icons">
              <div>
                {currentTemplateIndex !== 0 && (
                  <button
                    onClick={() =>
                      setCurrentTemplateIndex(currentTemplateIndex - 1)
                    }
                  >
                    <FaAngleLeft className="arrow-icon left-arrow" />
                  </button>
                )}
              </div>

              <img
                src={Images[currentTemplateIndex].filePath}
                alt={`template_${selectedTemplate.id}`}
                height="80%"
                style={{objectFit:"contain"}}
              />

              <div>
                {currentTemplateIndex !== Images.length - 1 && (
                  <button
                    onClick={() =>
                      setCurrentTemplateIndex(currentTemplateIndex + 1)
                    }
                  >
                    <FaAngleRight className="arrow-icon" />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {showAlert && (
        <div className="alert-container">
          <div className="upper-section">
            <p>ALOIS</p>

            <span
              className="delete-btn"
              onClick={() => setShowAlert(!showAlert)}
            >
              <AiOutlineClose className="icon" />
            </span>
          </div>

          <hr />

          <div className="lower-section py-2">
            {errorNotific && <p className="text-danger">{errorNotific}</p>}
            {successNotific && <p className="text-success">{successNotific}</p>}
          </div>
        </div>
      )}
    </div>
  );
};

export default BrandStarterKitTemplateBank;
