import React, { useEffect, useState } from "react";
import QueryGenerateBox from "../QueryGenerateBox/QueryGenerateBox";
import Spinner from "react-bootstrap/Spinner";
import { useRef } from "react";
import axios from "../../config/axios";
import Notification from "../Notification/Notification";
import { Toast } from "bootstrap";
import licenceValidator from "../../permission/validate";
import { useDispatch, useSelector } from "react-redux";
import { setSkillsList } from "../../features/skillSlice";
import { MdSnippetFolder } from "react-icons/md";
import jobDescriptionService from "../../services/jobDescription.service";
import BooleanQueryService from "../../services/booleanQuery.service";

import { AiOutlineClose } from "react-icons/ai";
import booleanQueryService from "../../services/booleanQuery.service";
import { MdOutlineQuiz } from "react-icons/md";
import quizResultService from "../../services/quizResult.service";
import { FaGreaterThan, FaLessThan } from "react-icons/fa";
const BooleanQueryGeneratorComponent = () => {
  let skillsList = useSelector((state) => state.skills.skillsList);
  let [jobDescription, setJobDescription] = useState("");
  const [selectedDomain, setSelectedDomain] = useState("");

  const [domains, setDomains] = useState([]);
  const jdRef = useRef(null);
  const [queryBox, setQueryBox] = useState(false);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");
  const [booleanQuery, setBooleanQuery] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [quizResults, setQuizResults] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [successNotific, setSuccessNotific] = useState("");
  const [errorNotific, setErrorNotific] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [hideRightArrow, sethideRideArrow] = useState(false);
  const [hideLeftArrow, sethideLeftArrow] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [selectedText, setSelectedText] = useState("");
  const [editBooleanQuery, setEditBooleanQuery] = useState(false);
  const [selectedTextArray, setSelectedTextArray] = useState([]);
  const [showAddButton, setShowAddButton] = useState(false);
  const [buttonPosition, setButtonPosition] = useState({ top: 0, left: 0 });
  const [questionId, setQuestionId] = useState("")
  const [booleanQueryId, setBooleanQueryId] = useState("")
  const userData = JSON.parse(localStorage.getItem("user"));
  const [isFocused, setIsFocused] = useState(false);
  const [openedBrackets, setOpenedBrackets] = useState(0)
  const [closedBrackets, setClosedBrackets] = useState(0)

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    
    setIsFocused(false);
  };
  let dispatch = new useDispatch();
  useEffect(() => {
    setCurrentPage(1)

    if (selectedDomain) {
      

      async function getRandomJD() {

try{
  let res = await jobDescriptionService.getJDs(
    selectedDomain,
  );
  let result = await booleanQueryService.getAnsweredBooleanQueries( userData.id, res.results[0].id,)
  
  console.log(res.results[0].id, result.results[0])
  
  if(result.results[0]){
    
    setEditBooleanQuery(true)
    
    setBooleanQueryId(result.results[0].id)
    setJobDescription(result.results[0].jobDescription);
    setBooleanQuery(result.results[0].booleanString)
  } else {
    setBooleanQueryId()
    
    setJobDescription(res.results[0].jobDescription);
setBooleanQuery("")
setEditBooleanQuery(false)
  }
  setQuestionId(res.results[0].id)
  let tempTotalPages = Math.max(1, res.totalPages);
  setTotalPages(tempTotalPages);
  if (tempTotalPages <= 1) {
    sethideRideArrow(true);
  } else {
    sethideRideArrow(false);
  }
  if (tempTotalPages > 5) {
    tempTotalPages = 5;
  }
  let tempPageArr = [];
  for (let i = 1; i <= tempTotalPages; i++) {
    tempPageArr.push(i);
  }
  
  setPageNumbers(tempPageArr);
  setCurrentPage(1);
  sethideLeftArrow(true);

} catch (err) {
  
  setShowAlert(true);
  setErrorNotific(err.response.data.error);
  setJobDescription("")
  setSuccessNotific("");
  setTimeout(() => {
    setShowAlert(false);
  }, 2000);
}
        
      }
      getRandomJD();
    } else {
      setJobDescription("");

      setTotalPages(1);
      setCurrentPage(1);
      setBooleanQuery("");
    }
  }, [selectedDomain]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
  
      if (selectedDomain && jobDescription) {
        
        
        let test = await quizResultService.getQuizResults(
          userData.id,
          selectedDomain
        );
        let testId;
        try {
          if (test.results.length === 0) {
            const createResult = await quizResultService.createQuizResult({
              userId: userData.id,
              domain: selectedDomain,
              fullName: userData.firstName + " " + userData.lastName,
              marks: 0,
            });
            testId = createResult.id;
          } else {
            testId = test.results[0].id;
          }
        } catch (err) {
          console.error(" Error:", err);
        }
        try {
          
          if(editBooleanQuery){
            let result = await booleanQueryService.updateBooleanQuery(booleanQueryId, {
              userId: userData.id,
              jobDescription,
              domain: selectedDomain,
              booleanString: booleanQuery,
              fullName: userData.firstName + " " + userData.lastName,
              email: userData.email,
              testId: testId,
              questionId
            }).then((result1)=>{
            
              setShowAlert(true);
              setSuccessNotific("Answer updated successfully");
              setErrorNotific("");
              setTimeout(() => {
                setShowAlert(false);
              }, 2000);
            })
          } else {
            let result = await booleanQueryService.createBooleanQuery({
              userId: userData.id,
              jobDescription,
              domain: selectedDomain,
              booleanString: booleanQuery,
              fullName: userData.firstName + " " + userData.lastName,
              email: userData.email,
              testId: testId,
              questionId
            }).then(()=>{
              setShowAlert(true);
              setSuccessNotific("Answer submitted successfully");
              setErrorNotific("");
              setTimeout(() => {
                setShowAlert(false);
              }, 2000);
            })
          }   
         
            await handleNext()

              
        } catch(err) {
          
          setShowAlert(true);
          setErrorNotific(err.response.data.message);
          setSuccessNotific("");
          setTimeout(() => {
            setShowAlert(false);
          }, 2000);
        }
      } else if (!selectedDomain) {
        setShowAlert(true);
        setSuccessNotific("");
        setErrorNotific("Select a domain to proceed!");
        setTimeout(() => {
          setShowAlert(false);
        }, 2000);
      } else if (!jobDescription) {
        setShowAlert(true);
        setSuccessNotific("");
        setErrorNotific("Job description cannot be empty");
        setTimeout(() => {
          setShowAlert(false);
        }, 2000);
      }
    } catch (err) {
      console.log("Error:", err);
    }


    
  };
 async function paginate(page){
  if(page == totalPages){
    sethideRideArrow(true);
  }else {
    sethideRideArrow(false);
  }
  if (page == 1) {
    sethideLeftArrow(true);
  } else {
    sethideLeftArrow(false);
  }
   
  

 
    const res = await jobDescriptionService.getJDs(
      selectedDomain, page
    );
    
    setCurrentPage(page)
    let result = await booleanQueryService.getAnsweredBooleanQueries( userData.id, res.results[0].id,)

console.log(res.results[0].id, result.results[0])
if(result.results[0]){
  
  setEditBooleanQuery(true)
  
  setBooleanQueryId(result.results[0].id)
  setJobDescription(result.results[0].jobDescription);
  setBooleanQuery(result.results[0].booleanString)
} else {
  setBooleanQueryId()
  
  setJobDescription(res.results[0].jobDescription);
  setBooleanQuery("")
  setEditBooleanQuery(false)
}
setQuestionId(res.results[0].id)
              setJobDescription(res.results[0].jobDescription);
  }
async function handleNext(){

  if(totalPages < currentPage + 1){
  setTimeout(() => {
    setShowAlert(true);
    setErrorNotific("No more job descriptions available in this domain");
    setSuccessNotific("");
    setBooleanQuery("")
    setJobDescription("")
    setTimeout(() => {
      setShowAlert(false);
    }, 2000);
  }, 3000);
  
    return
      } else  if (totalPages > pageNumbers[4] && currentPage == pageNumbers[4]) {
        let tempPageArr = [];
        const endPage = Math.min(totalPages, pageNumbers[4] + 5);
        for (let page = pageNumbers[4] + 1; page <= endPage; page++) {
          tempPageArr.push(page);
        }
       setPageNumbers(tempPageArr)
      }
      if(currentPage + 1 == totalPages){
        sethideRideArrow(true);
      }else {
        sethideRideArrow(false);
      }
      if (currentPage + 1 == 1) {
        sethideLeftArrow(true);
      } else {
        sethideLeftArrow(false);
      }
       
      

     
        const res = await jobDescriptionService.getJDs(
          selectedDomain, currentPage + 1
        );
        
        setCurrentPage(currentPage + 1)
        let result = await booleanQueryService.getAnsweredBooleanQueries( userData.id, res.results[0].id,)

    console.log(res.results[0].id, result.results[0])
    if(result.results[0]){
      
      setEditBooleanQuery(true)
      
      setBooleanQueryId(result.results[0].id)
      setJobDescription(result.results[0].jobDescription);
      setBooleanQuery(result.results[0].booleanString)
    } else {
      setBooleanQueryId()
      
      setJobDescription(res.results[0].jobDescription);
      setBooleanQuery("")
      setEditBooleanQuery(false)
    }
    setQuestionId(res.results[0].id)
                  setJobDescription(res.results[0].jobDescription);
      
      
}
async function handlePrevious(){
  if (currentPage - 1 == totalPages) {
    sethideRideArrow(true);
  } else {
    sethideRideArrow(false);
  }
  if (currentPage - 1 == 1) {
    sethideLeftArrow(true);
  } else {
    sethideLeftArrow(false);
  }
  if (currentPage > pageNumbers[0]) {
    // console.log(memories);
    // paginate(currentPage + 1, memories[window.location.pathname]);
    setCurrentPage(currentPage - 1);
  } else if (currentPage > 1) {
    let tempPageArr = [];
    // const endPage = Math.min(totalPages, pageNumbers[0] - 1);
    for (let page = pageNumbers[0] - 5; page <= pageNumbers[0] - 1; page++) {
      tempPageArr.push(page);
    }
  
    setCurrentPage(currentPage - 1);
    setPageNumbers(tempPageArr);
  }
        const res = await jobDescriptionService.getJDs(
          selectedDomain, currentPage - 1
        );
        setCurrentPage(currentPage - 1)
        let result = await booleanQueryService.getAnsweredBooleanQueries( userData.id, res.results[0].id,)

    console.log(res.results[0].id, result.results[0])
    if(result.results[0]){
      
      setEditBooleanQuery(true)
      
      setBooleanQueryId(result.results[0].id)
      setJobDescription(result.results[0].jobDescription);
      setBooleanQuery(result.results[0].booleanString)
    } else {
      setBooleanQueryId("")
      
      setJobDescription(res.results[0].jobDescription);
      setBooleanQuery("")
      setEditBooleanQuery(false)
    }
    setQuestionId(res.results[0].id)
                  setJobDescription(res.results[0].jobDescription);
      
      
}
  const handleContextMenu = (e) => {
    e.preventDefault();
    const text = window.getSelection().toString();
    const { clientX, clientY } = e;
    setSelectedText(text);
    setShowAddButton(true);
    setButtonPosition({ top: clientY, left: clientX });
  };

  //   const handleAddText = () => {
  //     if (selectedText) {
  //       setSelectedTextArray([...selectedTextArray, selectedText]);
  //       setSelectedText('');
  //       dispatch(setSkillsList([...skillsList, selectedText]))
  //       setShowAddButton(false);
  //     }
  //   };
  useEffect(() => {
    async function getJDs() {
      let jds = await jobDescriptionService.getJDs();
      const domainCounts = {};
      jds.results.forEach((item) => {
        const domain = item.domain;
        if (domainCounts[domain]) {
          // Increment the count if the domain already exists in the object
          domainCounts[domain]++;
        } else {
          // Initialize the count to 1 for a new domain
          domainCounts[domain] = 1;
        }
      });

      // Extract the unique domains from the object keys
      const uniqueDomains = Object.keys(domainCounts);
      setDomains(uniqueDomains);
    }
    getJDs();
  }, []);
  const countBrackets = (input) => {
    const open = [];
    const close = [];
    const openBrackets = '([{';
    const closeBrackets = ')]}';
    const bracketPairs = {
      ')': '(',
      ']': '[',
      '}': '{'
    };
    let properlyCount = 0;
    let improperCount = 0;
  
    for (let char of input) {
      if (openBrackets.includes(char)) {
        open.push(char);
        
      } else if (closeBrackets.includes(char)) {
      close.push(char)
      }
    }
    
    improperCount += open.length;
  
    return {
      open:open.length,
      close:close.length,
    };
  };
  useEffect(()=>{
    let counts = countBrackets(booleanQuery)
    setOpenedBrackets(counts.open)
    setClosedBrackets(counts.close)
  },[booleanQuery])
 
  
  // Example usage:
 
  
  
  
  
  
  
  // const onGenerate = async (e) => {
  //   e.preventDefault();
  //   setLoader(true);
  //   setQueryBox(false);

  //   try {
  //     const res = await axios.post("/utility/searchStringGenerator", {
  //       prompt: jdRef.current.value,
  //     });
  //     let data = res.data.value;
  //     if (data.length > 0) {
  //       setDomain(data);

  //       dispatch(setSkillsList(data))
  //       setQueryBox(true);
  //     } else {
  //       setError(
  //         "Seems like there are no skills in the given job decription. If there are any skills missing in the job description, you can add them to the feedback"
  //       );
  //       const toast1 = document.getElementById("toast-2");
  //       const toast = new Toast(toast1);
  //       toast.show();
  //     }
  //   } catch (err) {
  //
  //     setQueryBox(false);
  //   }
  //   setLoader(false);
  // };
  // useEffect(() => {
  //   let user = localStorage.getItem("user");
  //   let validator = new licenceValidator(user, "assist");
  //   setIsVisible(validator.isReadable("search_query_generator"));
  // }, []);
  return (
    // Changes class clan-score-admin to boolean-query
    // Because clan-score-admin class is overriding css in other files
    <div
      className="outer_div mx-auto h-75 boolean-query"
      style={{ paddingTop: "1px", marginTop: "1.7rem" }}
    >
      {showAlert ? (
        <div className="alert-container">
          <div className="upper-section">
            <p style={{ marginBottom: "0px", marginTop: "5px" }}>ALOIS</p>
            <span  className="delete-btn" onClick={() => setShowAlert(!showAlert)}>
              <AiOutlineClose className="icon" style={{ fill: "black" }} />
            </span>
          </div>
          <hr
            style={{
              display: "block !important",
              height: "1px !important",
              border: "0 !important",
              borderTop: "1px solid gray !important",
            }}
          />
          <div className="lower-section pt-2">
            <p className="text-danger">{errorNotific}</p>
            <p className="text-success">{successNotific}</p>
          </div>
        </div>
      ) : null}
      <div className="indicator-container ">
        <div>
          <MdOutlineQuiz className="icon" />
        </div>
        <div className="text">Quiz</div>
      </div>
      <div className="buzz_inner_div mt-3" style={{ borderRadius: "10px" }}>
        <div className="ess-dashboard">
          <div className="container">
       {selectedDomain ?   <div className="pagination mt-4">
                {!hideLeftArrow ? (
                  <button
                    onClick={handlePrevious}
                    disabled={currentPage === 1}
                    className={currentPage === 1 ? "active" : ""}
                  >
                    <FaLessThan
                      className="arrow"
                      style={{
                        marginBottom: "4px",
                        marginRight: "2px",
                        padding: "1px",
                      }}
                    />
                  </button>
                ) : (
                  <></>
                )}
                {pageNumbers.map((page) => (
                  <button
                    key={page}
                    disabled={currentPage === page}
                    className={currentPage === page ? "active" : ""}
                    onClick={() => {
                      paginate(page);
                      // adjustPages(page);
                    }}
                  >
                    {page}
                  </button>
                ))}
                {!hideRightArrow ? (
                  <button
                    onClick={handleNext}
                    disabled={currentPage === totalPages}
                    className={currentPage === totalPages ? "active" : ""}
                  >
                    <FaGreaterThan
                      className="arrow"
                      style={{
                        marginBottom: "4px",
                        marginLeft: "2px",
                        padding: "1px",
                      }}
                    />
                  </button>
                ) : (
                  <></>
                )}
              </div> :<></>}
            {/* <div className="row mt-4">
                <div className="col">
            <label htmlFor="">Full Name : </label>
              <div className="">
                <input
                  className="form-control"
                  type="text"
                  name="name"
                  value={inputData.name}
                  onChange={handleChange}
                  placeholder="Input Your Full Name"
                  required
                  style={{ marginBottom: "10px", borderRadius: "5px", width : "70%" }}
                />
              </div>
              </div>
              <div className="col">
              <label htmlFor="">Email : </label>
              <div className="">
                <input
                  className="form-control"
                  readOnly
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e)=>{email(e.target.value)}}
                  placeholder="Input Your Email"
                  required
                  style={{ marginBottom: "10px", borderRadius: "5px", width : "70%" }}
                />
              </div>
              </div>
              </div> */}
          </div>
        </div>
        <div className="search-query ">
     
          <div>
            <div className="toasts-container position-absolute top-0  mx-3">
              <div
                className="toast fade hide"
                data-autohide="true"
                id="toast-2"
              >
                <div className="toast-header">
                  <i className="far fa-bell text-muted me-2"></i>
                  <strong className="me-auto">ALOIS</strong>
                  <button
                    type="button"
                    id="toastclose_btn"
                    className="btn-close"
                    data-bs-dismiss="toast"
                  ></button>
                </div>
                <div className="toast-body">
                  {/* <p className="text-success">{success}</p> */}
                  <p className="text-danger">{error}</p>
                </div>
              </div>
            </div>
          </div>
          {/* {showAddButton && (
          <button
          className="mt-3"
            style={{ position: 'absolute',border:"0.4px black solid",background:"white",width:"150px", top: buttonPosition.top, left: buttonPosition.left }}
            onClick={handleAddText}
          >
            Add as a skill
          </button>
        )} */}
          <Notification error={error} />
          <form className="form-group p-4 pt-3" onSubmit={handleSubmit}>
          <div className="row d-flex  justify-content-start mb-3">
                      <div className="col-xl-6">
                        <div className="form-outline">
                          <label className="form-label" htmlFor="department">
                            Domain:
                          </label>
                          <select
                            // ref={clanRef}
                            id="department"
                            className="form-select"
                            value={selectedDomain}
                            // onChange={handleInputChange}
                            onChange={(e) => {
                              setSelectedDomain(e.target.value);
                            }}
                            required
                          >
                            <option value="" className="p-5">
                              Select an option
                            </option>
                            {domains.map((domain) => (
                              <option value={domain}>{domain}</option>
                            ))}
                          </select>
                          <div className="arrow-down"></div>
                        </div>
                      </div>
                    </div>

            <textarea
              readOnly
              // onContextMenu={handleContextMenu}
              //   ref={jdRef}
              value={jobDescription}
              className="jd-textarea form-control d-flex w-100"
              placeholder="JD will come here"
              required
            ></textarea>
            <div className="row d-flex mt-3 justify-content-center">
              <div className="col-xl-12">
                <textarea
                  // onChange={handleInputChange}
                  onChange={(e) => {
                    setBooleanQuery(e.target.value);
                  }}
                  className="text-center form-control w-75 mx-auto w-100"
                  type="text"
                  id="booleanQuery"
                  rows="2"
                  value={booleanQuery}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  required
                  placeholder={isFocused ? '' : 'Enter Your Boolean-Query'}
                  // value={final}
                ></textarea>
               <div className="d-flex flex-column" style={{float:"Left"}}>
              <span style={{float:"Left"}}> {openedBrackets ?" No of open brackets: "+openedBrackets:""}</span>
                <span style={{float:"Left"}}> {closedBrackets ?" No of closed brackets: "+closedBrackets:""}</span>
                </div>
                <span style={{float:"right"}}> {booleanQuery?" No of characters: "+booleanQuery.length:""}</span>
              </div>
            
              <button type="submit" style={{marginTop:"0"}} className="generate-btn mx-auto mt-0 p-1">
                Submit
              </button>
              {/* <div className="col-xl-1">
     <CopyToClipboard text={final}>
            <button type="button">
              <FiIcons.FiCopy
                onClick={handleCopy}
                className="recruit-icons"
              />
            </button>
          </CopyToClipboard>
     </div> */}
              {/* <div className="col-xl-11 text-end  d-block me-5">
 {skillCount?" No of characters: "+final.length:""} 

      
     </div> */}
            </div>
            {/* {loader ? (
          <div className="mx-auto mt-3 p-1">
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            &nbsp; Loading...
          </div>
        ) : isVisible ? (
          <div className="d-flex">
            <div
              role="button"
              className="generate-btn mx-auto mt-3 p-1"
              onClick={() => {
                jdRef.current.value = "";
              }}
            >
              Clear
            </div>
            <button type="submit" className="generate-btn mx-auto mt-3 p-1">
              Generate
            </button>
          </div>
        ) : null} */}

            {/* {queryBox && (
          <div>
            <QueryGenerateBox skill={skillsList} />
          </div>
        )} */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default BooleanQueryGeneratorComponent;
