import React, { useEffect, useRef, useState } from "react";
import "jspdf-autotable";
import { Button, Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "chart.js/auto";
import ChartPluginDatalabels from "chartjs-plugin-datalabels";
import { BiSolidUniversalAccess } from "react-icons/bi";
import activityLogsService from "../../services/activity.service";
import { AiFillCloseCircle, AiOutlineClose } from "react-icons/ai";
import { GrPowerReset } from "react-icons/gr";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Papa from "papaparse";
import Select from "react-select";
import userRegistrationService from "../../services/userRegistration.service";
import {
  FaFileExcel,
  FaFilePdf,
  FaGreaterThan,
  FaLessThan,
} from "react-icons/fa";
import JsonView from "react18-json-view";
import "react18-json-view/src/style.css";
import jsPDF from "jspdf";
import json2html from "json2html";
import { Bar } from "react-chartjs-2";
import * as XLSX from "xlsx";
import GradientBorderButton from "../Widgets/GradientBorderButton";
import masterDBService from "../../services/masterDB.service";
function ActivityLog() {
  const [departments, setDepartments] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [shiftTimings, setShiftTimings] = useState([]);
  const [hideRightArrow, sethideRideArrow] = useState(false);
  const [filteredShiftTimings, setFilteredShiftTimings] = useState([]);
  const [selectedModuleName, setSelectedModuleName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [error, setError] = useState("");
  const [selectedShift, setSelectedShift] = useState("");
  const [usersCountByDepartment, setUsersCountByDepartment] = useState({});
  const [userList, setUserList] = useState([]);
  const [exportFileType, setExportFileType] = useState("");
  const departmentRef = useRef(null);
  const shiftTimingRef = useRef(null);
  const allUsersRef = useRef(null);
  const shiftRef = useRef(null);
  const yearRef = useRef(null);

  // const [loadingNextPage, setLoadingNextPage] = useState(false);
  const [pageNumbers, setPageNumbers] = useState([]);
  const visiblePages = 5;
  const [paginationLoader, setPaginationLoader] = useState(false);
  const [hideLeftArrow, sethideLeftArrow] = useState(true);
  const [ActivityLogList, setActivityLogList] = useState([]);
  const [selectedValue, setSelectedValue] = useState();
  const [userEmails, setUserEmails] = useState([]);

  const [selectedUser, setSelectedUser] = useState("");
  const [selectedMethod, setSelectedMethod] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [successNotific, setSuccessNotific] = useState("");
  const [errorNotific, setErrorNotific] = useState("");
  const [selectedDuration, setSelectedDuration] = useState("");
  const [loading, setLoading] = useState(false);
  const [reportActivityLogs, setReportActivityLogs] = useState();
  const [chartData, setChartData] = useState();
  const [chartOptions, setChartOptions] = useState();
  // Function to handle date selection

  useEffect(() => {
    if (userList.length !== 0) {
      const counts = {};

      userList.forEach((user) => {
        const department = user.department;
        counts[department] = (counts[department] || 0) + 1;
      });

      setUsersCountByDepartment(counts);
    }
  }, [userList]);
  const dropdown = async () => {
    try {
      const data = await masterDBService.getAllDepartments();
      setDepartments(data.results);

      const results = await masterDBService.getAllGeographies();
      setShifts(results.results);
      const result1 = await masterDBService.getAllShiftTimings();
      setShiftTimings(result1.results);
    } catch (error) {
      console.error("Error Fetching data ", error);
    }
  };

  let generatePdf = async () => {
    // Create new jsPDF instance
    const doc = new jsPDF();

    // Define JSON data
    let activityLogs = await activityLogsService.getActivitylogsForReport(
      selectedUser?.label,
      selectedDuration,
      startDate,
      endDate,
      departmentRef?.current?.value,
      shiftRef?.current?.value,
      shiftTimingRef?.current?.value,
      selectedModuleName.value,
      yearRef?.current?.value
    );
    const usersData = activityLogs.map((item) => {
      let moduleName = "";

      if (item.url.includes("/")) {
        let urlWithoutv1 = item.url.replace(/^\/v1\//, "");
        urlWithoutv1 = urlWithoutv1.replace(new RegExp(`\\bget\\b`), "");
        // Replace slashes in the URL with underscores
        const urlParts = urlWithoutv1.split("/");
        const urlWithUnderscores = urlParts.join("_");
        let method1 = "_" + item.method;
        if (item.method == "POST") {
          method1 = "_ (Create)";
        }
        if (item.method == "PUT") {
          method1 = "_ (Update)";
        }
        if (item.method == "DELETE") {
          method1 = "_ (Delete)";
        }
        if (
          urlWithUnderscores.includes("create") ||
          urlWithUnderscores.includes("update")
        ) {
          method1 = "";
        }
        moduleName = `${urlWithUnderscores}${method1.toLowerCase()}`;
      } else {
        moduleName = item.url;
      }
      

      // Split the string by underscores
      let parts = moduleName.split("_");

      let firstPart = parts[0];

      if (parts[0] == "utility") {
        parts.splice(0, 1);

        function findTitleByValue(value) {
          if (value.includes("Classification")) {
            value = "Classification";
          }
          const foundTitle = titlesArray.find((title) =>
            title.value.includes(value)
          );

          return foundTitle ? foundTitle.label : null;
        }
        moduleName = findTitleByValue(parts[0]) + " (Create)";
      }
      if (firstPart != "utility") {
        // Remove the second part
        if (parts.length == 3) {
          parts.splice(1, 1);
        }
        function findTitleByValue(value) {
          const foundTitle = titlesArray.find((title) =>
            title.value.includes(value)
          );

          return foundTitle ? foundTitle.label : null;
        }
        // Join the remaining parts with underscores
        moduleName = findTitleByValue(parts[0]);
        if (
          !firstPart.includes("user") &&
          !firstPart.includes("clanScoreMaster") &&
          !firstPart.includes("mailer") &&
          !firstPart.includes("chatbot")
        ) {
          if (parts[1].includes("create")) {
            moduleName += " (Upload)";
          } else if (parts[1].includes("update")) {
            moduleName += " (Update)";
          } else if (parts[1].includes("delete")) {
            moduleName += " (Delete)";
          }
        } else if (firstPart.includes("user")) {
          if (parts[1].includes("create")) {
            moduleName += " (Create)";
          } else if (parts[1].includes("update")) {
            moduleName += " (Update)";
          } else if (parts[1].includes("delete")) {
            moduleName += " (Delete)";
          }
        } else if (firstPart.includes("clanScoreMaster")) {
          if (parts[1].includes("create")) {
            moduleName += " (Update)";
          } else if (parts[1].includes("update")) {
            moduleName += " (Update)";
          } else if (parts[1].includes("delete")) {
            moduleName += " (Delete)";
          }
        } else if (firstPart.includes("chatbot")) {
          if (parts[1].includes("create")) {
            moduleName += " (Create)";
          }
        } else if (firstPart.includes("mailer")) {
          if (parts[1].includes("create")) {
            moduleName += " (sent)";
          } else if (parts[1].includes("update")) {
            moduleName += " (Update)";
          } else if (parts[1].includes("delete")) {
            moduleName += " (Delete)";
          }
        }
      } else {
      }
      item.moduleName = moduleName;
      delete item.url;
      // delete item.createdAt
      const inputDate = item.createdAt;
      if (inputDate) {
        const date = new Date(inputDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const formattedDate = `${day}/${month}/${year}`;
        item["Activity Date"] = formattedDate;
      } else {
        item["Activity Date"] = "N/A"; // or any  value for empty dates
      }
   
        if (item.department) {
          let department = departments.find(
            (department) => department.id == item.department
          );
          item.department = department ? department.department : "-";
        }
     
     
        if (item.shiftTiming) {
          let shiftTiming = shiftTimings.find(
            (shiftTiming) => shiftTiming.id == item.shiftTiming
          );
          item.shiftTiming = shiftTiming ? shiftTiming.startTime + " - " + shiftTiming.endTime : "-";
        }
      
      
        if (item.shift) {
          let shift = shifts.find(
            (shift) => shift.id == item.shift
          );
          item.shift = shift ? shift.geography : "-";
        }
    
      delete item.updatedAt;
      delete item.__v;
      delete item.request;
      delete item.response;
      delete item.userId;
      delete item._id;
      delete item.method;
      delete item.body;
      delete item.params;
      delete item.createdAt;
      return item;
    });

    // Convert JSON data to HTML table

    const columns = [
      "Email",
      "Activity date",
      "Module name(Action)",
      "Department",
      "Shift timing",
      "Shift",
    ];

    doc.autoTable({
      head: [columns],
      columnStyles: {
        0: { columnWidth: 40 },
        1: { columnWidth: 40 },
        2: { columnWidth: 40 },
        3: { columnWidth: 40 },
        4: { columnWidth: 40 },
        5: { columnWidth: 40 },
      },
    });
    usersData.forEach(function (user, i) {
      // Check if a new page is needed

      // Push user data to table rows
      doc.autoTable({
        body: [
          [
            user.userEmail,
            user["Activity Date"],
            user.moduleName,
            user.department,
            user.shiftTiming,
            user.shift,
          ],
        ],
        columnStyles: {
          0: { columnWidth: 32 },
          1: { columnWidth: 32 },
          2: { columnWidth: 32 },
          3: { columnWidth: 32 },
          4: { columnWidth: 32 },
          5: { columnWidth: 32 },
        },
      });

      // Increment the user count
      // userCount++;
    });

    // Set the filename for the PDF
    const filename = `Exported_${
      usersData.length
    }_activitylogs_With_startedate_${usersData[0].activitydate}_enddate_${
      usersData[usersData.length - 1].activitydate
    }.pdf`;

    // Save the PDF
    doc.save(filename);
    setLoading(false);
  };
  let getActivityLogs1 = async () => {
    let activityLogs = await activityLogsService.getActivitylogsForReport(
      selectedUser?.label,
      selectedDuration,
      startDate,
      endDate,
      departmentRef?.current?.value,
      shiftRef?.current?.value,
      shiftTimingRef?.current?.value,
      selectedModuleName.value,
      yearRef?.current?.value
    );
    const usersData = activityLogs.map((item) => {
      let moduleName = "";
      if (item.url.includes("/")) {
        let urlWithoutv1 = item.url.replace(/^\/v1\//, "");
        urlWithoutv1 = urlWithoutv1.replace(new RegExp(`\\bget\\b`), "");
        // Replace slashes in the URL with underscores
        const urlParts = urlWithoutv1.split("/");
        const urlWithUnderscores = urlParts.join("_");
        let method1 = "_" + item.method;
        if (item.method == "POST") {
          method1 = "_ (Create)";
        }
        if (item.method == "PUT") {
          method1 = "_ (Update)";
        }
        if (item.method == "DELETE") {
          method1 = "_ (Delete)";
        }
        if (urlWithUnderscores.includes("create")) {
          method1 = "";
        }
        moduleName = `${urlWithUnderscores}${method1.toLowerCase()}`;
      } else {
        moduleName = item.url;
      }
      item.moduleName = moduleName;
      delete item.url;
      let parts = moduleName.split("_");

      let firstPart = parts[0];

      if (parts[0] == "utility") {
        parts.splice(0, 1);

        function findTitleByValue(value) {
          if (value.includes("Classification")) {
            value = "Classification";
          }
          const foundTitle = titlesArray.find((title) =>
            title.value.includes(value)
          );

          return foundTitle ? foundTitle.label : null;
        }
        moduleName = findTitleByValue(parts[0]) + " (Create)";
      }
      if (firstPart != "utility") {
        // Remove the second part
        if (parts.length == 3) {
          parts.splice(1, 1);
        }
        function findTitleByValue(value) {
          const foundTitle = titlesArray.find((title) =>
            title.value.includes(value)
          );

          return foundTitle ? foundTitle.label : null;
        }
        // Join the remaining parts with underscores
        moduleName = findTitleByValue(parts[0]);
        if (
          !firstPart.includes("user") &&
          !firstPart.includes("clanScoreMaster") &&
          !firstPart.includes("mailer") &&
          !firstPart.includes("chatbot")
        ) {
          if (parts[1].includes("create")) {
            moduleName += " (Upload)";
          } else if (parts[1].includes("update")) {
            moduleName += " (Update)";
          } else if (parts[1].includes("delete")) {
            moduleName += " (Delete)";
          }
        } else if (firstPart.includes("user")) {
          if (parts[1].includes("create")) {
            moduleName += " (Create)";
          } else if (parts[1].includes("update")) {
            moduleName += " (Update)";
          } else if (parts[1].includes("delete")) {
            moduleName += " (Delete)";
          }
        } else if (firstPart.includes("clanScoreMaster")) {
          if (parts[1].includes("create")) {
            moduleName += " (Update)";
          } else if (parts[1].includes("update")) {
            moduleName += " (Update)";
          } else if (parts[1].includes("delete")) {
            moduleName += " (Delete)";
          }
        } else if (firstPart.includes("chatbot")) {
          if (parts[1].includes("create")) {
            moduleName += " (Create)";
          }
        } else if (firstPart.includes("mailer")) {
          if (parts[1].includes("create")) {
            moduleName += " (sent)";
          } else if (parts[1].includes("update")) {
            moduleName += " (Update)";
          } else if (parts[1].includes("delete")) {
            moduleName += " (Delete)";
          }
        }
      } else {
      }
      item["User"] = item.userEmail;

      // delete item.createdAt
      const inputDate = item.createdAt;
      if (inputDate) {
        const date = new Date(inputDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const formattedDate = `${day}/${month}/${year}`;
        item["Activity Date"] = formattedDate;
      } else {
        item["Activity Date"] = "N/A"; // or any  value for empty dates
      }
      if (item.department) {
        let department = departments.find(
          (department) => department.id == item.department
        );
        item.department = department ? department.department : "-";
      }
   
   
      if (item.shiftTiming) {
        let shiftTiming = shiftTimings.find(
          (shiftTiming) => shiftTiming.id == item.shiftTiming
        );
        item.shiftTiming = shiftTiming ? shiftTiming.startTime + " - " + shiftTiming.endTime : "-";
      }
    
    
      if (item.shift) {
        let shift = shifts.find(
          (shift) => shift.id == item.shift
        );
        item.shift = shift ? shift.geography : "-";
      }
  
      let department = item.department;
      let shiftTiming = item.shiftTiming;
      let shift = item.shift;

      delete item.updatedAt;
      delete item.__v;
      delete item.request;
      delete item.response;
      delete item.userId;
      delete item._id;
      delete item.method;
      delete item.params;
      delete item.body;
      delete item.department;
      delete item.shiftTiming;
      delete item.shift;

      delete item.createdAt;
      delete item.moduleName;
      delete item.userEmail;
      item["Module Name(Action)"] = moduleName;

      item["Department"] = department;
      item["Shift Timing"] = shiftTiming;
      item["Shift"] = shift;
      return item;
    });

    const csvData = Papa.unparse(usersData);
    const filename = `Exported_${
      usersData.length
    }_activitylogs_With_startedate_${usersData[0]["Activity Date"]}_enddate_${
      usersData[usersData.length - 1]["Activity Date"]
    }.csv`;

    const blob = new Blob([csvData], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = filename;

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
    setLoading(false);
  };
  let generateExcel = async () => {
    let activityLogs = await activityLogsService.getActivitylogsForReport(
      selectedUser?.label,
      selectedDuration,
      startDate,
      endDate,
      departmentRef?.current?.value,
      shiftRef?.current?.value,
      shiftTimingRef?.current?.value,
      selectedModuleName.value,
      yearRef?.current?.value
    );
    const usersData = activityLogs.map((item) => {
      let moduleName = "";
      if (item.url.includes("/")) {
        let urlWithoutv1 = item.url.replace(/^\/v1\//, "");
        urlWithoutv1 = urlWithoutv1.replace(new RegExp(`\\bget\\b`), "");
        // Replace slashes in the URL with underscores
        const urlParts = urlWithoutv1.split("/");
        const urlWithUnderscores = urlParts.join("_");
        let method1 = "_" + item.method;
        if (item.method == "POST") {
          method1 = "_ (Create)";
        }
        if (item.method == "PUT") {
          method1 = "_ (Update)";
        }
        if (item.method == "DELETE") {
          method1 = "_ (Delete)";
        }
        if (urlWithUnderscores.includes("create")) {
          method1 = "";
        }
        moduleName = `${urlWithUnderscores}${method1.toLowerCase()}`;
      } else {
        moduleName = item.url;
      }
      let parts = moduleName.split("_");

      let firstPart = parts[0];

      if (parts[0] == "utility") {
        parts.splice(0, 1);

        function findTitleByValue(value) {
          if (value.includes("Classification")) {
            value = "Classification";
          }
          const foundTitle = titlesArray.find((title) =>
            title.value.includes(value)
          );

          return foundTitle ? foundTitle.label : null;
        }
        moduleName = findTitleByValue(parts[0]) + " (Create)";
      }
      if (firstPart != "utility") {
        // Remove the second part
        if (parts.length == 3) {
          parts.splice(1, 1);
        }
        function findTitleByValue(value) {
          const foundTitle = titlesArray.find((title) =>
            title.value.includes(value)
          );

          return foundTitle ? foundTitle.label : null;
        }
        // Join the remaining parts with underscores
        moduleName = findTitleByValue(parts[0]);
        if (
          !firstPart.includes("user") &&
          !firstPart.includes("clanScoreMaster") &&
          !firstPart.includes("mailer") &&
          !firstPart.includes("chatbot")
        ) {
          if (parts[1].includes("create")) {
            moduleName += " (Upload)";
          } else if (parts[1].includes("update")) {
            moduleName += " (Update)";
          } else if (parts[1].includes("delete")) {
            moduleName += " (Delete)";
          }
        } else if (firstPart.includes("user")) {
          if (parts[1].includes("create")) {
            moduleName += " (Create)";
          } else if (parts[1].includes("update")) {
            moduleName += " (Update)";
          } else if (parts[1].includes("delete")) {
            moduleName += " (Delete)";
          }
        } else if (firstPart.includes("clanScoreMaster")) {
          if (parts[1].includes("create")) {
            moduleName += " (Update)";
          } else if (parts[1].includes("update")) {
            moduleName += " (Update)";
          } else if (parts[1].includes("delete")) {
            moduleName += " (Delete)";
          }
        } else if (firstPart.includes("chatbot")) {
          if (parts[1].includes("create")) {
            moduleName += " (Create)";
          }
        } else if (firstPart.includes("mailer")) {
          if (parts[1].includes("create")) {
            moduleName += " (sent)";
          } else if (parts[1].includes("update")) {
            moduleName += " (Update)";
          } else if (parts[1].includes("delete")) {
            moduleName += " (Delete)";
          }
        }
      } else {
      }
      delete item.url;
      item["User"] = item.userEmail;

      // delete item.createdAt
      const inputDate = item.createdAt;
      if (inputDate) {
        const date = new Date(inputDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const formattedDate = `${day}/${month}/${year}`;
        item["Activity Date"] = formattedDate;
      } else {
        item["Activity Date"] = "N/A"; // or any  value for empty dates
      }
      if (item.department) {
        let department = departments.find(
          (department) => department.id == item.department
        );
        item.department = department ? department.department : "-";
      }
   
   
      if (item.shiftTiming) {
        let shiftTiming = shiftTimings.find(
          (shiftTiming) => shiftTiming.id == item.shiftTiming
        );
        item.shiftTiming = shiftTiming ? shiftTiming.startTime + " - " + shiftTiming.endTime : "-";
      }
    
    
      if (item.shift) {
        let shift = shifts.find(
          (shift) => shift.id == item.shift
        );
        item.shift = shift ? shift.geography : "-";
      }
  
      let department = item.department;
      let shiftTiming = item.shiftTiming;
      let shift = item.shift;
      delete item.updatedAt;
      delete item.__v;
      delete item.request;
      delete item.response;
      delete item.userId;
      delete item._id;
      delete item.method;
      delete item.body;
      delete item.params;
      delete item.department;
      delete item.shiftTiming;
      delete item.shift;
      delete item.createdAt;
      delete item.moduleName;
      delete item.userEmail;
      item["Module Name(Action)"] = moduleName;

      item["Department"] = department;
      item["Shift Timing"] = shiftTiming;
      item["Shift"] = shift;
      return item;
    });
    // Convert data to worksheet
    const worksheet = XLSX.utils.json_to_sheet(usersData);

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "ActivityLogs");

    // Save the Excel file
    const excelFileName = `Exported_${
      usersData.length
    }_activitylogs_With_startedate_${usersData[0]["Activity Date"]}_enddate_${
      usersData[usersData.length - 1]["Activity Date"]
    }.xlsx`;

    XLSX.writeFile(workbook, excelFileName);
    setLoading(false);
  };
  useEffect(() => {
    if (exportFileType) {
      setLoading(true);
    }
    if (exportFileType === "csv") {
      getActivityLogs1();
    } else if (exportFileType === "pdf") {
      generatePdf();
    } else if (exportFileType === "excel") {
      generateExcel();
    }
  }, [exportFileType]);

  const paginate = async (currPage) => {
    setPaginationLoader(true);
    const data = await activityLogsService.getActivitylogs(
      selectedUser?.label,
      currPage,
      selectedDuration,
      startDate,
      endDate,
      departmentRef?.current?.value,
      shiftRef?.current?.value,
      shiftTimingRef?.current?.value,
      selectedModuleName.value,
      yearRef?.current?.value
    );

    // setLoadingNextPage(true);
    // setTimeout(() => {
    //   setLoadingNextPage(false);
    // }, 200);
    setActivityLogList(data.results);
    setCurrentPage(currPage);

    if (currPage === totalPages) {
      sethideRideArrow(true);
    } else {
      sethideRideArrow(false);
    }
    if (currPage === 1) {
      sethideLeftArrow(true);
    } else {
      sethideLeftArrow(false);
    }

    setPaginationLoader(false);
  };
  const adjustPages = () => {
    let tempStart = 1;
    let tempEnd = Math.min(5, totalPages);
    if (currentPage > 3) {
      tempStart = Math.max(1, currentPage - Math.floor(visiblePages / 2));
      tempEnd = Math.min(tempStart + visiblePages - 1, totalPages);
    }
    let tempPageNumbers = Array.from(
      { length: tempEnd - tempStart + 1 },
      (_, index) => tempStart + index
    );
    if (tempPageNumbers[0] !== 0) {
      setPageNumbers(tempPageNumbers);
    }

    // setCurrentPage(currentPage);
  };
  useEffect(() => {
    adjustPages();
  }, [currentPage]);
  useEffect(() => {
    setExportFileType("");
    setStartDate(null);
    setEndDate(null);
    if (selectedDuration != "custom") {
      filterActivityLogs();
    }
  }, [selectedDuration]);
  useEffect(() => {
    filterActivityLogs();
  }, [selectedUser]);
  const handleNextPage = async () => {
    setPaginationLoader(true);

    // setLoadingNextPage(true);
    // setTimeout(() => {
    //   setLoadingNextPage(false);
    // }, 2000);
    if (currentPage + 1 === totalPages) {
      sethideRideArrow(true);
    } else {
      sethideRideArrow(false);
    }
    if (currentPage + 1 === 1) {
      sethideLeftArrow(true);
    } else {
      sethideLeftArrow(false);
    }
    if (currentPage < pageNumbers[pageNumbers.length - 1]) {
      const data = await activityLogsService.getActivitylogs(
        selectedUser?.label,
        currentPage + 1,
        selectedDuration,
        startDate,
        endDate,
        departmentRef?.current?.value,
        shiftRef?.current?.value,
        shiftTimingRef?.current?.value,
        selectedModuleName.value,
        yearRef?.current?.value
      );

      // const data = await activityLogsService.getActivitylogs( selectedUser, selectedMethod, currentPage+1)
      setActivityLogList(data.results);

      // paginate(currentPage + 1, memories[window.location.pathname]);
      setCurrentPage(currentPage + 1);
    } else if (totalPages > pageNumbers[4]) {
      let tempPageArr = [];
      const endPage = Math.min(totalPages, pageNumbers[4] + 4);
      for (let page = pageNumbers[4] + 1; page <= endPage; page++) {
        tempPageArr.push(page);
      }
      const data = await activityLogsService.getActivitylogs(
        selectedUser?.label,
        currentPage + 1,
        selectedDuration,
        startDate,
        endDate,
        departmentRef?.current?.value,
        shiftRef?.current?.value,
        shiftTimingRef?.current?.value,
        yearRef?.current?.value
      );

      // const data = await activityLogsService.getActivitylogs( selectedUser, currentPage+1);
      setActivityLogList(data.results);

      setCurrentPage(currentPage + 1);
      setPageNumbers(tempPageArr);
    }
    setPaginationLoader(false);
  };
  const handleClear = async () => {
    setSelectedDuration("");
    if (
      departmentRef.current &&
      shiftTimingRef.current &&
      shiftRef.current &&
      yearRef.current
    ) {
      departmentRef.current.value = "";
      shiftTimingRef.current.value = "";
      shiftRef.current.value = "";
      yearRef.current.value = "";
    }
    async function getUsers() {
      let users = await userRegistrationService.getUsers1();

      const userEmails1 = users.results.map((obj) => {
        return { value: obj.email, label: obj.email, ...obj };
      });
      setUserList(userEmails1);
      setUserEmails(userEmails1);
    }
    dropdown();
    getUsers();
    setStartDate(null);
    setEndDate(null);
    setSelectedUser("");
    filterActivityLogs();
    setSelectedModuleName("");
  };
  const plugins = [ChartPluginDatalabels];
  const handlePrevPage = async () => {
    setPaginationLoader(true);

    if (currentPage - 1 === totalPages) {
      sethideRideArrow(true);
    } else {
      sethideRideArrow(false);
    }
    if (currentPage - 1 === 1) {
      sethideLeftArrow(true);
    } else {
      sethideLeftArrow(false);
    }

    if (currentPage > pageNumbers[0]) {
      const data = await activityLogsService.getActivitylogs(
        selectedUser?.label,
        currentPage - 1,
        selectedDuration,
        startDate,
        endDate,
        departmentRef?.current?.value,
        shiftRef?.current?.value,
        shiftTimingRef?.current?.value,
        selectedModuleName.value,
        yearRef?.current?.value
      );

      // const data = await activityLogsService.getActivitylogs( selectedUser, currentPage - 1);

      setActivityLogList(data.results);
      // paginate(currentPage + 1, memories[window.location.pathname]);
      setCurrentPage(currentPage - 1);
    }
    setPaginationLoader(false);
  };
  const titlesArray = [
    { label: "Recruiter Social", value: "recruiterSocial" },
    { label: "Search Query Generator", value: "searchStringGenerator" },
    { label: "JD Generator", value: "jobDescriptionMaker" },
    { label: "Recruiter Outreach", value: "contentGenerator" },
    { label: "Domain Identifier", value: "Classification" },
    { label: "Recommended Jobs", value: "JDCVCompatibilty" },
    { label: "Relevant Candidate Finder", value: "CVJDCompatibility" },
    { label: "Skill Highlighter", value: "skillHighlighter" },
    { label: "Interview Q & A", value: "createQuestionAnswer" },
    { label: "Memories", value: "memories" },
    { label: "Buzz", value: "buzz" },
    { label: "Social Template Bank", value: "linkedin" },
    { label: "Emailers", value: "mailer" },
    { label: "User", value: "users" },
    { label: "Clan Score Master", value: "clanScoreMaster" },
    { label: "Chat Bot", value: "chatbot" },
  ];

  useEffect(() => {
    filterActivityLogs();
  }, [selectedModuleName]);

  async function filterActivityLogs() {
    setPaginationLoader(true);

    const data = await activityLogsService.getActivitylogs(
      selectedUser?.label,
      1,
      selectedDuration,
      startDate,
      endDate,
      departmentRef?.current?.value,
      shiftRef?.current?.value,
      shiftTimingRef?.current?.value,
      selectedModuleName.value,
      yearRef?.current?.value
    );

    if (data.totalResults < 9) {
      setPageNumbers([1]);
      if (data.totalResults === 0) {
        setPageNumbers([]);
      }
      sethideRideArrow(true);
    } else {
      let tempTotalPages = Math.max(1, Math.ceil(data.totalResults / 8));
      setTotalPages(tempTotalPages);

      if (tempTotalPages <= 1) {
        sethideRideArrow(true);
      } else {
        sethideRideArrow(false);
      }
      if (tempTotalPages > 5) {
        tempTotalPages = 5;
      }
      let tempPageArr = [];
      for (let i = 1; i <= tempTotalPages; i++) {
        tempPageArr.push(i);
      }
      setPageNumbers(tempPageArr);
    }
    setCurrentPage(1);
    sethideLeftArrow(true);
    setActivityLogList(data.results);
    setPaginationLoader(false);
  }

  useEffect(() => {
    dropdown();
    async function getUsers() {
      let users = await userRegistrationService.getUsers1();

      const userEmails1 = users.results.map((obj) => {
        return { value: obj.email, label: obj.email, ...obj };
      });
      setUserList(userEmails1);
      setUserEmails(userEmails1);
      // await  getActivityLogs()
    }
    getUsers();
    yearRef.current.value = "2024";
  }, []);

  useEffect(() => {
    filterActivityLogs();
  }, [startDate, endDate]);

  async function handleFilterAllUsers() {
    setPaginationLoader(true);

    const departmentValue = departmentRef.current.value;
    const shiftValue = shiftRef.current.value;
    const shiftTimingValue = shiftTimingRef.current.value;

    let filteredUsers = userList;

    if (departmentValue !== "") {
      filteredUsers = filteredUsers.filter(
        (user) => user.department === departmentValue
      );
    }

    if (shiftValue !== "") {
      filteredUsers = filteredUsers.filter((user) => user.shift === shiftValue);
      const shiftTimingsArray = [];
    }

    if (shiftTimingValue !== "") {
      filteredUsers = filteredUsers.filter(
        (user) => user.shiftTiming === shiftTimingValue
      );
    }

    const userEmails = filteredUsers.map((obj) => ({
      value: obj.email,
      label: obj.email,
      ...obj,
    }));

    setUserEmails(userEmails);
    setSelectedUser(null);

    await filterActivityLogs();

    setPaginationLoader(false);
  }

  const handleRegionChange = async (e) => {
    const { value } = e.target;

    if (value == "") {
      setShiftTimings(filteredShiftTimings);
    } else {
      let shiftTimingsArray = [];
      shiftTimingsArray =
        shifts.find((shift) => shift.id === value)?.shiftTiming || [];

      const uniqueShiftTimings = [...new Set(shiftTimingsArray)];
      setShiftTimings(uniqueShiftTimings);
      shiftTimingRef.current.value = "";

      const allUsers = [];
      userList.filter((item) => {
        if (item.shift === value) [allUsers.push(item)];
      });
      const uniqueUsers = [...new Set(allUsers)];
      setUserEmails(uniqueUsers);
      setSelectedUser(null);
    }

    filterActivityLogs();
    handleFilterAllUsers();
  };

  const handleShiftTimings = async (e) => {
    const { value } = e.target;
    if (value == "") {
      setShiftTimings(filteredShiftTimings);
    } else {
      const allUsers = [];

      userList.filter((item) => {
        if (item.shiftTiming === value) [allUsers.push(item)];
      });
      const uniqueUsers = [...new Set(allUsers)];
      setUserEmails(uniqueUsers);
      setSelectedUser(null);
    }

    filterActivityLogs();
    handleFilterAllUsers();
  };

  const handleDeparmentChange = async (e) => {
    const { value } = e.target;

    if (value === "") {
      const result1 = await masterDBService.getAllShiftTimings();
      const results = await masterDBService.getAllGeographies();

      setShifts(results.results);

      shiftRef.current.value = "";

      setShiftTimings(result1.results);
      shiftTimingRef.current.value = "default";
    }

    if (departments.find((item) => item.id == value)) {
      let shiftsArray = [];

      shiftsArray =
        departments.find((department) => department.id === value)?.geography ||
        [];

      setShifts(shiftsArray);
      shiftRef.current.value = "";

      let shiftTimingsArray = [];
      const allShiftTimings = shiftsArray.flatMap((obj) => obj.shiftTiming);
      shiftTimingsArray = allShiftTimings;
      setShiftTimings(shiftTimingsArray);
      let filteredShifts = departments.flatMap((obj) => obj.geography);
      let filterShiftTiming = filteredShifts.flatMap((obj) => obj.shiftTiming);
      const uniqueShiftTimings = [...new Set(filterShiftTiming)];

      setFilteredShiftTimings(uniqueShiftTimings);
      shiftTimingRef.current.value = "";
    }
    const allUsers = [];
    userList.filter((item) => {
      if (item.department === value) [allUsers.push(item)];
    });
    const uniqueUsers = [...new Set(allUsers)];
    setUserEmails(uniqueUsers);
    setSelectedUser(null);
    filterActivityLogs();
    handleFilterAllUsers();
  };

  const handleEmailWrap = (email) => {
    const atIndex = email.indexOf("@");
    if (atIndex !== -1) {
      const firstPart = email.slice(0, atIndex);
      const secondPart = email.slice(atIndex);
      return (
        <>
          {firstPart}
          <br />
          {secondPart}
        </>
      );
    }
    return email;
  };

  return (
    <div className="d-flex buzz">
      <div className="indicator-container pt-2 pb-2">
        <div className="d-flex text">
          <BiSolidUniversalAccess className="icon" />
          Activity Log
        </div>
        <div>
          <select
            id="ActivityLogSelect"
            // onChange={getActivityLogData}
            className="form-select"
            value={exportFileType}
            onChange={(e) => {
              setExportFileType(e.target.value);
            }}
            disabled={loading}
            required
          >
            <option value="">Export logs</option>
            <option value="csv">CSV</option>
            <option value="pdf">PDF</option>
            <option value="excel">Excel</option>
          </select>
        </div>
      </div>
      {showAlert && (
        <div className="alert-container">
          <div className="upper-section">
            <p>ALOIS</p>
            <span
              className="delete-btn"
              onClick={() => setShowAlert(!showAlert)}
            >
              <AiOutlineClose className="icon" />
            </span>
          </div>

          <hr />

          <div className="lower-section py-2">
            <p className="text-danger">{errorNotific}</p>
            <p className="text-success">{successNotific}</p>
          </div>
        </div>
      )}
      <div className="toasts-container position-absolute top-0 mt-3 mx-3">
        <div className="toast fade hide" data-autohide="true" id="toast-2">
          <div className="toast-header">
            <i className="far fa-bell text-muted me-2"></i>
            <strong className="me-auto">ALOIS</strong>
            <button
              type="button"
              id="toastclose_btn"
              className="btn-close"
              data-bs-dismiss="toast"
            ></button>
          </div>
          <div className="toast-body">
            {/* <p className="text-success">{success}</p> */}
            <p className="text-danger">{error}</p>
          </div>
        </div>
      </div>
      {selectedValue ? (
        <div className="user-reg-popup-container">
          <div className="user-reg-popup w-50">
            <div className="user-reg-popup-header">
              <span className="fs-6 fw-bold">Request/Response</span>
              <button
                className="close-button"
                onClick={() => setSelectedValue("")}
              >
                &#10005;
              </button>
            </div>
            <div style={{ maxHeight: "500px", overflowY: "scroll" }}>
              <JsonView collapsed src={selectedValue} />
            </div>
          </div>
        </div>
      ) : null}

      <div
        className="outer_div mx-auto d-flex justify-content-center"
        style={{ marginTop: "2rem", height: "fit-content" }}
      >
        <div
          className="access rounded-4 bg-white mt-3 d-flex flex-column justify-content-start "
          style={{ width: "99.8%", overflow: "hidden" }}
        >
          <div
            className="upper-section pt-1 justify-content-between"
            style={{ clear: "both" }}
          >
            <div className="row d-flex pb-1 ps-2 col-xl-12 px-2 flex-wrap gap-3">
              <div className="d-flex mx-2 col-xl-2 col-lg-3">
                <div
                  className="form-outline"
                  style={{ width: "100%", minWidth: "100%" }}
                >
                  <select
                    id="department"
                    className="form-select dropdown-styles mt-1"
                    ref={departmentRef}
                    onChange={handleDeparmentChange}
                  >
                    <option value="" className="p-5">
                      All Departments
                    </option>
                    {departments.map(
                      (item, index) => (
                        <option value={item.id} key={index}>
                          {item.department}
                        </option>
                      ),
                      []
                    )}
                  </select>
                  <div className="arrow-down"></div>
                </div>
              </div>

              <div className="d-flex col-xl-2 col-lg-3">
                <div
                  className="form-outline"
                  style={{ width: "100%", minWidth: "100%" }}
                >
                  <select
                    id="region"
                    className="form-select dropdown-styles mt-1"
                    ref={shiftRef}
                    onChange={handleRegionChange}
                  >
                    <option value="">All Shifts</option>
                    {shifts.map(
                      (item, index) => (
                        <option value={item.id} key={index}>
                          {item.geography}
                        </option>
                      ),
                      []
                    )}
                  </select>
                  <div className="arrow-down"></div>
                </div>
              </div>

              <div className="d-flex col-xl-2 col-lg-3">
                <div
                  className="form-outline "
                  style={{ width: "100%", minWidth: "100%" }}
                >
                  <select
                    id="shift"
                    className="form-select dropdown-styles mt-1"
                    ref={shiftTimingRef}
                    onChange={handleShiftTimings}
                  >
                    <option value="">All Shift timings</option>
                    {shiftTimings.map(
                      (item, index) => (
                        <option value={item.id} key={index}>
                          {item.startTime + " - " + item.endTime}
                        </option>
                      ),
                      []
                    )}
                  </select>
                  <div className="arrow-down"></div>
                </div>
              </div>
              <div className="d-flex mx-2 col-xl-2 col-lg-3">
                <div
                  className="form-outline"
                  style={{ width: "100%", minWidth: "100%" }}
                >
                  <Select
                    className="h-50 mt-1"
                    styles={{ height: "30px" }}
                    options={userEmails}
                    isSearchable={true}
                    value={selectedUser}
                    onChange={setSelectedUser}
                    placeholder="All users"
                  />
                  <div className="arrow-down"></div>
                </div>
              </div>
              <div className="d-flex mx-2 col-xl-2 col-lg-4 justify-content-end">
                <div
                  className="form-outline"
                  style={{ width: "100%", minWidth: "100%" }}
                >
                  <Select
                    className="h-50 mt-1"
                    styles={{ height: "30px" }}
                    options={titlesArray}
                    isSearchable={true}
                    value={selectedModuleName}
                    onChange={setSelectedModuleName}
                    placeholder="All modules"
                  />
                  <div className="arrow-down"></div>
                </div>
              </div>
            </div>

            <div className="row d-flex pb-1 ps-2 col-xl-12 px-2 flex-wrap mt-1 gap-3">
              <div className="d-flex mx-2 col-xl-2 col-lg-3">
                <div
                  className="form-outline"
                  style={{ width: "100%", minWidth: "100%" }}
                >
                  <select
                    id="department"
                    className="form-select dropdown-styles  "
                    placeholder="All years"
                    // style={{width:"fit-content"}}
                    value={yearRef?.current?.value}
                    ref={yearRef}
                    defaultValue="2024"
                    onChange={filterActivityLogs}
                  >
                    <option value="" className="p-5">
                      All years
                    </option>
                    <option value="2023" className="p-5">
                      2023
                    </option>

                    <option value="2024">2024</option>
                  </select>
                  <div className="arrow-down"></div>
                </div>
              </div>

              <div className="d-flex col-xl-2 col-lg-3">
                <div
                  className="form-outline"
                  style={{ width: "100%", minWidth: "100%" }}
                >
                  <select
                    id="ActivityLogSelect"
                    // onChange={getActivityLogData}
                    className="form-select dropdown-styles  "
                    placeholder="All durations"
                    value={selectedDuration}
                    onChange={(e) => {
                      setSelectedDuration(e.target.value);
                    }}
                    
                  >
                    <option value="">All durations</option>
                    <option value="week">Current Week</option>
                    <option value="month">Current Month</option>
                    <option value="custom">Custom</option>
                  </select>
                  <div className="arrow-down"></div>
                </div>
              </div>

              {selectedDuration == "custom" ? (
                <div className="d-flex col-xl-2 col-lg-3 mx-2">
                  <div
                    className="form-outline"
                    style={{ width: "100%", minWidth: "100%" }}
                  >
                    <DatePicker
                      type="date"
                      id="dateInput"
                      selected={startDate}
                      onChange={(e) => {
                        setStartDate(e);
                      }}
                      style={{ height: "60px" }}
                      placeholderText="Select a start date"
                      maxDate={endDate ? new Date(endDate) : new Date()}
                    />
                    <div className="arrow-down"></div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {selectedDuration == "custom" ? (
                <div className="d-flex col-xl-2 col-lg-3">
                  <div
                    className="form-outline"
                    style={{ width: "100%", minWidth: "100%" }}
                  >
                    <DatePicker
                      type="date"
                      id="dateInput"
                      selected={endDate}
                      onChange={(e) => setEndDate(e)}
                      placeholderText="Select a end date"
                      minDate={new Date(startDate)}
                      maxDate={new Date()}
                    />
                    <div className="arrow-down"></div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div className="col-xl-2 d-flex justify-content-start mx-2 align-content-center">
                <GradientBorderButton
                  text={"Reset filters"}
                  clickHandler={handleClear}
                />
              </div>
            </div>
          </div>

          {reportActivityLogs ? (
            <div className="d-flex flex-column justify-content-around ">
              <div className="d-flex  justify-content-between mx-2">
                <h5 className="m-3 ">User Activity Chart(Top 10)</h5>
                <AiFillCloseCircle
                  className="icon"
                  onClick={() => setReportActivityLogs()}
                />
              </div>
              <div>
                <Bar
                  data={chartData}
                  options={chartOptions}
                  plugins={plugins}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="access-form">
            <form id="clanForm" />

            <div
              className="row  mt-2 w-100 table-responsive"
              style={{ marginLeft: "1px", height: "fit-content" }}
            >
              <table className="table w-100 equal-width-table">
                <thead>
                  <tr
                    className="permission-headings "
                    style={{ height: "50px" }}
                  >
                    <th scope="col" className="text-center">
                      {" "}
                      User{" "}
                    </th>
                    <th scope="col" className="text-center">
                      Activity date
                    </th>
                    <th scope="col" className="text-center">
                      Module name (action)
                    </th>
                    <th className="text-center">Department</th>
                    <th scope="col" className="text-center">
                      Shift Timing
                    </th>
                    <th scope="col" className="text-center">
                      Shift
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {ActivityLogList.length ? (
                    !paginationLoader ? (
                      ActivityLogList.map((item, index) => {
                        let moduleName = "";

                        if (item.url.includes("/")) {
                          let urlWithoutv1 = item.url.replace(/^\/v1\//, "");
                          urlWithoutv1 = urlWithoutv1.replace(
                            new RegExp(`\\bget\\b`),
                            ""
                          );
                          // Replace slashes in the URL with underscores
                          const urlParts = urlWithoutv1.split("/");
                          const urlWithUnderscores = urlParts.join("_");
                          let method1 = "_" + item.method;
                          if (item.method == "POST") {
                            method1 = "_ (Create)";
                          }
                          if (item.method == "PUT") {
                            method1 = "_ (Update)";
                          }
                          if (item.method == "DELETE") {
                            method1 = "_ (Delete)";
                          }
                          if (
                            urlWithUnderscores.includes("create") ||
                            urlWithUnderscores.includes("update")
                          ) {
                            method1 = "";
                          }
                          moduleName = `${urlWithUnderscores}${method1.toLowerCase()}`;
                        } else {
                          moduleName = item.url;
                        }

                        // Split the string by underscores
                        let parts = moduleName.split("_");
                        let firstPart = parts[0];

                        if (parts[0] == "utility") {
                          parts.splice(0, 1);

                          function findTitleByValue(value) {
                            if (value.includes("Classification")) {
                              value = "Classification";
                            }
                            const foundTitle = titlesArray.find((title) =>
                              title.value.includes(value)
                            );

                            return foundTitle ? foundTitle.label : null;
                          }
                          moduleName = findTitleByValue(parts[0]) + " (Create)";
                        }
                        if (firstPart != "utility") {
                          // Remove the second part
                          if (parts.length == 3) {
                            parts.splice(1, 1);
                          }
                          function findTitleByValue(value) {
                            const foundTitle = titlesArray.find((title) =>
                              title.value.includes(value)
                            );

                            return foundTitle ? foundTitle.label : null;
                          }
                          // Join the remaining parts with underscores
                          moduleName = findTitleByValue(parts[0]);
                          if (
                            !firstPart.includes("user") &&
                            !firstPart.includes("clanScoreMaster") &&
                            !firstPart.includes("mailer") &&
                            !firstPart.includes("chatbot")
                          ) {
                            if (parts[1].includes("create")) {
                              moduleName += " (Upload)";
                            } else if (parts[1].includes("update")) {
                              moduleName += " (Update)";
                            } else if (parts[1].includes("delete")) {
                              moduleName += " (Delete)";
                            }
                          } else if (firstPart.includes("user")) {
                            if (parts[1].includes("create")) {
                              moduleName += " (Create)";
                            } else if (parts[1].includes("update")) {
                              moduleName += " (Update)";
                            } else if (parts[1].includes("delete")) {
                              moduleName += " (Delete)";
                            }
                          } else if (firstPart.includes("chatbot")) {
                            if (parts[1].includes("create")) {
                              moduleName += " (Create)";
                            }
                          } else if (firstPart.includes("clanScoreMaster")) {
                            if (parts[1].includes("create")) {
                              moduleName += " (Update)";
                            } else if (parts[1].includes("update")) {
                              moduleName += " (Update)";
                            } else if (parts[1].includes("delete")) {
                              moduleName += " (Delete)";
                            }
                          } else if (firstPart.includes("mailer")) {
                            if (parts[1].includes("create")) {
                              moduleName += " (sent)";
                            } else if (parts[1].includes("update")) {
                              moduleName += " (Update)";
                            } else if (parts[1].includes("delete")) {
                              moduleName += " (Delete)";
                            }
                          }
                        } else {
                        }
                        // Check if there are exactly three parts

                        return (
                          <tr
                            className="w-100"
                            key={index}
                            style={{ height: "50px !important" }}
                          >
                            <td className="text-center">
                              {handleEmailWrap(item.userEmail)}
                            </td>
                            {/* <td className="text-center">
                        {item.method}
                        </td> */}
                            <td className="text-center">
                              {(() => {
                                const inputDate = item.createdAt;
                                if (inputDate) {
                                  const date = new Date(inputDate);
                                  const year = date.getFullYear();
                                  const month = String(
                                    date.getMonth() + 1
                                  ).padStart(2, "0");
                                  const day = String(date.getDate()).padStart(
                                    2,
                                    "0"
                                  );
                                  const formattedDate = `${day}/${month}/${year}`;
                                  return formattedDate;
                                } else {
                                  return "N/A"; // or any  value for empty dates
                                }
                              })()}
                            </td>
                            <td className="text-center">{moduleName}</td>
                            <td className="text-center">
                              {departments.length &&
                              departments.find(
                                (department) =>
                                  department.id === item.department
                              )
                                ? departments.find(
                                    (department) =>
                                      department.id === item.department
                                  ).department
                                : "Department not found"}
                            </td>
                            <td className="text-center">
                              {shifts.find((shift) => shift.id === item.shift)
                                ? shifts?.find(
                                    (shift) => shift.id === item.shift
                                  ).geography
                                : "Geography not found"}
                            </td>
                            <td className="text-center">
                              {shiftTimings.find(
                                (shift) => shift.id === item.shiftTiming
                              )
                                ? shiftTimings.find(
                                    (shift) => shift.id === item.shiftTiming
                                  ).startTime +
                                  " - " +
                                  shiftTimings.find(
                                    (shift) => shift.id === item.shiftTiming
                                  ).endTime
                                : "Shift timing not found"}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="6">
                          <p
                            className="text-center"
                            style={{ verticalAlign: "middle" }}
                          >
                            <Spinner animation="border" role="status">
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </Spinner>
                          </p>
                        </td>
                      </tr>
                    )
                  ) : (
                    <tr>
                      <td colSpan="6">
                        <p
                          className="text-center"
                          style={{ verticalAlign: "middle" }}
                        >
                          No logs found
                        </p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="pagination my-4">
              {!hideLeftArrow ? (
                <button
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                  className={currentPage === 1 ? "active" : ""}
                >
                  <FaLessThan
                    className="arrow"
                    style={{
                      marginBottom: "4px",
                      marginRight: "2px",
                      padding: "1px",
                    }}
                  />
                </button>
              ) : (
                <></>
              )}
              {pageNumbers.map((page) => (
                <button
                  key={page}
                  disabled={currentPage === page}
                  className={
                    currentPage === page ? "active text-center" : "text-center"
                  }
                  onClick={() => {
                    paginate(page);
                    // adjustPages(page);
                  }}
                  style={{ textAlign: "center" }}
                >
                  {page}
                </button>
              ))}
              {!hideRightArrow ? (
                <button
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                  className={currentPage === totalPages ? "active" : ""}
                >
                  <FaGreaterThan
                    className="arrow"
                    style={{
                      marginBottom: "4px",
                      marginLeft: "2px",
                      padding: "1px",
                    }}
                  />
                </button>
              ) : (
                <></>
              )}
            </div>
            <div className="row bottom-0 d-flex justify-content-end pb-2">
              <div
                className="col-xl-3 px-3 bottom-0 loader-container"
                style={{ maxWidth: "200px" }}
              ></div>
            </div>
            {/* <div className="saveBtn">
              <button className="check-btn mx-auto p-1 mb-3" onClick={saveChanges}>
              {loading ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (<span>Save</span>)}
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActivityLog;
