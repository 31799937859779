import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import axios from "../../config/axios";
import { useRef } from "react";
import Notification from "../Notification/Notification";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import licenceValidator from "../../permission/validate";
import GradientBorderButton from "../Widgets/GradientBorderButton";

const DomainClassification = () => {
  const [showDiv, setDiv] = useState(false);
  const [domain, setDomain] = useState("");
  let cvRef = useRef()
  const [loader, setLoader] = useState(false);
  const [newId, setId] = useState();
  const [prediction, setPrediction] = useState([]);
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [showClearBtn, setShowClearBtn] = useState(false);
  const [show, setShow] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const jdRef = useRef();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowClearBtn(true);
    setLoader(true);
    setError("");
    setSuccess("");
    try {
      const res = await axios.post("/utility/oneShotClassification", {
        jobDescription: jdRef.current.value,
      });
      setId(res.data.value.id);
      setPrediction(res.data.value.expectedDomains);
      setDomain(res.data.value.prediction);
      setDiv(true);
    } catch (err) {
      setSuccess("");
      setLoader(false);
      console.log(err);
      setError(err.response.data.message);
    }
    setLoader(false);
  };
  useEffect(() => {
    let user = localStorage.getItem("user");
    let validator = new licenceValidator(user, "assist");
    setIsVisible(validator.isReadable("domain_identifier"));
  }, []);
  const correctDomain = async (e) => {
    setError("");
    e.preventDefault();
    const res = await axios.post("/utility/correctClassification", {
      prompt: jdRef.current.value,
      actualPrediction: e.target.value,
      id: newId,
    });
    jdRef.current.value = "";
    handleClose();
    setSuccess(e.target.value + " is set as actual prediction for given JD");
    setDiv(false);
  };

  const handleClear = (e) => {
    e.preventDefault();
    jdRef.current.value = "";
    setShowClearBtn(false);
  };
  let dashboardElement = document.querySelector(".dashboard");

  useEffect(()=>{
    
  
    if (dashboardElement && showDiv) {
            // If yes, set height to viewport height
       
        } else {
       
    
        }
    },[showDiv])
  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Domain Classification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {prediction.map((i) => {
            return (
              <button
                className="btn btn-outline-primary mx-1 my-1"
                onClick={(e) => correctDomain(e)}
                value={i}
              >
                {i}
              </button>
            );
          })}
        </Modal.Body>
      </Modal>
      <Notification error={error} success={success} />
      <div className="search-query">
        <form >
        {/* <div className="row mx-3 mt-2">
          <div className="col">
            <div className="form-outline">
              <label className="form-label" htmlFor="customFile">
                Upload job description / Resume
              </label>
              <input
                ref={cvRef}
                accept=".pdf"
                type="file"
                className="form-control"
                id="file"
                required
              />
            </div>
          </div>
        </div> */}
          <div className="form-group p-4">
            <textarea
              ref={jdRef}
              rows="12"
              className="form-control  mb-4"
              placeholder="Paste your JD here"
              required
            ></textarea>
            {loader ? (
              <div className="mx-auto mt-3 p-1">
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp; Loading...
              </div>
            ) : isVisible ? (
              <div className="d-flex justify-content-center gap-3">
              
              <GradientBorderButton clickHandler={handleSubmit} text={"Classify"} />
          <GradientBorderButton clickHandler={handleClear} text={"Clear"} />
   
           
         

              
                <div className="augmentry"></div>
              </div>
            ) : null}
          </div>
          {showDiv && showClearBtn && (
            <div className="d-flex justify-content-between mx-3 my-2">
              <h4> Predicted Domain : {domain}</h4>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default DomainClassification;
