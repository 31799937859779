import React, { useEffect, useRef, useState } from "react";
import GenerateBox from "../GenerateBox/GenerateBox";
import licenceValidator from "../../permission/validate";
import { Toast } from "bootstrap";
import essService from "../../services/ess.service";
import { AiOutlineClose } from "react-icons/ai";
import GradientBorderButton from "../Widgets/GradientBorderButton";

import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaAngleLeft,
  FaAngleRight,
  FaPencilAlt,
  FaSearch,
} from "react-icons/fa";
import { Spinner } from "react-bootstrap";
import minioService from "../../services/minio.service";
import Select from "react-select";
import bstService from "../../services/bst.service";
const BrandStarterKit = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [successNotific, setSuccessNotific] = useState("");
  const [errorNotific, setErrorNotific] = useState("");
  const [selectedOrganization, setSelectedOrganiztion] = useState(null);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [pageNumbers1, setPageNumbers1] = useState([]);

  const visiblePages = 5;
  const logsPerPage = 10;

  const [currentPage, setCurrentPage] = useState(1);
  const [currentPage1, setCurrentPage1] = useState(1);
  const [companyBio, setCompanyBio] = useState("");
  const [coverImages, setCoverImages] = useState([]);
  const [showActivityLogs, setShowActivityLogs] = useState(false);

  const [Images, setImages] = useState([]);
  const templatesPerPage = 8;
  const [totalPages, setTotalPages] = useState(null);
  const [totalPages1, setTotalPages1] = useState(null);
  const [showPreviewPopup, setShowPreviewPopup] = useState(false);
  const [showCoverPreviewPopup, setShowCoverPreviewPopup] = useState(false);

  const [selectedTemplate, setSelectedTemplate] = useState({});

  const [currentTemplateIndex, setCurrentTemplateIndex] = useState(0);
  const Organizations = [
    { value: "ALOIS Solutions", label: "ALOIS Solutions" },
    { value: "ALOIS Healthcare", label: "ALOIS Healthcare" },
    { value: "ALOIS Exposites", label: "ALOIS Exposites" },
    { value: "ALOIS Exports", label: "ALOIS Exports" },
    { value: "Adriva Services", label: "Adriva Services" },
  ];
  const handleOrgChange = async (org) => {
    setSelectedOrganiztion(org);
    await call(1);
  };
  const handleDualPrevPage = () => {
    const setPageNumber = currentPage - 5;
    setPageNumber <= 0 && (setPageNumber = 1);
    setCurrentPage(setPageNumber);
    adjustPages(setPageNumber);
    getNewTemplateList(setPageNumber);
  };
  const handleDualPrevPage1 = () => {
    const setPageNumber = currentPage1 - 5;
    setPageNumber <= 0 && (setPageNumber = 1);

    setCurrentPage1(setPageNumber);
    adjustPages1(setPageNumber);

    getNewTemplateList1(setPageNumber);
  };
  const handlePreviewTemplate = (data, index) => {
    setShowAlert(false);
    setShowPreviewPopup(true);
    setSelectedTemplate(data);
    setCurrentTemplateIndex(index);
  };
  const handlePreviewCoverTemplate = (data, index) => {
    setShowAlert(false);
    setShowCoverPreviewPopup(true);
    setSelectedTemplate(data);
    setCurrentTemplateIndex(index);
  };
  const handleNextPage = () => {
    const setPageNumber = currentPage + 1;
    setCurrentPage(setPageNumber);
    adjustPages(setPageNumber);

    getNewTemplateList(setPageNumber);
  };
  const handlePrevPage = () => {
    const setPageNumber = currentPage - 1;
    setCurrentPage(setPageNumber);
    adjustPages(setPageNumber);

    getNewTemplateList(setPageNumber);
  };
  const handlePrevPage1 = () => {
    const setPageNumber = currentPage1 - 1;
    setCurrentPage1(setPageNumber);
    adjustPages1(setPageNumber);

    getNewTemplateList1(setPageNumber);
  };
  const handleNextPage1 = () => {
    const setPageNumber = currentPage1 + 1;
    setCurrentPage1(setPageNumber);
    adjustPages1(setPageNumber);

    getNewTemplateList1(setPageNumber);
  };
  const handleDualNextPage = () => {
    const setPageNumber = currentPage + 5;
    setPageNumber > totalPages && (setPageNumber = totalPages);

    setCurrentPage(setPageNumber);
    adjustPages(setPageNumber);

    getNewTemplateList(setPageNumber);
  };
  const handleDualNextPage1 = () => {
    const setPageNumber = currentPage1 + 5;
    setPageNumber > totalPages1 && (setPageNumber = totalPages1);

    setCurrentPage1(setPageNumber);
    adjustPages1(setPageNumber);

    getNewTemplateList1(setPageNumber);
  };
  const call = async (pageNo) => {
    const response = await bstService.getKits(
      pageNo,
      templatesPerPage,
      "logo",
      selectedOrganization?.value
    );
    const response1 = await bstService.getKits(
      pageNo,
      templatesPerPage,
      "company-bio",
      selectedOrganization?.value
    );
    const response2 = await bstService.getKits(
      pageNo,
      templatesPerPage,
      "cover-page",
      selectedOrganization?.value
    );

    if (selectedOrganization?.value && response.data.results.length) {
      let bioCompany = response1.data.results;
      if (bioCompany.length) {
        setCompanyBio(bioCompany[0].bio.replace(/<[^>]*>/g, " "));
      } else {
        setCompanyBio("");
      }
    }

    const results = response.data.results;
    setImages(results);
    const results1 = response2.data.results;
    setCoverImages(results1);

    const tempTotalPages = Math.max(
      1,
      Math.ceil(response.data.totalResults / templatesPerPage)
    );
    setTotalPages(tempTotalPages);

    const tempPageArr = [];
    for (let i = 1; i <= tempTotalPages; i++) {
      tempPageArr.push(i);
    }
    tempPageArr.length <= 5 && setPageNumbers(tempPageArr);

    // cover page
    const tempTotalPages1 = Math.max(
      1,
      Math.ceil(response2.data.totalResults / templatesPerPage)
    );
    setTotalPages1(tempTotalPages1);

    const tempPageArr1 = [];
    for (let i = 1; i <= tempTotalPages1; i++) {
      tempPageArr1.push(i);
    }
    tempPageArr1.length <= 5 && setPageNumbers1(tempPageArr1);
  };
  useEffect(() => {
    call(1);
  }, []);
  const adjustPages = (currentPage, tempTotalPages) => {
    let finalTotalPages = tempTotalPages || totalPages;
    let tempStart = 1;
    let tempEnd = Math.min(5, finalTotalPages);

    if (finalTotalPages > 5) {
      if (currentPage > 3 && currentPage < finalTotalPages - 1) {
        tempStart = Math.max(1, currentPage - Math.floor(visiblePages / 2));
        tempEnd = Math.min(tempStart + visiblePages - 1, finalTotalPages);
      } else if (currentPage >= finalTotalPages - 1) {
        // If it is the last or last second page, then show the last 5 page numbers
        tempStart = Math.max(1, finalTotalPages - visiblePages + 1);
        tempEnd = finalTotalPages;
      }
    }

    let tempPageNumbers = Array.from(
      { length: tempEnd - tempStart + 1 },
      (_, index) => tempStart + index
    );

    if (tempPageNumbers[0] !== 0) {
      setPageNumbers(tempPageNumbers);
    }
  };
  const adjustPages1 = (currentPage, tempTotalPages) => {
    let finalTotalPages = tempTotalPages || totalPages;
    let tempStart = 1;
    let tempEnd = Math.min(5, finalTotalPages);

    if (finalTotalPages > 5) {
      if (currentPage > 3 && currentPage < finalTotalPages - 1) {
        tempStart = Math.max(1, currentPage - Math.floor(visiblePages / 2));
        tempEnd = Math.min(tempStart + visiblePages - 1, finalTotalPages);
      } else if (currentPage >= finalTotalPages - 1) {
        // If it is the last or last second page, then show the last 5 page numbers
        tempStart = Math.max(1, finalTotalPages - visiblePages + 1);
        tempEnd = finalTotalPages;
      }
    }

    let tempPageNumbers = Array.from(
      { length: tempEnd - tempStart + 1 },
      (_, index) => tempStart + index
    );

    if (tempPageNumbers[0] !== 0) {
      setPageNumbers1(tempPageNumbers);
    }
  };
  const getNewTemplateList = async (pageNo) => {
    const response = await bstService.getKits(
      pageNo,
      templatesPerPage,
      "logo",
      selectedOrganization?.value
    );
    const results = response.data.results;
    setImages(results);

    setCurrentPage(pageNo);
  };
  const getNewTemplateList1 = async (pageNo) => {
    const response = await bstService.getKits(
      pageNo,
      templatesPerPage,
      "cover-page",
      selectedOrganization?.value
    );
    const results = response.data.results;
    setCoverImages(results);

    setCurrentPage1(pageNo);
  };
  const paginate = (currPage, data) => {
    // Scroll to the top of the table whenever page number is changed

    const start = (currPage - 1) * logsPerPage;
    const end = start + logsPerPage;
  };
  const paginate1 = (currPage, data) => {
    // Scroll to the top of the table whenever page number is changed

    const start = (currPage - 1) * logsPerPage;
    const end = start + logsPerPage;
  };
  return (
    <div className="brand-starter-kit">
      {showAlert ? (
        <div className="alert-container">
          <div className="upper-section">
            <p style={{ marginBottom: "0px", marginTop: "5px" }}>ALOIS</p>
            <span
              className="delete-btn"
              onClick={() => setShowAlert(!showAlert)}
            >
              <AiOutlineClose className="icon" style={{ fill: "black" }} />
            </span>
          </div>
          <hr
            style={{
              display: "block !important",
              height: "1px !important",
              border: "0 !important",
              borderTop: "1px solid gray !important",
            }}
          />
          <div className="lower-section pt-2">
            <p className="text-danger">{errorNotific}</p>
            <p className="text-success">{successNotific}</p>
          </div>
        </div>
      ) : null}
      {showCoverPreviewPopup && (
        <div className="preview-popup-container">
          <div className="popup">
            <div className="popup-header">
              <div>Template Preview</div>

              <div>
                <button
                  className="close-button"
                  onClick={() => setShowCoverPreviewPopup(false)}
                >
                  &#10005;
                </button>
              </div>
            </div>

            <hr className="hr-border" />

            <div className="popup-content preview-icons">
              <div>
                {currentTemplateIndex !== 0 && (
                  <button
                    onClick={() =>
                      setCurrentTemplateIndex(currentTemplateIndex - 1)
                    }
                  >
                    <FaAngleLeft className="arrow-icon left-arrow" />
                  </button>
                )}
              </div>

              <img
                src={coverImages[currentTemplateIndex].filePath}
                alt={`template_${selectedTemplate.id}`}
              />

              <div>
                {currentTemplateIndex !== Images.length - 1 && (
                  <button
                    onClick={() =>
                      setCurrentTemplateIndex(currentTemplateIndex + 1)
                    }
                  >
                    <FaAngleRight className="arrow-icon" />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {showPreviewPopup && (
        <div className="preview-popup-container">
          <div className="popup">
            <div className="popup-header">
              <div>Template Preview</div>

              <div>
                <button
                  className="close-button"
                  onClick={() => setShowPreviewPopup(false)}
                >
                  &#10005;
                </button>
              </div>
            </div>

            <hr className="hr-border" />

            <div className="popup-content preview-icons">
              <div>
                {currentTemplateIndex !== 0 && (
                  <button
                    onClick={() =>
                      setCurrentTemplateIndex(currentTemplateIndex - 1)
                    }
                  >
                    <FaAngleLeft className="arrow-icon left-arrow" />
                  </button>
                )}
              </div>

              <img
                src={Images[currentTemplateIndex].filePath}
                alt={`template_${selectedTemplate.id}`}
              />

              <div>
                {currentTemplateIndex !== Images.length - 1 && (
                  <button
                    onClick={() =>
                      setCurrentTemplateIndex(currentTemplateIndex + 1)
                    }
                  >
                    <FaAngleRight className="arrow-icon" />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="row mt-3 pb-3">
        <div className="col-3 brand">
          <div className="form-outline">
            <label className="form-label" htmlFor="upload-template">
              Select an Organization
            </label>

            <div
              className="form-outline"
              style={{
                width: "100%",
                minWidth: "100%",
              }}
            >
              <Select
                className="h-50 mt-1"
                styles={{
                  height: "10px",
                  width: "20px",
                }}
                onChange={handleOrgChange}
                isSearchable={true}
                value={selectedOrganization}
                options={Organizations}
                placeholder="Select an Organization"
              />
              <div className="arrow-down"></div>
            </div>
          </div>
        </div>
      </div>
      {companyBio ? (
        <GenerateBox
          text={companyBio}
          showJdIcons={true}
          noReload={true}
          showSocialIcons={false}
          additionalClass="mt-0 h-25"
        />
      ) : (
        <></>
      )}
      <p className="ms-1">Logos:</p>

      <div className="d-flex overflow-auto my-2 images-container rounded px-2 py-2">
        {Images.map((i, index) => {
          return (
            <div>
              <img
                className="mx-2 rounded"
                // onClick={(e) => {
                //   selectImage(e);
                // }}
                onClick={() => handlePreviewTemplate(i, index)}
                style={{ width: "300px" }}
                src={i.filePath}
                alt={index}
              />
            </div>
          );
        })}
      </div>
      {totalPages && pageNumbers.length > 1 && (
        <div className="pagination">
          {currentPage !== 1 && totalPages > 5 && (
            <button onClick={handleDualPrevPage}>
              <FaAngleDoubleLeft className="arrow-icon left-arrow " />
            </button>
          )}

          {currentPage !== 1 && (
            <button onClick={handlePrevPage}>
              <FaAngleLeft className="arrow-icon left-arrow" />
            </button>
          )}

          {pageNumbers.map((page) => (
            <button
              key={page}
              disabled={currentPage === page}
              className={currentPage === page ? "active" : ""}
              onClick={() => {
                getNewTemplateList(page);
                adjustPages(page);
              }}
            >
              {page}
            </button>
          ))}

          {currentPage !== totalPages && (
            <button onClick={handleNextPage}>
              <FaAngleRight className="arrow-icon" />
            </button>
          )}

          {currentPage !== totalPages && totalPages > 5 && (
            <button onClick={handleDualNextPage}>
              <FaAngleDoubleRight className="arrow-icon" />
            </button>
          )}
        </div>
      )}
      <p className="ms-1">Cover Images:</p>
      <div className="d-flex overflow-auto my-2 images-container rounded px-2 py-2">
        {coverImages.map((i, index) => {
          return (
            <div>
              <img
                className="mx-2 rounded"
                // onClick={(e) => {
                //   selectImage(e);
                // }}
                onClick={() => handlePreviewCoverTemplate(i, index)}
                style={{ width: "300px" }}
                src={i.filePath}
                alt={index}
              />
            </div>
          );
        })}
      </div>
      {totalPages1 && pageNumbers1.length > 1 && (
        <div className="pagination pb-4">
          {currentPage1 !== 1 && totalPages1 > 5 && (
            <button onClick={handleDualPrevPage1}>
              <FaAngleDoubleLeft className="arrow-icon left-arrow " />
            </button>
          )}

          {currentPage1 !== 1 && (
            <button onClick={handlePrevPage1}>
              <FaAngleLeft className="arrow-icon left-arrow" />
            </button>
          )}

          {pageNumbers1.map((page) => (
            <button
              key={page}
              disabled={currentPage1 === page}
              className={currentPage1 === page ? "active" : ""}
              onClick={() => {
                getNewTemplateList1(page);
                adjustPages1(page);
              }}
            >
              {page}
            </button>
          ))}

          {currentPage1 !== totalPages1 && (
            <button onClick={handleNextPage1}>
              <FaAngleRight className="arrow-icon" />
            </button>
          )}

          {currentPage1 !== totalPages1 && totalPages1 > 5 && (
            <button onClick={handleDualNextPage1}>
              <FaAngleDoubleRight className="arrow-icon" />
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default BrandStarterKit;
