import axios from "../config/axios";

class ClanMasterService {
  getClanPoints = async (teamName) => {
    const response = await axios.get(`/clanScoreMaster?teamName=${teamName}&limit=150`);
    
    return response.data;
  };

  deleteClanPoint = async (id) => {
    const response = await axios.delete(`/clanScoreMaster/${id}`);
    return response.data;
  };

  updateClanPoints = async (id, score) => {
    const response = await axios.post(`/clanScoreMaster/${id}`, { score });
    
    return response.data;
  };
}

export default new ClanMasterService();
