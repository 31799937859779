import React, { useEffect, useRef, useState } from "react";
import GenerateBox from "../GenerateBox/GenerateBox";
import { useSearchParams } from "react-router-dom";
import axios from "../../config/axios";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Notification from "../Notification/Notification";
import licenceValidator from "../../permission/validate";
import { Toast } from "bootstrap";
import essService from "../../services/ess.service";
import { AiOutlineClose } from "react-icons/ai";
import GradientBorderButton from "../Widgets/GradientBorderButton";

const InterviewQAndA = () => {
  const [generateComponents, setGenerateComponents] = useState(false);
  const desRef = useRef();
  const difficultyRef = useRef();
  const modeRef = useRef();
  const [showAlert, setShowAlert] = useState(false);
  const [successNotific, setSuccessNotific] = useState("");
  const [errorNotific, setErrorNotific] = useState("");
  const remRef = useRef();
  const noOfQuestionsRef = useRef();
  const [success, setSuceess] = useState("");
  const [error, setError] = useState("");

  const [isVisible, setIsVisible] = useState(false);
  const [answer, setAnswer] = useState("");
  const [loader, setloader] = useState(false);
  const jdRef = useRef(null);
  const intents = [
    "Witty",
    "Humourous",
    "Creative",
    "Professional",
    "Formal",
    "Informal",
    "Casual",
    "CTA based",
    "Insperational",
    "Enthusiastic",
    "Informative",
    "Story based",
  ];

  const handleDesChange = (e) => {
    let jdInput = document.getElementById("jd_file")
    
    if(e.target.value.length){
     
      if(jdInput){
        jdInput.disabled = true;
      } 
    } else {
      if(jdInput){
        jdInput.disabled = false;
      } 

    }
  }
  const handleJDChange = (e) =>{
    let desInput = document.getElementById("designation")
    
    if(e.target.files.length){
     
      if(desInput){
        desInput.disabled = true;
      } 
    } else {
      if(desInput){
        desInput.disabled = false;
      } 

    }
  }
  useEffect(() => {
    let user = localStorage.getItem("user");
    let validator = new licenceValidator(user, "assist");
    setIsVisible(validator.isReadable("interview_q_and_a"));
  }, []);
  const handleReset = () =>{
    setloader(false);
    setGenerateComponents(false);
    setAnswer("");

  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setloader(true);
  
    try {

      if((jdRef.current.value || desRef.current.value) && /^[3-9]|[1-9]\d+$/.test(noOfQuestionsRef.current.value)){
        setGenerateComponents(false);
        let formData = new FormData()
        if(jdRef.current.value){
        formData.append("jd", jdRef.current.files[0])

        } else if(desRef.current.value){
        formData.append("designation", desRef.current.value)
        }
        formData.append("difficulty",  difficultyRef.current.value)
        formData.append("noOfQuestions", noOfQuestionsRef.current.value)
        if(remRef.current.value){
        formData.append("remarks",  remRef.current.value)

        }
        setError("");
        const res = await essService.interviewQandA(formData)
        
        setAnswer(res.answer);
        setGenerateComponents(true);
      } else {
        if((!noOfQuestionsRef.current.value ||  !/^[3-9]|[1-9]\d+$/.test(noOfQuestionsRef.current.value) && (!desRef.current.value || /^[a-zA-Z\s]*$/g.test(desRef.current.value)))){
          setErrorNotific("Number of questions must be greater than or equal to 3");
          setShowAlert(true)
          setSuccessNotific("")
          
          setTimeout(() => {
            setErrorNotific("")
            setShowAlert(false)
          }, 2000);
        }
        if(!jdRef.current.value && !desRef.current.value){
          setShowAlert(true)
          setSuccessNotific("")
          setErrorNotific("Please enter atleast one Designation or Job description")
          setTimeout(() => {
            setErrorNotific("")
            setShowAlert(false)
          }, 2000);

     
        }
        if(desRef.current.value && !/^[a-zA-Z\s]*$/g.test(desRef.current.value)){
          setShowAlert(true)
          setSuccessNotific("")
          setErrorNotific("Designation must be alphabets only")
          setTimeout(() => {
            setErrorNotific("")
            setShowAlert(false)
          }, 2000);
        }
      }
     
    } catch (err) {
      setShowAlert(true)
      setSuccessNotific("")
      setErrorNotific("Server Timeout: The server took too long to respond. Please try again later.")
      setTimeout(() => {
        setErrorNotific("")
        setShowAlert(false)
      }, 2000);
      
     
    }
    setloader(false);
  };
  let dashboardElement = document.querySelector(".dashboard");

  useEffect(()=>{
    
  
    if (dashboardElement && generateComponents) {
            // If yes, set height to viewport height
       
        } else {
       
    
        }
    },[generateComponents])
  return (
    <div className="recruiter-social py-4 px-3">
      {showAlert ? (
        <div className="alert-container">
          <div className="upper-section">
            <p style={{ marginBottom: "0px", marginTop: "5px" }}>ALOIS</p>
            <span  className="delete-btn" onClick={() => setShowAlert(!showAlert)}>
              <AiOutlineClose className="icon" style={{ fill: "black" }} />
            </span>
          </div>
          <hr
            style={{
              display: "block !important",
              height: "1px !important",
              border: "0 !important",
              borderTop: "1px solid gray !important",
            }}
          />
          <div className="lower-section pt-2">
            <p className="text-danger">{errorNotific}</p>
            <p className="text-success">{successNotific}</p>
          </div>
        </div>
      ) : null}
        <div>
        <div className="toasts-container position-absolute top-0 mt-3 mx-3">
          <div className="toast fade hide" data-autohide="true" id="toast-2">
            <div className="toast-header">
              <i className="far fa-bell text-muted me-2"></i>
              <strong className="me-auto">ALOIS</strong>
              <button
                type="button"
                id="toastclose_btn"
                className="btn-close"
                data-bs-dismiss="toast"
              ></button>
            </div>
            <div className="toast-body">
              {/* <p className="text-success">{success}</p> */}
              <p className="text-danger">{error}</p>
            </div>
          </div>
        </div>
      </div>
      <form >

        <div className="row align-items-center">
          <div className="col-xl-5">
            <div className="form-outline">
              <label className="form-label" htmlFor="form8Example1">
                Designation
              </label>
              <input
                ref={desRef}
                type="text"
                onChange={handleDesChange}
                id="designation"
                className="form-control"
                
              />
            </div>
          </div>
          <div className="col-xl-1 text-center gx-0 mt-4">
            <b className=" mt-5 fs-5 " style={{color:"#A4B3BA"}}>OR</b>
          </div>
          <div className="col-xl-6">
          <div className="form-outline">
              <label className="form-label" htmlFor="customFile">
                Upload job description / Resume
              </label>
              <input
                ref={jdRef}
                accept=".pdf"
                onChange={handleJDChange}
                type="file"
                className="form-control"
                id="jd_file"
              />
            </div>
          </div>
         
        </div>
        <div className="row">
          <div className="col">
            <div className="form-outline">
            <label className="form-label pt-3" htmlFor="form8Example1">
                Remarks{" "}
                <span className="fw-normal">
                  <em>(Optional)</em>
                </span>
              </label>
              <input
                ref={remRef}
                type="text"
                id="form8Example1"
                className="form-control"
                
              />
            </div>
          </div>
          {/* <div className="col">
            <div className="form-outline">
              <label className="form-label pt-3" htmlFor="form8Example2">
                Geography
              </label>
              <select
                ref={difficultyRef}
                id="form8Example2"
                className="form-select form-control"
                required
              >
                <option value="" selected disabled className="p-5">
                  Select an option
                </option>
                <option value="apac">APAC</option>
                <option value="IND">IND</option>
                <option value="usa">USA</option>
                <option value="uk">EU/UK</option>
              </select>
              <div className="arrow-down"></div>
            </div>
          </div> */}
        </div>
        <div className="row">
          {/* <div className="col">
                    <div className="form-outline">
                        <label className="form-label" htmlFor="form8Example1">Phone Number</label>
                        <input ref={phoneRef} type="text" id="form8Example1" className="form-control" />
                    </div>
                </div> */}
        </div>
        <div className="row d-flex align-items-center">
          <div className="col-xl-6">
            <div className="form-outline">
              <label className="form-label pt-3" htmlFor="form8Example1">
              Number of Questions
              </label>
              <input
                ref={noOfQuestionsRef}
                type="number"
                id="form8Example1"
                className="form-control"
                required
              />
            </div>
            
          </div>
           <div className="col-xl-6 mt-3">
                    <div className="form-outline">
                        <label className="form-label" htmlFor="form8Example2">Difficulty</label>
                        <div className="position-relative">
                            <select ref={difficultyRef} id="form8Example2" className="form-select form-control" required>
                            <option value="" selected disabled className='p-5'>Select an option</option>
                            <option value="low"   className='p-5'>Low</option>
                            <option value="medium"   className='p-5'>Medium</option>
                            <option value="high"   className='p-5'>High</option>
                            </select>
                            <div className="arrow-down"></div>
                        </div>
                    </div>
                </div>
          {/* <div className="col"> 
                    <div className="form-outline">
                        <label className="form-label" htmlFor="form8Example2">Company Name<span className='fw-normal'><em>(Optional)</em></span></label>
                        <input ref={companyRef} type="text" id="form8Example2" className="form-control" />
                    </div>
                </div> */}
        </div>
        {loader ? (
          <div className="mx-auto mt-3 p-1">
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            &nbsp; Loading...
          </div>
        ) : isVisible ? (
          <div className="d-flex justify-content-center w-100 mt-3">
          <GradientBorderButton clickHandler={handleSubmit} text={"Submit"} />
          </div>
        
        ) : (
          <></>
        )}

        {generateComponents && (
          <div>
            <GenerateBox
              text={answer}
              showJdIcons={true}
              showSocialIcons={false}
              fileName={desRef.current.value}
              handleSubmit={handleSubmit}
              handleReset={handleReset}
            />
            {/* <GenerateBox text={"Social media post with caption will be generated here"} showSocialIcons={true} showJdIcons={false}/> */}
          </div>
        )}
      </form>
      {/* <Notification success={success} error={error} /> */}
  
    </div>
  );
};

export default InterviewQAndA;
