import TokenService from "./token.service";
import axios from "../config/axios";

class BSTService {
  uploadKit = async (payload) => {
    const response = await axios.post(
      `/brandstarterkit/upload`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
        },
      }
    );

    return response;
  };


 

  deleteKit = async (id, payload) => {
    const response = await axios.delete(
      `/brandstarterkit/${id}`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
        },
      }
    );

    return response;
  };
  updateKit = async (id, payload) => {
    const response = await axios.post(
      `/brandstarterkit/${id}`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
        },
      }
    );

    return response;
  };
  getKits = async (pageNo, templatesPerPage, type, org) => {
    let url = `/brandstarterkit/?page=${pageNo}&limit=${templatesPerPage}`
    if(type){
      url+=`&type=${type}`
    }
    if(org){
      url+=`&companyName=${org}`
    }
    const response = await axios.get(
      url,
      {
        headers: {
          Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
        },
      }
    );

    return response;
  };

 

  



 
}

export default new BSTService();
