import React, { useEffect, useRef, useState } from "react";
import { FaUsersCog } from "react-icons/fa";
import { TbCameraPlus } from "react-icons/tb";
import masterDBService from "../../services/masterDB.service";
import minioService from "../../services/minio.service";
import userRegistrationService from "../../services/userRegistration.service";
import { AiOutlineClose } from "react-icons/ai";
import { MdEdit } from 'react-icons/md';
function UserProfile(){
    const [imagePreview, setImagePreview] = useState(null);
    const [firstName, setFirstName] = useState("");
    const fileInputRef = useRef(null);
    const [lastName, setLastName] = useState("");
    const [designation, setDesignation] = useState("");
    const [empId, setEmpId] = useState("");
    const [dob, setDob] = useState(""); // Date of birth
    const [gender, setGender] = useState("");
    const [bloodGroup, setBloodGroup] = useState("");
    const [nationality, setNationality] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [address, setAddress] = useState("");
    const [department, setDepartment] = useState("");
    const [shift, setShift] = useState("");
    const [shiftTimings, setShiftTimings] = useState("");
    const [doj, setDoj] = useState(""); // Date of Joining
    const [confirmationDate, setConfirmationDate] = useState("");
    const [employmentType, setEmploymentType] = useState("");
    const [email, setEmail] = useState("");
    const [reportTo,setReportTo] = useState("")
    const [userId, setUserId] = useState("")
    const [showAlert, setShowAlert] = useState(false);
    const [successNotific, setSuccessNotific] = useState("");
  const [errorNotific, setErrorNotific] = useState("");

useEffect(() => {
    let user = localStorage.getItem("user");
    if (user) {
        user = JSON.parse(user);
        setUserId(user.id)
        setImagePreview(user.profilePic);
        setFirstName(user.firstName);
        setLastName(user.lastName);
        setDesignation(user.designation);
        setEmpId(user.empId);
        setDob(user.dateOfBirth);
        setGender(user.gender);
        setBloodGroup(user.bloodGroup);
        setNationality(user.nationality);
        setPhoneNumber(user.phoneNumber);
        setAddress(user.address);
        try {
        async function getGetdepartments(){
            const result = await masterDBService.getAllDepartments();
            let departments = result.results
            let userDepartment = departments.find(department => department.id === user.department).department;
            let userShift = departments.find(department => department.id === user.department)?.geography.find(shift => shift.id === user.shift).geography;
            let userShiftTimings = departments.find(department => department.id === user.department)?.geography.find(shift => shift.id === user.shift).shiftTiming.find(shift => shift.id === user.shiftTiming).startTime + " - " + departments.find(department => department.id === user.department)?.geography.find(shift => shift.id === user.shift).shiftTiming.find(shift => shift.id === user.shiftTiming).endTime
            setShift(userShift ? userShift : "-");
        setShiftTimings(userShiftTimings ? userShiftTimings : "-");
            setDepartment(userDepartment ? userDepartment : "-");
        }
        getGetdepartments()
    } catch (err) {
        console.log(err)
    }

        
        setDoj(user.dateOfJoining);
        setConfirmationDate(user.confirmationDate);
        setEmploymentType(user.employmentType);
        setEmail(user.email);
        setReportTo(user.reportTo)
    }
}, []);
const handleImageChange = async (e) => {
    const file = e.target.files[0];
    const response = await minioService.UploadSingleFile(
        file,
        "user_photo"
    );
    let profilePicUrl = response.data.url ;
    let userData = {
        profilePic : profilePicUrl
    }
    const result = await userRegistrationService.updateUser(userId, userData);
    if (profilePicUrl) {
        setErrorNotific("")
        setSuccessNotific("Your profile photo has been updated successfully")
        setShowAlert(true)

        setTimeout(() => {
            setSuccessNotific("")
          setShowAlert(false)
        }, 2000);
        setImagePreview(profilePicUrl);
        let user = localStorage.getItem("user");
        if (user) {
            user = JSON.parse(user);
            user.profilePic = profilePicUrl;
            localStorage.setItem("user", JSON.stringify(user))
        }
    } else {
      setImagePreview(null);
    }
  };

  const handleEditClick = () => {
    fileInputRef.current.click();
  };
    return <div className="d-flex my-profile-container flex-column">
    {showAlert ? (
        <div className="alert-container">
          <div className="upper-section">
            <p style={{ marginBottom: "0px", marginTop: "5px" }}>ALOIS</p>
            <span  className="delete-btn" onClick={() => setShowAlert(!showAlert)}>
              <AiOutlineClose className="icon" style={{ fill: "black" }} />
            </span>
          </div>
          <hr
            style={{
              display: "block !important",
              height: "1px !important",
              border: "0 !important",
              borderTop: "1px solid gray !important",
            }}
          />
          <div className="lower-section py-2">
            <p className="text-danger">{errorNotific}</p>
            <p className="text-success">{successNotific}</p>
          </div>
        </div>
      ) : null}
        <div className="indicator-container ">
            <div className="d-flex text">
                <FaUsersCog className="icon mx-2" /> My Profile
            </div>
        </div>
        <div className="profile-outer mx-auto">
        <div className="profile-inner mt-3">
            <div className="profile-info-container">
                <div className="row">
                    <div className="col-md-3 profile-image-container d-flex flex-column">
                        <div className="profile-picture-upload">
            <label htmlFor="profilePicture">
              {imagePreview ? (
                <div className="preview" style={imagePreview ? { backgroundImage: `url(${imagePreview})`, backgroundSize: 'cover', backgroundPosition: 'top', backgroundRepeat: 'no-repeat' } : null}></div>
              ) : (
                <TbCameraPlus className="camera-icon" />
              )}
            </label>
            <input type="file" id="profilePicture"  ref={fileInputRef} className="profilePicture-input" onChange={handleImageChange} accept="image/*" />
          
          <MdEdit onClick={handleEditClick} className="edit-icon" />
            
                        </div>
                    <p className="mt-2">Tap on the circle to change the profile picture</p>
                    </div>
                    
                    <div className="col-md-9 info-details">
                            <div className="info-item" style={{marginBottom:"20px"}}>
                                <p className="profile-name">{firstName} {lastName}</p>
                                <p className="profile-designation">{designation ? designation : "NA"}</p>
                            </div>
                            <div className="info-item">
                                <p>
                                    <span className="label">Employee Id:</span>
                                    <span className="value">{empId}</span>
                                </p>
                            </div>
                            <div className="info-item">
                                <p>
                                    <span className="label">Email:</span>
                                    <span className="value">{email}</span>
                                </p>
                            </div>
                            <div className="info-item">
                                <p>
                                    <span className="label">Department:</span>
                                    <span className="value">{department}</span>
                                </p>
                            </div>
                            <div className="info-item">
                                <p>
                                    <span className="label">Report To:</span>
                                    <span className="value">{reportTo ? reportTo : "NA"}</span>
                                </p>
                            </div>
                        </div>
                        

                </div>
                <hr />
                <div className="row">
                    <p className="section-title">Personal Information</p>
                    <div className="personal-info">
                        <div className="row">
                            <div className="col-md-4">
                                <p className="label">Date of Birth</p>
                                <p className="value">{dob?dob:"NA"}</p>
                            </div>
                            <div className="col-md-4">
                                <p className="label">Gender</p>
                                <p className="value">{gender ? gender.charAt(0).toUpperCase() + gender.slice(1) : "NA"}</p>
                            </div>
                            <div className="col-md-4">
                                <p className="label">Blood Group</p>
                                <p className="value">{bloodGroup ? bloodGroup.charAt(0).toUpperCase() + bloodGroup.slice(1) : "NA"}</p>
                            </div>

                        </div>
                        <div className="row">
                        <div className="col-md-4">
                            <p className="label">Nationality</p>
                            <p className="value">{nationality ? nationality.charAt(0).toUpperCase() + nationality.slice(1) : "NA"}</p>
                        </div>
                        <div className="col-md-4">
                            <p className="label">Phone Number</p>
                            <p className="value">{phoneNumber ? phoneNumber : "NA"}</p>
                        </div>
                        <div className="col-md-4">
                            <p className="label">Address</p>
                            <p className="value">{address ? address.charAt(0).toUpperCase() + address.slice(1) : "NA"}</p>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <hr />
                    <p className="section-title">Employment Information</p>
                    <div className="personal-info">
                        <div className="row">
                            <div className="col-md-4">
                                <p className="label">Date of Joining</p>
                                <p className="value">{doj?doj:"NA"}</p>
                            </div>
                            <div className="col-md-4">
                                <p className="label">Confirmation Date</p>
                                <p className="value">{confirmationDate ? confirmationDate : "NA"}</p>
                            </div>
                            <div className="col-md-4">
                                <p className="label">Shift</p>
                                <p className="value">{shift ? shift : "NA"}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <p className="label">Shift Timing</p>
                                <p className="value">{shiftTimings?shiftTimings:"NA"}</p>
                            </div>
                            <div className="col-md-4">
                                <p className="label">Employment Type</p>
                                <p className="value">{employmentType ? employmentType : "NA"}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
  </div>
}

export default UserProfile;