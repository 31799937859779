import TokenService from "./token.service";
import axios from "../config/axios";
import alt_axios from "axios";

// dummy data for testing purpose.

class AloisFeed {
  getAllEvents = async (folderName = "",pageNo = 1) => {
    
    const accessToken = TokenService.getLocalAccessToken();
    folderName += "/";
    const response = await axios.get(
      `/feed?limit=5000&sortBy=createdAt:desc`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    
    return response.data;
  };
  getUnfilteresEvents = async (folderName = "") => {
    
    const accessToken = TokenService.getLocalAccessToken();
    folderName += "/";
    const response = await axios.get(
      `/feed/showALOISFeedStructure${folderName.toLowerCase()}?limit=1000`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    
    return response.data;
  };
  getAllEvents1 = async (folderName = "") => {
    
    const accessToken = TokenService.getLocalAccessToken();
    folderName += "/";
    const response = await axios.get(
      `/feed/showALOISFeedStructure${folderName.toLowerCase()}?limit=1000&sortBy=createdAt:desc`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    
    return response.data;
  };

  createALOISFeed = async (data) => {
    const response = await alt_axios.post(
      `https://${process.env.REACT_APP_API_URL}/feed/createALOISFeed`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
        },
      }
    );
    return response.data;
  };
  updateALOISFeed = async ( data) => {
    const response = await alt_axios.post(
      `https://${process.env.REACT_APP_API_URL}/feed/${data._id}`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
        },
      }
    );
    return response.data;
  };

  manageLike = async (data) => {
    const response = await alt_axios.post(
      `https://${process.env.REACT_APP_API_URL}/feed/like`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
        },
      }
    );
    return response.data;
  };

  deleteALOISFeed = async (id) => {
    const response = await alt_axios.delete(
      `https://${process.env.REACT_APP_API_URL}/feed/${id}`,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
        },
      }
    );
    return response.data;
  };
  updateFeedComments = async (feedId, comments) => {
    const response = await axios.post(
      "/feed/comment",
      {
        feedId,
        comments,
      },
      {
        headers: {
          Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
        },
      }
    );
    return response.data;
  };
 

}

export default new AloisFeed();
