import React, { useEffect, useRef, useState } from "react";
import { BsPlusCircle } from "react-icons/bs";
import { MdSnippetFolder } from "react-icons/md";
import { Button } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Switch from "../Widgets/Switch";
import {AiOutlineMinus, AiOutlinePlus} from "react-icons/ai";
import {BiSolidUniversalAccess} from "react-icons/bi";
import userRegistrationService from "../../services/userRegistration.service";
import { AiOutlineClose } from "react-icons/ai";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import GradientBorderButton from "../Widgets/GradientBorderButton";


function AccessManagementByRoles() {
  let groupRef = useRef();
  let desRef = useRef();
  const [userList, setUserList] = useState([]);
  const [selectedUserdata, setSelectedUserData] = useState({});
  const [updatedPermissions, setUpdatedPermissions] = useState([]);
  const [moduleVisibility, setModuleVisibility] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [successNotific, setSuccessNotific] = useState("");
  const [errorNotific, setErrorNotific] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null)

  const getUsers = async () => {
    const data = await userRegistrationService.getUsers1();
    let data1 = data.results.map((item)=> {
      item.value = item.email;
    item.label = item.email
    return item
  })
  
    setUserList(data1);
  }
  useEffect(()=>{
    getUserData()
  },[selectedUser])
  useEffect(() => {
    getUsers()
  }, []);
  const handlePlus = (moduleId) => {
    setModuleVisibility((prevVisibility) => ({
      ...prevVisibility,
      [moduleId]: !prevVisibility[moduleId]
    }));
  };

const getUserData = () => {
  setSelectedUserData(selectedUser)
  if(selectedUser){
    
    setUpdatedPermissions(selectedUser?.license)

  }
}

const updateUserDataRecursively = (data, moduleId, permissionId, permissionType, isChecked) => {
  return data.map((item) => {
    if (item._id === moduleId) {
      if (item.permissions) {
        const updatedPermissions = item.permissions.map((perm) => {
          if (perm._id === permissionId) {
            return {
              ...perm,
              [permissionType]: isChecked,
            };
          } else {
            return perm;
          }
        });
        return {
          ...item,
          permissions: updatedPermissions,
        };
      }
    }
    if (item.permissions) {
      return {
        ...item,
        permissions: updateUserDataRecursively(item.permissions, moduleId, permissionId, permissionType, isChecked),
      };
    }
    return item;
  });
};

const handleCheckboxChange = (moduleId, permissionId, permissionType, isChecked) => {
  setSelectedUserData((prevUserData) => {
    const updatedUserData = { ...prevUserData };
    updatedUserData.license = updateUserDataRecursively(
      updatedUserData.license,
      moduleId,
      permissionId,
      permissionType,
      isChecked
    );
    return updatedUserData;
  });
};
const saveChanges = async () => {
  try {
    
    const userId = selectedUserdata.id;
    const response = await userRegistrationService.updateUser(userId, {
      license: selectedUserdata.license,
    });
    setErrorNotific("");
    setSuccessNotific("Permissions updated Successfully!");
  } catch (error) {
    console.error(error);
    setErrorNotific("Permission Update Failed!");
    setSuccessNotific("");
  }
  setLoading(true);
    setTimeout(() => {
      setLoading(false)
      setShowAlert(true);
    }, 2000);
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
};
const updateUserDataRecursively1 =  (data, moduleId) => {
  
  return  data.map(  (item) => {
    if (item._id === moduleId) {
      let item1 = item
      item1.isAccessible = !item.isAccessible
      
      return item1
    }
    
    if (item.permissions?.some(permission => permission?.permissions && permission?._id == moduleId) ) {
      
      let newPermission =  updateUserDataRecursively1(item.permissions, moduleId)
      
      return {
        ...item,
        permissions: newPermission,
      };
    }
    return item;
  });
};

const handleSwitchChange =  (moduleId) => {
  setSelectedUserData( (prevUserData) => {
    const updatedUserData = { ...prevUserData };
    updatedUserData.license =  updateUserDataRecursively1(updatedUserData.license, moduleId);
    return updatedUserData;
  });
};

const renderChildren = (item, moduleId) => {
  
 return item.permissions?.map((permission, index) =>{
    if(permission.subModuleName ){
     
     return(
                              
                                 <tr  key={permission._id}>
                                 <td className="d-flex align-items-center justify-content-between submodules">
                                 {permission.subModuleName ? permission.subModuleName : permission.moduleName} 
                               </td>
                                     <td>
                                       <label className="custom-checkbox">
                                         <input
                                           className="form-check-input"
                                           type="checkbox"
                                           id="create"
                                           value=""
                                           checked={permission.create}
                                           onChange={(e) => handleCheckboxChange(moduleId, permission._id, 'create', e.target.checked)}
                                         />
   
                                         <span className="checkmark"></span>
                                       </label>
                                     </td>
                                     <td>
                                       <label className="custom-checkbox">
                                         <input
                                           className="form-check-input"
                                           type="checkbox"
                                           value=""
                                           checked={permission.read}
                                           onChange={(e) => handleCheckboxChange(moduleId, permission._id, 'read', e.target.checked)}

                                         />
                                         <span className="checkmark"></span>
                                       </label>
                                     </td>
                                     <td>
                                       <label className="custom-checkbox">
                                         <input
                                           className="form-check-input"
                                           type="checkbox"
                                           value=""
                                           checked={permission.update}    
                                           onChange={(e) => handleCheckboxChange(moduleId, permission._id, 'update', e.target.checked)}                                          
                                         />
                                         <span className="checkmark"></span>
                                       </label>
                                     </td>
                                     <td>
                                       <label className="custom-checkbox">
                                         <input
                                           className="form-check-input"
                                           type="checkbox"
                                           value=""
                                           checked={permission.delete}
                                           onChange={(e) => handleCheckboxChange(moduleId, permission._id, 'delete', e.target.checked)}

                                         />
                                         <span className="checkmark"></span>
                                       </label>
                                     </td>                                    
                               </tr>

            
         )}else if(permission.moduleName) {
          const moduleId = permission._id;
                      const isModuleVisible = moduleVisibility[moduleId] || false; 
          
          return (<><tr className="w-100" key={index}>
          <td style={{ color: "#175572" }} className="d-flex justify-content-between" colSpan="2">
            {permission.moduleName}
            <div className="d-flex">
           {isModuleVisible && permission.permissions? 
           <AiOutlineMinus className="plus-icon mx-2" onClick={() => handlePlus(moduleId)}/>
           :
           <AiOutlinePlus className="plus-icon mx-2" onClick={() => handlePlus(moduleId)} />}
           <label className="custom-checkbox">
            <input
            className="form-check-input ms-1"
            type="checkbox"
            id={permission.moduleName}
            value={permission.isAccessible}
            checked={permission.isAccessible}
            onChange={(e) => handleSwitchChange(moduleId)}
             />
              <span className="checkmark"></span>
             </label>
            </div>
          </td>
        </tr>
         {isModuleVisible && renderChildren(permission, moduleId)}</>)
         }})
}
  return (
    <div className="d-flex buzz">
      {showAlert ? (
        <div className="alert-container">
          <div className="upper-section">
            <p style={{ marginBottom: "0px", marginTop: "5px" }}>ALOIS</p>
            <span  className="delete-btn" onClick={() => setShowAlert(!showAlert)}>
              <AiOutlineClose className="icon" style={{ fill: "black" }} />
            </span>
          </div>
          <hr
            style={{
              display: "block !important",
              height: "1px !important",
              border: "0 !important",
              borderTop: "1px solid gray !important",
            }}
          />
          <div className="lower-section pt-2">
            <p className="text-danger">{errorNotific}</p>
            <p className="text-success">{successNotific}</p>
          </div>
        </div>
      ) : null}
      <div className="indicator-container " style={{ width: "fit-content" }}>
        <div>
          <BiSolidUniversalAccess className="icon" />
        </div>
        <div className="text">Access Management</div>
      </div>

      <div
        className="outer_div mx-auto d-flex justify-content-center"
        style={{ marginTop: "2rem", height: "fit-content" }}
      >
        <div
          className="access rounded-4 bg-white mt-3 d-flex flex-column justify-content-start"
          style={{ height: "700px", width: "99.8%" }}
        >
          <p
            className="ms-3 mt-3"
            style={{ fontSize: "1.3rem", color: "#a4b3ba" }}
          >
            Access Management
          </p>
          
          <div className="access-form">
            <form id="clanForm" />
            <div className="row mt-2 mx-2">
              <div className="col"> 
              <div
                  className="form-outline"
                  style={{ width: "100%", minWidth: "100%" }}
                >
                  <Select
                    className="h-50 mt-1"
                    styles={{ height: "30px" }}
                    options={userList}
                    isSearchable={true}
                    value={selectedUser}
                    onChange={setSelectedUser}
                    placeholder="All users"
                  />
                  <div className="arrow-down"></div>
                </div>
              </div>
              <div className="col">
              </div>
            </div>
            <div className="row mx-2 mt-3 w-100">
              <table className="table w-100">
                <thead>
                  <tr class="permission-headings">
                    <th
                      scope="col"
                      style={{ color: "#175572" }}
                      className="table-header"
                    >
                      Features
                    </th>
                    <th scope="col">Create</th>
                    <th scope="col">Read</th>
                    <th scope="col">Update</th>
                    <th scope="col">Delete</th>
                  </tr>
                </thead>
                <tbody className="w-100">
                  {selectedUserdata?.license ? (
                    selectedUserdata.license.map((item, index) => {
                      const moduleId = item._id;
                      const isModuleVisible = moduleVisibility[moduleId] || false; 
                      
                      return (
                        <>
                          <tr className="w-100" key={index}>
                            <td style={{ color: "#175572" }} className="d-flex justify-content-between" colSpan="2">
                              {item.moduleName}
                              <div className="d-flex">
                             {isModuleVisible && item.permissions? 
                             <AiOutlineMinus className="plus-icon mx-2" onClick={() => handlePlus(moduleId)}/>
                             :
                             <AiOutlinePlus className="plus-icon mx-2" onClick={() => handlePlus(moduleId)} />}
                             <label className="custom-checkbox">
                              <input
                              className="form-check-input ms-1"
                              type="checkbox"
                              id={item.moduleName}
                              value={item.isAccessible}
                              checked={item.isAccessible}
                              onChange={(e) => handleSwitchChange(moduleId)}
                               />
                                <span className="checkmark"></span>
                               </label>
                              </div>
                            </td>
                          </tr>
                        {isModuleVisible && renderChildren(item, moduleId)}
                        </>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>
            </div>
            <div className="w-100 d-flex justify-content-center my-3">

                             <GradientBorderButton text=
              {loading ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (<span>Save</span>)} clickHandler={saveChanges} />

              
            </div>
          </div>
        </div>
      </div>
     </div>
  );
}

export default AccessManagementByRoles;
