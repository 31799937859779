import React, { useEffect, useRef, useState } from "react";
import GenerateBox from "../GenerateBox/GenerateBox";
import { useSearchParams } from "react-router-dom";
import axios from "../../config/axios";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Notification from "../Notification/Notification";
import licenceValidator from "../../permission/validate";
import { AiOutlineClose } from "react-icons/ai";
import tokenService from "../../services/token.service";
import GradientBorderButton from "../Widgets/GradientBorderButton";

const JdGenerator = () => {
  const [generateComponents, setGenerateComponents] = useState(false);
  const desRef = useRef();
  const geoRef = useRef();
  const modeRef = useRef();
  const locRef = useRef();
  const limRef = useRef();
  const remRef = useRef();
  const [success, setSuceess] = useState("");
  const [error, setError] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [answer, setAnswer] = useState("");
  const [loader, setloader] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const companyRef = useRef();


  const [successNotific, setSuccessNotific] = useState("");
  const [errorNotific, setErrorNotific] = useState("");
  const intents = [
    "Witty",
    "Humourous",
    "Creative",
    "Professional",
    "Formal",
    "Informal",
    "Casual",
    "CTA based",
    "Insperational",
    "Enthusiastic",
    "Informative",
    "Story based",
  ];
  useEffect(() => {
    let user = localStorage.getItem("user");
    
    let validator = new licenceValidator(user, "assist");
    setIsVisible(validator.isReadable("jd_generator"));
  

    
  }, []);
  const handleReset = () =>{
    setloader(false);
    setGenerateComponents(false);
    setAnswer("");

  }
  const handleSubmit = async (e) => {
    const accessToken = tokenService.getLocalAccessToken();
    e.preventDefault();
    if((!limRef.current.value || /^(5[0-9]|[6-9][0-9]|[1-9][0-9]{2,})$/.test(limRef.current.value)) && /^[a-zA-Z\s]*$/g.test(desRef.current.value) && /^[a-zA-Z, ]*$/.test(locRef.current.value)){
    setloader(true);
    setGenerateComponents(false);
    try {
      setError("");
      const res = await axios.post("/utility/jobDescriptionMaker", {
        prompt: desRef.current.value,
        geography: geoRef.current.value,
        mode: modeRef.current.value,
        location: locRef.current.value,
        limit:limRef.current.value,
        remark: remRef.current.value,
        company: companyRef.current.value
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      let user = JSON.parse(localStorage.getItem("user"))
      if(companyRef.current.value){
      setAnswer(res.data.answer);
      } else if(user.email.includes("@aloissolutions.com")){
        setAnswer(res.data.answer + "\n \n " +"About ALOIS Solutions: \n ALOIS Solutions stands at the forefront of the Talent and Technology Solutions industry, characterized by our dynamic expansion. As dedicated partners to leading MSP programs like Magnit, Kelly, Tapfin, Allegis, and Guidant Group, we take great pride in consistently delivering excellence. Our global presence is strategically woven through a network of seven offices across the USA, Costa Rica, UK, Netherlands, Poland, India, and Australia. With expertise rooted in each region, our team has achieved remarkable success in servicing clients across more than 21 countries, cementing our reputation as an unwavering international ally you can trust without hesitation.")
      } else if(user.email.includes("@aloishealthcare.com")) {
        setAnswer(res.data.answer + "\n \n " + "About ALOIS Healthcare: \n At ALOIS Healthcare, we pride ourselves on exceptional patient care through our staffing solutions across the US. We're dedicated to building lasting relationships and a family-like atmosphere among our professionals and clients. Offering local, travel, and permanent positions, we cater to diverse healthcare needs. We see our professionals as dedicated warriors, ensuring they find ideal assignments with deserved compensation. Join us at ALOIS Healthcare, where we support and empower healthcare heroes at every turn.");
      }
      setGenerateComponents(true);
    } catch (err) {
      setError("Server Error!");
    }
    setloader(false);
  } else {
    if(limRef.current.value && !/^(5[0-9]|[6-9][0-9]|[1-9][0-9]{2,})$/.test(limRef.current.value)){
      setShowAlert(true)
      setErrorNotific("Limit must be greater than or equal to 50")
      setTimeout(() => {
        setErrorNotific("")
        setShowAlert(false)
      }, 2000);
    }
    if(!/^[a-zA-Z\s]*$/g.test(desRef.current.value)){
      setShowAlert(true)
      setErrorNotific("Designation must be alphabets only")
      setTimeout(() => {
        setErrorNotific("")
        setShowAlert(false)
      }, 2000);
    }
    if(!/^[a-zA-Z, ]*$/.test(locRef.current.value)){
      setShowAlert(true)
      setErrorNotific("Location must be alphabets only")
      setTimeout(() => {
        setErrorNotific("")
        setShowAlert(false)
      }, 2000);
    }
  }
  };
  let dashboardElement = document.querySelector(".dashboard");

  useEffect(()=>{
    
  
  if (dashboardElement && generateComponents) {
          // If yes, set height to viewport height
     
      } else {
     
  
      }
  },[generateComponents])
  return (
    <div className="recruiter-social py-4 px-3">
            {showAlert ? (
        <div className="alert-container">
          <div className="upper-section">
            <p style={{ marginBottom: "0px", marginTop: "5px" }}>ALOIS</p>
            <span  className="delete-btn" onClick={() => setShowAlert(!showAlert)}>
              <AiOutlineClose className="icon" style={{ fill: "black" }} />
            </span>
          </div>
          <hr
            style={{
              display: "block !important",
              height: "1px !important",
              border: "0 !important",
              borderTop: "1px solid gray !important",
            }}
          />
          <div className="lower-section pt-2">
            <p className="text-danger">{errorNotific}</p>
            <p className="text-success">{successNotific}</p>
          </div>
        </div>
      ) : null}
      <form>
        <div className="row">
          <div className="col">
            <div className="form-outline">
              <label className="form-label" htmlFor="form8Example1">
                Designation
              </label>
              <input
                ref={desRef}
                type="text" 
                id="form8Example1"
                className="form-control"
               
                required
              />
            </div>
          </div>
          <div className="col">
            <div className="form-outline">
              <label className="form-label" htmlFor="form8Example2">
                Mode of work
              </label>
              <select
                ref={modeRef}
                id="form8Example2"
                className="form-select"
                required
              >
                <option value=""  className="p-5">
                  Select an option
                </option>
                <option value="onsite">Onsite</option>
                <option value="remote">Remote</option>
                <option value="hybrid">Hybrid</option>
              </select>
              <div className="arrow-down"></div>
            </div>
          </div>
          {/* <div className="col">
                    <div className="form-outline">
                        <label className="form-label" htmlFor="form8Example2">Intent Of The Post</label>
                        <div className="position-relative">
                            <select ref={geoRef} id="form8Example2" className="form-select form-control">
                            <option value="" selected disabled className='p-5'>Select an option</option>
                            {
                                intents.map((intent, index) => {
                                    return <option key={index} value={intent}>{intent}</option>
                                })
                            }
                            </select>
                            <div className="arrow-down"></div>
                        </div>
                    </div>
                </div> */}
        </div>
        <div className="row">
          <div className="col">
            <div className="form-outline">
              <label className="form-label pt-3" htmlFor="form8Example1">
                Location
              </label>
              <input
                ref={locRef}
                type="text"
                id="form8Example1"
                className="form-control"
                // pattern="/^[A-Za-z]+$/"

                required
              />
            </div>
          </div>
          <div className="col">
            <div className="form-outline">
              <label className="form-label pt-3" htmlFor="form8Example2">
                Geography
              </label>
              <select
                ref={geoRef}
                id="form8Example2"
                className="form-select form-control"
                required
              >
                <option value="" selected disabled className="p-5">
                  Select an option
                </option>
                <option value="apac">APAC</option>
                <option value="IND">IND</option>
                <option value="usa">USA</option>
                <option value="uk">EU/UK</option>
              </select>
              <div className="arrow-down"></div>
            </div>
          </div>
        </div>
        <div className="row">
          
          <div className="col-6">
            <div className="form-outline">
              <label className="form-label pt-3" htmlFor="form8Example1">
              Word limit{" "}
                <span className="fw-normal">
                  <em>(Optional)</em>
                </span>
              </label>
              <input

                ref={limRef}
                type="number"
                id="form8Example1"
                className="form-control"
                pattern="/^\d+$/"
              />
            </div>
          </div>
          <div className="col">
            <div className="form-outline">
              <label className="form-label pt-3" htmlFor="form8Example1">
                Company Name{" "}
                <span className="fw-normal">
                  <em>(Optional)</em>
                </span>
              </label>
              <input
                ref={companyRef}
                type="text"
                id="form8Example1"
                className="form-control"
              />
            </div>
          </div>
        </div>
        <div className="row">
          {/* <div className="col">
                    <div className="form-outline">
                        <label className="form-label" htmlFor="form8Example1">Phone Number</label>
                        <input ref={phoneRef} type="text" id="form8Example1" className="form-control" />
                    </div>
                </div> */}
        </div>
        <div className="row">
          <div className="col">
            <div className="form-outline">
              <label className="form-label pt-3" htmlFor="form8Example1">
                Remark{" "}
                <span className="fw-normal">
                  <em>(Optional)</em>
                </span>
              </label>
              <input
                ref={remRef}
                type="text"
                id="form8Example1"
                className="form-control"
              />
            </div>
          </div>
          {/* <div className="col">
                    <div className="form-outline">
                        <label className="form-label" htmlFor="form8Example2">Company Name<span className='fw-normal'><em>(Optional)</em></span></label>
                        <input ref={companyRef} type="text" id="form8Example2" className="form-control" />
                    </div>
                </div> */}
        </div>
        {loader ? (
          <div className="mx-auto mt-3 p-1">
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            &nbsp; Loading...
          </div>
        ) : isVisible ? (
          <div className="d-flex justify-content-center w-100 mt-3">
          <GradientBorderButton clickHandler={handleSubmit} text={"Submit"} />
          </div>
        
        ) : (
          <></>
        )}

        {generateComponents && (
          <div>
            <GenerateBox
              text={answer}
              showJdIcons={true}
              showSocialIcons={false}
              fileName={desRef.current.value}
              handleSubmit={handleSubmit}
              handleReset={handleReset}
            />
            {/* <GenerateBox text={"Social media post with caption will be generated here"} showSocialIcons={true} showJdIcons={false}/> */}
          </div>
        )}
      </form>
      <Notification success={success} error={error} />
    </div>
  );
};

export default JdGenerator;
