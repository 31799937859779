import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { AiOutlineClose } from "react-icons/ai";
import { FaGreaterThan, FaLessThan, FaSearch } from "react-icons/fa";
import { MdAdminPanelSettings } from "react-icons/md";
import licenceValidator from "../../permission/validate";
import mailerService from "../../services/mailer.service";
import GradientBorderButton from "../Widgets/GradientBorderButton";

function PreviousMail() {
  const visiblePages = 5;

  // Email listing and search
  const [mails, setMails] = useState([]);
  const [searchTxt, setSearchTxt] = useState("");
  const [activeSection, setActiveSection] = useState("Scheduled");
  const [searched, setSearched] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  // Pop up hooks
  const [users, setUsers] = useState([]);
  const [showUsers, setShowUsers] = useState(false);

  // Alert hooks
  const [showAlert, setShowAlert] = useState(false);
  const [successNotific, setSuccessNotific] = useState("");
  const [errorNotific, setErrorNotific] = useState("");

  // Pagination hooks
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [hideRightArrow, sethideRideArrow] = useState(false);
  const [hideLeftArrow, sethideLeftArrow] = useState(true);
  const [remails, setReMails] = useState({});

  // Permission hooks
  const [isvisible, setIsVisible] = useState(false);
  const [isdeletable, setIsDeletable] = useState(false);

  useEffect(() => {
    let user = localStorage.getItem("user");
    let validator = new licenceValidator(user, "emailers");
    setIsVisible(validator.isReadable("previous_mail"));
    setIsDeletable(validator.isDelete("previous_mail"));
  }, []);

  // Search email by reciepient details
  const handleSearch = async () => {
    const result = await mailerService.getMailers(
      activeSection,
      selectedDate,
      searchTxt
    );

    setSearched(true);

    if (result.totalResults < 12) {
      setPageNumbers([1]);
      sethideRideArrow(true);
    } else {
      let tempTotalPages = Math.max(1, Math.ceil(result.totalResults / 12));
      setTotalPages(tempTotalPages);

      if (tempTotalPages <= 1) {
        sethideRideArrow(true);
      } else {
        sethideRideArrow(false);
      }

      if (tempTotalPages > 5) {
        tempTotalPages = 5;
      }

      let tempPageArr = [];
      for (let i = 1; i <= tempTotalPages; i++) {
        tempPageArr.push(i);
      }
      setPageNumbers(tempPageArr);
    }

    setCurrentPage(1);
    sethideLeftArrow(true);
    setMails(result.results);
  };

  // Clear search inputs
  const handleClear = async () => {
    setSearchTxt("");
    setSelectedDate("");
    setSearched(false);
    getMailers(activeSection);
  };

  // Adjust pagination buttons
  const adjustPages = () => {
    let tempStart = 1;
    let tempEnd = Math.min(5, totalPages);

    if (currentPage > 3) {
      tempStart = Math.max(1, currentPage - Math.floor(visiblePages / 2));
      tempEnd = Math.min(tempStart + visiblePages - 1, totalPages);
    }

    let tempPageNumbers = Array.from(
      { length: tempEnd - tempStart + 1 },
      (_, index) => tempStart + index
    );

    if (tempPageNumbers[0] !== 0) {
      setPageNumbers(tempPageNumbers);
    }
  };

  useEffect(() => {
    adjustPages();
  }, [currentPage]);

  // Change pages data on page change
  const paginate = async (currPage) => {
    let result;
    if (searched) {
      result = await mailerService.getMailers(
        activeSection,
        selectedDate,
        searchTxt,
        currPage
      );
    } else {
      result = await mailerService.getMailers(activeSection, "", "", currPage);
    }
    setMails(result.results);

    setCurrentPage(currPage);

    if (currPage == totalPages) {
      sethideRideArrow(true);
    } else {
      sethideRideArrow(false);
    }

    if (currPage == 1) {
      sethideLeftArrow(true);
    } else {
      sethideLeftArrow(false);
    }
  };

  // Next button
  const handleNextPage = async () => {
    if (currentPage + 1 == totalPages) {
      sethideRideArrow(true);
    } else {
      sethideRideArrow(false);
    }

    if (currentPage + 1 == 1) {
      sethideLeftArrow(true);
    } else {
      sethideLeftArrow(false);
    }

    if (currentPage < pageNumbers[pageNumbers.length - 1]) {
      let result;
      if (searched) {
        result = await mailerService.getMailers(
          activeSection,
          selectedDate,
          searchTxt,
          currentPage + 1
        );
      } else {
        result = await mailerService.getMailers(
          activeSection,
          "",
          "",
          currentPage + 1
        );
      }

      setMails(result.results);
      setCurrentPage(currentPage + 1);
    } else if (totalPages > pageNumbers[4]) {
      let tempPageArr = [];
      const endPage = Math.min(totalPages, pageNumbers[4] + 4);
      for (let page = pageNumbers[4] + 1; page <= endPage; page++) {
        tempPageArr.push(page);
      }

      let result;
      if (searched) {
        result = await mailerService.getMailers(
          activeSection,
          selectedDate,
          searchTxt,
          currentPage + 1
        );
      } else {
        result = await mailerService.getMailers(
          activeSection,
          "",
          "",
          currentPage + 1
        );
      }
      setMails(result.results);

      setCurrentPage(currentPage + 1);
      setPageNumbers(tempPageArr);
    }
  };

  // Prev button
  const handlePrevPage = async () => {
    if (currentPage - 1 == totalPages) {
      sethideRideArrow(true);
    } else {
      sethideRideArrow(false);
    }

    if (currentPage - 1 == 1) {
      sethideLeftArrow(true);
    } else {
      sethideLeftArrow(false);
    }

    if (currentPage > pageNumbers[0]) {
      let result;
      if (searched) {
        result = await mailerService.getMailers(
          activeSection,
          selectedDate,
          searchTxt,
          currentPage - 1
        );
      } else {
        result = await mailerService.getMailers(
          activeSection,
          "",
          "",
          currentPage - 1
        );
      }
      setMails(result.results);

      setCurrentPage(currentPage - 1);
    } else if (currentPage > 1) {
      let tempPageArr = [];
      for (let page = pageNumbers[0] - 5; page <= pageNumbers[0] - 1; page++) {
        tempPageArr.push(page);
      }

      let result;
      if (searched) {
        result = await mailerService.getMailers(
          activeSection,
          selectedDate,
          searchTxt,
          currentPage - 1
        );
      } else {
        result = await mailerService.getMailers(
          activeSection,
          "",
          "",
          currentPage - 1
        );
      }
      setMails(result.results);

      setCurrentPage(currentPage - 1);
      setPageNumbers(tempPageArr);
    }
  };

  // Recipients popup
  const handleShowUsers = (users, mails) => {
    setUsers(users);
    setReMails(mails);
    setShowUsers(true);
  };

  // Get mailer in section change
  useEffect(() => {
    getMailers(activeSection);
    setSearchTxt("");
    setSelectedDate("");
  }, [activeSection]);

  // Get mailers
  const getMailers = async (type) => {
    const result = await mailerService.getMailers(type);

    if (result.totalResults < 12) {
      setPageNumbers([1]);
      sethideRideArrow(true);
    } else {
      let tempTotalPages = Math.max(1, Math.ceil(result.totalResults / 12));
      setTotalPages(tempTotalPages);

      if (tempTotalPages <= 1) {
        sethideRideArrow(true);
      } else {
        sethideRideArrow(false);
      }

      if (tempTotalPages > 5) {
        tempTotalPages = 5;
      }

      let tempPageArr = [];
      for (let i = 1; i <= tempTotalPages; i++) {
        tempPageArr.push(i);
      }
      setPageNumbers(tempPageArr);
    }

    setCurrentPage(1);
    sethideLeftArrow(true);
    setMails(result.results);
  };

  // Change section
  const changeSection = (section) => {
    setActiveSection(section);
  };

  // Delete Email
  const handleDeleteMail = async (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to unschedule this mail?"
    );
    if (confirmed) {
      let response = await mailerService.deleteMailer(id);
      setShowAlert(true);
      setErrorNotific("Mail unscheduled!");

      setSuccessNotific("");
      setTimeout(() => {
        setErrorNotific("");
        setShowAlert(false);
      }, 2000);
    }
    await getMailers(activeSection);
  };

  let usersNotReceivedMailCount = users.filter(
    (user) => !user.isMailSent
  ).length;

  const handleSentAgain = async () => {
    let resendMail = JSON.stringify(users.filter((user) => !user.isMailSent));
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();

    // Format the current time as "hh:mm"
    const currentTime = `${String(hours).padStart(2, "0")}:${String(
      minutes
    ).padStart(2, "0")}`;

    let data = {
      ccMail: remails.ccMail,
      bccMail: remails.bccMail,
      department: remails.department,
      users: resendMail,
      message: remails.message,
      time: currentTime,
      date: new Date(),
      subject: remails.subject,
      mailType: "Sent",
    };

    const response = await mailerService.createMailer(data);
    if (response) {
      setShowAlert(true);
      setErrorNotific();
      setSuccessNotific("Mail Sent Successfully");
      setTimeout(() => {
        setSuccessNotific("");
        setShowAlert(false);
      }, 2000);
      return;
    }
  };
  useEffect(() => {
    let dashboardElement = document.querySelector(".dashboard");
    const body = document.body;

    if (showUsers && dashboardElement) {
      // Remove the 'transform' property
      // Remove the 'transform-origin' property
      // dashboardElement.style.transformOrigin = '';
    } else if (!showUsers && dashboardElement) {
    }
  }, [showUsers]);
  return (
    <div className="d-flex previous-mail-container flex-column">
      <div className="indicator-container ">
        <div className="d-flex text">
          <MdAdminPanelSettings className="icon" />
          Previous Emails
        </div>
      </div>
      {showAlert && (
        <div className="alert-container">
          <div className="upper-section">
            <p style={{ marginBottom: "0px", marginTop: "5px" }}>ALOIS</p>

            <span
              className="delete-btn"
              onClick={() => setShowAlert(!showAlert)}
            >
              <AiOutlineClose className="icon" style={{ fill: "black" }} />
            </span>
          </div>

          <hr
            style={{
              display: "block !important",
              height: "1px !important",
              border: "0 !important",
              borderTop: "1px solid gray !important",
            }}
          />

          <div className="lower-section py-2">
            <p className="text-danger">{errorNotific}</p>
            <p className="text-success">{successNotific}</p>
          </div>
        </div>
      )}

      {showUsers && (
        <div className="previous-mail-popup-container">
          <div className="previous-mail-popup">
            <div className="previous-mail-popup-header">
              <span className="fs-6 fw-bold">Email Details</span>

              <button
                className="close-button"
                style={{ width: "20px" }}
                onClick={() => {
                  setShowUsers(false);
                  setUsers([]);
                }}
              >
                &#10005;
              </button>
            </div>

            <hr style={{ borderTop: "2px dashed black" }} />

            {users.length ? (
              <>
                <div>
                  <label className="form-label">
                    Recipients ({users.length}):
                  </label>

                  <div className="row mx-2 py-2 align-items-center border border-1 recipients-box">
                    <div className="col-xl-12">
                      {users.map((user, index) => (
                        <button
                          type="button"
                          className="button email-btn"
                          value={user}
                          key={index}
                        >
                          {user.email}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="mt-3">
                  <label className="form-label">Email Subject:</label>

                  <div className="row mx-2 px-3 py-2 align-items-center border border-1 recipients-box">
                    {remails.subject}
                  </div>
                </div>

                <div className="mt-3">
                  <label className="form-label">Email Body:</label>

                  <div
                    className="row mx-2 px-3 py-2 align-items-center border border-1 recipients-box"
                    dangerouslySetInnerHTML={{ __html: remails.message }}
                  />
                </div>

                <div>
                  <label className="form-label mt-3">
                    Delivered ({users.length - usersNotReceivedMailCount}):
                  </label>

                  <div className="row mx-2 py-2 align-items-center border border-1 recipients-box">
                    <div className="col-xl-12">
                      {users.length - usersNotReceivedMailCount ? (
                        users.map(
                          (user, index) =>
                            user.isMailSent && (
                              <button
                                type="button"
                                className="button"
                                value={user}
                                key={index}
                              >
                                {user.email}
                              </button>
                            )
                        )
                      ) : (
                        <p className="d-flex justify-content-center m-3">
                          No Recipient Found
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="mt-3">
                  <div className="draft-box py-2">
                    <label className="form-label2">
                      {activeSection === "Scheduled" ? (
                        <p>Draft ({usersNotReceivedMailCount}):</p>
                      ) : (
                        <p>Not Delivered ({usersNotReceivedMailCount}):</p>
                      )}
                    </label>

                    {users.some((user) => !user.isMailSent) && isvisible && (
                      <div>
                        <GradientBorderButton
                          text={"Send Again"}
                          clickHandler={handleSentAgain}
                        />
                      </div>
                    )}
                  </div>

                  <div className="row mx-2 p-2 align-items-center border border-1 recipients-box">
                    <div className="col-xl-12">
                      {usersNotReceivedMailCount ? (
                        users.map(
                          (user, index) =>
                            !user.isMailSent && (
                              <button
                                type="button"
                                className="button"
                                value={user}
                                key={index}
                              >
                                {user.email}
                              </button>
                            )
                        )
                      ) : (
                        <p className="d-flex justify-content-center m-3">
                          No Recipient Found
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="d-flex align-items-center justify-content-center">
                <p className="m-3">No Details Found</p>
              </div>
            )}
          </div>
        </div>
      )}

      <div
        className="previous-mail-outer mx-auto"
        style={{ marginTop: "1rem" }}
      >
        <div
          className="previous-mail-inner mt-3"
          style={{ minHeight: "500px", height: "100%" }}
        >
          <div className="upper-section row">
            <div className="col-xl-6">
              <p style={{ fontSize: "1.3rem", color: "#a4b3ba" }}>
                {activeSection == "Scheduled"
                  ? "Scheduled Emails"
                  : "Sent Emails"}
              </p>
            </div>
          </div>

          <div className="row justify-content-center  align-items-center pb-0">
            <div
              className="col-2 p-0 mx-1 h-50 px-1"
              style={{ marginRight: "20px", width: "fit-content" }}
            >
              <DatePicker
                type="date"
                id="dateInput"
                selected={selectedDate}
                onChange={setSelectedDate}
                placeholderText="Select a date"
              />
            </div>

            <div className="col-3 mx-1 p-0 h-50 w-auto">
              <div className="input-group">
                <button
                  className="btn btn-outline-secondary search-btn"
                  type="button"
                  id="button-addon1"
                >
                  <FaSearch className="mb-1" />
                </button>

                <input
                  style={{ borderRadius: "0 7px 7px 0" }}
                  type="text"
                  value={searchTxt}
                  onChange={(e) => {
                    setSearchTxt(e.target.value);
                  }}
                />
              </div>
            </div>

            <div
              className="col-2 p-0 mx-1 mt-1 align-items-center"
              style={{
                width: "fit-content",
                height: "fit-content",
                marginTop: "-10px",
              }}
            >
              <GradientBorderButton
                text={"Search"}
                clickHandler={handleSearch}
              />
            </div>

            <div
              className="col-2 p-0 mx-1 mt-1 align-items-center"
              style={{
                width: "fit-content",
                height: "fit-content",
                marginTop: "-10px",
              }}
            >
              <GradientBorderButton text={"Clear"} clickHandler={handleClear} />
            </div>
          </div>

          <div
            className="row d-flex justify-content-center my-3"
            style={{ marginLeft: "5px" }}
          >
            <button
              className={`col-6 filter-btn  ${
                activeSection == "Scheduled" ? "active-btn" : ""
              }`}
              style={{ borderRadius: "7px 0 0 7px" }}
              onClick={() => changeSection("Scheduled")}
            >
              Scheduled
            </button>

            <button
              className={`col-6 filter-btn ${
                activeSection == "Sent" ? "active-btn" : ""
              }`}
              style={{ borderRadius: "0 7px 7px 0" }}
              onClick={() => changeSection("Sent")}
            >
              Sent
            </button>
          </div>

          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th key="recipients">recipients</th>
                  <th key="subject">subject</th>
                  <th key="date">date</th>
                  <th key="time">time</th>

                  {activeSection == "Sent" ? (
                    <th key="status">status</th>
                  ) : (
                    <th key="status"></th>
                  )}
                </tr>
              </thead>

              <tbody>
                {mails.map((mail, index) => {
                  return (
                    <tr key={mail.id}>
                      <td>
                        <button
                          style={{
                            width: "fit-content",
                            backgroundColor: "$fig-5",
                            padding: "$spacer*0.5 $spacer",
                            borderRadius: "$border-radius-lg",
                            margin: " $spacer*0.1",
                          }}
                          onClick={() => {
                            handleShowUsers(mail.users, mail);
                          }}
                          type="button"
                          className="button"
                        >
                          {mail?.users[0]?.email}
                        </button>
                      </td>

                      <td>{mail.subject}</td>

                      <td>
                        {(() => {
                          const inputDate = mail.date;
                          if (inputDate) {
                            const date = new Date(inputDate);
                            const year = date.getFullYear();
                            const month = String(date.getMonth() + 1).padStart(
                              2,
                              "0"
                            );
                            const day = String(date.getDate()).padStart(2, "0");
                            const formattedDate = `${day}/${month}/${year}`;
                            return formattedDate;
                          } else {
                            return "N/A"; // or any default value for empty dates
                          }
                        })()}
                      </td>

                      <td>{mail.time}</td>

                      {activeSection == "Scheduled" && isdeletable ? (
                        <td>
                          <GradientBorderButton
                            text="Unschedule"
                            clickHandler={() => handleDeleteMail(mail.id)}
                          />
                        </td>
                      ) : activeSection == "Sent" ? (
                        mail.users.some((user) => !user.isMailSent) ? (
                          <td>Not Delivered</td>
                        ) : (
                          <td>Delivered</td>
                        )
                      ) : (
                        <></>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="pagination my-4">
            {!hideLeftArrow && (
              <button
                onClick={handlePrevPage}
                disabled={currentPage === 1}
                className={currentPage === 1 ? "active" : ""}
              >
                <FaLessThan
                  className="arrow"
                  style={{
                    marginBottom: "4px",
                    marginRight: "2px",
                    padding: "1px",
                  }}
                />
              </button>
            )}

            {pageNumbers.map((page) => (
              <button
                key={page}
                disabled={currentPage === page}
                className={currentPage === page ? "active" : ""}
                onClick={() => {
                  paginate(page);
                }}
              >
                {page}
              </button>
            ))}

            {!hideRightArrow && (
              <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
                className={currentPage === totalPages ? "active" : ""}
              >
                <FaGreaterThan
                  className="arrow"
                  style={{
                    marginBottom: "4px",
                    marginLeft: "2px",
                    padding: "1px",
                  }}
                />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PreviousMail;
